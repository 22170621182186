import React, { FunctionComponent, useEffect, useState } from 'react';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import slugify from 'slugify';
import { FlightButton, FlightTextArea, FlightTextInput, getIcon } from '@flybits/design-system';
import { useActions } from 'actions';
import * as ManageTemplatesActions from 'actions/manageTemplates';
import { CategoriesEntity } from 'model/manageTemplates';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import './CustomCategoriesContent.scss';
import { cloneDeep } from 'lodash';

const CustomCategoriesContent: FunctionComponent = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [parentCategoryKey, setParentCategoryKey] = useState<string>();
  const [selectedCategoryKey, setSelectedCategoryKey] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<CategoriesEntity>();
  const [defaultSelectedImage, setDefaultSelectedImage] = useState<string>();
  const importLibraryCategories = useSelector((state: RootState) => state.manageTemplates?.importLibraryCategories);
  const manageTemplatesActions = useActions(ManageTemplatesActions);
  const action = !!selectedCategory?.key ? 'update' : 'create';
  const type = !!parentCategoryKey ? 'subcategory' : 'category';

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;

    if (name === 'name' && value !== '') setErrorMessage('');

    setSelectedCategory(
      oldState =>
        ({
          ...oldState,
          [name]: value,
        } as CategoriesEntity),
    );
  };

  const handleImageUploadSuccess = (url?: string) => {
    setSelectedCategory(
      oldState =>
        ({
          ...oldState,
          img: url,
        } as CategoriesEntity),
    );
  };

  const validate = (category: CategoriesEntity): boolean => {
    if (!category.name) {
      setErrorMessage('The category name is required');
      return false;
    }

    const regex = /^[a-zA-Z0-9 ()-]+$/;
    if (!regex.test(category.name)) {
      setErrorMessage('Accepted characters are: a-z, 0-9, "-", "(" and ")"');
      return false;
    }

    if (!parentCategoryKey) {
      const catIndex = importLibraryCategories?.settings?.categories?.findIndex(
        c =>
          (category.key === undefined && c.name === category.name.trim()) ||
          (c.name === category.name.trim() && c.key !== category.key),
      );
      if (catIndex !== undefined && catIndex >= 0) {
        setErrorMessage('This category name is already in use');
        return false;
      }
    }

    if (parentCategoryKey) {
      const parentCategory = importLibraryCategories?.settings?.categories?.find(c => c.key === parentCategoryKey);
      const subcatIndex = parentCategory?.subcategories?.findIndex(
        sc =>
          (category.key === undefined && sc.name === category.name.trim()) ||
          (sc.name === category.name.trim() && sc.key !== category.key),
      );

      if (subcatIndex !== undefined && subcatIndex >= 0) {
        setErrorMessage('This subcategory name is already in use');
        return false;
      }
    }

    return true;
  };

  const handleFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!selectedCategory || !validate(selectedCategory)) return;
    setIsBusy(true);
    const categories = cloneDeep(importLibraryCategories.settings.categories) as CategoriesEntity[];

    if (action === 'create') {
      selectedCategory.key = slugify(selectedCategory.name, { lower: true, trim: false });
      if (parentCategoryKey) {
        const parentCategory = categories.find(c => c.key === parentCategoryKey);
        if (parentCategory && !parentCategory?.subcategories) parentCategory.subcategories = [];
        parentCategory?.subcategories?.push(selectedCategory);
      } else {
        categories.push(selectedCategory);
      }
    } else {
      if (parentCategoryKey) {
        const parentCategory = categories.find(c => c.key === parentCategoryKey);
        const categoryIndex = parentCategory?.subcategories?.findIndex(cat => cat.key === selectedCategory.key);
        selectedCategory.key = selectedCategory.key = slugify(selectedCategory.name, { lower: true, trim: false });
        parentCategory?.subcategories?.splice(categoryIndex as number, 1, selectedCategory);
      } else {
        const categoryIndex = categories.findIndex(cat => cat.key === selectedCategory.key);
        selectedCategory.key = selectedCategory.key = slugify(selectedCategory.name, { lower: true, trim: false });
        categories.splice(categoryIndex, 1, selectedCategory);
      }
    }

    const newImportLibraryCategories = {
      ...importLibraryCategories,
      settings: {
        ...importLibraryCategories.settings,
        categories,
      },
    };

    await manageTemplatesActions.updateTemplateCategories(newImportLibraryCategories, () =>
      setSelectedCategoryKey(selectedCategory.key),
    );
    setIsBusy(false);
  };

  useEffect(() => {
    let cat: CategoriesEntity;
    if (parentCategoryKey) {
      const parentCategory = importLibraryCategories?.settings?.categories?.find(c => c.key === parentCategoryKey);
      cat = parentCategory?.subcategories?.find(c => c.key === selectedCategoryKey) as CategoriesEntity;
    } else {
      cat = {
        ...importLibraryCategories?.settings?.categories?.find(c => c.key === selectedCategoryKey),
      } as CategoriesEntity;
    }
    setErrorMessage('');
    setSelectedCategory(cat || {});
    setDefaultSelectedImage(cat?.img);
  }, [parentCategoryKey, selectedCategoryKey, importLibraryCategories]);

  if (!importLibraryCategories) return null;

  return (
    <div className="custom-categories-content">
      <div className="custom-categories-content__header">
        <h2>Custom categories</h2>
        <h2>{action === 'update' ? selectedCategory?.name : `New ${type}`}</h2>
      </div>
      <div className="custom-categories-content__middle">
        <ul className="custom-categories-content__category-list">
          <li>
            <button
              className={`custom-categories-content__category-list__button${
                action === 'create' && !parentCategoryKey
                  ? ' custom-categories-content__category-list__button--is-selected'
                  : ''
              }`}
              type="button"
              onClick={() => {
                setSelectedCategoryKey('create-new');
                setParentCategoryKey(undefined);
              }}
            >
              {getIcon('addTag', {})} Add category
            </button>
          </li>
          {importLibraryCategories.settings.categories
            ?.filter(c => c.key !== 'all')
            ?.map(c => (
              <li key={c.key}>
                <button
                  className={`custom-categories-content__category-list__button${
                    !parentCategoryKey && selectedCategoryKey === c.key
                      ? ' custom-categories-content__category-list__button--is-selected'
                      : ''
                  }`}
                  type="button"
                  onClick={() => {
                    setSelectedCategoryKey(c.key);
                    setParentCategoryKey(undefined);
                  }}
                >
                  {c.name}
                </button>
                <ul>
                  {c.subcategories?.map(sc => (
                    <li key={sc.key}>
                      <button
                        className={`custom-categories-content__category-list__button${
                          parentCategoryKey === c.key && selectedCategoryKey === sc.key
                            ? ' custom-categories-content__category-list__button--is-selected'
                            : ''
                        }`}
                        type="button"
                        onClick={() => {
                          setSelectedCategoryKey(sc.key);
                          setParentCategoryKey(c.key);
                        }}
                      >
                        {sc.name}
                      </button>
                    </li>
                  ))}
                  <li>
                    <button
                      className={`custom-categories-content__category-list__button custom-categories-content__category-list__button--is-subcategory${
                        action === 'create' && parentCategoryKey === c.key
                          ? ' custom-categories-content__category-list__button--is-selected'
                          : ''
                      }`}
                      type="button"
                      onClick={() => {
                        setSelectedCategoryKey('create-new');
                        setParentCategoryKey(c.key);
                      }}
                    >
                      {getIcon('addTag', {})} Add subcategory
                    </button>
                  </li>
                </ul>
              </li>
            ))}
        </ul>
        <div className="custom-categories-content__category-data">
          <form onSubmit={handleFormSubmit}>
            {selectedCategory && (
              <div className="custom-categories-content__category-form" key={selectedCategory.key}>
                <div className="custom-categories-content__field">
                  <label htmlFor="name">Category Name</label>
                  <FlightTextInput
                    name="name"
                    iconInput="editOutline"
                    value={selectedCategory.name}
                    onChange={handleInputChange}
                    width="100%"
                    errorMessage={errorMessage}
                    hasError={!!errorMessage}
                  />
                </div>
                <div className="custom-categories-content__field">
                  <label htmlFor="subcategories">Parent category</label>
                  <p>
                    {importLibraryCategories?.settings?.categories?.find(c => c.key === parentCategoryKey)?.name || '-'}
                  </p>
                </div>
                <div className="custom-categories-content__field">
                  <div className="custom-categories-content__flex">
                    <div>
                      <label htmlFor="name">Category Banner</label>
                      <ImageUpload
                        onSuccess={handleImageUploadSuccess}
                        value={selectedCategory.img}
                        defaultValue={defaultSelectedImage}
                        ratio={[17, 5]}
                      />
                    </div>
                    <div>
                      <label htmlFor="description">Description</label>
                      <FlightTextArea
                        name="description"
                        value={selectedCategory.description}
                        onChange={handleInputChange}
                        width="100%"
                        label=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="custom-categories-content__actions">
              <FlightButton
                type="submit"
                label={action === 'create' ? `Create ${type}` : `Update ${type}`}
                iconLeft=""
                onClick={() => null}
                disabled={isBusy}
                loading={isBusy}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomCategoriesContent;
