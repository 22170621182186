import React, { useContext, useEffect, useState, useRef } from 'react';
import { Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { AuthContext } from 'auth/providers/AuthProvider';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import LocalStore from 'services/localstore';
import { generateAuthCode } from 'auth/services';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { cleanUpLocalForage } from 'auth/utils';

const localStore = new LocalStore();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AsyncRenderWrapper(props: any) {
  const { isLoading, isAuthenticated } = useContext(AuthContext);
  const [isAysncOp, setIsAsyncOp] = useState(true);
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const [isLoadingIconVisible, setIsLoadingIconVisible] = useState(true);

  const { pathname, search } = window.location;
  const urlParams = new URLSearchParams(search);
  const isFromControlTower = urlParams.get('fromControlTower')?.toLowerCase() === 'true';

  const {
    component: Component,
    match: {
      params: { id: projectId },
    },
  } = props;

  const externalAuthUrl = useRef();
  const [isExtAuthError, setIsExtAuthError] = useState(false);

  useEffect(() => {
    (async () => {
      await localStore.setItem('goto_path_after_auth', `${pathname}${search}`);

      externalAuthUrl.current = await localStore.getItem(`external_auth_url+${projectId}`);

      setIsAsyncOp(false);
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (isLoading || isAysncOp) {
        return;
      } else {
        if (isAuthenticated) {
          setShouldRenderComponent(true);
        } else {
          if (isFromControlTower) {
            // Control Tower IDP
            generateAuthCode(projectId);

            await cleanUpLocalForage(`external_auth_url+${projectId}`);
          } else {
            const extAuthUrl = externalAuthUrl.current;

            if (extAuthUrl) {
              // External IDP
              if (extAuthUrl === 'NOTSET') {
                setIsExtAuthError(true);
              } else {
                window.location.assign(extAuthUrl);
              }
            } else {
              // Control Tower IDP
              generateAuthCode(projectId);
            }
          }
        }

        setIsLoadingIconVisible(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, isAysncOp]);

  return (
    <>
      <LoadingIcon width={80} height={80} fullScreen visible={isLoadingIconVisible} />
      {shouldRenderComponent && <Component {...props} />}
      {isExtAuthError && <NotFoundPage isError isExtAuthError />}
    </>
  );
}

function PrivateRoute({
  exact,
  path,
  component,
}: {
  exact: boolean;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}) {
  return <Route exact={exact} path={path} render={props => <AsyncRenderWrapper {...props} component={component} />} />;
}

export default PrivateRoute;
