export interface Category {
  labels: string[];
  name: string;
  pos: number;
  localizations: {
    [key: string]: { name: string };
  };
}

export type TModalCategoryNames = { [key: string]: { name: string; hasError?: boolean; error?: string } };

export interface ConciergeCategoriesState {
  categories: Category[];
}

export enum ConciergeCategoriesActionTypes {
  SET_CONCIERGE_CATEGORIES = 'SET_CONCIERGE_CATEGORIES',
}

export interface ConciergeCategoriesAPIPayload {
  configName: {
    configName: string;
    tenant: {
      tenantId: string;
    };
  };
  settings: {
    categories: Category[];
  };
}

interface ConciergeCategoriesActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type ConciergeCategoriesActions = ConciergeCategoriesActionCreatorType<
  typeof ConciergeCategoriesActionTypes.SET_CONCIERGE_CATEGORIES,
  ConciergeCategoriesState
>;
