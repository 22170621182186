import React, { useEffect } from 'react';
import ContentIframe from './ContentIframe';
import './ContentIframeParent.scss';

interface ContentIframeProps {
  onSubmit: () => void;
  onCancel: () => void;
  templateId: string;
}

export default function ContentIframeParent(props: ContentIframeProps) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const messageListener = (e: any) => {
    if (e.data.key === 'action_back') {
      props.onCancel();
    } else if (e.data.key === 'template_created' || e.data.key === 'template_updated') {
      props.onSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageListener, false);
    return () => window.removeEventListener('message', messageListener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="template-builder">
        <div className="main">
          <div className="iframe-container">
            <ContentIframe isVisible={true} templateId={props.templateId} />
          </div>
        </div>
      </div>
    </>
  );
}
