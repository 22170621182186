import BaseService from './base.service';
import {
  FcmSettingsPayload,
  ApnsSettings,
  CreateNotificationType,
  NotificationType,
  HuaweiSettingsPayload,
  PushLimitSettings,
} from 'model/pushSettings/settings';
import UploadBaseService from './uploadbase.service';

class PushUploadService extends UploadBaseService {
  CURRENT_PATH: string;
  SECONDARY_RESOURCE: string;
  PUSH_PATH: string;
  APNS_TOKEN_RESOURCE: string;
  FCM_RESOURCE: string;

  constructor() {
    super();
    this.PUSH_PATH = 'push/';
    this.APNS_TOKEN_RESOURCE = 'apns/apnssettingsp8';
    this.FCM_RESOURCE = 'fcm/settings';
    this.CURRENT_PATH = 'kernel/file-manager/';
    this.SECONDARY_RESOURCE = 'files/upload?';
  }

  async uploadApnsCertificate(data: FormData) {
    return this.request('POST', this.CURRENT_PATH + this.SECONDARY_RESOURCE, data);
  }

  async createUpdateApnsTokenSettings(data: FormData) {
    return this.request('POST', this.PUSH_PATH + this.APNS_TOKEN_RESOURCE, data);
  }

  async uploadFCMServiceAccountJSON(data: FormData) {
    return this.request('POST', this.PUSH_PATH + this.FCM_RESOURCE, data);
  }
}

class PushService extends BaseService {
  CURRENT_PATH: string;
  GCM_RESOURCE: string;
  FCM_RESOURCE: string;
  HUAWEI_RESOURCE: string;
  APNS_RESOURCE: string;
  APNS_TOKEN_RESOURCE: string;
  PUSH_PAYLOAD_TYPES: string;
  PUSH_LIMIT_SETTINGS: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'push/';
    this.GCM_RESOURCE = 'gcm/tenantsettings';
    this.FCM_RESOURCE = 'fcm/settings';
    this.HUAWEI_RESOURCE = 'huawei/settings';
    this.APNS_RESOURCE = 'apns/apnssettings';
    this.APNS_TOKEN_RESOURCE = 'apns/apnssettingsp8';
    this.PUSH_PAYLOAD_TYPES = 'push/payloadtypes';
    this.PUSH_LIMIT_SETTINGS = 'push/limitSettings';
  }

  async getFcmV1Settings() {
    return this.request('GET', this.CURRENT_PATH + this.FCM_RESOURCE);
  }

  async deleteFcmV1Settings() {
    return this.request('DELETE', this.CURRENT_PATH + this.FCM_RESOURCE);
  }

  async createFcmSettings(params: FcmSettingsPayload) {
    return this.request('POST', this.CURRENT_PATH + this.GCM_RESOURCE, params);
  }

  async getFcmSettings() {
    return this.request('GET', this.CURRENT_PATH + this.GCM_RESOURCE);
  }

  async deleteFcmSetings(params: FcmSettingsPayload) {
    return this.request('POST', this.CURRENT_PATH + this.GCM_RESOURCE, params);
  }

  async createHuaweiSettings(params: HuaweiSettingsPayload) {
    return this.request('POST', this.CURRENT_PATH + this.HUAWEI_RESOURCE, params);
  }

  async updateHuaweiSettings(params: HuaweiSettingsPayload) {
    return this.request('PATCH', this.CURRENT_PATH + this.HUAWEI_RESOURCE, params);
  }

  async getHuaweiSettings() {
    return this.request('GET', this.CURRENT_PATH + this.HUAWEI_RESOURCE);
  }

  async deleteHuaweiSetings() {
    return this.request('DELETE', this.CURRENT_PATH + this.HUAWEI_RESOURCE);
  }

  async getApnsSettings() {
    return this.request('GET', this.CURRENT_PATH + this.APNS_RESOURCE);
  }

  async createApnsSettings(params: ApnsSettings) {
    return this.request('POST', this.CURRENT_PATH + this.APNS_RESOURCE, params);
  }

  async updateApnsSettings(params: ApnsSettings) {
    return this.request('PATCH', this.CURRENT_PATH + this.APNS_RESOURCE, params);
  }

  async deleteApnsSettings(fileID: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.APNS_RESOURCE + '/' + fileID);
  }

  // Token Service calls
  async getApnsTokenSettings() {
    return this.request('GET', this.CURRENT_PATH + this.APNS_TOKEN_RESOURCE);
  }

  async deleteApnsTokenSettings() {
    return this.request('DELETE', this.CURRENT_PATH + this.APNS_TOKEN_RESOURCE);
  }

  async getPushPayloadTypes() {
    return this.request('GET', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES);
  }

  async createPushPayloadTypes(data: CreateNotificationType) {
    return this.request('POST', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES, data);
  }

  async updatePushPayLoadTypes(data: NotificationType) {
    return this.request('PUT', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES + '/' + data.id, data);
  }

  async deletePushPayLoadTypes(id: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.PUSH_PAYLOAD_TYPES + '/' + id);
  }

  // Push Limit settings
  async getPushLimitSettings(limitType?: string) {
    const baseURL = this.CURRENT_PATH + this.PUSH_LIMIT_SETTINGS;
    const finalURL = limitType ? `${baseURL}/${limitType}` : baseURL;
    return this.request('GET', finalURL);
  }

  async updatePushLimitSettings(limitType: string, data: PushLimitSettings) {
    return this.request('PUT', this.CURRENT_PATH + this.PUSH_LIMIT_SETTINGS + '/' + limitType, data);
  }
}

export { PushUploadService, PushService };
