import {
  ConciergeCategoriesActions,
  ConciergeCategoriesActionTypes,
  ConciergeCategoriesState,
} from 'model/conciergeCategories';
import createReducer from './createReducer';

const initialState = {} as ConciergeCategoriesState;

export const conciergeCategories = createReducer<ConciergeCategoriesState>(initialState, {
  [ConciergeCategoriesActionTypes.SET_CONCIERGE_CATEGORIES](
    state: ConciergeCategoriesState,
    action: ConciergeCategoriesActions,
  ) {
    return { ...state, categories: action.payload };
  },
});
