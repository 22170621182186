import React, { useRef, useState } from 'react';
import { getIcon } from '@flybits/design-system';
import { isValidJson } from 'helpers/validJson';
import { JSON_VALIDATION } from 'constants/errors/errors';
import { KEYCODE_ESCAPE, KEYCODE_TAB } from 'constants/keycodes';
import './JsonEditor.scss';

interface Props {
  code: string;
  disabled?: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (event: React.ChangeEvent<any>) => void;
}

export default function JsonEditor(props: Props) {
  const { code, disabled, handleChange } = props;
  const [enableTab, setEnableTab] = useState(false);

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const numRef = useRef<any>();
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textareaRef = useRef<any>();

  const codeString = code.toString();
  const numLines = codeString.split(/\r\n|\r|\n/).length + 1;
  const lineNumbers = [];
  for (let line = 1; line < numLines; ++line) {
    lineNumbers.push(
      <div key={line} className="json-editor__line-number">
        {line}
      </div>,
    );
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleScroll(event: React.ChangeEvent<any>) {
    if (numRef.current) {
      numRef.current.scrollTop = event.target.scrollTop;
    }
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleKeyDown(event: React.KeyboardEvent<any>) {
    if (event.keyCode === KEYCODE_TAB && !event.shiftKey && enableTab) {
      event.preventDefault();
      const cursorPos: number = textareaRef.current.selectionStart;
      const currentCode = textareaRef.current.value;
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      const changeEvent = {
        target: {
          value: currentCode.substring(0, cursorPos) + '  ' + currentCode.substring(cursorPos, currentCode.length),
        },
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as React.ChangeEvent<any>;

      handleChange(changeEvent);
      setTimeout(() => {
        textareaRef.current.setSelectionRange(cursorPos + 2, cursorPos + 2);
      }, 5);
    } else if (event.keyCode === KEYCODE_ESCAPE) {
      setEnableTab(false);
    }
  }

  return (
    <div className="json-editor">
      <div className="json-editor__editor">
        <div className="json-editor__line-numbers" ref={numRef}>
          {lineNumbers}
        </div>
        <textarea
          ref={textareaRef}
          className={`json-editor__text-area${!isValidJson(codeString) ? ' error' : ''}`}
          disabled={disabled}
          value={codeString}
          onChange={handleChange}
          onScroll={handleScroll}
          onKeyDown={handleKeyDown}
        />
        {!isValidJson(codeString) && getIcon('error', { className: 'json-editor__error-icon' })}
      </div>
      {!isValidJson(codeString) && <span className="json-editor__error-message">{JSON_VALIDATION}</span>}
    </div>
  );
}
