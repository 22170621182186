import React, { useCallback, useEffect, useState } from 'react';
import './ConflictsTemplateResolution.scss';
import { ReactComponent as JSONIcon } from 'assets/images/json-icon.svg';
import { ReactComponent as ComputerIcon } from 'assets/images/computer-icon.svg';
import {
  CategoriesEntity,
  TemplateConflicts,
  TemplateExportedContent,
  TemplateImportContent,
  TemplatePayload,
} from 'model/manageTemplates/manageTemplates';
import { FlightRadioButton, getIcon } from '@flybits/design-system';
import { useActions } from 'actions';
import * as ManageTemplatesActions from 'actions/manageTemplates';
interface ConflictsTemplateResolutionProps {
  templatesJSON: TemplateExportedContent;
  templatesPayload: TemplateImportContent;
  library: TemplatePayload[];
  conflicts: TemplateConflicts;
  categories: CategoriesEntity[];
  filter: string | null;
}
interface ListCardProps {
  currentAction: string;
  data: {
    id: string;
    name: string;
    desc?: string;
    categories?: string[];
    imageUrl?: string;
  };
  strategy: string;
  disabled?: boolean;
  selected?: boolean;
  categoriesLibrary: CategoriesEntity[];
  changeStrategyForTemplateHandler: (arg0: string, arg1: string) => void;
}

export const ListCard = (props: ListCardProps) => {
  const {
    currentAction,
    data: { name, id, imageUrl, categories },
    strategy,
    selected,
    disabled,
    categoriesLibrary,
    changeStrategyForTemplateHandler,
  } = props;

  const cat = categoriesLibrary.find(
    category => category.key === categories?.find(c => c.startsWith('category-'))?.substring(9),
  );

  const subcat = cat?.subcategories?.find(
    subcategory => subcategory.key === categories?.find(c => c.startsWith('subcategory-'))?.substring(12),
  );

  return (
    <div className={`conflicts-resolution__column ${disabled ? 'disabled' : ''}`}>
      <div className="conflicts-resolution__column-content">
        {currentAction === 'manual' && (
          <label htmlFor={`manual-select-${id}`} className="template-manual-select">
            <input
              type="checkbox"
              name={`manual-select-${id}`}
              checked={selected}
              onChange={() => changeStrategyForTemplateHandler(id, strategy)}
            />
          </label>
        )}
        <div className="template-infos">
          <h5>{name}</h5>
          <ul>
            {cat && <li>{cat.name}</li>}
            {subcat && <li>{subcat.name}</li>}
            <li>{id}</li>
          </ul>
        </div>
        <div className="template-image">
          {imageUrl && (
            <img
              src={imageUrl}
              alt={name}
              onError={ev => {
                (ev.target as Element).setAttribute('style', 'display:none');
                (ev.target as Element)?.parentElement?.setAttribute('style', 'display:none');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default function ConflictsTemplateResolution(props: ConflictsTemplateResolutionProps) {
  const { library, conflicts, filter, templatesPayload, categories: categoriesLibrary } = props;
  const [currentAction, setCurrentAction] = useState(''); // manual, skip, overwrite
  const manageTemplatesActions = useActions(ManageTemplatesActions);

  const updateImportPayload = (templatesData: TemplateImportContent) => {
    manageTemplatesActions.setImportTemplatePayloadState(templatesData);
  };
  const templateHasConflicts = (templateID: string) => !!conflicts?.templateConflicts?.find(t => t.id === templateID);

  // Filter
  const filteredTemplates = useCallback(() => {
    if (!filter)
      return templatesPayload?.templates?.filter(template => templateHasConflicts(template.payload.id)) || [];
    return (
      templatesPayload?.templates?.filter(template => {
        // show only templates that have conflicts
        if (!templateHasConflicts(template.payload.id)) return false;
        const showImportedTemplate = template.payload.categories
          ? template.payload.categories.some(item => item.endsWith(filter))
          : false;

        let showLibraryTemplate = false;
        // get corresponding library item
        const libraryItem = library.find(tpl => tpl.id === template.payload.id);
        if (libraryItem && libraryItem.categories) {
          showLibraryTemplate = libraryItem.categories
            ? libraryItem.categories.some(item => item.endsWith(filter))
            : false;
        }
        return !!(showLibraryTemplate || showImportedTemplate);
      }) || []
    );
    // eslint-disable-next-line
  }, [filter, library, templatesPayload]);

  const setCorrectStrategy = (currentMergeStrategy: string, newStrategy: string) => {
    // current can be:
    // '' => not set
    // 'create_new' => both selected
    // 'overwrite' => left only
    // 'skip' => right only
    if (!currentMergeStrategy) return newStrategy;
    if (currentMergeStrategy === newStrategy) return '';
    if (currentMergeStrategy === 'create_new') {
      if (newStrategy === 'overwrite') return 'skip';
      return 'overwrite';
    }
    return 'create_new';
  };

  const changeStrategyForTemplate = (templateId: string, strategy: string) => {
    if (!templatesPayload) return;
    if (!templatesPayload.templates) return;
    updateImportPayload({
      name: templatesPayload.name,
      templates: templatesPayload.templates.map(t => {
        if (t.payload.id === templateId) return { ...t, mergeStrategy: setCorrectStrategy(t.mergeStrategy, strategy) };
        return t;
      }),
      components: templatesPayload.components,
    });
  };

  const changeAction = (method: string) => {
    setCurrentAction(method);
    updateImportPayload({
      name: templatesPayload.name,
      templates: templatesPayload?.templates?.map(t => ({
        ...t,
        mergeStrategy: method === 'manual' ? '' : method,
      })),
      components: templatesPayload.components,
    });
  };

  useEffect(() => {
    // Add base mergeStrategy to all templates
    // if exist without conflict set default to overwrite
    // else use current mergeStrategy or set to blank so the user can choose
    if (templatesPayload) {
      updateImportPayload({
        name: templatesPayload.name,
        templates: templatesPayload.templates,
        components: templatesPayload.components,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="conflicts-resolution__list">
      <div className="conflicts-resolution__title">
        <h4>
          <JSONIcon /> Templates from JSON file:{' '}
          <strong title={templatesPayload?.name}>{templatesPayload?.name}</strong>
        </h4>
        <div className="conflict-list-item"></div>
        <h4>
          <ComputerIcon /> Templates already in your <strong>Library</strong>
        </h4>
      </div>
      <div className="conflicts-resolution__templates">
        {filteredTemplates()?.map(({ mergeStrategy, payload: { name, id, categories, imageUrl } }) => {
          const importData = {
            id,
            name: name || 'Untitled',
            categories,
            imageUrl,
          };
          // get corresponding library item
          const libraryItem = library.find(t => t.id === id);
          if (!libraryItem) return null;
          const libraryItemData = {
            id: libraryItem.id,
            name: libraryItem.name || 'Untitled',
            categories: libraryItem?.categories || [],
            imageUrl: libraryItem?.imageUrl || '',
          };

          // get conflicts
          const conflictsFound = conflicts?.templateConflicts?.find(t => t.id === id)?.conflicts?.join(', ') || null;

          if (!conflictsFound) return null;

          return (
            <div key={id} className={`conflicts-resolution__row`}>
              <ListCard
                currentAction={currentAction}
                data={{
                  ...importData,
                  name: name + (mergeStrategy === 'create_new' && name === libraryItemData.name ? '-1' : ''),
                }}
                strategy={'overwrite'}
                changeStrategyForTemplateHandler={changeStrategyForTemplate}
                selected={mergeStrategy === 'overwrite' || mergeStrategy === 'create_new'}
                disabled={
                  currentAction === 'skip' ||
                  (currentAction === 'manual' && (mergeStrategy === '' || mergeStrategy === 'skip'))
                }
                categoriesLibrary={categoriesLibrary}
              />

              <ListCard
                data={libraryItemData}
                currentAction={currentAction}
                strategy={'skip'}
                changeStrategyForTemplateHandler={changeStrategyForTemplate}
                selected={mergeStrategy === 'skip' || mergeStrategy === 'create_new'}
                disabled={
                  currentAction === 'overwrite' ||
                  (currentAction === 'manual' && (mergeStrategy === '' || mergeStrategy === 'overwrite'))
                }
                categoriesLibrary={categoriesLibrary}
              />
              {conflictsFound && (
                <div className="conflict-list-item">
                  {getIcon('error', {})} <strong>Conflicts:</strong> {conflictsFound}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="conflicts-resolution__actions">
        <FlightRadioButton
          label="Resolve manually"
          checked={currentAction === 'manual' ? true : false}
          className="conflicts-resolution__actions-item"
          onSelect={(method: string) => changeAction(method)}
          value={'manual'}
        />
        <FlightRadioButton
          label="Replace all with new"
          checked={currentAction === 'overwrite' ? true : false}
          className="conflicts-resolution__actions-item"
          onSelect={(method: string) => changeAction(method)}
          value={'overwrite'}
        />
        <FlightRadioButton
          label="Skip all new with same IDs"
          checked={currentAction === 'skip' ? true : false}
          className="conflicts-resolution__actions-item"
          onSelect={(method: string) => changeAction(method)}
          value={'skip'}
        />
      </div>
    </div>
  );
}
