export interface ManageTemplateState {
  importLibraryTemplates: TemplatePayload[];
  importLibraryCategories: TemplateCategories;
  importTemplatesPayload: TemplateImportContent;
  importedFile: TemplateExportedContent;
  importConflicts: TemplateConflicts;
  importStatus: TemplateImportStatus;
  importStatusInterval: NodeJS.Timeout;
  importedTemplates: TemplateImported[];
  exportTemplates: TemplateExportedContent;
}

// Status
export interface TemplateImportStatus {
  requestId: string;
  id: string;
  tenantId: string;
  creatorId: string;
  name: string;
  Name: string;
  createdAt: number;
  finishedAt: number;
  status: string;
  errMsg: string;
  result: string;
}
export interface TemplateImported {
  id: string;
  tenantId: string;
  name: string;
  createdAt: number;
  finishedProcessingAt: number;
  status: string;
  creator: string;
}

// Conflicts
export interface TemplateConflicts {
  componentsWithoutConflicts: ComponentsWithoutConflicts;
  existingTemplatesWithoutConflicts: string[] | null;
  templateConflicts: TemplateConflictsEntity[];
  componentConflicts: ComponentConflictsEntity[];
}

export interface ComponentsWithoutConflicts {
  RuleTemplates: string[] | null;
  PushTemplates: string[] | null;
  WebhookTemplates: string[] | null;
  ContentTemplatePrototype: string[] | null;
  ContextPlugin: string[] | null;
  ContentTemplate: string[] | null;
}
export interface TemplateConflictsEntity {
  id: string;
  conflicts?: string[] | null;
}
export interface ComponentConflictsEntity {
  id: string;
  component: string;
  conflicts?: string[] | null;
  originalName?: string;
}

// Template Import Schema

export interface TemplateImportContent {
  name: string;
  templates?: TemplatesEntityImport[];
  components: Components;
}
export interface TemplatesEntityImport {
  mergeStrategy: string;
  payload: TemplatePayload;
}

// Exported Templates

export interface TemplateExportedContent {
  name: string;
  components?: Components;
  templates?: TemplatesEntityExport[];
}
export interface Components {
  ruleTemplates?: RuleTemplatesEntityOrPushTemplatesEntity[] | null;
  contentTemplatePrototypes?: ContentTemplatePrototypesEntity[] | null;
  contentTemplates?: ContentTemplatesEntityOrContextPluginsEntity[] | null;
  pushTemplates?: RuleTemplatesEntityOrPushTemplatesEntity[] | null;
  webhookTemplates?: RuleTemplatesEntityOrPushTemplatesEntity[] | null;
  contextPlugins?: ContentTemplatesEntityOrContextPluginsEntity[] | null;
  relationships?: Relationships;
}

export interface ComponentData {
  mergeStrategy: string;
  id: string;
  payload: ComponentPayload;
  variables?: null;
}
export interface ComponentPayload {
  constraints: Constraints;
  contextVariables?: string[] | null;
  createdAt: number;
  creator: string;
  description: string;
  emitEveryEvaluationResult: boolean;
  endIntervalEvaluation: number;
  hasBeenStreamed: boolean;
  id: string;
  ignorePastSchedule: boolean;
  labels?: null[] | null;
  lastUpdatedAt: number;
  logic: string;
  name: string;
  predicates: Predicates;
  schedule: Schedule;
  tenantID: string;
  variables?: null[] | null;
}
export interface Constraints {
  predicateConstraints?: null;
  predicateParameterConstraints?: null;
  ruleBehaviourConstraints: RuleBehaviourConstraints;
}
export interface RuleBehaviourConstraints {
  emitEveryEvaluationResultConstraint: RuleBehaviourConstraintEntity;
  endIntervalEvaluationConstraint: RuleBehaviourConstraintEntity;
  hasBeenStreamedConstraint: RuleBehaviourConstraintEntity;
  ignorePastScheduleConstraint: RuleBehaviourConstraintEntity;
}
export interface RuleBehaviourConstraintEntity {
  isHidden: boolean;
  isReadOnly: boolean;
}
export interface Predicates {
  [key: string]: Predicate;
}
export interface Predicate {
  name: string;
  parameters?: ParametersEntity[] | null;
}
export interface ParametersEntity {
  type: string;
  plugin?: string | null;
  attribute?: string | null;
  dataType: string;
  value?: string | null;
}
export interface Schedule {
  endScheduler: EndSchedulerOrStartScheduler;
  startScheduler: EndSchedulerOrStartScheduler;
}
export interface EndSchedulerOrStartScheduler {
  contextualRule: ContextualRule;
  isRelative: boolean;
  repeat: Repeat;
  start: number;
  suspend: boolean;
  timezone: Timezone;
}
export interface ContextualRule {
  logic: string;
  modifier: string;
  predicates: Predicates;
  reevaluateOnReschedule: boolean;
  target: string;
}
export interface Repeat {
  frequency: number;
  frequencyType: string;
  limit: number;
}
export interface Timezone {
  name: string;
  offset: number;
}

export interface RuleTemplatesEntityOrPushTemplatesEntity {
  id: string;
  mergeStrategy?: string;
  payload: any;
  variables?: VariablesEntity[] | null;
}
export interface VariablesEntity {
  key: string;
  type: string;
}
export interface ContentTemplatePrototypesEntity {
  id: string;
  mergeStrategy?: string;
  payload: any;
  variables?: null;
}
export interface ContentTemplatesEntityOrContextPluginsEntity {
  id: string;
  mergeStrategy?: string;
  payload: any;
}
export interface Relationships {
  ruleTemplatesParents: RuleTemplatesParentsOrPushTemplatesParentsOrContentTemplatePrototypesParents;
  contextPluginsParents: ContextPluginsParents;
  pushTemplatesParents: RuleTemplatesParentsOrPushTemplatesParentsOrContentTemplatePrototypesParents;
  webhookTemplatesParents: RuleTemplatesParentsOrPushTemplatesParentsOrContentTemplatePrototypesParents;
  contentTemplatesParents: ContentTemplatesParents;
  contentTemplatePrototypesParents: RuleTemplatesParentsOrPushTemplatesParentsOrContentTemplatePrototypesParents;
}
export interface RuleTemplatesParentsOrPushTemplatesParentsOrContentTemplatePrototypesParents {
  [key: string]: JourneyIds;
}
export interface JourneyIds {
  journeyIDs?: string[] | null;
}
export interface ContextPluginsParents {
  [key: string]: RuleTemplateIDs;
}
export interface RuleTemplateIDs {
  ruleTemplateIDs?: string[] | null;
}
export interface ContentTemplatesParents {
  [key: string]: ContentTemplatePrototypeIDs;
}
export interface ContentTemplatePrototypeIDs {
  contentTemplatePrototypeIDs?: string[] | null;
}
export interface TemplatesEntityExport {
  payload: TemplatePayload;
  mergeStrategy?: string;
}
export interface TemplatePayload {
  id: string;
  name?: string;
  desc?: string;
  imageUrl?: string;
  steps?: StepsEntity[];
  categories?: string[];
  audienceOptimizationEligible: boolean;
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
  metadata?: unknown | null;
  provider: 'Custom' | 'Flybits';
}
export interface StepsEntity {
  audience?: Audience;
  actions?: ActionsEntity[];
}
export interface Audience {
  preferred?: Preferred;
  restricted?: Restricted;
}
export interface Restricted {
  refId?: string;
  templateId?: string;
}
export interface Preferred {
  refId?: string;
  templateId?: string;
}
export interface ActionsEntity {
  refId: string;
  templateId: string;
  actionType: string;
  dependencies?: {
    [key: string]: string;
  };
}

// END Exported Template

// Categories
export interface TemplateCategories {
  config: string;
  configName: ConfigName;
  settings: Settings;
}
export interface ConfigName {
  tenant: Tenant;
  configName: string;
}
export interface Tenant {
  tenantID: string;
}
export interface Settings {
  categories?: CategoriesEntity[];
}
export interface CategoriesEntity {
  color?: string;
  description?: string;
  img?: string;
  key: string;
  name: string;
  subcategories?: CategoriesEntity[] | null;
}

// Redux/Actions
export enum ManageTemplatesActionTypes {
  SET_IMPORT_LIBRARY_STATE = 'SET_IMPORT_LIBRARY_STATE',
  SET_IMPORT_CATEGORY_STATE = 'SET_IMPORT_CATEGORY_STATE',
  RESET_IMPORT_STATE = 'RESET_IMPORT_STATE',
  RESET_EXPORT_STATE = 'RESET_EXPORT_STATE',
  SET_IMPORT_TEMPLATES_STATE = 'SET_IMPORT_TEMPLATES_STATE',
  SET_IMPORT_STATUS_STATE = 'SET_IMPORT_STATUS_STATE',
  SET_IMPORT_STATUS_INTERVAL_STATE = 'SET_IMPORT_STATUS_INTERVAL_STATE',
  SET_IMPORTED_TEMPLATES_STATE = 'SET_IMPORTED_TEMPLATES_STATE',
  SET_ORIGINAL_IMPORT_TEMPLATES_STATE = 'SET_ORIGINAL_IMPORT_TEMPLATES_STATE',
  SET_IMPORT_CONFLICTS_STATE = 'SET_IMPORT_CONFLICTS_STATE',
  SET_EXPORT_TEMPLATES_STATE = 'SET_EXPORT_TEMPLATES_STATE',
}

interface ManageTemplatesActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type ManageTemplatesActions =
  | ManageTemplatesActionCreatorTypes<
      typeof ManageTemplatesActionTypes.SET_IMPORT_TEMPLATES_STATE,
      TemplateImportContent
    >
  | ManageTemplatesActionCreatorTypes<
      typeof ManageTemplatesActionTypes.SET_ORIGINAL_IMPORT_TEMPLATES_STATE,
      TemplateImportContent
    >
  | ManageTemplatesActionCreatorTypes<typeof ManageTemplatesActionTypes.SET_IMPORT_CONFLICTS_STATE, TemplateConflicts>
  | ManageTemplatesActionCreatorTypes<typeof ManageTemplatesActionTypes.SET_IMPORT_STATUS_STATE, TemplateImportStatus>
  | ManageTemplatesActionCreatorTypes<
      typeof ManageTemplatesActionTypes.SET_IMPORT_STATUS_INTERVAL_STATE,
      NodeJS.Timeout
    >
  | ManageTemplatesActionCreatorTypes<typeof ManageTemplatesActionTypes.SET_IMPORTED_TEMPLATES_STATE, TemplateImported>
  | ManageTemplatesActionCreatorTypes<
      typeof ManageTemplatesActionTypes.SET_EXPORT_TEMPLATES_STATE,
      TemplateExportedContent
    >;
