import { WebhookState, WebhookAction, WebhookActionTypes } from 'model/webhook';
import createReducer from './createReducer';

const initialState = {} as WebhookState;

export const webhooks = createReducer<WebhookState>(initialState, {
  [WebhookActionTypes.SET_WEBHOOKS_STATE](state: WebhookState, action: WebhookAction) {
    return { ...state, webhooks: action.payload };
  },
  [WebhookActionTypes.SET_WEBHOOK_STATE](state: WebhookState, action: WebhookAction) {
    return { ...state, webhook: action.payload };
  },
});
