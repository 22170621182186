import React, { useState, useRef, useEffect } from 'react';
import { ValidationError } from 'yup';
import { FlightButton } from '@flybits/design-system';
import { buildYup } from 'schema-to-yup';
import { TemplateImportContent, TemplatesEntityExport } from 'model/manageTemplates';
import { EXPECTED_SCHEMA } from 'constants/importTemplateSchema';
import { ReactComponent as JSONIcon } from 'assets/images/json-icon.svg';
import './UploadTemplate.scss';
interface Props {
  handleCheckForConflicts: (templatesData: TemplateImportContent, cb?: () => void) => Promise<void>;
}

export default function UploadTemplate(props: Props) {
  const { handleCheckForConflicts } = props;
  const [dragActive, setDragActive] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const showErrors = (errors: string[]) => {
    setProgressBar(1);
    setErrors(errors);
  };
  const clearErrors = () => {
    setProgressBar(0);
    setErrors([]);
  };
  useEffect(() => {
    clearErrors();
    return () => {
      clearErrors();
    };
  }, []);

  // ref
  const inputRef = useRef<any>(null);
  const buildYupConfig = {
    mode: {
      notRequired: true, // default setting
    },
  };

  /**
   * Log the uploaded file to the console
   * @param {ProgressEvent<FileReader>} event The file loaded event
   */
  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const processFile = async (event: ProgressEvent<FileReader>) => {
    const yupSchema = await buildYup(EXPECTED_SCHEMA, buildYupConfig);

    const str = event?.target?.result;
    if (typeof str !== 'string') {
      showErrors([`Invalid file type "${typeof str}". Expected "string"`]);
      return;
    }
    if (!isJsonString(str)) {
      showErrors([`Invalid JSON file format`]);
      return;
    }
    setProgressBar(17);

    const JSONtoValidate = JSON.parse(str);
    const audienceErrors: string[] = [];
    // normalize metadata to avoid null
    if (JSONtoValidate?.templates?.length) {
      JSONtoValidate.templates.map((template: TemplatesEntityExport) => {
        const { payload } = template;
        // check for empty audience to avoid receiving audience = {}
        // check each step

        if (payload.steps?.some(({ audience }) => audience && !audience.hasOwnProperty('restricted'))) {
          // no audience, return error
          audienceErrors.push(`Audience in template "${payload.name}" should have the 'restricted' property present`);
        }
        if (payload && payload.hasOwnProperty('metadata')) {
          payload.metadata = payload.metadata || {};
          return { ...template, payload: payload };
        }
        return { ...template };
      });
    } else {
      // no templates, return error
      showErrors(['Templates list cannot be empty']);
      return;
    }
    if (audienceErrors.length) {
      // no audience, return error
      showErrors(audienceErrors);
      return;
    }
    if (JSONtoValidate.hasOwnProperty('name')) {
      if (typeof JSONtoValidate.name === 'number') {
        showErrors(['The template name should be a string']);
        return;
      }
    }
    clearErrors();
    setTimeout(() => setProgressBar(43), 750);
    setTimeout(() => {
      yupSchema
        .validate(JSONtoValidate)
        .then(() => {
          setProgressBar(100);
          handleCheckForConflicts(JSONtoValidate, () => setProgressBar(0)).catch(() => {
            showErrors(['There was an error processing your file']);
          });
        })
        .catch((error: ValidationError) => {
          showErrors(error.errors);
        });
    }, 1200);
  };
  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleFile = (file: any) => {
    // If there's no file, do nothing
    if (file.type !== 'application/json') {
      showErrors(['Invalid JSON file']);
      return;
    }

    // Create a new FileReader() object
    const reader = new FileReader();
    // Setup the callback event to run when the file is read
    reader.onload = processFile;

    // Read the file
    reader.readAsText(file);
  };
  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: any) => {
    e.preventDefault();
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }
    // 👇️ reset file input
    e.target.value = null;

    handleFile(fileObj);
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    // 👇️ reset file input
    inputRef.current.value = null;
    inputRef.current.click();
  };

  return (
    <div className="upload-template">
      {(progressBar > 0 || errors.length > 0) && (
        <div className={`upload-template__progress-modal ${errors.length > 0 && 'error'}`}>
          <div className="upload-template__progress-modal-wrapper">
            <div className={`upload-template__progress-modal-header ${errors.length > 0 && 'error'}`}>
              {errors.length > 0 ? 'Error uploading template' : 'Uploading template'}
            </div>
            <div className={`upload-template__progress-modal-content ${errors.length > 0 && 'error'}`}>
              {errors.length === 0 ? (
                <>
                  <div className="upload-template__progress-modal-content-progress"></div>
                  <progress
                    className="upload-template__progress-modal-content-progress"
                    max="100"
                    value={progressBar}
                  ></progress>
                  <p>Uploading and analyzing template.</p>
                  <p>Please wait.</p>
                </>
              ) : (
                <>
                  <h5>Errors</h5>
                  <ul>
                    {errors.map(error => (
                      <li key={error}>{error.replace('data/', '')}</li>
                    ))}
                  </ul>
                  <p>
                    <small>Please verify the file and try again.</small>
                  </p>
                </>
              )}
            </div>
            {errors.length > 0 && (
              <div className="upload-template__progress-modal-footer">
                <FlightButton theme="link" onClick={clearErrors} label="Close" />
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className="upload-template-drop-area"
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={e => e.preventDefault()}
      >
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
          <div className="upload-template-drop-area-content">
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              accept=".json"
              multiple={false}
              onChange={handleChange}
            />
            <JSONIcon />
            <div>
              Drag and drop your JSON file here
              <br />
              or
            </div>
            <FlightButton theme="secondary" iconLeft="download" onClick={onButtonClick} label="Browse files" />
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      <div className="upload-template-info-box">
        <div className="upload-template-info-box-item ">
          <h3>Import your custom templates in three easy steps:</h3>
          <ol>
            <li>Upload your JSON file</li>
            <li>Update template details</li>
            <li>Confirm details and upload</li>
          </ol>
        </div>
        <div className="upload-template-info-box-item ">
          <h3>Import conflicts</h3>
          <p>Conflict resolution may be required for experience or component templates when importing a new file</p>
        </div>
        <div className="upload-template-info-box-item ">
          <h3>Imported Templates</h3>
          <p>
            Imported templates will be active by default and can be found by filtering active templates and sorting by
            date
          </p>
        </div>
      </div>
    </div>
  );
}
