import React, { useEffect, useRef } from 'react';
import jdenticon from 'jdenticon';

interface Props {
  size: string;
  value: string;
  className?: string;
}

export default function Jdenticon(props: Props) {
  const { size, value, className } = props;
  //TODO: Sai, investigate the correct type to avoid using 'any' here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const icon = useRef<any>(null);

  useEffect(() => {
    if (icon.current) {
      jdenticon.update(icon.current, value);
    }
  }, [value]);

  return (
    <div className={className}>
      <svg data-jdenticon-value={value} height={size} ref={icon} width={size} />
    </div>
  );
}
