import React, { useEffect, useState } from 'react';
import './CreateOrManageContentTemplate.scss';
import { ContentTemplateBuilderContainer } from '@flybits/webapp-react-content-template-builder';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import * as ContentTemplatesActions from 'actions/contentTemplates';
import { useActions } from 'actions';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import LocalStore from 'services/localstore';
import useLabelsInfiniteScroll from '../../hooks/useLabelsInfiniteScroll';
const localStore = new LocalStore();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function CreateOrManageContentTemplate(props: any) {
  const {
    match: {
      params: { id, contentTemplatesId },
    },
  } = props;
  const history = useHistory();
  const contentTemplatesActions = useActions(ContentTemplatesActions);
  const contentTemplateFromState = useSelector((state: RootState) => state.contentTemplates.contentTemplate);
  const [contentTemplateTemp, setContentTemplateTemp] = useState({});
  const [isComponentReady, setIsComponentReady] = useState(false);
  const [authTokenValue, setAuthTokenValue] = useState('');
  const [labelName, setLabelName] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [options] = useLabelsInfiniteScroll(labelName, page);
  const hostUrl = process.env.REACT_APP_HOST_URL ? process.env.REACT_APP_HOST_URL : '';
  useEffect(() => {
    (async () => {
      const authToken = await localStore.getItem(`token+${id}`);
      setAuthTokenValue(authToken);
      if (contentTemplatesId !== 'create') {
        await contentTemplatesActions.getContentTemplate(contentTemplatesId);
      } else {
        setContentTemplateTemp({});
      }
      setIsComponentReady(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contentTemplatesId !== 'create' && contentTemplateFromState) {
      setContentTemplateTemp(contentTemplateFromState);
    }
  }, [contentTemplatesId, contentTemplateFromState]);

  function goBack() {
    if (
      document.getElementsByClassName('flight-nav__sidebar')[0]?.classList.contains('flight-nav__sidebar--collapsed')
    ) {
      const element = document.getElementsByClassName('flight-button')[0] as HTMLElement;
      element.click();
    }
    history.goBack();
  }

  async function handleSubmit(contentTemplate: string) {
    if (contentTemplatesId !== 'create') {
      const result = await contentTemplatesActions.updateContentTemplate(contentTemplate, contentTemplatesId);
      if (result === 'done') {
        goBack();
      }
    } else {
      await contentTemplatesActions.createNewContentTemplate(contentTemplate);
      goBack();
    }
  }

  const handleLabelsSearch = (searchString: string) => {
    setLabelName(searchString);
  };

  const handleInfiniteScroll = () => {
    setPage(page + 1);
  };

  return (
    <div className="create-or-manage-content-template">
      {isComponentReady ? (
        <ContentTemplateBuilderContainer
          hostUrl={hostUrl}
          authToken={authTokenValue}
          templateJSON={contentTemplateTemp}
          contentTemplateLabels={options}
          onLabelsSearch={handleLabelsSearch}
          onInfiniteScroll={handleInfiniteScroll}
          onSubmit={handleSubmit}
          onCancel={goBack}
        />
      ) : (
        <LoadingIcon width={80} height={80} visible />
      )}
    </div>
  );
}
