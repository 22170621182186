import { AxiosError } from 'axios';
import { PushSettingsActionTypes } from 'model/pushSettings';
import { makeActionCreator } from 'helpers/actionCreator';
import { PushLimitSettings } from 'model/pushSettings/settings';
import { PushService } from 'services/push.service';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from '../snackBarNotifications';

export const setPushLimitSettings = makeActionCreator(PushSettingsActionTypes.SET_PUSH_LIMIT_STATE);

export function fetchPushLimitSettings(limitType: string = '') {
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.getPushLimitSettings(limitType);
      dispatch(setPushLimitSettings(response.data));
    } catch (error) {
      const fetchError = error as AxiosError;

      // @ts-expect-error: TODO: fix the typescript error properly
      const errorObjectFromResponse = fetchError?.response?.data?.error?.exceptionType || '';
      if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
        dispatch(
          setPushLimitSettings({
            timeValue: 1,
            timeUnit: 'WEEKS',
            isActive: false,
            maxNotifications: 10,
            limitType: 'user-device',
          }),
        );
      }
    }
    return 'done';
  };
}

export function addOrUpdatePushLimitSettings(limitType: string, pushLimitSettings: PushLimitSettings) {
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      const response = await req.updatePushLimitSettings(limitType, pushLimitSettings);

      if ([200, 201].includes(response?.status)) {
        dispatch(setPushLimitSettings(response.data));

        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Push limit settings has been set successfully',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      const fetchError = error as AxiosError;

      // @ts-expect-error: TODO: fix the typescript error properly
      const errorMessage = fetchError?.response?.data.error.exceptionMessage || 'Failed to set push limit settings';
      const randomId = generateUniqueId();

      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: errorMessage,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}
