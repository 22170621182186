import React from 'react';
import { FlightButton } from '@flybits/design-system';
import { ReactComponent as ConciergeCategoryEmptyStateImage } from 'assets/images/concierge-category-graphic.svg';
import './ConciergeCategoryEmptyState.scss';

interface TProps {
  handleCreateConciergeCategory: () => void;
}

function ConciergeCategoryEmptyState(props: TProps) {
  const { handleCreateConciergeCategory } = props;

  return (
    <div className="concierge-category-empty-state">
      <ConciergeCategoryEmptyStateImage />
      <div className="concierge-category-empty-state__content">
        <div className="concierge-category-empty-state__content__text">
          No concierge category filters have been added yet.
        </div>
        <div
          className="concierge-category-empty-state__content__link"
          onClick={() =>
            window
              .open(
                'https://flybits.gitbook.io/product-guide/-MCJQnb9xKBWvcUyw_9V/for-admins/about-project-settings/about-content-templates/concierge-category-filters',
                '_blank',
              )
              ?.focus()
          }
        >
          What are concierge categories filters?
        </div>
        <FlightButton
          className="concierge-category-empty-state__content__btn"
          label="Create your first category"
          onClick={handleCreateConciergeCategory}
        />
      </div>
    </div>
  );
}

export default ConciergeCategoryEmptyState;
