import React, { useEffect, useState } from 'react';
import { FlightOverflowMenu, FlightTable, FlightSelect } from '@flybits/design-system';
import { useActions } from 'actions';
import * as ContextPluginActions from 'actions/contextPlugins';
import { ReactComponent as DeviceIcon } from 'assets/images/device-icon.svg';
import { ReactComponent as UserIcon } from 'assets/images/person-icon.svg';
import { ReactComponent as TenantScopeIcon } from 'assets/images/tenant-scope.svg';

import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import { Attribute, ContextPlugin, TPreFilterCategory } from 'model/contextPlugins';
import { getDataExpiryText } from 'helpers/timeConverters';
import SearchBar from 'components/Shared/SearchBar/SearchBar';

import './ContextAttributesTable.scss';
import { getCtxAttributeFromString, getCtxCategoryFromString } from 'helpers/getCategoryFromString';
import { getCtxInputType, isFreeForm } from 'helpers/getCtxInputType';
import { isEmpty, isEqual, startCase } from 'lodash';
import { searchWithRegExp } from 'helpers/searchWithRegExp';
import {
  contextAttributesScopeOptions,
  contextDataTypeOptions,
  getContextAttributeCategoryOptions,
} from 'constants/contextAttributesOptions';
import { FlightSelectOptions } from 'model/misc/flightSelectOptions';
import { applyCombinationFilters } from 'helpers/ctxFilter';
import { CONTEXT_ATTRIBUTES_SCOPES } from 'constants/contextAttributesScopes';

interface Props {
  contextPlugins: ContextPlugin[];
  preFilterCategory?: TPreFilterCategory;
  handleAttributeClicked: (attribute: Attribute) => void;
  isNoFilters?: boolean;
  isReadOnly?: boolean;
}

interface TableData {
  key: string;
  scope?: JSX.Element;
  name?: JSX.Element;
  category?: JSX.Element;
  inputType?: JSX.Element;
  dataExpiry?: JSX.Element;
  moreOpts?: JSX.Element;
}

enum ConfirmModalState {
  HIDDEN = 'HIDDEN',
  STATUS = 'STATUS',
  DELETE = 'DELETE',
}

interface ValueOptions {
  displayName: string;
  value: string;
}

const tableHeaders = [
  {
    name: 'Scope',
    key: 'scope',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Name',
    key: 'name',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Category',
    key: 'category',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Input type',
    key: 'inputType',
    isVisible: true,
    hideTooltip: true,
  },

  {
    name: 'Data expiry',
    key: 'dataExpiry',
    isVisible: true,
    hideTooltip: true,
  },

  {
    name: 'Options',
    key: 'moreOpts',
    isVisible: true,
    hideTooltip: true,
  },
];

export default function ContextAttributesTable(props: Props) {
  const {
    contextPlugins,
    preFilterCategory = { key: '', name: '' },
    handleAttributeClicked,
    isNoFilters,
    isReadOnly,
  } = props;
  const contextPluginActions = useActions(ContextPluginActions);
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(ConfirmModalState.HIDDEN);
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>();
  const [scopeFilter, setScopeFilter] = useState<FlightSelectOptions>({ key: 'default', name: 'Any scope' });
  const [categoryFilter, setCategoryFilter] = useState<FlightSelectOptions>({ key: 'default', name: 'Any Category' });
  const [dataTypeFilter, setDataTypeFilter] = useState<FlightSelectOptions>({ key: 'default', name: 'Any input type' });
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState<TableData[]>([]);
  const confirmModalTitle = 'Confirm delete attribute';
  const confirmModalMessage = 'Are you sure you want to delete this attribute?';

  useEffect(() => {
    if (preFilterCategory.key && preFilterCategory.name) {
      setCategoryFilter(preFilterCategory);
      setSearchTerm('');
    }
  }, [preFilterCategory]);

  const hideConfirmModal = () => {
    setSelectedAttribute(undefined);
    setConfirmModalState(ConfirmModalState.HIDDEN);
  };

  const getCategoryForAttribute = (attribute: Attribute) => {
    const ctxCat = getCtxCategoryFromString(attribute.uid);
    // the standard sdk plugin activity has a typo, handle that here
    if (ctxCat === 'activity') {
      const _ctxFromList = contextPlugins.find(el => el.category === 'acivity');
      return _ctxFromList;
    }
    const _ctxFromList = contextPlugins.find(el => el.category === ctxCat);
    return _ctxFromList;
  };

  const handleDeleteConfirmation = () => {
    if (selectedAttribute) {
      const catForSelectedAttr = getCategoryForAttribute(selectedAttribute);
      const attributeKey = getCtxAttributeFromString(selectedAttribute.uid);
      delete catForSelectedAttr?.values?.[`${attributeKey}`];
      contextPluginActions.updateContextPlugin(catForSelectedAttr);
    }
    hideConfirmModal();
  };

  const createOptionGroups = (attribute: Attribute) => {
    return [
      [
        {
          key: 1,
          name: 'Delete',
          disabled: getCategoryForAttribute(attribute)?.isReserved,
          onClick: () => {
            setSelectedAttribute(attribute);
            setConfirmModalState(ConfirmModalState.DELETE);
          },
        },
        {
          key: 2,
          name: getCategoryForAttribute(attribute)?.isReserved ? 'View' : 'Edit',
          onClick: () => {
            handleAttributeClicked(attribute);
          },
        },
      ],
    ];
  };

  const filterDataType = (valueType: string, valueOptions?: ValueOptions[], timeContext?: boolean) => {
    if (isFreeForm(valueOptions)) {
      if (
        (dataTypeFilter.key === 'bool' && valueType === 'bool') ||
        (dataTypeFilter.key === 'string' && valueType === 'string') ||
        (dataTypeFilter.key === 'int' && valueType === 'int') ||
        (dataTypeFilter.key === 'float' && valueType === 'float')
      ) {
        return true;
      }
    } else {
      if (
        (dataTypeFilter.key === 'boolDropdown' && valueType === 'bool') ||
        (dataTypeFilter.key === 'stringDropDown' && valueType === 'string') ||
        (dataTypeFilter.key === 'intDropDown' && valueType === 'int' && !timeContext) ||
        (dataTypeFilter.key === 'floatDropDown' && valueType === 'float')
      ) {
        return true;
      }
    }
    if (timeContext && dataTypeFilter.key === 'dateTime' && valueType === 'int') {
      return true;
    }
    return false;
  };

  const applyFilters = (attribute: Attribute) => {
    //eval criteria
    const filterBySearch = !isEmpty(searchTerm);
    const filterByDataType = dataTypeFilter.key !== 'default';
    const filterByScope = scopeFilter.key === CONTEXT_ATTRIBUTES_SCOPES.TENANT.key;
    const filterByCategory = categoryFilter.key !== 'default';
    //eval conditions only eval if criteria is met
    const searchTermCondition =
      filterBySearch &&
      Object.values(attribute)
        .toString()
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase());
    const scopeCondition = filterByScope && attribute.isTenantScoped;
    const dataTypeCondition =
      filterByDataType && filterDataType(attribute.valueType, attribute.valueTypeOptions, attribute.isTimeContext);
    const categoryCondition = filterByCategory && attribute.uid.includes(categoryFilter.key);
    const retVal = applyCombinationFilters(
      filterBySearch,
      filterByDataType,
      filterByScope,
      filterByCategory,
      searchTermCondition,
      dataTypeCondition,
      categoryCondition,
      scopeCondition,
    );
    return retVal;
  };

  // TODO: abstract this into a helper or simplify this later.
  // possibly very non-performant, investigate and optimize later (sai)
  useEffect(() => {
    const _tableData: TableData[] = [];
    let _pluginData: ContextPlugin[] = [];

    if (isEmpty(searchTerm)) {
      _pluginData = contextPlugins;
    } else {
      // the backend api does not support searching or pagination
      // here we pre filter some of the data, this filters at a high level
      // but greatly reduces the size of the object
      _pluginData = searchWithRegExp(contextPlugins, searchTerm);
    }
    _pluginData?.forEach(plugin => {
      const attributes = plugin.values;
      const isDatasourcePlugin = !!plugin.dataSource;

      for (const attributeName in attributes) {
        const attribute: Attribute = attributes[attributeName];

        attribute.scope =
          (attribute.isTenantScoped && CONTEXT_ATTRIBUTES_SCOPES.TENANT.key) ||
          (plugin.userScope && CONTEXT_ATTRIBUTES_SCOPES.USER.key) ||
          CONTEXT_ATTRIBUTES_SCOPES.DEVICE.key;

        const category = getCtxCategoryFromString(attribute.uid);
        const expiryValue = getDataExpiryText(attribute.defaultExpDuration).value;
        const expiryUnit = getDataExpiryText(attribute.defaultExpDuration).unit;

        // further searching or filtering,
        const filterOrNot = () => {
          if (
            // user searched for nothing, return early
            isEmpty(searchTerm) &&
            scopeFilter.key !== CONTEXT_ATTRIBUTES_SCOPES.TENANT.key &&
            dataTypeFilter.key === 'default' &&
            categoryFilter.key === 'default'
          ) {
            return true;
          }
          return applyFilters(attribute);
        };

        if (filterOrNot()) {
          let tableDataScope;
          switch (attribute.scope) {
            case CONTEXT_ATTRIBUTES_SCOPES.USER.key:
              tableDataScope = <UserIcon className="context-attributes__table__icon" />;
              break;

            case CONTEXT_ATTRIBUTES_SCOPES.TENANT.key:
              tableDataScope = <TenantScopeIcon className="context-attributes__table__icon" />;
              break;

            default:
              tableDataScope = <DeviceIcon className="context-attributes__table__icon" />;
          }

          let attributeName = attribute.name;
          if (!attributeName) {
            const attributeUidSplit = attribute.uid.split('.');
            const attributeNameCamelCase = attributeUidSplit[attributeUidSplit.length - 1];
            attributeName = startCase(attributeNameCamelCase);
          }

          _tableData.push({
            key: attribute.id,
            scope: tableDataScope,
            name: (
              <>
                <a
                  href="# "
                  onClick={() => handleAttributeClicked(attribute)}
                  className="context-attributes__table__name"
                >
                  {attributeName}
                </a>
                <div>{attribute.uid}</div>
              </>
            ),
            category: <div className="context-attributes__table__category">{category}</div>,
            inputType: (
              <div>{getCtxInputType(attribute.valueType, attribute.valueTypeOptions, attribute.isTimeContext)}</div>
            ),
            dataExpiry: (
              <div>
                {expiryValue} {expiryUnit}
              </div>
            ),
            moreOpts:
              isReadOnly || isDatasourcePlugin ? (
                <></>
              ) : (
                <FlightOverflowMenu optionGroups={createOptionGroups(attribute)} direction="bottom-left" />
              ),
          });
        }
      }
    });

    if (isEmpty(_tableData) && isEmpty(searchTerm)) {
      _tableData.push({
        key: '',
        name: <span>No context attributes have been added to this project.</span>,
      });
    }
    if (
      isEmpty(_tableData) &&
      (!isEmpty(searchTerm) ||
        dataTypeFilter.key !== 'default' ||
        categoryFilter.key !== 'default' ||
        scopeFilter.key !== 'default')
    ) {
      _tableData.push({
        key: '',
        name: <span>No results found.</span>,
      });
    }
    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextPlugins, searchTerm, scopeFilter, dataTypeFilter, categoryFilter]);

  const handleCategoryFilterClick = (opt: FlightSelectOptions) => {
    setCategoryFilter(opt);
  };

  return (
    <div className="context-attributes">
      {!isNoFilters && (
        <div className="context-attributes__filter">
          <SearchBar
            className="context-attributes__filter__search-bar"
            initialValue={searchTerm}
            handleSearchTermChange={value => setSearchTerm(value)}
            width="160px"
            label="Search"
            labelId="context-attributes__filter__search-bar"
          />
          <FlightSelect
            label="Any input type"
            width="220px"
            selected={dataTypeFilter}
            handleOptionClick={(opt: FlightSelectOptions) => setDataTypeFilter(opt)}
            className="context-attributes__filter__select"
            options={contextDataTypeOptions}
          />
          <FlightSelect
            label="Any category"
            width="240px"
            selected={categoryFilter}
            handleOptionClick={(opt: FlightSelectOptions) => handleCategoryFilterClick(opt)}
            className="context-attributes__filter__select"
            options={getContextAttributeCategoryOptions(contextPlugins, true)}
          />
          <FlightSelect
            label="Any scope"
            width="220px"
            selected={scopeFilter}
            handleOptionClick={(opt: FlightSelectOptions) => setScopeFilter(opt)}
            className="context-attributes__filter__select"
            options={contextAttributesScopeOptions}
          />
        </div>
      )}
      <div aria-live="assertive">
        <FlightTable
          className="context-attributes__table"
          tableHeaders={tableHeaders}
          isLoading={isEmpty(tableData)}
          tableData={tableData}
          hasPaginationBeforeTable={false}
          ariaLabel="context attributes Table"
        />
      </div>
      <ConfirmModal
        isVisible={confirmModalState !== ConfirmModalState.HIDDEN}
        title={confirmModalTitle}
        message={confirmModalMessage}
        continueCallback={handleDeleteConfirmation}
        cancelCallback={hideConfirmModal}
      />
    </div>
  );
}
