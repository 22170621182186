import {
  ManageTemplatesActions,
  ManageTemplatesActionTypes,
  TemplateCategories,
  TemplateImportContent,
} from 'model/manageTemplates';
import ManageTemplatesService from 'services/manageTemplate.service';
import { makeActionCreator } from 'helpers/actionCreator';
import { showNotification } from './snackBarNotifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';

// Template Library & Configs
export const setLibraryTemplatesState = makeActionCreator(ManageTemplatesActionTypes.SET_IMPORT_LIBRARY_STATE);
export const setLibraryCategories = makeActionCreator(ManageTemplatesActionTypes.SET_IMPORT_CATEGORY_STATE);

// Import template data
export const resetImportInfo = makeActionCreator(ManageTemplatesActionTypes.RESET_IMPORT_STATE);
export const setImportStatus = makeActionCreator(ManageTemplatesActionTypes.SET_IMPORT_STATUS_STATE);
export const setImportStatusInterval = makeActionCreator(ManageTemplatesActionTypes.SET_IMPORT_STATUS_INTERVAL_STATE);
export const setImportTemplatePayloadState = makeActionCreator(ManageTemplatesActionTypes.SET_IMPORT_TEMPLATES_STATE);
export const setImportedFileState = makeActionCreator(ManageTemplatesActionTypes.SET_ORIGINAL_IMPORT_TEMPLATES_STATE);
export const setImportConflictsState = makeActionCreator(ManageTemplatesActionTypes.SET_IMPORT_CONFLICTS_STATE);

export function getTemplateLibrary(query?: string) {
  return async (dispatch: { (arg0: ManageTemplatesActions): void }) => {
    try {
      const req = new ManageTemplatesService();
      const response = await req.getTemplateLibrary(query);
      dispatch(setLibraryTemplatesState(response.data.data));
    } catch (error) {
      dispatch(
        showNotification({
          id: generateUniqueId(),
          showNotification: true,
          header: 'Failed To Get Templates',
          body: String(error),
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function getTemplateCategories(callback?: () => void) {
  return async (dispatch: { (arg0: ManageTemplatesActions): void }) => {
    try {
      const req = new ManageTemplatesService();
      const response = await req.getTemplateCategories();
      dispatch(setLibraryCategories(response.data));
      if (callback) callback();
    } catch (error) {
      dispatch(
        showNotification({
          id: generateUniqueId(),
          showNotification: true,
          header: 'Failed To Get Categories',
          body: String(error),
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function updateTemplateCategories(payload: TemplateCategories, callback?: () => void) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new ManageTemplatesService();
      await req.updateTemplateCategories(payload);
      dispatch(getTemplateCategories(callback));
    } catch (error) {
      throw error;
    }

    return 'done';
  };
}

export function getLatestTemplateImportStatus() {
  return async (dispatch: { (arg0: ManageTemplatesActions): void }) => {
    try {
      const req = new ManageTemplatesService();
      const { data } = await req.getLatestImportTemplateStatus();
      dispatch(setImportStatus(data?.[0] || null));
    } catch (error) {
      dispatch(
        showNotification({
          id: generateUniqueId(),
          showNotification: true,
          header: 'Failed To Get Status',
          body: String(error),
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function requestTemplateImport(data: TemplateImportContent) {
  return async (dispatch: { (arg0: ManageTemplatesActions): void }) => {
    try {
      const req = new ManageTemplatesService();
      await req.requestTemplateImport(data);
      getLatestTemplateImportStatus();
    } catch (error) {
      dispatch(
        showNotification({
          id: generateUniqueId(),
          showNotification: true,
          header: 'Failed Import',
          body: String(error),
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function checkForTemplateConflicts(data: TemplateImportContent, callback?: () => void) {
  return async (dispatch: { (arg0: ManageTemplatesActions): void }) => {
    // Set original data
    dispatch(
      setImportTemplatePayloadState({
        name: data.name,
        templates: data.templates?.map(t => ({
          ...t,
          mergeStrategy: 'overwrite',
        })),
        components: data.components,
      }),
    );
    dispatch(setImportedFileState(data));
    try {
      const req = new ManageTemplatesService();
      const response = await req.checkTemplateForConflicts(data);
      if (typeof callback === 'function') callback();
      dispatch(setImportConflictsState(response.data));
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: false,
          header: 'Import failed',
          body: 'Error while checking for conflicts. Check console for more infos',
          type: 'error',
        }),
      );
      throw error;
    }
    return 'done';
  };
}
