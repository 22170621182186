/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './Notifications.scss';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import { useActions } from 'actions';
import * as NotificationsActions from 'actions/snackBarNotifications';
import { FlightSnackbar } from '@flybits/design-system';

const Notifications = () => {
  const notificationState = useSelector((state: RootState) => state.snackBarNotifications);
  const notficationActions = useActions(NotificationsActions);
  const { showNotification, header, body, type } = notificationState;

  const onDismiss = () => {
    notficationActions.hideNotification();
  };

  return (
    <FlightSnackbar
      isVisible={showNotification}
      className="notification"
      title={header}
      dismissAfter={3000}
      content={body}
      isAutoDismiss
      type={type}
      handleClose={() => onDismiss()}
      action={() => onDismiss()}
      actionName="Close"
    />
  );
};

export default Notifications;
