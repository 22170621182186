import React, { useState } from 'react';
import { FlightTable } from '@flybits/design-system';
import './SignInProviders.scss';
import { ReactComponent as EditPen } from 'assets/images/editpenblue.svg';
import { ReactComponent as SignedLoginLogo } from 'assets/images/idplogos/signed-login.svg';
import jwtSignedLoginLogo from 'assets/images/idplogos/jwt-logo.png';
import { ReactComponent as ApiKeyLogo } from 'assets/images/idplogos/api.svg';
import { ReactComponent as SamlLogo } from 'assets/images/idplogos/saml.svg';
import { ReactComponent as OpenIdLogo } from 'assets/images/idplogos/openid.svg';

import ApiKeyModal from './Modals/ApiKeyModal';
import OpenIdConnectModal from './Modals/OpenIdConnectModal';
import SAMLModal from './Modals/SAMLModal';
import SignedLoginModal from './Modals/SignedLoginModal';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AllowedTenants } from 'model/accountAccess/settings';

export default function SignInProviders() {
  const [apiModal, setApiModal] = useState(false);
  const [openIdModal, setOpenIdModal] = useState(false);
  const [samlModal, setSamlModal] = useState(false);
  const [signedLogin, setSignedLoginModal] = useState(false);
  const [isJWTSignedLogin, setIsJWTSignedLogin] = useState(false);

  const openIDSettingsFromState = useSelector((state: RootState) => state.accountAccess.openIDSettings);
  const samlSettingsFromState = useSelector((state: RootState) => state.accountAccess.samlSettings);
  const apiKeySettingsFromState = useSelector((state: RootState) => state.accountAccess.apiKeySettings);
  const tenantAccessSettingsFromState = useSelector((state: RootState) => state.accountAccess.tenantAccessSettings);
  const signedLoginSettingsFromState = useSelector((state: RootState) => state.accountAccess.signedLoginSettings);
  const jwtSignedLoginSettingsFromState = useSelector((state: RootState) => state.accountAccess.jwtSignedLoginSettings);

  const triggerApiModal = () => {
    setApiModal(!apiModal);
  };

  const triggerOpenIdModal = () => {
    setOpenIdModal(!openIdModal);
  };

  const triggerSamlModal = () => {
    setSamlModal(!samlModal);
  };

  const triggerSignedLoginModal = () => {
    setSignedLoginModal(!signedLogin);
  };

  const tableHeaders = [
    {
      name: 'Provider',
      key: 'provider',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Status',
      key: 'status',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Action',
      key: 'actions',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  const allowed: AllowedTenants[] = tenantAccessSettingsFromState?.allowed || [{ type: 0, value: '' }];

  const getStatus = (key: string) => {
    if (allowed.length !== 0) {
      const isEnabled = allowed.find(item => (item.value === key ? true : false));
      if (isEnabled) {
        return <span className="status-text--enabled">Enabled</span>;
      } else {
        return 'Disabled';
      }
    } else {
      return 'Disabled';
    }
  };

  const openModal = (e: { key: number }) => {
    switch (e.key) {
      case 1:
        triggerApiModal();
        break;
      case 2:
        triggerOpenIdModal();
        break;
      case 3:
        triggerSamlModal();
        break;
      case 4:
        setIsJWTSignedLogin(true);
        triggerSignedLoginModal();
        break;
      case 5:
        setIsJWTSignedLogin(false);
        triggerSignedLoginModal();
        break;
      default:
        return;
    }
  };

  const tableBody = [
    {
      provider: (
        <span>
          <ApiKeyLogo className="signin-provider-table__logo" />
          <span>API Key</span>
        </span>
      ),
      status: <span className="signin-provider-table__status-text">{getStatus('apikey')}</span>,
      actions: (
        <span
          className="signin-provider-table__edit-provider"
          tabIndex={0}
          aria-label="edit api key"
          role="button"
          onClick={() => openModal({ key: 1 })}
          onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
              openModal({ key: 1 });
            }
          }}
        >
          <EditPen className="edit-pen" aria-label="edit pen" />
        </span>
      ),
      key: 1,
    },
    {
      provider: (
        <span>
          <OpenIdLogo className="signin-provider-table__logo" />
          <span>Open ID Connect</span>
        </span>
      ),
      status: <span className="signin-provider-table__status-text">{getStatus('oidc')}</span>,
      actions: (
        <span
          className="signin-provider-table__edit-provider"
          tabIndex={0}
          aria-label="edit open id connect"
          role="button"
          onClick={() => openModal({ key: 2 })}
          onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
              openModal({ key: 2 });
            }
          }}
        >
          <EditPen className="edit-pen" />
        </span>
      ),
      key: 2,
    },
    {
      provider: (
        <span>
          <SamlLogo className="signin-provider-table__logo" />
          <span>SAML</span>
        </span>
      ),
      status: <span className="signin-provider-table__status-text">{getStatus('samllogin')}</span>,
      actions: (
        <span
          className="signin-provider-table__edit-provider"
          tabIndex={0}
          aria-label="edit saml"
          role="button"
          onClick={() => openModal({ key: 3 })}
          onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
              openModal({ key: 3 });
            }
          }}
        >
          <EditPen className="edit-pen" />
        </span>
      ),
      key: 3,
    },
    {
      provider: (
        <span>
          <img className="logo" src={jwtSignedLoginLogo} alt="JWT Signed Login Logo" />
          <span>JWT Signed Login</span>
        </span>
      ),
      status: <span className="signin-provider-table__status-text">{getStatus('jwtlogin')}</span>,
      actions: (
        <span
          className="signin-provider-table__edit-provider"
          tabIndex={0}
          aria-label="edit jwt signed login"
          role="button"
          onClick={() => openModal({ key: 4 })}
          onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
              openModal({ key: 4 });
            }
          }}
        >
          <EditPen className="edit-pen" />
        </span>
      ),
      key: 4,
    },
    {
      provider: (
        <span>
          <SignedLoginLogo className="signin-provider-table__logo" />
          <span>Signed Login (deprecated)</span>
        </span>
      ),
      status: <span className="signin-provider-table__status-text">{getStatus('signedlogin')}</span>,
      actions: (
        <span
          className="signin-provider-table__edit-provider"
          tabIndex={0}
          aria-label="edit signed login (deprecated)"
          role="button"
          onClick={() => openModal({ key: 5 })}
          onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
              openModal({ key: 5 });
            }
          }}
        >
          <EditPen className="edit-pen" />
        </span>
      ),
      key: 5,
    },
  ];

  return (
    <div>
      <ApiKeyModal
        openModal={apiModal}
        toggleModal={triggerApiModal}
        apiKeySettingsFromState={apiKeySettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <OpenIdConnectModal
        openModal={openIdModal}
        toggleModal={triggerOpenIdModal}
        openIDSettingsFromState={openIDSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <SAMLModal
        openModal={samlModal}
        toggleModal={triggerSamlModal}
        samlSettingsFromState={samlSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
      />
      <SignedLoginModal
        openModal={signedLogin}
        toggleModal={triggerSignedLoginModal}
        signedLoginSettingsFromState={signedLoginSettingsFromState}
        jwtSignedLoginSettingsFromState={jwtSignedLoginSettingsFromState}
        tenantAccessSettingsFromState={tenantAccessSettingsFromState}
        isJWTSignedLogin={isJWTSignedLogin}
      />
      <FlightTable
        className="signin-provider-table"
        tableHeaders={tableHeaders}
        tableData={tableBody}
        handleDataSelect={openModal}
        allowRowSelect
        hasPaginationBeforeTable={false}
        ariaLabel="Sign in provider Table"
      />
    </div>
  );
}
