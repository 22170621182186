import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { cloneDeep } from 'lodash';
import { useActions } from 'actions';
import * as WebhookActions from 'actions/webhook';
import { history } from 'configureStore';
import { FlightButton } from '@flybits/design-system';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import WebhookEmptyState from 'components/WebhookComponents/WebhookEmptyState';
import WebhooksTable from 'components/WebhookComponents/WebhooksTable';
import { GetWebhooksParams, Webhook } from 'model/webhook';
import './WebhooksPage.scss';

export default function WebhooksPage() {
  const webhooksFromState = useSelector((state: RootState) => state.webhooks.webhooks);
  const webhookActions = useActions(WebhookActions);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [showEmptyState, setShowEmptyState] = useState(false);

  const localWebhooksFromState: Webhook[] = cloneDeep(webhooksFromState);

  const queryParam = new URLSearchParams({
    searchTerm,
  });

  async function fetchWebhooks(params: GetWebhooksParams) {
    await webhookActions.fetchAllWebhooks(params);
  }

  function handleSearchTermChange(value: string) {
    setSearchTerm(value);
  }

  function handleHeaderSort(isDesc: boolean) {
    const sortString = 'name' + (isDesc ? '-' : '+');
    setSortOrder(sortString);
  }

  function handleViewWebhook(webhook: Webhook) {
    webhookActions.setWebhookState(webhook);
    history.push('webhooks/' + webhook.id);
  }

  function handleCreateNew() {
    webhookActions.setWebhookState({} as Webhook);
    history.push('webhooks/create');
  }

  function handleDeleteWebhook(webhook: Webhook) {
    webhookActions.deleteWebhook(webhook);
  }

  async function fetchDependencies() {
    fetchWebhooks({});
  }

  useEffect(() => {
    document.title = "Webhooks | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    fetchDependencies();
    (document.getElementsByClassName('create-webhook-button')?.[0]  as HTMLElement)?.focus();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchWebhooks({ search: searchTerm, ...(sortOrder !== '' && { sort: sortOrder }) });
    if (searchTerm !== '') {
      history.push({
        search: `${queryParam}`,
      });
    } else {
      history.push({
        search: ``,
      });
    }
    //eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    fetchWebhooks({ search: searchTerm, sort: sortOrder });
    //eslint-disable-next-line
  }, [sortOrder]);

  useEffect(() => {
    if (history.location.search === '' && localWebhooksFromState?.length === 0) {
      setShowEmptyState(true);
    } else {
      setShowEmptyState(false);
    }
    //eslint-disable-next-line
  }, [localWebhooksFromState && localWebhooksFromState?.length === 0]);

  return (
    <div role="main" className="webhooks">
      {showEmptyState ? (
        <WebhookEmptyState handleCreateWebhook={handleCreateNew} />
      ) : (
        <div>
          <div>
            <div className="webhooks__header">
              <PageHeader className="webhooks__page-header" title="Webhooks" isLarge={true} />
              <FlightButton className="create-webhook-button" label="Create Webhook" onClick={handleCreateNew} />
            </div>
            <SearchBar
              className="webhooks__search-bar"
              handleSearchTermChange={handleSearchTermChange}
              width="296px"
              label="Search"
              labelId="webhooks__search-bar"/>
            <WebhooksTable
              localWebhooksFromState={localWebhooksFromState}
              handleHeaderSort={handleHeaderSort}
              handleViewWebhook={handleViewWebhook}
              handleDeleteWebhook={handleDeleteWebhook}
            />
          </div>
        </div>
      )}
    </div>
  );
}
