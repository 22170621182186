import { useEffect, useState } from 'react';
import LocalStore from 'services/localstore';
import { AuthContextValue, UserData } from 'auth/types/auth.types';
import { generateAuthCode, generateIdToken, oidcLogin, getUserData } from 'auth/services';
import { getCurrentProjectId } from 'helpers/getCurrentProjectId';
import { cleanUpLocalForage } from 'auth/utils';

const localStore = new LocalStore();

export function useAuthContextValue(): AuthContextValue {
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    date: '',
    currentDeviceID: '',
    projectId: '',
  });

  useEffect(() => {
    (async () => {
      try {
        // For backward compatibility with previous local store
        // which had dependency on `project_id`
        await cleanUpLocalForage('persist:root', 'project_id');

        const projectId = getCurrentProjectId();
        const accessToken = await localStore.getItem(`token+${projectId}`);

        if (accessToken) {
          const { data } = await getUserData(accessToken);

          setToken(accessToken);
          setUserData(data);
          setIsAuthenticated(true);
          setIsLoading(false);
        } else {
          throw new Error();
        }
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoading(false);
        setToken('');
      }
    })();
    //eslint-disable-next-line
  }, []);

  return {
    token,
    isLoading,
    isAuthenticated,
    userData,
    generateAuthCode,
    generateIdToken,
    oidcLogin,
    setToken,
    setIsAuthenticated,
    setUserData,
  };
}
