import { makeActionCreator, makeFetchActionCreator } from 'helpers/actionCreator';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { ServiceAccount, ServiceAccountActionTypes } from 'model/serviceAccount';
import ServiceAccountService from 'services/serviceAccount.service';
import { showNotification } from './snackBarNotifications';

export const setServiceAccountsState = makeActionCreator(ServiceAccountActionTypes.SET_SERVICE_ACCOUNTS_STATE);

export const fetchAllServiceAccounts = makeFetchActionCreator(
  new ServiceAccountService(),
  'getAllServiceAccounts',
  setServiceAccountsState,
  'data',
);

export function createServiceAccount(serviceAccount: ServiceAccount) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ServiceAccountService();
      const response = await req.createServiceAccount(serviceAccount);
      if (response.status === 200) {
        dispatch(fetchAllServiceAccounts({}));
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function deleteServiceAccount(serviceAccount: ServiceAccount) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ServiceAccountService();
      const response = await req.deleteServiceAccount(serviceAccount.id);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: `Successfully deleted service account "${serviceAccount.name}".`,
            type: 'success',
          }),
        );
        dispatch(fetchAllServiceAccounts({}));
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: `Failed to delete service account "${serviceAccount.name}".`,
          type: 'error',
        }),
      );
    }
  };
}
