import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';
import { FcmSettings } from 'model/pushSettings/settings';
import * as FcmActions from 'actions/pushSettings/fcmSettings';
import { useActions } from 'actions';
import { ReactComponent as AndroidLogo } from 'assets/images/android.svg';
import { FlightButton, FlightModal } from '@flybits/design-system';

interface Props {
  fcmSettingsFromState?: FcmSettings;
}

export default function FcmSettingCard(props: Props) {
  const { fcmSettingsFromState } = props;
  const pushActions = useActions(FcmActions);

  const [openConfirm, setOpenConfirm] = useState(false);
  const confirmToggle = () => setOpenConfirm(!openConfirm);

  const key = fcmSettingsFromState?.key || '';
  const maskKey = '000000';
  function maskKeyField() {
    return maskKey.replace(/.(?=.{0,}$)/g, '*');
  }

  function deleteFcmSettings() {
    pushActions.deleteFcmSettings();
    confirmToggle();
  }

  return (
    <div className="push-settings">
      <div>
        <FlightModal
          isVisible={openConfirm}
          size="medium"
          toggleModalShown={confirmToggle}
          className="push-settings__modal"
          header={<span>Confirm</span>}
          content={<p>Are you sure you want to delete your FCM server key?</p>}
          footer={
            <div className="modal-footer">
              <FlightButton theme="secondary" onClick={confirmToggle} label="Cancel" />
              <FlightButton type="submit" label="Continue" onClick={deleteFcmSettings} />
            </div>
          }
        />
      </div>
      <Container>
        <Row>
          <AndroidLogo />
          <span id="fcm-container-heading" className="push-settings__header">
            <h2>FCM Server Key</h2>
          </span>
        </Row>
        <Row>
          {!key ? (
            <span className="push-settings__body">You haven&apos;t specified your FCM server key.</span>
          ) : (
            <div className="push-settings__info">
              <span className="property">Server Key</span>
              <div className="value key">
                {maskKeyField()}
                {key}
              </div>
            </div>
          )}
          <p>On June 20, 2024, Google will be discontinuing legacy FCM APIs.</p>
          <p>As a result, all Flybits projects using FCM will required to upgrade to V1.</p>
          <p>
            {/*noreferrer is no longer needed on post 2021 browsers, but eslint disagrees :(*/}
            <a target="_blank" rel="noreferrer" href="https://firebase.google.com/support/faq#fcm-depr-features">
              Here
            </a>
            &nbsp;is a list of affected APIs, and&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://flybits.gitbook.io/android/v/uploading-your-clients-fcm-token-to-flybits/"
            >
              here
            </a>
            &nbsp;you can find a guide on upgrading your Flybits Concierge/SDK.
          </p>
        </Row>
        <Row>
          <FlightButton
            label={key === '' ? '' : 'Remove'}
            size="medium"
            theme="link"
            className="push-settings__btn--red"
            onClick={confirmToggle}
            isAriaDescribedBy
            ariaDescribedBy={'fcm-container-heading'}
            ariaLabel="Remove"
          />
        </Row>
      </Container>
    </div>
  );
}
