export interface AnalyticsExportState {
  whitelistedDomains: string[];
}

export enum AnalyticsExportActionTypes {
  SET_WHITELISTED_DOMAINS_STATE = 'SET_WHITELISTED_DOMAINS_STATE',
}
export interface SettingsAPIPayload {
  configName: {
    configName: string;
    tenant: {
      tenantId: string;
    };
  };
  settings: {
    key: string[];
  };
}

interface AnalyticsExportActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type AnalyticsExportActions = AnalyticsExportActionCreatorType<
  typeof AnalyticsExportActionTypes.SET_WHITELISTED_DOMAINS_STATE,
  AnalyticsExportState
>;
