import React, { useEffect } from 'react';
import EmailUsageAlerts from '../../components/ApiUsageComponents/EmailUsageAlerts/EmailUsageAlerts';
import Subscriptions from '../../components/ApiUsageComponents/Subscriptions/Subscriptions';
import { useSelector } from 'react-redux';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { RootState } from 'reducers';
import { useActions } from 'actions';
import * as ApiUsageActions from 'actions/apiUsage';
import './ApiUsage.scss';

export default function ApiUsagePage() {
  const subscriptionsFromState = useSelector((state: RootState) => state.apiUsage.subscriptions);
  const ratelimitsFromState = useSelector((state: RootState) => state.apiUsage.ratelimits);
  const apiUsageActions = useActions(ApiUsageActions);

  const routeParamsId = window.location.href.split('/')[4];

  async function fetchProjectDependencies() {
    await apiUsageActions.fetchAllSubscriptions();
    await apiUsageActions.fetchRatelimitsForProject(routeParamsId);
  }

  useEffect(() => {
    document.title = "API Usage | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);
  return (
    <div role="main" className="api-usage-page">
      <PageHeader title="API Usage" isLarge={true} />
      <div className="api-usage-page__content">
        <Subscriptions ratelimitsFromState={ratelimitsFromState} />
        <EmailUsageAlerts subscriptionsFromState={subscriptionsFromState} />
      </div>
    </div>
  );
}
