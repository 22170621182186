import {
  ContextPlugin,
  ContextPluginsActionTypes,
  ContextPluginsActions,
  CreateContextPlugin,
  ContextPluginValueRemappingData,
} from 'model/contextPlugins';
import ContextService from 'services/context.service';
import { makeActionCreator } from 'helpers/actionCreator';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './snackBarNotifications';

export const setContextPluginState = makeActionCreator(ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_STATE);
export const setContextPluginAttributes = makeActionCreator(ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_ATTRIBUTES);
export const setContextPluginsState = makeActionCreator(ContextPluginsActionTypes.SET_CONTEXT_PLUGINS_STATE);
export const setChangedPlugin = makeActionCreator(ContextPluginsActionTypes.SET_CHANGE_PLUGIN);

// TODO: We should refactor this so the payload is a boolean & we set the isCreateAttribute property in the reducer
// This always just sets the attribute of the current ContextPluginState so passing a contextplugin is redundant
export function setCreateAttribute(contextPlugin: ContextPlugin, isCreateAttribute: boolean): ContextPluginsActions {
  contextPlugin.isCreateAttribute = isCreateAttribute;
  return {
    type: ContextPluginsActionTypes.SET_CREATE_ATTRIBUTE,
    payload: contextPlugin,
  };
}

// TODO: We should refactor this so the payload is a string & we set the isViewAttribute property in the reducer
// This always just sets the attribute of the current ContextPluginState so passing a contextplugin is redundant
export function setViewAttribute(contextPlugin: ContextPlugin, name: string): ContextPluginsActions {
  contextPlugin.isViewAttribute = name;
  return {
    type: ContextPluginsActionTypes.SET_VIEW_ATTRIBUTE,
    payload: contextPlugin,
  };
}

export function fetchContextPlugins() {
  return async (dispatch: { (arg0: ContextPluginsActions): void }) => {
    try {
      const req = new ContextService();
      const params = {
        limit: 1000000,
      };
      const response = await req.getAllContextPlugins(params);
      const contextPlugins = response.data.filter(
        (plugin: ContextPlugin) =>
          plugin.uid !== 'ctx.flybits.contentDeviceAnalytics' &&
          plugin.uid !== 'ctx.flybits.experienceContentDeviceAnalytics' &&
          plugin.uid !== 'ctx.flybits.pushContentDeviceAnalytics' &&
          plugin.uid !== 'ctx.flybits.pushDeviceAnalytics',
      );
      dispatch(setContextPluginsState(contextPlugins));
    } catch (error) {}
    return 'done';
  };
}

export function fetchContextPlugin(contextPluginId: string) {
  return async (dispatch: { (arg0: ContextPluginsActions): void }) => {
    try {
      const req = new ContextService();
      const response = await req.getContextPlugin(contextPluginId);

      dispatch(setContextPluginState(response.data));
    } catch (error) {}
    return 'done';
  };
}

export function createContextPlugin(params: CreateContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.createContextPlugin(params);
      if (response.status === 200 || response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Context category created.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'error',
        }),
      );
    }
  };
}

export function deleteContextPlugin(contextPluginId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.deleteContextPlugin(contextPluginId);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Context category deleted.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to delete category.',
          type: 'error',
        }),
      );
    }
  };
}

export function updateContextPlugin(params: ContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.updateContextPlugin(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Context category Updated.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'error',
        }),
      );
    }
  };
}

export function disableContextPluginStatus(params: ContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.disableContextPluginStatus(params);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Disabled category',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed to disable category',
          body: error.response.data?.error.exceptionMessage,
          type: 'error',
        }),
      );
    }
  };
}

export function enableContextPluginStatus(params: ContextPlugin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.enableContextPluginStatus(params);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Activated category',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Error activating context category',
          type: 'error',
        }),
      );
    }
  };
}

export function updateContextPluginValueRemapping(data: ContextPluginValueRemappingData[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContextService();
      const response = await req.updateContextPluginValueRemapping(data);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Updated context category value remapping.',
            type: 'success',
          }),
        );
        dispatch(fetchContextPlugins());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Error updating context category value remapping.',
          type: 'error',
        }),
      );
    }
  };
}
