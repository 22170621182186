import React, { useState, useEffect, useRef } from 'react';
import {
  FlightModal,
  FlightButton,
  FlightTextArea,
  FlightTextInput,
  FlightToggleSwitch,
} from '@flybits/design-system';
import { ReactComponent as SamlLogo } from 'assets/images/idplogos/saml.svg';
import './IdpModal.scss';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import { SamlConfigs, TenantAccessSettings, AllowedTenants } from 'model/accountAccess/settings';
import { flatten, cloneDeep, debounce } from 'lodash';
import * as AccountAccessActions from 'actions/accountAccess';
import { useActions } from 'actions';
import * as Yup from 'yup';
import { ACS_URL_REQUIRED, PUBLIC_CERT_REQUIRED } from 'constants/errors/errors';
import { history } from 'configureStore';
import { SAML_CERT_TEXT_MAX_LENGTH } from 'constants/authCertOptions';

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  samlSettingsFromState?: SamlConfigs[];
  tenantAccessSettingsFromState?: TenantAccessSettings;
}

const validationSchema = Yup.object().shape({
  acsURL: Yup.string().required(ACS_URL_REQUIRED),
  publicCert: Yup.string().required(PUBLIC_CERT_REQUIRED),
});

export default function SAMLModal(props: Props) {
  const { openModal, toggleModal, samlSettingsFromState = [], tenantAccessSettingsFromState } = props;
  const [checked, setChecked] = useState(false);
  const accountAccessActions = useActions(AccountAccessActions);
  const [checkedStateApi, setCheckedStateApi] = useState(false);

  let acsURL = '';
  let idpIssuer = '';
  let idpMetadataURL = '';
  let idpSSOURL = '';
  let publicCert = '';
  const skipSignatureValidation = false;
  const pathname = history.location.pathname;
  const tenantID = pathname.slice(9, 45);
  const localSamlSettings = flatten(cloneDeep(samlSettingsFromState));

  if (localSamlSettings.length !== 0) {
    [{ acsURL, idpIssuer, idpMetadataURL, idpSSOURL, publicCert }] = localSamlSettings;
  }

  const allowed: AllowedTenants[] = tenantAccessSettingsFromState?.allowed || [{ type: 0, value: '' }];

  let allowedFacebookSetting: AllowedTenants[] = [{ type: 0, value: '' }];
  if (allowed.length !== 0) {
    allowedFacebookSetting = allowed.filter(item => item.value === 'samllogin');
  }

  useEffect(() => {
    if (allowedFacebookSetting.length !== 0 && allowedFacebookSetting[0].value !== '') {
      setCheckedStateApi(true);
    }
    return () => {
      setCheckedStateApi(false);
    };
    // eslint-disable-next-line
  }, [allowedFacebookSetting]);

  useEffect(() => {
    setChecked(checkedStateApi);
    return () => {
      setChecked(checkedStateApi);
    };
    // eslint-disable-next-line
  }, [checkedStateApi]);

  const [certTextLength, setCertTextLength] = useState(0);
  const certTextArea = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (!openModal) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const certTextKeydownListener = debounce((keydownEvent: any) => {
      setCertTextLength(keydownEvent.target.value?.length || 0);
    }, 400);

    certTextArea.current = document.querySelector(
      '.idp-modal__content__text-field-container__text-field .flight-text-area .flight-text-area__textarea',
    );

    certTextArea.current?.addEventListener('keydown', certTextKeydownListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const certTextLengthErrorMsg = `The certificate length exceeds ${SAML_CERT_TEXT_MAX_LENGTH} characters`;
  const [certTextLengthError, setCertTextLengthError] = useState(false);

  useEffect(() => {
    certTextLength > SAML_CERT_TEXT_MAX_LENGTH ? setCertTextLengthError(true) : setCertTextLengthError(false);
  }, [certTextLength]);

  return (
    <div className="idp-modal">
      <Formik
        initialValues={{
          acsURL: acsURL,
          idpSSOURL: idpSSOURL,
          idpIssuer: idpIssuer,
          idpMetadataURL: idpMetadataURL,
          publicCert: publicCert,
        }}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        validateOnMount={true}
        onSubmit={(values, { resetForm }) => {
          const objectForApi = {
            acsURL: values.acsURL,
            idpIssuer: values.idpIssuer,
            idpSSOURL: values.idpSSOURL,
            idpMetadataURL: values.idpMetadataURL,
            publicCert: values.publicCert,
            skipSignatureValidation: skipSignatureValidation,
            tenantID: tenantID,
          };
          accountAccessActions.createOrUpdateSamlSettings(objectForApi);
          const allowedTenants: AllowedTenants[] = allowed;
          allowedTenants.push({
            type: 1,
            value: 'samllogin',
          });
          if (checked) {
            const enableAPKeySettings = {
              allowed: allowedTenants,
            };
            accountAccessActions.createOrUpdateTenantAccessSettings(enableAPKeySettings);
          } else {
            const disableApiKeySettings = {
              allowed: allowed.filter(item => item.value !== 'samllogin'),
            };
            accountAccessActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
          }
          resetForm();
          toggleModal();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, isValid }) => (
          <FlightModal
            isVisible={openModal}
            toggleModalShown={() => {
              setChecked(checkedStateApi);
              handleReset();
              toggleModal();
            }}
            scrollable={false}
            size="small"
            content={
              <Col className="idp-modal__content">
                <Row className="idp-modal__content__content-header">
                  <SamlLogo className="idp-modal__content__content-header__header-logo" />
                  <span className="idp-modal__content__content-header__header-text">SAML</span>
                </Row>
                <Col className="idp-modal__content__toggle">
                  <Row>
                    <FlightToggleSwitch checked={checked} onChange={setChecked} label={'Enable'} disabled={!isValid} />
                  </Row>
                </Col>
                <Col className="idp-modal__content__text-field-container">
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="acsURL"
                      label="ACS URL"
                      width="345px"
                      hasError={touched.acsURL && errors.acsURL ? true : false}
                      value={values.acsURL}
                      onBlur={handleBlur}
                      errorMessage={<span>{errors.acsURL}</span>}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="idpSSOURL"
                      label="SSO Url"
                      width="345px"
                      value={values.idpSSOURL}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="idpIssuer"
                      label="Issuer"
                      width="345px"
                      value={values.idpIssuer}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="idpMetadataURL"
                      label="Metadata URL"
                      width="345px"
                      value={values.idpMetadataURL}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextArea
                      width="345px"
                      name="publicCert"
                      label="Public Certificate"
                      value={values.publicCert}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={(touched.publicCert && errors.publicCert) || certTextLengthError ? true : false}
                      errorMessage={certTextLengthError ? certTextLengthErrorMsg : errors.publicCert}
                      maxLength={SAML_CERT_TEXT_MAX_LENGTH + 1}
                      hideCharCounter
                    />{' '}
                  </Row>
                </Col>
              </Col>
            }
            footer={
              <div className="modal-footer">
                <FlightButton
                  onClick={() => {
                    setChecked(checkedStateApi);
                    handleReset();
                    toggleModal();
                  }}
                  label="Cancel"
                  theme="secondary"
                />
                <FlightButton
                  type="primary"
                  label="Apply Changes"
                  onClick={handleSubmit}
                  disabled={!isValid || certTextLengthError}
                />{' '}
              </div>
            }
          />
        )}
      </Formik>
    </div>
  );
}
