import React, { useState, useEffect, useCallback } from 'react';
import { FlightButton, FlightTooltip, getIcon } from '@flybits/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useActions } from 'actions';
import * as ManageTemplatesActions from 'actions/manageTemplates';
import LocalStore from 'services/localstore';
import { getCurrentProjectId } from 'helpers/getCurrentProjectId';
import axios from 'axios';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import SearchBar from 'components/Shared/SearchBar/SearchBar';

import { ImportTemplateModal } from 'components/ImportTemplateModal/ImportTemplateModal';
import { ExportTemplateModal } from 'components/ExportTemplateModal/ExportTemplateModal';
import TemplateViewTable from 'components/TemplateViewTable/TemplateViewTable';
import './ManageTemplatesPage.scss';
import { TemplatePayload } from 'model/manageTemplates';
import { isEmpty } from 'lodash';
import { showNotification } from 'actions/snackBarNotifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import CustomCategoriesModal from 'components/CustomCategoriesModal/CustomCategoriesModal';

export default function ManageTemplatesPage() {
  const [selectedTemplates, setSelectedTemplates] = useState<TemplatePayload[]>([]);
  const [selectedTemplateIDs, setSelectedTemplateIDs] = useState<string[]>([]);
  const [isMultipleExport, setIsMultipleExport] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [enableImport, setEnableImport] = useState(true);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const dispatch = useDispatch();
  const manageTemplatesActions = useActions(ManageTemplatesActions, []);
  const { importLibraryTemplates, importStatusInterval, importStatus } = useSelector(
    (state: RootState) => state?.manageTemplates,
  );

  const filteredTemplates = !!searchText
    ? importLibraryTemplates?.filter(template => template.name?.toUpperCase()?.includes(searchText.toUpperCase())) || []
    : importLibraryTemplates;

  const tableDataKey = new Set(selectedTemplateIDs);

  const fetchLibraryDependencies = useCallback(async () => {
    await manageTemplatesActions.getTemplateCategories();
    await manageTemplatesActions.getTemplateLibrary('?limit=1000&offset=0&search=');
    await manageTemplatesActions.getLatestTemplateImportStatus();
    setIsLoadingTemplates(false);
  }, [manageTemplatesActions]);

  const toggleImportModal = () => {
    setShowImportModal(showImportModal => !showImportModal);
  };

  const toggleExportModal = () => {
    setShowExportModal(showExportModal => !showExportModal);
  };

  const toggleCategoriesModal = () => {
    setShowCategoriesModal(showCategoriesModal => !showCategoriesModal);
  };

  const handleSingleExportTemplateClick = (template: TemplatePayload, multiple: boolean) => {
    setSelectedTemplates([template]);
    setIsMultipleExport(multiple);
    toggleExportModal();
  };

  const handleSingleDeleteTemplateClick = async (template: string) => {
    const localStore = new LocalStore();
    const projectId = getCurrentProjectId();
    const origin = process.env.REACT_APP_HOST_URL;
    const url = `${origin}/kernel/journey/templates/${template}?deleteComponents=true`;
    const token = await localStore.getItem(`token+${projectId}`);
    await axios({
      method: 'delete',
      url: url,
      headers: { 'X-Authorization': token },
    });
    await manageTemplatesActions.getTemplateLibrary('?limit=1000&offset=0&search=');
  };

  const handleMultipleExportTemplateClick = () => {
    setIsMultipleExport(true);
    toggleExportModal();
  };

  const handleExportSuccess = useCallback(() => {
    setSelectedTemplates([]);
    setSelectedTemplateIDs([]);
  }, []);

  const handleImportSuccess = useCallback(() => {
    setIsLoadingTemplates(true);
    fetchLibraryDependencies();
    toggleImportModal();
    clearInterval(importStatusInterval);
    dispatch(
      showNotification({
        id: generateUniqueId(),
        showNotification: true,
        header: 'Success',
        body: 'The pending import was finished successfully.',
        type: 'success',
      }),
    );
  }, [dispatch, fetchLibraryDependencies]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleImportError = useCallback(() => {
    clearInterval(importStatusInterval);
    dispatch(
      showNotification({
        id: generateUniqueId(),
        showNotification: true,
        header: 'Error',
        body: 'There was an error while importing the templates.',
        type: 'error',
      }),
    );
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableRowSelect = (template: any) => {
    if (template.key) {
      if (selectedTemplateIDs?.includes(template.key)) {
        const removeTemplate = selectedTemplateIDs?.filter(key => key !== template.key);
        const removeMultiTemplate = selectedTemplates?.filter(temp => temp.id !== template.key);
        setSelectedTemplateIDs(removeTemplate);
        setSelectedTemplates(removeMultiTemplate);
      } else {
        const templatesToAdd = importLibraryTemplates?.filter(
          temp => temp.id === template?.key && temp.provider === 'Custom',
        );
        setSelectedTemplateIDs(oldState => [...oldState, ...templatesToAdd.map(template => template.id)]);
        setSelectedTemplates([...selectedTemplates, ...templatesToAdd]);
      }
    }
  };

  const handleTableRowSelectAll = () => {
    if (!isEmpty(selectedTemplates)) {
      setSelectedTemplates([]);
      setSelectedTemplateIDs([]);
    } else {
      const templatesToAdd = importLibraryTemplates.filter(
        (template: TemplatePayload) => template.provider === 'Custom',
      );
      setSelectedTemplates(templatesToAdd);
      setSelectedTemplateIDs(templatesToAdd.map(template => template.id));
    }
  };

  useEffect(() => {
    document.title = 'Experience Templates | Developer Portal @ Flybits';
  }, []);

  useEffect(() => {
    fetchLibraryDependencies();
  }, [fetchLibraryDependencies]);

  useEffect(() => {
    (document.getElementsByClassName('flight-text-input__input')?.[0] as HTMLElement).focus();
  }, []);

  useEffect(() => {
    const startChecking = async () => {
      if (importStatus?.status === 'processing') {
        setEnableImport(false);
        setTimeout(() => {
          manageTemplatesActions.getLatestTemplateImportStatus();
        }, 2000);
      } else {
        setEnableImport(true);
      }
    };

    startChecking();
  }, [importStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  const copyErrorToClipboard = () => {
    navigator.clipboard.writeText(importStatus.errMsg).then(() => {
      /* clipboard successfully set */
      dispatch(
        showNotification({
          id: generateUniqueId(),
          showNotification: true,
          header: 'Success',
          body:
            'The import error was successfully copied to clipboard. It was also printed on the console for better debugging :)',
          type: 'success',
        }),
      );
      // TODO: Uncomment this for debugging purposes
      //       const errorBanner = `
      // __________________________________________________________________________

      //   PREVIOUS IMPORT ERROR:

      //   Name: ${importStatus.Name}
      //   Request ID: ${importStatus.requestId}
      //   ID: ${importStatus.id}
      //   Tenant ID: ${importStatus.tenantId}
      //   Creator ID: ${importStatus.creatorId}
      //   Created At: ${importStatus.createdAt}
      //   Finished At: ${importStatus.finishedAt}
      //   Status: ${importStatus.status}
      //   ---
      //   Error message:
      //    ${importStatus.errMsg}
      // __________________________________________________________________________
      // `;
      // console.clear();
      // console.log(`%c${errorBanner}`, 'font-family: monospace; font-size: 10pt; white-space: pre;');
    });
  };
  return (
    <div role="main" className="manage-templates-page">
      {showImportModal && (
        <ImportTemplateModal
          toggleModal={toggleImportModal}
          onImportSuccess={handleImportSuccess}
          onImportError={handleImportError}
        />
      )}
      <ExportTemplateModal
        templates={selectedTemplates}
        toggleModal={toggleExportModal}
        openModal={showExportModal}
        directExport={!isMultipleExport}
        onSuccess={handleExportSuccess}
      />
      {showCategoriesModal && <CustomCategoriesModal toggleModal={toggleCategoriesModal} />}
      <PageHeader title="Experience templates" isLarge={true} />
      <div className="manage-templates-page__topbar">
        <SearchBar
          className="manage-templates-page__search-bar"
          initialValue={searchText}
          handleSearchTermChange={value => setSearchText(value)}
          placeholderText={'Search templates by name'}
          width="300px"
          label="Search"
          labelId="manage-templates-page__search-bar"
        />
        <div className="manage-templates-page__topbar__buttons">
          <FlightButton
            label="Custom categories"
            theme="secondary"
            onClick={toggleCategoriesModal}
            iconLeft="categories"
          />
          <FlightButton
            label="Export custom"
            disabled={isEmpty(selectedTemplates)}
            theme="secondary"
            onClick={handleMultipleExportTemplateClick}
            iconLeft="export"
          />
          <span className="import_button">
            <FlightButton
              label="Import custom"
              theme="secondary"
              onClick={toggleImportModal}
              loading={!enableImport}
              disabled={!enableImport}
              iconLeft="download"
            />
            {importStatus?.status === 'failed' && (
              <span className="import_error_icon" onClick={copyErrorToClipboard}>
                <FlightTooltip
                  direction="top right"
                  delay={400}
                  description={`An error occured while trying to import the template "${importStatus.Name}". Click to copy the full error to clipboard.`}
                >
                  {getIcon('error', {}) ?? ''}
                </FlightTooltip>
              </span>
            )}
          </span>
        </div>
      </div>
      <div className="manage-templates-page__content">
        <div className="manage-templates-page__content__list-view" aria-live="assertive">
          <TemplateViewTable
            templates={filteredTemplates}
            tableDataKey={tableDataKey}
            isLoading={isLoadingTemplates}
            onExportTemplateClick={handleSingleExportTemplateClick}
            onDeleteTemplateClick={handleSingleDeleteTemplateClick}
            onTableRowSelect={handleTableRowSelect}
            onTableRowSelectAll={handleTableRowSelectAll}
          />
        </div>
      </div>
    </div>
  );
}
