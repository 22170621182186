import createReducer from './createReducer';
import { ApiUsage, ApiUsageAction, ApiUsageActionTypes } from 'model/apiUsage';

const initialState = {} as ApiUsage;

export const apiUsage = createReducer<ApiUsage>(initialState, {
  [ApiUsageActionTypes.SET_SUBSCRIPTIONS_STATE](state: ApiUsage, action: ApiUsageAction) {
    return { ...state, subscriptions: action.payload };
  },
  [ApiUsageActionTypes.SET_RATELIMITS_STATE](state: ApiUsage, action: ApiUsageAction) {
    return { ...state, ratelimits: action.payload };
  },
});
