import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalStore from 'services/localstore';
import { AuthContext } from 'auth/providers/AuthProvider';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { getUserData } from 'auth/services';

const localStore = new LocalStore();

type TProps = {
  match: {
    params: { projectId: string };
  };
};

function ExternalAuthRedirect({
  match: {
    params: { projectId },
  },
}: TProps) {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  const authToken = urlParams.get('token');
  const authUrl = urlParams.get('authUrl') || 'NOTSET';

  const { setIsAuthenticated, setUserData, setToken } = useContext(AuthContext);
  const history = useHistory();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      if (projectId && authToken) {
        await localStore.setItem(`token+${projectId}`, authToken);
        await localStore.setItem(`external_auth_url+${projectId}`, authUrl);

        try {
          const { data } = await getUserData(authToken);

          setToken(authToken);
          setUserData(data);
          setIsAuthenticated(true);

          history.push(`/project/${projectId}`);
        } catch (error) {
          if (authUrl.includes('.flybits.com')) {
            window.location.assign(authUrl);
          } else {
            setIsError(true);
          }
        }
      } else {
        setIsError(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingIcon width={80} height={80} fullScreen visible={!isError} />
      {isError && <NotFoundPage isError isExtAuthError />}
    </>
  );
}

export default ExternalAuthRedirect;
