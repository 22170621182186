import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { FlightTextInput } from '@flybits/design-system';
import { FormGroup, Button } from 'reactstrap';
import { ReactComponent as ClearIcon } from 'assets/images/clear-icon.svg';
import './ArrayFields.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data: any) => void;
  initialValues: { values: Array<string> };
  placeHolderText?: string;
  validationSchema?: {};
  className?: string;
  width?: string;
  hasError: (data: boolean) => void;
}

interface ArrayForm {
  values: Array<string>;
}
export const ArrayFields = (props: Props) => {
  const { callback, initialValues, placeHolderText, hasError, validationSchema, className, width } = props;
  const MAIN_CLASS = className ? `${className} array-fields` : 'array-fields';
  const onFormSubmit = (values: ArrayForm) => {
    const removeStrays = values.values.filter(function(str: string) {
      return /\S/.test(str);
    });
    callback(removeStrays);
  };
  function setUpdatedValue(values: ArrayForm) {
    onFormSubmit(values);
  }

  // eslint-disable-next-line
  const inputRef = useRef<any>();

  function handleDelete(values: ArrayForm, index: number) {
    values.values.splice(index, 1);
    setUpdatedValue(values);
  }

  const triggerAdd = () => {
    if (inputRef.current && inputRef.current.onClick) {
      inputRef.current.onClick();
    }
  };

  const keyHandler = useCallback((event: { key: string }) => {
    if (event.key === "Enter") {
      triggerAdd();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyHandler, false);
    return () => {
      document.removeEventListener('keydown', keyHandler, false);
    };
  });

  return (
    <div className={MAIN_CLASS}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async () => onFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur }) => (
          <div>
            <Form onBlur={() => setUpdatedValue(values)}>
              <FormGroup>
                <FieldArray
                  name="values"
                  render={arrayHelpers => (
                    <div>
                      <div>
                        <Button
                          type="button"
                          className="array-fields__btn-no-display"
                          ref={inputRef}
                          onClick={() => (errors.values ? null : arrayHelpers.push(''))}
                        />
                        {values.values && values.values.length > 0 ? (
                          <>
                            {values.values.map((field, index) => {
                              if (errors?.values) hasError(true)
                              else hasError(false)

                              return (
                                <div key={index} className="array-fields__field-array">
                                  <div className={`array-fields__index-box ${errors?.values?.[index] ? 'array-fields__index-box--error' : ''}`}>{index + 1}</div>
                                  <div className="array-fields__input-wrapper">
                                      <ClearIcon
                                        className="flight-text-input__input__clear-icon"
                                        onBlur={handleBlur}
                                        role="button"
                                        aria-label={"delete current user " + field + " from whitelist"}
                                        tabIndex={0}
                                        onKeyDown={(event) => {
                                        if(event.key === "Enter"){
                                          if (index === 0 && values.values.length === 1) {
                                            if (values.values[0] === '') {
                                              return;
                                            } else {
                                              arrayHelpers.handleRemove(index);
                                              handleDelete(values, 0);
                                              arrayHelpers.push('');
                                            }
                                          } else {
                                            arrayHelpers.handleRemove(index);
                                            handleDelete(values, index);
                                          }
                                        }
                                      }}
                                        onClick={() => {
                                          if (index === 0 && values.values.length === 1) {
                                            if (values.values[0] === '') {
                                              return;
                                            } else {
                                              arrayHelpers.handleRemove(index);
                                              handleDelete(values, 0);
                                              arrayHelpers.push('');
                                            }
                                          } else {
                                            arrayHelpers.handleRemove(index);
                                            handleDelete(values, index);
                                          }
                                        }}
                                      />
                                    <FlightTextInput
                                      name={`values.${index}`}
                                      onChange={handleChange}
                                      placeholderText={placeHolderText || ''}
                                      value={field}
                                      width={width}
                                      hasError={errors?.values?.[index] ? true : false}
                                      onBlur={handleBlur}
                                      trailingIconTooltipDescription={"Invalid Domain"}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                            <button
                              className="array-fields__add-field"
                              ref={inputRef}
                              onClick={() => arrayHelpers.push('')}
                              tabIndex={0}
                              aria-label="add another"
                            >
                              + Add another
                            </button>
                          </>
                        ) : (
                          <button
                            className="array-fields__add-field"
                            ref={inputRef}
                            onClick={() => arrayHelpers.push('')}
                          >
                            Add field
                          </button>
                        )}
                      </div>
                      <div className="array-fields__error">
                        {errors.values
                          ?.toString()
                          .replace(/,/g, '')
                          .split('.', 1)}
                      </div>
                    </div>
                  )}
                />
              </FormGroup>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ArrayFields;
