import BaseService from './base.service';
import { GetDatasourcesParams } from '../model/datasource';

export default class DatasourcesService extends BaseService {
  CURRENT_PATH: string;
  DATASOURCE_RESOURCE: string;

  constructor() {
    super();
    this.CURRENT_PATH = 'context/integrations-manager/';
    this.DATASOURCE_RESOURCE = 'datasources';
  }

  async getAllDatasources(params: GetDatasourcesParams) {
    return this.request('GET', this.CURRENT_PATH + this.DATASOURCE_RESOURCE, params);
  }
}
