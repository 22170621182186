import { Webhook, WebhookActionTypes, RuleForwardingData } from 'model/webhook';
import ContextService from 'services/context.service';
import WebhookService from 'services/webhook.service';
import { updateRuleForwarding as updateTemplateRuleForwarding } from 'actions/contentTemplates';
import { makeActionCreator, makeFetchActionCreator } from 'helpers/actionCreator';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { showNotification } from './snackBarNotifications';

export const setWebhooksState = makeActionCreator(WebhookActionTypes.SET_WEBHOOKS_STATE);
export const setWebhookState = makeActionCreator(WebhookActionTypes.SET_WEBHOOK_STATE);

export const fetchAllWebhooks = makeFetchActionCreator(
  new WebhookService(),
  'getAllWebhooks',
  setWebhooksState,
  'data',
);

export function createWebhook(webhook: Webhook) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new WebhookService();
      const response = await req.createWebhook(webhook);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: `Webhook ${webhook.name} created.`,
            type: 'success',
          }),
        );
        dispatch(fetchAllWebhooks({}));
        return response.data;
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: `Failed to create webhook ${webhook.name}.`,
          type: 'error',
        }),
      );
    }
  };
}

export function updateWebhook(webhook: Webhook) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new WebhookService();
      const response = await req.updateWebhook(webhook);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: `Webhook ${webhook.name} updated.`,
            type: 'success',
          }),
        );
        dispatch(fetchAllWebhooks({}));
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: `Failed to update webhook ${webhook.name}.`,
          type: 'error',
        }),
      );
    }
  };
}

export function updateRuleForwarding(ruleId: string, data: RuleForwardingData[]) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ContextService();
      await req.updateRuleForwarding(ruleId, data);
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: `Failed to update rule for ${ruleId}.`,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function deleteWebhook(webhook: Webhook) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new WebhookService();
      const response = await req.deleteWebhook(webhook.id);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: `Successfully deleted webhook ${webhook.name}.`,
            type: 'success',
          }),
        );
        dispatch(fetchAllWebhooks({}));

        const rulesToRemove: { [ruleId: string]: boolean } = {};
        for (const triggerAction in webhook.triggers) {
          for (const rule of webhook.triggers[triggerAction]) {
            rulesToRemove[rule.id] = triggerAction !== 'onInstanceCreated';
          }
        }

        for (const ruleId in rulesToRemove) {
          const data: RuleForwardingData[] = [{ op: 'remove', dest: 'webhook', id: webhook.id }];
          if (rulesToRemove[ruleId]) {
            dispatch(updateRuleForwarding(ruleId, data));
          } else {
            dispatch(updateTemplateRuleForwarding(ruleId, data));
          }
        }
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: `Failed to delete webhook ${webhook.name}.`,
          type: 'error',
        }),
      );
    }
  };
}
