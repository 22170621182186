export function getValueType(type: string | undefined, valueType: string | undefined) {
  if (type && valueType) {
    if (type === 'primitive' && valueType === 'bool') {
      return 'Yes/No';
    } else if (type === 'dropdown' && valueType === 'string') {
      return 'Text Dropdown';
    } else if (type === 'primitive' && valueType === 'string') {
      return 'Text Field';
    } else if (type === 'primitive' && valueType === 'int') {
      return 'Integer Field';
    } else if (type === 'dropdown' && valueType === 'float') {
      return 'Float Dropdown';
    } else if (type === 'primitive' && valueType === 'float') {
      return 'Float Field';
    } else if (type === 'dropdown' && valueType === 'int') {
      return 'Integer Dropdown';
    } else if (type === 'url' && valueType === 'string') {
      return 'Weblink';
    } else if (type === 'json' && valueType === 'string') {
      return 'JSON Object';
    } else if (type === 'image' && valueType === 'string') {
      return 'Image Picker';
    } else if (type === 'entity' && valueType === 'string') {
      return 'Content Picker';
    }
  }

  return '';
}
