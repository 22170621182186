import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { FlightNav, FlightButton } from '@flybits/design-system';
import { AuthContext } from 'auth/providers/AuthProvider';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import Jdenticon from 'components/Shared/Jdenticon/Jdenticon';
import { cleanUpLocalForage } from 'auth/utils';
import { getCurrentProjectId } from 'helpers/getCurrentProjectId';
import LocalStore from 'services/localstore';
import { useFeatureFlagsContextValue } from 'providers/flagsmith/useFeatureFlagsContext';
import {
  INTEGRATIONS_ENABLED,
  SERVICE_ACCOUNTS_ENABLED,
  FLYBITS_LIFE_APP_ENABLED,
  MAKER_CHECKER_SETTINGS,
} from 'providers/flagsmith/feature-flags.constants';
import { cloneDeep, isEmpty } from 'lodash';
import * as ProjectsActions from 'actions/project';
import './CombinedNavBar.scss';
import { useActions } from 'actions';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';

const localStore = new LocalStore();

type TProps = {
  children: ReactElement;
};

const CONTROL_TOWER_APP_SIGNOUT_URI =
  process.env.REACT_APP_CONTROL_TOWER_APP_SIGNOUT_PAGE || 'https://controltower.flybits.com/app/signout';

export default function CombinedNavBar(props: TProps) {
  const { children } = props;
  const {
    userData: { id: userId, email, firstName, lastName, level },
    isAuthenticated,
  } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const projectActions = useActions(ProjectsActions);
  const projectState = useSelector((state: RootState) => state.project);

  const handleSignOut = async () => {
    const projectId = getCurrentProjectId();
    await cleanUpLocalForage(`token+${projectId}`, 'goto_path_after_auth', 'auth_redirect_uri', 'id_token');

    window.location.assign(CONTROL_TOWER_APP_SIGNOUT_URI);
  };

  const returnToControlTower = async () => {
    window.location.assign(process.env.REACT_APP_CONTROL_TOWER_ORGS_PAGE || '');
  };

  const [isCTLinkVisible, setIsCTLinkVisible] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const projectId = getCurrentProjectId();
        await projectActions.fetchProject(projectId);

        const isExternalAuth = await localStore.getItem(`external_auth_url+${projectId}`);
        if (!isExternalAuth) {
          setIsCTLinkVisible(true);
        }
      })();
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  function getNavRoutes(projectId: string) {
    return [
      {
        key: 'about-project',
        label: 'About Project',
        icon: 'infoStroke',
        location: `/project/${projectId}`,
      },
      {
        key: 'api-usage',
        label: 'API Usage',
        icon: 'speedo',
        location: `/project/${projectId}/api-usage`,
      },
      {
        key: 'experience-templates',
        label: 'Experience Templates',
        icon: 'manageTemplates',
        location: `/project/${projectState.id}/experience-templates`,
      },
      {
        key: 'content-templates',
        label: 'Content Templates',
        icon: 'textBox',
        location: `/project/${projectId}/content-templates`,
      },
      {
        key: 'push-settings',
        label: 'Push Settings',
        icon: 'phoneSetup',
        location: `/project/${projectId}/push`,
      },
      {
        key: 'channel-languages',
        label: 'Channel Languages',
        icon: 'language',
        location: `/project/${projectId}/channel-languages`,
      },
      {
        key: 'context-attributes',
        label: 'Context Attributes',
        icon: 'userAttributes',
        location: `/project/${projectId}/context-attributes`,
      },
      {
        key: 'webhooks',
        label: 'Webhooks',
        icon: 'nodeConnect',
        location: `/project/${projectId}/webhooks`,
      },
      {
        key: 'authentication',
        label: 'Authentication',
        icon: 'key',
        location: `/project/${projectId}/authentication`,
      },
      {
        key: 'analytics-export',
        label: 'Analytics Export',
        icon: 'mailStatistics',
        location: `/project/${projectId}/analytics-export`,
      },
      {
        key: 'maker-checker',
        label: 'Maker/Checker Settings',
        icon: 'people',
        location: `/project/${projectId}/maker-checker`,
      },
    ];
  }

  const [navRoutes, setNavRoutes] = useState(getNavRoutes(projectState.id));

  useEffect(() => {
    if (isEmpty(projectState)) {
      return;
    }

    setNavRoutes(getNavRoutes(projectState.id));
  }, [projectState]);

  const { isLoading: isFeatureFlagsLoading, featureFlags } = useFeatureFlagsContextValue();

  useEffect(() => {
    const routes = cloneDeep(navRoutes);

    if (!isFeatureFlagsLoading) {
      if (featureFlags[SERVICE_ACCOUNTS_ENABLED]) {
        if (!routes.find(route => route.key === 'service-accounts')) {
          const manageTemplatesNavItem = {
            key: 'service-accounts',
            label: 'Service Accounts',
            icon: 'contactDetails',
            location: `/project/${projectState.id}/service-accounts`,
          };

          routes.push(manageTemplatesNavItem);
        }
      }

      if (featureFlags[INTEGRATIONS_ENABLED]) {
        if (!routes.find(route => route.key === 'connected-datasources')) {
          const datasourceNavItemPos = routes.findIndex(navRoute => navRoute.key === 'context-attributes') + 1;
          const datasourceNavItem = {
            key: 'connected-datasources',
            label: 'Connected Data Sources',
            icon: 'dataConfiguration',
            location: `/project/${projectState.id}/datasources`,
          };

          routes.splice(datasourceNavItemPos, 0, datasourceNavItem);
        }
      }

      if (featureFlags[MAKER_CHECKER_SETTINGS]) {
        if (!routes.find(route => route.key === 'maker-checker')) {
          const itemPos = routes.findIndex(navRoute => navRoute.key === 'analytics-export') + 1;
          const makerCheckerSettingsNavItem = {
            key: 'maker-checker',
            label: 'Maker/Checker Settings',
            icon: 'people',
            location: `/project/${projectState.id}/maker-checker`,
          };

          routes.splice(itemPos, 0, makerCheckerSettingsNavItem);
        }
      }

      if (featureFlags[FLYBITS_LIFE_APP_ENABLED]) {
        if (!routes.find(route => route.key === 'flybits-life-app')) {
          const flybitsLifeAppNavItem = {
            key: 'flybits-life-app',
            label: 'Flybits Life App',
            icon: 'contactDetails',
            location: `/project/${projectState.id}/flybits-life-app`,
          };

          routes.push(flybitsLifeAppNavItem);
        }
      }

      setNavRoutes(routes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatureFlagsLoading, featureFlags]);

  return isLoading ? (
    <LoadingIcon width={80} height={80} fullScreen visible={isLoading} />
  ) : (
    <FlightNav
      isActiveRouteExactMatch={true}
      user={{
        id: userId,
        email,
        firstName,
        lastName,
        role: level === 20 ? 'Owner' : 'User',
      }}
      routes={navRoutes}
      actionables={
        isCTLinkVisible ? (
          <FlightButton
            iconLeft="baselineArrowLeft"
            label="return to Control Tower"
            theme="minor"
            onClick={() => returnToControlTower()}
          />
        ) : null
      }
      project={{
        id: projectState.id,
        name: projectState.name,
        image: <Jdenticon size="24" value={projectState.name} />,
      }}
      handleSignOut={handleSignOut}
      collapsed={false}
    >
      {children}
    </FlightNav>
  );
}
