import React, { useState } from 'react';
import './AccountDomainAccess.scss';
import { FlightButton } from '@flybits/design-system';
import AccountDomainSettingsModal from './Modals/AccountDomainSettingsModal';
import { AllowedTenants } from 'model/accountAccess/settings';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';

export default function AccountDomainAccess() {
  const [accountDomainSettingsModal, setAccountDomainSettingsModal] = useState(false);
  const tenantAccessSettingsFromState = useSelector((state: RootState) => state?.accountAccess?.tenantAccessSettings);

  const triggerAccountDomainSetttingsModal = () => {
    setAccountDomainSettingsModal(!accountDomainSettingsModal);
  };

  const allowed: AllowedTenants[] = tenantAccessSettingsFromState?.allowed || [];

  let allowedTypeZeroTenants: AllowedTenants[] = [];
  if (allowed.length !== 0) {
    allowedTypeZeroTenants = allowed.filter(item => item.type === 0);
  }

  return (
    <div className="account-domain-access">
      <AccountDomainSettingsModal
        openModal={accountDomainSettingsModal}
        toggleModal={triggerAccountDomainSetttingsModal}
      />
      <ReusableCard maxHeight={'90px'}>
        <h2 className="account-domain-access__header">Account domain access</h2>
        <div className="account-domain-access__description">
          {allowedTypeZeroTenants.length !== 0 ? (
            <span>
              {' '}
              Only accept accounts from the{' '}
              <span className="account-domain-access__link">
                {allowedTypeZeroTenants.length} specified
              </span> domains.{' '}
            </span>
          ) : (
            <span> Any email domain is currently accepted for account creation. </span>
          )}
        </div>
        <FlightButton
          className="account-domain-access__btn"
          label="Manage"
          onClick={triggerAccountDomainSetttingsModal}
          theme="secondary"
        />
      </ReusableCard>
    </div>
  );
}
