import React, { useEffect, useState } from 'react';
import { FlightButton, FlightModal } from '@flybits/design-system';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useActions } from 'actions';
import { RootState } from 'reducers';
import * as AnalyticsExportActions from 'actions/analyticsExport';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import ArrayFields from 'components/Shared/ArrayFields/ArrayFields';
import './AnalyticsExportPage.scss';
import { INVALID_DOMAIN } from 'constants/errors/errors';
import { cloneDeep, isEmpty } from 'lodash';
import ViewBoxLoader from 'components/Shared/ContentLoader/ViewBoxLoader';

const emailDomainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const validationSchema = Yup.object().shape({
  values: Yup.array().of(Yup.string().matches(emailDomainRegex, INVALID_DOMAIN)),
});

function AnalyticsExportPage() {
  const analyticsExportActions = useActions(AnalyticsExportActions);

  const tenantId = useSelector((state: RootState) => state.project.id);
  const whitelistedDomainsFromState = useSelector((state: RootState) => state.analyticsExport.whitelistedDomains);

  const [isLoading, setIsLoading] = useState(true);
  const [whitelistDomainsModal, setWhitelistDomainsModal] = useState(false);
  const [arrayFieldValues, setArrayFieldValues] = useState<string[]>([]);
  const [valuesFromArrayFields, setValuesFromArrayFields] = useState<string[]>([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    document.title = "Analytics Export | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    (async () => {
      await analyticsExportActions.getWhitelistedDomains();

      setIsLoading(false);
      (document.getElementsByClassName('manage-analytics-export__btn')?.[0]  as HTMLElement)?.focus();
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(whitelistedDomainsFromState)) {
      setArrayFieldValues(cloneDeep(whitelistedDomainsFromState));
    }

    setIsLoading(false);
  }, [whitelistedDomainsFromState]);

  const getErrorState = (data: boolean) => {
    setHasError(data);
  };

  const handleWhitelistDomainsModalSubmit = () =>
    (async () => {
      setWhitelistDomainsModal(false);
      setIsLoading(true);

      await analyticsExportActions.updateWhitelistedDomains(tenantId, valuesFromArrayFields.flat());
    })();

  return (
    <div role="main" className="analytics-export">
      <PageHeader title="Analytics Export" isLarge={true} />
      <div className="analytics-export__card">
        <ReusableCard>
          <div className="analytics-export__card__wrapper">
            <div>
              <h2 className="analytics-export__card__header">Whitelisted email domains</h2>
              <div className="analytics-export__card__subheader">
                Users will only be able to export analytics to email addresses listed here by your organization. If no
                emails are on the whitelist, analytics cannot be exported.
              </div>
              {isLoading ? (
                <div className="analytics-export__card__viewbox-loader">
                  <ViewBoxLoader height={50} width={200} />
                </div>
              ) : (
                <div className="analytics-export__card__container">
                  {whitelistedDomainsFromState?.map((whitelistedDomain, index) => (
                    <span key={index}>
                      <span className="analytics-export__card__container__tabs">{whitelistedDomain}</span>
                      <span className="analytics-export__card__container__tabs--spacer" />
                    </span>
                  ))}
                  {isEmpty(whitelistedDomainsFromState) && (
                    <span className="analytics-export__card__container__tabs analytics-export__card__container__tabs--disabled">
                      No email domains are currently whitelisted
                    </span>
                  )}
                </div>
              )}
            </div>
            <FlightButton
              label="Manage"
              className="manage-analytics-export__btn"
              onClick={() => setWhitelistDomainsModal(true)}
              theme="secondary"
              disabled={isLoading}
            />
          </div>
        </ReusableCard>
      </div>
      <FlightModal
        isVisible={whitelistDomainsModal}
        size="medium"
        toggleModalShown={() => setWhitelistDomainsModal(!whitelistDomainsModal)}
        header="Whitelisted email domains"
        content={
          <>
            <div className="analytics-export__card__subheader">
              Users will only be able to export analytics to email addresses listed here by your organization. If no
              emails are on the whitelist, analytics cannot be exported.
            </div>
            <ArrayFields
              initialValues={{
                values: arrayFieldValues,
              }}
              callback={setValuesFromArrayFields}
              placeHolderText="my-organization.com"
              validationSchema={validationSchema}
              hasError={getErrorState}
              width="400px"
            />
          </>
        }
        footer={
          <div className="modal-footer">
            <FlightButton theme="secondary" onClick={() => setWhitelistDomainsModal(false)} label="Cancel" />
            <FlightButton
              type="submit"
              onClick={handleWhitelistDomainsModalSubmit}
              disabled={hasError}
              label="Apply Changes"
            />
          </div>
        }
      />
    </div>
  );
}

export default AnalyticsExportPage;
