import React, { useEffect, useState } from 'react';
import { FlightTable, FlightButton, FlightTooltip } from '@flybits/design-system';
import { cloneDeep, isEqual, size, orderBy } from 'lodash';
import moment from 'moment';
import { ReactComponent as PlaceholderPicture } from 'assets/images/empty-templates.svg';
import './TemplateViewTable.scss';
import { TemplatePayload } from 'model/manageTemplates';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';

interface Props {
  templates: Array<TemplatePayload>;
  tableDataKey: Set<string>;
  onExportTemplateClick: (template: TemplatePayload, multiple: boolean) => void;
  onDeleteTemplateClick: (id: string) => void;
  onTableRowSelect: (template: TableData) => void;
  onTableRowSelectAll: () => void;
  isLoading: boolean;
}

interface TableHeader {
  name: string;
  key: string;
  isVisible: boolean;
  isSortable: boolean;
  hideTooltip: boolean;
}
interface TableData {
  key: string | undefined;
  name?: JSX.Element;
  category?: string;
  subcategory?: string;
  type?: JSX.Element;
  dateAdded?: string;
  exportTemplate?: JSX.Element;
  deleteTemplate?: JSX.Element;
  metadata: unknown;
}

const tableHeaders: TableHeader[] = [
  {
    name: 'Template Name',
    key: 'name',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Category',
    key: 'category',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Subcategory',
    key: 'subcategory',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Type',
    key: 'type',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Date Added',
    key: 'dateAdded',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Export Template',
    key: 'exportTemplate',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Delete Template',
    key: 'deleteTemplate',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
];
const confirmModalTitle = 'Confirm delete';
const confirmModalMessage = 'Are you sure you want to delete this template?';
enum ConfirmModalState {
  HIDDEN = 'HIDDEN',
  DELETE = 'DELETE',
}

const NothingFound = () => (
  <div className="template-view-empty">
    <h5>No templates found for this project</h5>
    <p>
      You can click{' '}
      <strong>
        <em>Import Custom</em>
      </strong>{' '}
      to add your own Journey Templates
    </p>
    <PlaceholderPicture />
  </div>
);
export default function TemplateViewTable(props: Props) {
  const {
    isLoading,
    templates,
    tableDataKey,
    onExportTemplateClick,
    onDeleteTemplateClick,
    onTableRowSelect,
    onTableRowSelectAll,
  } = props;
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [selectDeletedTemplateID, setSelectDeletedTemplateID] = useState<string>('');
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(ConfirmModalState.HIDDEN);
  const [filters, setFilters] = useState<{ sortBy: string; sortOrder: 'desc' | 'asc'; limit: number; page: number }>({
    sortBy: 'dateAdded',
    sortOrder: 'desc',
    limit: 15,
    page: 1,
  });
  const [maxPage, setMaxPage] = useState<number>(0);
  const categoriesList = (state: RootState) => state?.manageTemplates?.importLibraryCategories?.settings?.categories;
  const categories = useSelector(categoriesList) || [];
  const handleHeaderSort = (header: TableHeader) => {
    let order: 'asc' | 'desc';
    if (filters.sortBy === header.key) {
      order = filters.sortOrder === 'desc' ? 'asc' : 'desc';
    } else {
      order = 'asc';
    }

    setFilters({
      ...filters,
      sortBy: header.key,
      sortOrder: order,
    });
  };

  const onPageChange = (page: number) => {
    if (filters.page !== page) {
      setFilters({
        ...filters,
        page,
      });
    }
  };

  const onRowPerPageChange = (rowPerPage: number) => {
    if (filters.limit !== rowPerPage) {
      const _currentPage = rowPerPage * Number(filters.page) > size(templates) ? 1 : Number(filters.page);
      setFilters({
        ...filters,
        page: _currentPage,
        limit: rowPerPage,
      });
    }
  };

  const handleExportTemplate = (tableItem: TableData) => {
    const template = templates.find(t => t.id === tableItem?.key) as TemplatePayload;
    return (
      <div className="template-view__table__options">
        <FlightTooltip
          isEnabled={template?.provider !== 'Flybits'}
          direction="bottom right"
          delay={100}
          description={'Export template'}
          tabIndex={-1}
        >
          <FlightButton
            className="template-view__table__options__button export"
            type="button"
            theme="secondary"
            ariaLabel="Export this journey template"
            onClick={() => {
              onExportTemplateClick(template, false);
            }}
            disabled={template?.provider === 'Flybits'}
            iconLeft="export"
          />
        </FlightTooltip>
      </div>
    );
  };

  const handleDeleteTemplate = (tableItem: TableData) => {
    const template = templates.find(t => t.id === tableItem?.key) as TemplatePayload;
    return (
      <div className="template-view__table__options">
        <FlightTooltip
          isEnabled={template?.provider !== 'Flybits'}
          direction="bottom right"
          delay={100}
          description={'Delete template'}
          tabIndex={-1}
        >
          <FlightButton
            className="template-view__table__options__button delete"
            type="button"
            theme="secondary"
            ariaLabel="Delete this journey template"
            onClick={() => {
              setSelectDeletedTemplateID(template.id);
              setConfirmModalState(ConfirmModalState.DELETE);
            }}
            disabled={template?.provider === 'Flybits'}
            iconLeft="trashCan"
          />
        </FlightTooltip>
      </div>
    );
  };
  const handleDelete = () => {
    onDeleteTemplateClick(selectDeletedTemplateID);
    setConfirmModalState(ConfirmModalState.HIDDEN);
  };

  useEffect(() => {
    const maxPage = Math.ceil(size(templates) / Number(filters.limit));
    setMaxPage(maxPage >= Number(filters.page) ? maxPage : Number(filters.page));

    const _tableData: TableData[] = [];
    const newTemplates = cloneDeep(templates);
    //array to be used for sorting due to keys
    const sortableTemplates = newTemplates?.map((template: TemplatePayload) => {
      const cat = categories?.find(
        category => category.key === template?.categories?.find(c => c.startsWith('category-'))?.substring(9),
      );
      const subcat = cat?.subcategories?.find(
        subcategory => subcategory.key === template?.categories?.find(c => c.startsWith('subcategory-'))?.substring(12),
      );
      return {
        key: template?.id,
        name: template.name,
        category: cat?.name || '-',
        subcategory: subcat?.name || '-',
        type: template?.provider || '-',
        dateAdded: template.updatedAt,
        template: template,
      };
    });

    //Sorting the array
    orderBy(
      sortableTemplates,
      [
        (temp: TableData) => {
          if (typeof temp[filters.sortBy as keyof TableData] === 'string') {
            return String(temp[filters.sortBy as keyof TableData])?.toLowerCase();
          }
          return temp[filters.sortBy as keyof TableData];
        },
        'name',
        'type',
      ],
      [filters.sortOrder, 'desc', 'desc'],
    )
      //pagination
      ?.slice(0 + filters.limit * (filters.page - 1), filters.limit * filters.page)
      //translating the array into values to be use by the FlightTable
      ?.map((template: any) => {
        _tableData.push({
          key: template?.key,
          name: (
            <span
              className="template-view__table__name"
              title={template?.type === 'Flybits' ? 'Templates of type "Flybits" cannot be exported.' : undefined}
            >
              {template?.name}
            </span>
          ),
          category: template?.category,
          subcategory: template?.subcategory,
          type: (
            <div
              className={`template-view__table__type template-view__table__type--${template?.type?.toLowerCase()}`}
              title={template?.type === 'Flybits' ? 'Templates of type "Flybits" cannot be exported.' : undefined}
            >
              {template?.type}
            </div>
          ),
          dateAdded: moment(template?.dateAdded * 1000).format('MMM D, YYYY'),
          exportTemplate: handleExportTemplate(template),
          deleteTemplate: handleDeleteTemplate(template),
          metadata: {
            isMultiSelectDisabled: template?.type === 'Flybits',
          },
        });
      });

    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, filters]);

  return (
    <div className="template-view">
      {!isLoading && tableData.length === 0 ? (
        <NothingFound />
      ) : (
        <FlightTable
          className="template-view__table"
          emptyState={<NothingFound />}
          allowMultiSelect
          allowRowSelect
          showSelectedQuantity
          selectedDataKey={tableDataKey}
          handleHeaderSort={handleHeaderSort}
          handleDataSelect={onTableRowSelect}
          handleCheckboxMultiSelect={onTableRowSelect}
          handleSelectAll={onTableRowSelectAll}
          tableHeaders={tableHeaders}
          tableData={tableData}
          isLoading={isLoading}
          hasPaginationBeforeTable={false}
          hasPaginationAfterTable
          sortByKey={String(filters.sortBy)}
          sortOrder={String(filters.sortOrder)}
          loadingRowNumber={filters.limit || 4}
          ariaLabel="template view Table"
          paginationProps={{
            totalPageNumber: maxPage,
            currentPageNumber: filters.page,
            rowsPerPageOptions: [15, 25, 50],
            currentRowsPerPage: filters.limit,
            handlePageChange: onPageChange,
            handleRowsPerPageChange: onRowPerPageChange,
          }}
        />
      )}
      <ConfirmModal
        isVisible={confirmModalState !== ConfirmModalState.HIDDEN}
        title={confirmModalTitle}
        message={confirmModalMessage}
        continueCallback={handleDelete}
        cancelCallback={() => setConfirmModalState(ConfirmModalState.HIDDEN)}
      />
    </div>
  );
}
