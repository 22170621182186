export interface CustomEmail {
  isActive: boolean;
  confirmSubject?: string;
  confirmBody?: string;
  color?: string;
  supportEmail?: string;
  inviteSubject?: string;
  inviteBody?: string;
  resetPassSubject?: string;
  resetPassBody?: string;
  signature?: string;
  imageLogoUrl?: string;
  androidAppUrl?: string;
  iosAppUrl?: string;
  webAppUrl?: string;
}

export interface CustomEmailHeaderLogo {
  previewImgUrl: string;
}

export enum CustomEmailActionTypes {
  SET_CUSTOM_EMAIL_STATE = 'SET_CUSTOM_EMAIL_STATE',
  SET_CUSTOM_EMAIL_HEADER_LOGO = 'SET_CUSTOM_EMAIL_HEADER_LOGO',
  RESET_CUSTOM_EMAIL_HEADER_LOGO = 'RESET_CUSTOM_EMAIL_HEADER_LOGO',
  RESET_CUSTOM_EMAIL_STATE = 'RESET_CUSTOM_EMAIL_STATE',
}

interface CustomEmailActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type CustomEmailActions =
  | CustomEmailActionCreatorTypes<typeof CustomEmailActionTypes.SET_CUSTOM_EMAIL_STATE, CustomEmail>
  | CustomEmailActionCreatorTypes<typeof CustomEmailActionTypes.SET_CUSTOM_EMAIL_HEADER_LOGO, CustomEmailHeaderLogo>
  | CustomEmailActionCreatorTypes<typeof CustomEmailActionTypes.RESET_CUSTOM_EMAIL_HEADER_LOGO, CustomEmailHeaderLogo>
  | CustomEmailActionCreatorTypes<typeof CustomEmailActionTypes.RESET_CUSTOM_EMAIL_STATE, CustomEmail>;
