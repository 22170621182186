export interface Project {
  id: string;
  name: string;
  subDomain: string;
  creatorID: string;
  isActive: boolean;
  tierID: string;
}

export interface ProjectResponseBody {
  id: string;
  Name: string;
  Subdomain: string;
  CreatorID: string;
  IsActive: boolean;
  TierID: string;
}

export enum ProjectActionTypes {
  SET_PROJECT_STATE = 'SET_PROJECT_STATE',
  SET_PROJECTS_STATE = 'SET_PROJECTS_STATE',
  FETCH_ALL_PROJECTS = 'FETCH_ALL_PROJECTS',
  RESET_PROJECTS_STATE = 'RESET_PROJECTS_STATE',
}

interface ProjectActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type ProjectAction =
  | ProjectActionCreatorType<typeof ProjectActionTypes.SET_PROJECT_STATE, Project>
  | ProjectActionCreatorType<typeof ProjectActionTypes.SET_PROJECTS_STATE, Project[]>
  | ProjectActionCreatorType<typeof ProjectActionTypes.FETCH_ALL_PROJECTS, Project[]>
  | ProjectActionCreatorType<typeof ProjectActionTypes.RESET_PROJECTS_STATE, Project[]>;
