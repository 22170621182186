import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FlightModal, FlightButton, FlightToggleSwitch } from '@flybits/design-system';
import { ReactComponent as SignedLoginLogo } from 'assets/images/idplogos/signed-login.svg';
import './IdpModal.scss';
import { Row, Col } from 'reactstrap';
import uploadIcon from 'assets/images/upload.svg';
import { useDropzone } from 'react-dropzone';
import * as AccountAccessActions from 'actions/accountAccess';
import { useActions } from 'actions';
import {
  SignedLoginSettings,
  TenantAccessSettings,
  AllowedTenants,
  JWTSignedLoginSettings,
} from 'model/accountAccess/settings';
import { ReactComponent as TrashIcon } from 'assets/images/trash_bin.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg';
import { maskField } from 'helpers/maskField';
import * as NotificationsActions from 'actions/snackBarNotifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import jwtSignedLoginLogo from 'assets/images/idplogos/jwt-logo.png';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  signedLoginSettingsFromState?: SignedLoginSettings;
  tenantAccessSettingsFromState?: TenantAccessSettings;
  jwtSignedLoginSettingsFromState?: JWTSignedLoginSettings;
  isJWTSignedLogin: boolean;
}

export default function SignedLoginModal(props: Props) {
  const {
    isJWTSignedLogin,
    openModal,
    toggleModal,
    signedLoginSettingsFromState,
    jwtSignedLoginSettingsFromState,
    tenantAccessSettingsFromState,
  } = props;
  const [checked, setChecked] = useState(false);
  const [checkedStateApi, setCheckedStateApi] = useState(false);
  const accountAccessActions = useActions(AccountAccessActions);
  const notficationActions = useActions(NotificationsActions);
  const loginType = isJWTSignedLogin ? 'jwtlogin' : 'signedlogin';
  const { showNotification, isErrorInModal } = useSelector((state: RootState) => state.snackBarNotifications);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const filesFormData = new FormData();
      for (let i = 0; i < acceptedFiles.length; i++) {
        filesFormData.append(`${loginType}-cert-${i + 1}`, acceptedFiles[i]);
      }

      isJWTSignedLogin
        ? accountAccessActions.uploadJWTSignedLoginCertificate(filesFormData)
        : accountAccessActions.uploadSignedLoginCertificate(filesFormData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isJWTSignedLogin, accountAccessActions],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: ['.crt', '.cer', '.cert', '.pem'],
  });

  const content = isJWTSignedLogin
    ? jwtSignedLoginSettingsFromState?.certificate || ''
    : signedLoginSettingsFromState?.content || '';
  const isJustUploaded = isJWTSignedLogin
    ? jwtSignedLoginSettingsFromState?.isJustUploaded || false
    : signedLoginSettingsFromState?.isJustUploaded || false;

  const allowed: AllowedTenants[] = tenantAccessSettingsFromState?.allowed || [{ type: 0, value: '' }];

  let allowedOidcSettings = useMemo<AllowedTenants[]>(() => [{ type: 0, value: '' }], []);
  if (allowed.length !== 0) {
    allowedOidcSettings = allowed.filter(item => item.value === loginType);
  }

  function handleDelete() {
    setChecked(false);
    const disableApiKeySettings = {
      allowed: allowed.filter(item => item.value !== loginType),
    };
    accountAccessActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
    isJWTSignedLogin
      ? accountAccessActions.deleteJWTSignedLoginCertificate()
      : accountAccessActions.deleteSignedLoginCertificate();
  }

  function handleApplyChanges() {
    const allowedTenants: AllowedTenants[] = [...allowed];
    allowedTenants.push({
      type: 1,
      value: loginType,
    });

    if (checked) {
      const enableAPKeySettings = {
        allowed: allowedTenants,
      };
      accountAccessActions.createOrUpdateTenantAccessSettings(enableAPKeySettings);
    } else {
      const disableApiKeySettings = {
        allowed: allowed.filter(item => item.value !== loginType),
      };
      accountAccessActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
    }

    isJWTSignedLogin
      ? accountAccessActions.setJWTSignedLoginSettings({ certificate: content, isJustUploaded: false })
      : accountAccessActions.setSignedLoginSettings({ content: content, isJustUploaded: false });

    toggleModal();
  }

  useEffect(() => {
    if (allowedOidcSettings.length !== 0 && allowedOidcSettings[0].value !== '') {
      setCheckedStateApi(true);
    }
    return () => {
      setCheckedStateApi(false);
    };
    // eslint-disable-next-line
  }, [allowedOidcSettings]);

  useEffect(() => {
    setChecked(checkedStateApi);
    return () => {
      setChecked(checkedStateApi);
    };
    // eslint-disable-next-line
  }, [checkedStateApi]);

  useEffect(() => {
    if (showNotification && isErrorInModal) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification, isErrorInModal]);

  function copyContent() {
    navigator.clipboard.writeText(content);
    const uniqueId = generateUniqueId();
    notficationActions.showNotification({
      id: uniqueId,
      showNotfication: true,
      body: 'Copied to clipboard.',
      type: 'success',
    });
  }

  return (
    <div className="idp-modal">
      <FlightModal
        isVisible={openModal}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        content={
          <Col className="idp-modal__content">
            <Row className="idp-modal__content__content-header">
              {isJWTSignedLogin ? (
                <img
                  className="idp-modal__content__content-header__header-logo"
                  src={jwtSignedLoginLogo}
                  alt="JWT Signed Login Logo"
                />
              ) : (
                <SignedLoginLogo className="idp-modal__content__content-header__header-logo" />
              )}
              <span className="idp-modal__content__content-header__header-text">
                {isJWTSignedLogin ? 'JWT Signed Login' : 'Signed Login (deprecated)'}
              </span>
            </Row>
            <Col className="idp-modal__content__toggle">
              <Row>
                <FlightToggleSwitch checked={checked} onChange={setChecked} label={'Enable'} />
              </Row>
            </Col>
            <Col className="idp-modal__content__text-field-container">
              <Row className="idp-modal__content__text-field-container__text-field">Certificate</Row>
              {content === '' ? (
                <div>
                  <Row className="idp-modal__content__upload-box">
                    <section className="container">
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input name="file" id="fileValidation" {...getInputProps()} />
                        <img className="idp-modal__content__upload-box__img" src={uploadIcon} alt="uploadicon" />
                        <span className="idp-modal__content__upload-box__text"
                              onClick={open}
                              tabIndex={0}
                              onKeyDown={(event) => {
                               if(event.key === "Enter"){
                                 open();
                               }
                              }}
                              >
                          Upload certificate
                        </span>
                      </div>
                    </section>
                  </Row>
                  <Row>
                    <span className="idp-modal__content__upload-box__allowed-text">Only .crt files are accepted</span>
                  </Row>
                </div>
              ) : (
                <Row className="idp-modal__content__text-block">
                  <span className="idp-modal__content__text-block__display-uploaded">
                    {maskField(Object.values(content).toString()).substring(
                      Object.values(content).toString().length - 12,
                    )}
                    <CopyIcon
                      className="idp-modal__content__text-block__display-uploaded__img
                         "
                      onClick={copyContent}
                    />
                  </span>
                  <TrashIcon className="idp-modal__content__text-block__delete" onClick={handleDelete} />
                </Row>
              )}
            </Col>
          </Col>
        }
        footer={
          <div className="modal-footer">
            <FlightButton
              onClick={() => {
                if (isJustUploaded) {
                  handleDelete();
                }
                toggleModal();
              }}
              label="Cancel"
              theme="secondary"
            />
            <FlightButton type="primary" label="Apply Changes" disabled={content === ''} onClick={handleApplyChanges} />
          </div>
        }
      />
    </div>
  );
}
