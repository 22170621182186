import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/clear-icon-outline.svg';
import './SidePanel.scss';

interface SidePanelProps {
  className?: string;
  title: string;
  showPanel: boolean;
  content: JSX.Element;
  footer?: JSX.Element;
  togglePanel: () => void;
}

export const SidePanel = (props: SidePanelProps) => {
  const { className, title, showPanel, content, footer, togglePanel } = props;
  const MAIN_CLASS = 'side-panel';

  const handleClose = () => {
    togglePanel();
  };

  return (
    <div className={`${MAIN_CLASS} ${className} ${!showPanel && 'hidden'}`}>
      <div className={`${MAIN_CLASS}__panel-wrapper__overlay`} />
      <div className={`${MAIN_CLASS}__header`}>
        <span className={`${MAIN_CLASS}__title`}>{title}</span>
        <CloseIcon className={`${MAIN_CLASS}__close`} onClick={handleClose} />
      </div>
      <div className={`${MAIN_CLASS}__content`}>{content}</div>
      {footer && <div className={`${MAIN_CLASS}__footer`}>{footer}</div>}
    </div>
  );
};
