import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  FlightModal,
  FlightButton,
  FlightToggleSwitch,
  FlightTooltip,
  FlightNumberInput,
  FlightSelect,
  getIcon,
} from '@flybits/design-system';
import { RootState } from 'reducers';
import { useActions } from 'actions';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import { PushLimitSettings } from 'model/pushSettings/settings';
import * as PushLimitActions from 'actions/pushSettings/pushLimitSettings';

// Style file
import './PushLimits.scss';

interface SelectOptionProps {
  key: string;
  name: string;
}

// FUTURE SCOPE: This type will be dynamic once other push limit categories are developed
export const LIMIT_TYPE = 'user-device';

const DEFAULT_MAX_NOTIFICATION_LIMIT = 10;
const DEFAULT_PER_FREQUENCY = 'DAYS';
const MAX_NOTIFICATION_WARNING_LIMIT = 100;
const PER_TIME_FREQUENCY: SelectOptionProps[] = [
  {
    key: 'DAYS',
    name: 'DAYS',
  },
  {
    key: 'WEEKS',
    name: 'WEEKS',
  },
  {
    key: 'MONTHS',
    name: 'MONTHS',
  },
];
const errorDictionary = {
  noVal: 'Please insert maximum notification count',
  minVal: 'Count should be at least 1',
};

export default function PushLimits() {
  // FUTURE SCOPE: Once field genereated for collecting time scope then this value will be dynamic too.
  // Currently not in-scope
  const timeValue = 1;

  const pushLimitActions = useActions(PushLimitActions);
  const pushLimitSettngsFormState = useSelector((state: RootState) => state.pushSettings.pushLimitSettngs);
  const [maxNotificationWarningModal, setMaxNotificationWarningModal] = useState<boolean>(false);
  const [isPushLimitActivated, setIsPushLimitActivated] = useState<boolean>(false);
  const [maxPushNotifications, setMaxPushNotifications] = useState<number>(DEFAULT_MAX_NOTIFICATION_LIMIT);
  const [timeUnit, setPerTimeFrame] = useState<SelectOptionProps>({
    key: DEFAULT_PER_FREQUENCY,
    name: DEFAULT_PER_FREQUENCY,
  });
  const [maxNotificationError, setMaxNotificationError] = useState<boolean>(false);
  const [maxNotificationErrorMsg, setMaxNotificationErrorMsg] = useState<string>(errorDictionary.noVal);

  useEffect(() => {
    if (pushLimitSettngsFormState) {
      const perTimeFrameFrequency = {
        key: pushLimitSettngsFormState?.timeUnit.toLocaleUpperCase(),
        name: pushLimitSettngsFormState?.timeUnit.toLocaleUpperCase(),
      };

      setIsPushLimitActivated(pushLimitSettngsFormState.isActive);
      setMaxPushNotifications(pushLimitSettngsFormState.maxNotifications);
      setPerTimeFrame(perTimeFrameFrequency);
    }
  }, [pushLimitSettngsFormState]);

  // Handle warning max notification count modal visibility
  const confirmMaxNotificationModalToggle = () => {
    setMaxNotificationWarningModal(prevMaxNotificationWarningModal => !prevMaxNotificationWarningModal);
  };

  const updatePushLimitSettings = (timeUnit: string, isActive: boolean, maxNotifications: number) => {
    const payLoad: PushLimitSettings = {
      timeValue,
      timeUnit,
      isActive,
      maxNotifications:
        maxNotifications === 0 && !isActive ? pushLimitSettngsFormState?.maxNotifications : maxNotifications,
      limitType: LIMIT_TYPE,
    };
    pushLimitActions.addOrUpdatePushLimitSettings(LIMIT_TYPE, payLoad);
  };

  // Handle Toggling of Push limit
  const handlePushLimitSwitchChange = () => {
    setIsPushLimitActivated(prevIsPushLimitActivated => !prevIsPushLimitActivated);
    updatePushLimitSettings(timeUnit.key.toLocaleUpperCase(), !isPushLimitActivated, maxPushNotifications);
  };

  // Handle maximum notification count change
  const handleMaxPushNotificationChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!isPushLimitActivated) return;
    const { value } = event.currentTarget;

    if (value) {
      if (maxNotificationError) {
        setMaxNotificationError(false);
      }
      const numberedVal = Number(value);

      // Show error message if value is less than 1
      if (numberedVal < 1) {
        setMaxNotificationErrorMsg(errorDictionary.minVal);
        setMaxNotificationError(true);
      }
      // Show warning modal if notification value is more than 100
      if (numberedVal > MAX_NOTIFICATION_WARNING_LIMIT) {
        confirmMaxNotificationModalToggle();
      }
    } else {
      setMaxNotificationErrorMsg(errorDictionary.noVal);
      setMaxNotificationError(true);
    }
    setMaxPushNotifications(Number(value));
  };

  const handleMaxNotificationBlur = (event: React.FormEvent<HTMLInputElement>) => {
    if (maxNotificationError) return;
    const { value } = event.currentTarget;

    if (!maxNotificationWarningModal) {
      updatePushLimitSettings(timeUnit.key.toLocaleUpperCase(), isPushLimitActivated, Number(value));
    }
  };

  // Handle time frame frequency change from Dropdown
  const handlePerTimeFrameChange = (args: SelectOptionProps) => {
    if (!isPushLimitActivated) return;
    setPerTimeFrame(args);
    updatePushLimitSettings(args.key.toLocaleUpperCase(), isPushLimitActivated, maxPushNotifications);
  };

  return (
    <div className="push-limits-card">
      <ReusableCard>
        <div className="push-limits">
          <h2 className="push-limits__header">
            {getIcon('person', { className: 'push-limits__header--infoIcon' })}
            Per user notification limit
          </h2>
          <div className="push-limits__toggle">
            <FlightToggleSwitch
              title="Toggle"
              className="push-limits__btn"
              checked={isPushLimitActivated}
              label={isPushLimitActivated ? 'On' : 'Off'}
              onChange={handlePushLimitSwitchChange}
            />
          </div>
          <div className="push-limits__description">
            Set a maximum number of push notifications per user device over a given period of time
            <FlightTooltip
              className="tooltip-class"
              description="Note: Limits are set on a per device basis. For example, if a user has multiple devices, the limit will apply to each device"
              maxWidth="300px"
            >
              {getIcon('infoStroke', { className: 'push-limits__description--infoIcon' }) ?? ''}
            </FlightTooltip>
          </div>
          {isPushLimitActivated && (
            <div className="push-limits__notification-area">
              <FlightNumberInput
                allowDecimal
                allowNegative
                hasError={maxNotificationError}
                errorMessage={maxNotificationErrorMsg}
                label="Maximum Notifications"
                maxValue={3000}
                minValue={-3000}
                onChange={handleMaxPushNotificationChange}
                onBlur={handleMaxNotificationBlur}
                value={maxPushNotifications.toString()}
              />
              <FlightSelect
                dropdownDirection="bottom"
                dropdownMaxHeight="232px"
                handleOptionClick={handlePerTimeFrameChange}
                hasLabelAnimation
                label="Per"
                options={PER_TIME_FREQUENCY}
                selected={timeUnit}
              />
            </div>
          )}
        </div>
      </ReusableCard>

      {/* Warning Modal */}
      <FlightModal
        isVisible={maxNotificationWarningModal}
        toggleModalShown={confirmMaxNotificationModalToggle}
        scrollable={false}
        size="small"
        header={<span>Confirm</span>}
        content={<span>Are you sure you want to set maximum notifications more than 100?</span>}
        footer={
          <div className="modal-footer">
            <FlightButton
              onClick={() => {
                setMaxPushNotifications(DEFAULT_MAX_NOTIFICATION_LIMIT);
                updatePushLimitSettings(
                  timeUnit.key.toLocaleUpperCase(),
                  isPushLimitActivated,
                  DEFAULT_MAX_NOTIFICATION_LIMIT,
                );
                confirmMaxNotificationModalToggle();
              }}
              label="Cancel"
              theme="secondary"
            />
            <FlightButton
              type="submit"
              label="Set limit"
              onClick={() => {
                updatePushLimitSettings(timeUnit.key.toLocaleUpperCase(), isPushLimitActivated, maxPushNotifications);
                confirmMaxNotificationModalToggle();
              }}
            />
          </div>
        }
      />
    </div>
  );
}
