import {
  ContentTemplatesActions,
  ContentTemplatesActionTypes,
  ContentTemplateAttributes,
} from 'model/contentTemplates';
import { RuleForwardingData } from 'model/webhook';
import SettingsService from 'services/settings.service';
import ContentService from 'services/content.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator, makeFetchActionCreator } from 'helpers/actionCreator';
import { showNotification } from './snackBarNotifications';

export const setContentTemplateState = makeActionCreator(ContentTemplatesActionTypes.SET_CONTENT_TEMPLATES_STATE);

export const setSingleContentTemplateState = makeActionCreator(
  ContentTemplatesActionTypes.SET_SINGLE_CONTENT_TEMPLATE_STATE,
);

export const setContentTemplateLabelsState = makeActionCreator(
  ContentTemplatesActionTypes.SET_CONTENT_TEMPLATE_LABELS_STATE,
);

export const setContentTemplateSettingState = makeActionCreator(
  ContentTemplatesActionTypes.SET_CONTENT_TEMPLATE_SETTINGS_STATE,
);

export const fetchContentTemplates = makeFetchActionCreator(
  new ContentService(),
  'getAllContentTemplates',
  setContentTemplateState,
  'data',
);

export const fetchContentTemplateSettings = makeFetchActionCreator(
  new SettingsService(),
  'getProjectCustomContentTemplates',
  setContentTemplateSettingState,
  'settings',
);

export function createNewContentTemplate(data: ContentTemplateAttributes) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ContentService();
      const response = await req.createContentTemplate(data);
      if (response.status === 201) {
        dispatch(fetchContentTemplates({ limit: 1000000 }));
        dispatch(fetchContentTemplateSettings());
      }
    } catch (error: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: false,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function getContentTemplate(id: string) {
  return async (dispatch: { (arg0: ContentTemplatesActions): void }) => {
    try {
      const req = new ContentService();
      const response = await req.getContentTemplate(id);
      dispatch(setSingleContentTemplateState(response.data));
    } catch (error) {}
    return 'done';
  };
}

export function fetchContentTemplateLabels(searchText: string, limit: number, offset: number) {
  return async (dispatch: { (arg0: ContentTemplatesActions): void }) => {
    try {
      const req = new ContentService();
      const response = await req.getContentTemplateLabels({ search: searchText, limit, offset });
      dispatch(setContentTemplateLabelsState(response?.data));
      return response;
    } catch (error) {}
    return 'done';
  };
}

export function updateContentTemplate(data: ContentTemplateAttributes, id: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new ContentService();
      const response = await req.updateContentTemplate(data, id);
      if (response.status === 201) {
        dispatch(fetchContentTemplates({ limit: 1000000 }));
        dispatch(fetchContentTemplateSettings());
      }
      return 'done';
    } catch (error: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'error',
        }),
      );
      return 'error';
    }
  };
}

export function deleteContentTemplates(templateId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ContentService();
      const response = await req.deleteContentTemplates(templateId);
      if (response.status === 200) {
        dispatch(fetchContentTemplates({ limit: 1000000 }));
        dispatch(fetchContentTemplateSettings());
      }
    } catch (error: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: error.response.data?.error.exceptionMessage,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function updateRuleForwarding(ruleId: string, data: RuleForwardingData[]) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ContentService();
      await req.updateRuleForwarding(ruleId, data);
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to update rule for ' + ruleId,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}
