import React, { useState, useEffect } from 'react';
import { SidePanel } from 'components/Shared/SidePanel/SidePanel';
import {
  FlightButton,
  FlightCheckbox,
  FlightModal,
  FlightSelect,
  FlightSnackbar,
  FlightTextArea,
  FlightTextInput,
  FlightTooltip,
} from '@flybits/design-system';
import { ReactComponent as PencilIcon } from 'assets/images/edit-pencil.svg';
import { ReactComponent as ClearIcon } from 'assets/images/clear-icon.svg';
import * as ContextPluginActions from 'actions/contextPlugins';
import './ContextAttributeSidePanel.scss';
import ValidationMessages from 'components/Shared/ValidationMessages/ValidationMessages';
import { Container, Row, Col, Label } from 'reactstrap';
import {
  contextDataFormatOptions,
  contextInputTypeOptions,
  getContextAttributeCategoryOptions,
  contextAttributeDataExpiryOptions,
  contextDataExpiryNumOptions,
  contextExpiryUnitOptions,
} from 'constants/contextAttributesOptions';
import { isEmpty, startCase } from 'lodash';
import { beginsWithNumberOrLetter, containsNoSpecialCharacters } from 'helpers/stringValidators';
import { FlightSelectOptions } from 'model/misc/flightSelectOptions';
import { ContextPlugin, Attribute, Parameter } from 'model/contextPlugins';
import { useActions } from 'actions';
import DoubleArrayFields from 'components/Shared/DoubleArrayFields/DoubleArrayFields';
import {
  getCtxCategoryFromString,
  getCtxAttributeFromString,
  getCtxPluginFromAttribute,
} from 'helpers/getCategoryFromString';
import { getCtxInputType, isFreeForm } from 'helpers/getCtxInputType';
import { ReactComponent as DeviceIcon } from 'assets/images/device-icon.svg';
import { ReactComponent as UserIcon } from 'assets/images/person-icon.svg';
import { ReactComponent as TenantScopeIcon } from 'assets/images/tenant-scope.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/images/question-mark.svg';
import { getDataExpiryText, getDefaultExpiry } from 'helpers/timeConverters';
import * as Yup from 'yup';
import { VALUE_REQUIRED } from 'constants/errors/errors';
import { CONTEXT_ATTRIBUTES_SCOPES } from 'constants/contextAttributesScopes';

type TContextAttributeSidePanelProps = {
  showPanel: boolean;
  userCreatedCategories: ContextPlugin[];
  contextPlugins: ContextPlugin[];
  attribute?: Attribute;
  togglePanel: () => void;
  openCategoryModal?: () => void;
  isReadOnly?: boolean;
};

interface ValueOptions {
  displayName: string;
  value: string;
}

const defaultDataFormat = { key: 'string', name: 'String' };
const defaultExpiryCond = { key: 'expiresEvery', name: 'Data expires every' };
const defaultExpiryDuration = { key: 24, name: 24 };
const defaultExpiryUnit = { key: 'hours', name: 'Hours' };
const defaultInputType = { key: 'freeform', name: 'Freeform' };
const defaultCategory = {
  key: 'default',
  name: 'Select or create a category',
};

export const ContextAttributeSidePanel = (props: TContextAttributeSidePanelProps) => {
  const {
    showPanel,
    togglePanel,
    userCreatedCategories,
    contextPlugins,
    attribute,
    openCategoryModal,
    isReadOnly,
  } = props;

  const contextPluginActions = useActions(ContextPluginActions);
  const [suggestedAttributeId, setSuggestedAttributeId] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [name, setName] = useState('');
  const [parameterKey, setParameterKey] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState<FlightSelectOptions>(defaultCategory);
  const [dataFormat, setDataFormat] = useState<FlightSelectOptions>(defaultDataFormat);
  const [dataExpiryCond, setDataExpiryCond] = useState(defaultExpiryCond);
  const [dataExpiryDuration, setDataExpiryDuration] = useState(defaultExpiryDuration);
  const [dataExpiryUnit, setDataExpiryUnit] = useState<FlightSelectOptions>(defaultExpiryUnit);
  const [isTenantScoped, setIsTenantScoped] = useState(false);
  const [inputType, setInputType] = useState<FlightSelectOptions>(defaultInputType);
  const [parameterInputType, setParameterInputType] = useState<FlightSelectOptions>(defaultInputType);
  const [nameTouched, setNameTouched] = useState(false);
  const [contextPlugin, setContextPlugin] = useState<ContextPlugin>();
  const [dropDownValues, setDropDownValues] = useState(['']);
  const [dropDownDisplayNames, setDropDownDisplayNames] = useState(['']);
  const [parameterDropDownValues, setParameterDropDownValues] = useState(['']);
  const [parameterDropDownDisplayNames, setParameterDropDownDisplayNames] = useState(['']);
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [editParameter, setEditParameter] = useState(false);
  const [openParameterModal, setOpenParameterModal] = useState(false);
  const [isDropDownError, setIsDropDownError] = useState(false);

  const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    setNameTouched(true);
    setSuggestedAttributeId(
      e.currentTarget.value
        .trim()
        .replace(/[^\w]/g, '-')
        .toLowerCase(),
    );
  };

  const handleSetDescription = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);
  };

  useEffect(() => {
    if (category.key !== 'default') {
      const _ctxPlugin = contextPlugins?.find(ctxPlugin => ctxPlugin.uid === category.key);

      setContextPlugin(_ctxPlugin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (showPanel) {
      if (attribute) {
        setTimeout(() => {
          (document.getElementsByClassName('context-attribute-sidepanel__content__edit-mode__values__attr')?.[0] as HTMLElement)?.focus();
        }, 0);
      } else {
        setTimeout(() => {
          (document.getElementsByClassName('context-attribute-sidepanel__content__label')?.[0] as HTMLElement)?.focus();
        }, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPanel]);

  useEffect(() => {
    if (nameTouched) {
      const newlyCreatedCategory = userCreatedCategories[userCreatedCategories?.length - 1];
      if (newlyCreatedCategory) {
        setCategory({ key: newlyCreatedCategory.uid, name: newlyCreatedCategory.name });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreatedCategories]);

  const [uniqParamError, setUniqParamError] = useState(false);
  useEffect(() => {
    if (
      !editParameter &&
      !isEmpty(parameters) &&
      !isEmpty(parameterKey) &&
      !isEmpty(parameters.filter(el => el.key === parameterKey))
    ) {
      return setUniqParamError(true);
    }
    setUniqParamError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterKey]);

  function resetContextAttributesSidePanelState() {
    setDataExpiryCond(defaultExpiryCond);
    setDataExpiryDuration(defaultExpiryDuration);
    setInputType(defaultInputType);
    setDropDownValues([]);
    setDropDownDisplayNames([]);
    setName('');
    setDataFormat(defaultDataFormat);
    setSuggestedAttributeId('');
    setCategory(defaultCategory);
    setParameters([]);
    setParameterKey('');
    setParameterInputType(defaultInputType);
    setParameterDropDownValues([]);
    setParameterDropDownDisplayNames([]);
  }

  const resetDeps = () => {
    setSuggestedAttributeId('');
    setShowEdit(false);
    setName('');
    setDescription('');
    setCategory(defaultCategory);
    setDataFormat(defaultDataFormat);
    setDataExpiryCond(defaultExpiryCond);
    setDataExpiryDuration(defaultExpiryDuration);
    setDataExpiryUnit(defaultExpiryUnit);
    setIsTenantScoped(false);
    setInputType(defaultInputType);
    setNameTouched(false);
    setDropDownValues(['']);
    setDropDownDisplayNames(['']);
    setContextPlugin(undefined);
    togglePanel();
  };

  useEffect(() => {
    if (attribute) {
      //set expiry and condition for exp
      const _expiry = getDataExpiryText(attribute.defaultExpDuration);
      const _expiryVal = getDataExpiryText(attribute.defaultExpDuration).value;
      if (typeof _expiryVal === 'string') {
        setDataExpiryCond({ key: 'neverExpires', name: 'Never expires' });
      } else if (typeof _expiryVal === 'number') {
        setDataExpiryCond(defaultExpiryCond);
        setDataExpiryDuration({ key: _expiryVal, name: _expiryVal });
        setDataExpiryUnit({ key: _expiry.unit, name: _expiry.unit });
      }
      // set input type and dropdown options if any
      if (isFreeForm(attribute.valueTypeOptions)) {
        setInputType(defaultInputType);
      } else {
        const _attrDropValues = attribute?.valueTypeOptions?.map(el => el.value);
        const _attrDropDisplayNames = attribute?.valueTypeOptions?.map(el => el.displayName);
        setInputType({ key: 'dropdown', name: 'Dropdown' });
        setDropDownValues(_attrDropValues);
        setDropDownDisplayNames(_attrDropDisplayNames);
      }
      const dataFormat = getCtxInputType(
        attribute?.valueType || '',
        attribute?.valueTypeOptions || [],
        attribute?.isTimeContext,
      );
      setName(attribute.name);
      setDataFormat({ key: attribute.valueType, name: dataFormat });

      const _attributeId = getCtxAttributeFromString(attribute.uid) || '';
      setSuggestedAttributeId(_attributeId);

      const _ctxPlugin = getCtxPluginFromAttribute(attribute, contextPlugins);
      setCategory({ key: _ctxPlugin?.uid || '', name: _ctxPlugin?.category || '' });

      setParameters(attribute.parameters as Parameter[]);
      setIsTenantScoped(attribute.isTenantScoped);
    }

    return resetContextAttributesSidePanelState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute]);

  const handleDeleteAttribute = () => {
    const _updateContextCategory = contextPlugin;
    const attributeKey = getCtxAttributeFromString(attribute?.uid || '');
    delete _updateContextCategory?.values?.[`${attributeKey}`];
    contextPluginActions.updateContextPlugin(_updateContextCategory);
    togglePanel();
  };

  const handleCreateOrUpdateAttribute = () => {
    const _dataFormat = () => {
      if (dataFormat.key === 'dateTime') {
        return 'int';
      }
      return dataFormat.key;
    };

    const valueOpts: ValueOptions[] = [];
    dropDownValues.forEach((val, index) => {
      const dispName = dropDownDisplayNames[index];
      valueOpts.push({ value: val, displayName: dispName });
    });

    const _attribute: Attribute = {
      id: '',
      uid: contextPlugin?.uid + '.' + suggestedAttributeId,
      description: description,
      name: name,
      valueType: _dataFormat(),
      isTimeContext: dataFormat.key === 'dateTime' ? true : false,
      isTenantScoped: isTenantScoped,
      defaultExpDuration:
        dataExpiryCond.key === 'neverExpires' ? -1 : getDefaultExpiry(dataExpiryUnit.key) * dataExpiryDuration.key,
      isShowDisplayName: false,
      isShowValue: false,
      shouldBeSaved: true, // this is the attribute for historical persistance, its enabled by default
      valueKeys: [],
      valueTypeOptions: inputType.key === 'dropdown' ? valueOpts : [],
      isNeverExpires: dataExpiryCond.key === 'neverExpires' ? true : false,
      parameters: parameters,
    };

    if (dataExpiryCond.key === 'expiresEvery') {
      //  only assign these keys if expiry date is not never
      Object.assign(_attribute, {
        inputAttrExpiryRate: dataExpiryDuration.key,
        inputAttrExpiryRateUnit: dataExpiryUnit.key,
      });
    }

    contextPluginActions.updateContextPlugin({
      ...contextPlugin,
      values: {
        ...contextPlugin?.values,
        [suggestedAttributeId]: _attribute,
      },
    });
    resetDeps();
  };

  const handleCreateParameter = () => {
    const valueOpts: ValueOptions[] = [];

    if (parameterInputType.key === 'dropdown') {
      parameterDropDownValues.forEach((val, index) => {
        if (val) {
          const dispName = parameterDropDownDisplayNames[index];
          valueOpts.push({ value: val, displayName: dispName });
        }
      });
    }

    const _param: Parameter = {
      index: 0,
      key: parameterKey,
      valueTypeOptions: valueOpts,
      valueType: 'string',
      isTenantScoped: false,
      shouldBeSaved: false,
      isTimeContext: false,
      performsExtraProcessing: false,
      extraProcessingAddress: '',
      valueKeys: null,
    };

    if (editParameter) {
      const editParam: Parameter | undefined = parameters.find(param => param.key === parameterKey);
      const uneditedParams: Parameter[] = parameters.filter(param => param.key !== parameterKey);

      if (editParam) {
        _param.index = editParam.index;
        uneditedParams.push(_param);
        uneditedParams.sort((a, b) => {
          if (a.index < b.index) return -1;
          if (a.index > b.index) return 1;
          return 0;
        });
      }

      setParameters([...uneditedParams]);
    } else {
      _param.index = parameters.length;

      setParameters([...parameters, _param]);
    }

    setParameterKey('');
    setParameterDropDownValues(['']);
    setParameterInputType(defaultInputType);
    setParameterDropDownDisplayNames(['']);
    setEditParameter(false);
    setOpenParameterModal(false);
    setIsDropDownError(false);
  };

  const handleCreateCategory = () => {
    openCategoryModal && openCategoryModal();
  };

  const handleEditParameter = (item: Parameter) => {
    setEditParameter(true);
    setParameterKey(item.key);
    const parameterInputType = !isEmpty(item?.valueTypeOptions);
    setParameterInputType(!parameterInputType ? defaultInputType : { key: 'dropdown', name: 'Dropdown' });
    const _parameterDropValues = item?.valueTypeOptions?.map(el => el.value);
    const _parameterDropDisplayNames = item?.valueTypeOptions?.map(el => el.displayName);
    setParameterDropDownValues(_parameterDropValues);
    setParameterDropDownDisplayNames(_parameterDropDisplayNames);
    setOpenParameterModal(true);
  };

  const handleDeleteParameter = (item: Parameter) => {
    const remainingParams = parameters.filter(el => el.key !== item.key);

    let index = 0;
    for (const param of remainingParams) {
      param.index = index++;
    }

    setParameters(remainingParams);
  };

  const isCategoryAssigned = () => {
    return category.key !== 'default' ? true : false;
  };

  const handleSetDoubleArrayFields = (keyData: string[], valueData: string[]) => {
    if (!isEmpty(keyData)) {
      setDropDownValues(keyData);
      setDropDownDisplayNames(valueData);
    }
  };

  const handleSetDropDownDoubleArrayFields = (keyData: string[], valueData: string[]) => {
    if (!isEmpty(keyData)) {
      setParameterDropDownValues(keyData);
      setParameterDropDownDisplayNames(valueData);
    }
  };

  const parameterModalContent = () => {
    const validationSchema = Yup.object().shape({
      values: Yup.string().required(VALUE_REQUIRED),
    });

    return (
      <Container>
        <FlightSnackbar
          type="info"
          icon="infoFilled"
          isVisible
          isAutoDismiss
          className="context-attribute-sidepanel__content__snack-bar"
          actionName={null}
          content="The parameter key cannot be changed after the parameter has been added to the attribute."
        />
        <Row xs="2">
          <Col>
            <Label className="context-attribute-sidepanel__content__label">Key</Label>
            <FlightTextInput
              name="name"
              width="270px"
              className="context-attribute-sidepanel__content__name"
              hasError={uniqParamError}
              errorMessage={'Parameter must be unique to the attribute.'}
              value={parameterKey}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setParameterKey(
                  e.currentTarget.value
                    .trim()
                    .replace(/[^\w]/g, '-')
                    .toLowerCase(),
                )
              }
              disabled={editParameter}
            />
          </Col>
          <Col>
            <Label className="context-attribute-sidepanel__content__label">Input Type</Label>
            <FlightSelect
              width="270px"
              selected={parameterInputType}
              className="context-attribute-sidepanel__content__field"
              handleOptionClick={(clickedOption: FlightSelectOptions) => {
                if (clickedOption.key !== 'dropdown') {
                  setIsDropDownError(false);
                }
                setParameterInputType(clickedOption);
              }}
              options={contextInputTypeOptions}
            />
          </Col>
        </Row>
        <div>
          {parameterInputType.key === 'dropdown' && (
            <>
              <Label className="context-attribute-sidepanel__content__label">{`Dropdown selection options (${dropDownValues?.length})`}</Label>
              <div>Number indicates list item order placement</div>
              <div>
                <DoubleArrayFields
                  initialValues={{ values: parameterDropDownValues, names: parameterDropDownDisplayNames }}
                  placeHolderText="Value"
                  secondPlaceHolderText="Display name (optional)"
                  headers={{ key: 'Value', value: 'Display name (optional)' }}
                  callback={(keyData: string[], valueData: string[]) => {
                    setIsDropDownError(false);
                    handleSetDropDownDoubleArrayFields(keyData, valueData);
                  }}
                  hasError={isError => (isError ? setIsDropDownError(true) : setIsDropDownError(false))}
                  validationSchema={validationSchema}
                />
              </div>
            </>
          )}
        </div>
      </Container>
    );
  };

  const isUniqueAttribute = (attrId: string) => {
    // this is a case where no category is selected
    if (category.key === 'default') return true;
    // this is a case where the selected category has no values
    if (category.key !== 'default' && !contextPlugin?.values) return true;
    return contextPlugin?.values && !Object?.keys(contextPlugin.values).find(el => el === attrId);
  };

  const attributeIdValidations = [
    { message: 'Must begin with a letter or a number.', isError: !beginsWithNumberOrLetter(suggestedAttributeId) },
    { message: 'Must be unique.', isError: !isUniqueAttribute(suggestedAttributeId) },
    {
      message: 'Cannot contain special characters or spaces.',
      isError: !containsNoSpecialCharacters(suggestedAttributeId),
    },
  ];

  const isValid = () => {
    if (!attribute) {
      for (const validationObject of attributeIdValidations) {
        if (validationObject.isError) {
          return false;
        }
      }
      if (isEmpty(name)) {
        return false;
      }

      if (isEmpty(suggestedAttributeId)) {
        return false;
      }

      if (category.key !== 'default' && isEmpty(contextPlugin)) {
        return false;
      }
      return true;
    } else {
      if (attribute.isReserved || attribute.isReserved === undefined) {
        return false;
      }

      return true;
    }
  };

  const footer = (
    <div className={`template-side-panel__footer`}>
      <FlightButton
        className="context-attribute-sidepanel__footer__button"
        disabled={!isValid() || isReadOnly || !!contextPlugin?.dataSource}
        label={
          attribute ? 'Save changes' : isCategoryAssigned() ? `Add attribute to category` : `Create context category`
        }
        onClick={
          attribute
            ? handleCreateOrUpdateAttribute
            : isCategoryAssigned()
            ? handleCreateOrUpdateAttribute
            : handleCreateCategory
        }
      />
      <FlightButton
        className="context-attribute-sidepanel__footer__button"
        label="Cancel"
        theme="secondary"
        onClick={resetDeps}
      />
      {!attribute?.isReserved && attribute?.isReserved !== undefined && !isReadOnly && !contextPlugin?.dataSource && (
        <FlightButton
          className="context-attribute-sidepanel__footer__button__delete"
          iconLeft="trashCan"
          label="Delete"
          theme="minor"
          onClick={handleDeleteAttribute}
        />
      )}
    </div>
  );

  const editView = () => {
    const attributeId = getCtxAttributeFromString(attribute?.uid || '');
    const attributeCategory = startCase(getCtxCategoryFromString(attribute?.uid || ''));
    const attributeDataFormat = getCtxInputType(
      attribute?.valueType || '',
      attribute?.valueTypeOptions || [],
      attribute?.isTimeContext,
    );

    let attributeScope;
    switch (attribute?.scope) {
      case CONTEXT_ATTRIBUTES_SCOPES.USER.key:
        attributeScope = (
          <>
            <UserIcon className="context-attribute-sidepanel__content__edit-mode__values__scope__icon" />
            {CONTEXT_ATTRIBUTES_SCOPES.USER.name}
          </>
        );
        break;

      case CONTEXT_ATTRIBUTES_SCOPES.TENANT.key:
        attributeScope = (
          <>
            <TenantScopeIcon className="context-attribute-sidepanel__content__edit-mode__values__scope__icon" />
            {CONTEXT_ATTRIBUTES_SCOPES.TENANT.name}
          </>
        );
        break;

      default:
        attributeScope = (
          <>
            <DeviceIcon className="context-attribute-sidepanel__content__edit-mode__values__scope__icon" />
            {CONTEXT_ATTRIBUTES_SCOPES.DEVICE.name}
          </>
        );
    }

    return (
      <Container className="context-attribute-sidepanel__content__edit-mode">
        <div className="context-attribute-sidepanel__content__edit-mode__description">{attribute?.description}</div>
        <Row xs="4" className="context-attribute-sidepanel__content__edit-mode__headers">
          <Col>Attribute ID</Col>
          <Col>Category</Col>
          <Col>Data format</Col>
          <Col>Scope</Col>
        </Row>
        <Row xs="4" className="context-attribute-sidepanel__content__edit-mode__values">
          <Col className="context-attribute-sidepanel__content__edit-mode__values__attr"
               tabIndex={0} aria-label={'Attribute ID ' + attributeId}>{attributeId}</Col>
          <Col tabIndex={0} aria-label={'Category ' + attributeCategory}>{attributeCategory}</Col>
          <Col tabIndex={0} aria-label={'Data format ' + attributeDataFormat}>{attributeDataFormat}</Col>
          <Col tabIndex={0} aria-label={'Scope ' + attributeScope}>
            <div className="context-attribute-sidepanel__content__edit-mode__values__scope">{attributeScope}</div>
          </Col>
        </Row>
      </Container>
    );
  };

  const panelContent = () => {
    const tenantScopeTooltipDesc =
      'User and device scope is inherited by the assigned category. ' +
      'This setting overrides that inheritance to tenant scope. ' +
      'The data being sent to a tenant scope attribute is not user or device specific.';

    return (
      <>
        {attribute && editView()}
        <Container className="context-attribute-sidepanel__content">
          {!attribute && (
            <>
              <Row xs="2">
                <Col>
                  <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>Attribute name*</Label>
                  <FlightTextInput
                    name="name"
                    width="360px"
                    className="context-attribute-sidepanel__content__name"
                    hasError={nameTouched && isEmpty(name)}
                    value={name}
                    onBlur={() => setNameTouched(true)}
                    errorMessage={'Required'}
                    onChange={handleSetName}
                  />
                </Col>
                <Col>
                  <Label className="context-attribute-sidepanel__content__label">Attribute ID</Label>
                  {!showEdit ? (
                    <div className="context-attribute-sidepanel__content__ctx-id">
                      <PencilIcon
                        className="context-attribute-sidepanel__content__ctx-id__icon"
                        onClick={() => setShowEdit(true)}
                      />
                      <span className="context-attribute-sidepanel__content__ctx-id__text">
                        {!isEmpty(suggestedAttributeId)
                          ? suggestedAttributeId
                          : 'Generated ctx attribute id will appear here'}
                      </span>
                    </div>
                  ) : (
                    <FlightTextInput
                      name="Context ID"
                      width="360px"
                      value={suggestedAttributeId}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSuggestedAttributeId(e.currentTarget.value)
                      }
                    />
                  )}
                  <ValidationMessages validationObjects={attributeIdValidations} />
                </Col>
                <Col>
                  <div style={{ marginTop: '-45px' }}>
                    <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>Description</Label>
                    <FlightTextArea
                      name="description"
                      className="context-attribute-sidepanel__content__text-area"
                      value={description}
                      label=""
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleSetDescription(e)}
                    />
                  </div>
                  <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>Assigned Category</Label>
                  <div>
                    <FlightSelect
                      label="Assigned category"
                      width="355px"
                      selected={category}
                      className="context-attribute-sidepanel__content__field"
                      handleOptionClick={setCategory}
                      options={getContextAttributeCategoryOptions(userCreatedCategories)}
                    />
                  </div>
                </Col>
              </Row>
              <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>Data Format </Label>
              <Row xs="2">
                <Col>
                  <FlightSelect
                    width="355px"
                    selected={dataFormat}
                    className="context-attribute-sidepanel__content__field"
                    handleOptionClick={setDataFormat}
                    options={contextDataFormatOptions}
                  />
                </Col>
                {isEmpty(attribute) && (
                  <Col>
                    <div className="context-attribute-sidepanel__content__tenant-scope">
                      <FlightCheckbox
                        onSelect={() => setIsTenantScoped(isTenantScoped => !isTenantScoped)}
                        label="Tenant Scoped"
                        checkState={isTenantScoped ? 'SELECTED' : 'UNSELECTED'}
                      />
                      <FlightTooltip direction="bottom left" delay={400} description={tenantScopeTooltipDesc}>
                        <QuestionMarkIcon className="context-attribute-sidepanel__content__tenant-scope__tooltip-icon" />
                      </FlightTooltip>
                    </div>
                  </Col>
                )}
              </Row>
            </>
          )}

          <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>Data Expiry </Label>
          <Row xs="4">
            <Col className="context-attribute-sidepanel__content__expiry">
              <FlightSelect
                label="Data expires every"
                selected={dataExpiryCond}
                disabled={attribute?.isReserved || isReadOnly || !!contextPlugin?.dataSource}
                className="context-attribute-sidepanel__content__expiry__select"
                handleOptionClick={setDataExpiryCond}
                options={contextAttributeDataExpiryOptions}
              />
            </Col>
            {dataExpiryCond.key === 'expiresEvery' && (
              <>
                <Col className="context-attribute-sidepanel__content__expiry-duration">
                  <FlightSelect
                    label="24"
                    disabled={attribute?.isReserved || isReadOnly || !!contextPlugin?.dataSource}
                    selected={dataExpiryDuration}
                    className="context-attribute-sidepanel__content__expiry-duration__select"
                    handleOptionClick={setDataExpiryDuration}
                    options={contextDataExpiryNumOptions()}
                  />
                </Col>
                <Col className="context-attribute-sidepanel__content__expiry-unit">
                  <FlightSelect
                    selected={dataExpiryUnit}
                    disabled={attribute?.isReserved || isReadOnly || !!contextPlugin?.dataSource}
                    className="context-attribute-sidepanel__content__expiry-unit__select"
                    handleOptionClick={setDataExpiryUnit}
                    options={contextExpiryUnitOptions}
                  />
                </Col>
              </>
            )}
          </Row>
          {dataFormat.key !== 'dateTime' && (
            <>
              <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>Input Type </Label>
              <div>
                <FlightSelect
                  width="355px"
                  selected={inputType}
                  disabled={attribute?.isReserved || isReadOnly || !!contextPlugin?.dataSource}
                  className="context-attribute-sidepanel__content__field"
                  handleOptionClick={setInputType}
                  options={contextInputTypeOptions}
                />
              </div>
            </>
          )}
          {inputType.key === 'dropdown' && dataFormat.key !== 'dateTime' && (
            <>
              <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>{`Dropdown selection options (${dropDownValues?.length})`}</Label>
              <div>Number indicates list item order placement</div>
              <div>
                <DoubleArrayFields
                  initialValues={{ values: dropDownValues, names: dropDownDisplayNames }}
                  placeHolderText="Value"
                  secondPlaceHolderText="Display name (optional)"
                  headers={{ key: 'Value', value: 'Display name (optional)' }}
                  callback={(keyData: string[], valueData: string[]) => {
                    handleSetDoubleArrayFields(keyData, valueData);
                  }}
                  hasError={() => {
                    // TODO: Should assert that every key has a value and vice versa
                    return false;
                  }}
                />
              </div>
            </>
          )}
          {!isEmpty(parameters) && (
            <>
              <Label className="context-attribute-sidepanel__content__label" tabIndex={0}>Parameters</Label>
              {parameters.map(item => {
                return (
                  <div
                    key={item.key}
                    className="context-attribute-sidepanel__content__existing-parameters"
                    onClick={() => handleEditParameter(item)}
                  >
                    <div className="context-attribute-sidepanel__content__existing-parameters__name">{item.key}</div>
                    <div>
                      {isEmpty(item.valueTypeOptions) ? 'Freeform Input' : 'Dropdown Input'}{' '}
                      {!isEmpty(item.valueTypeOptions) && (
                        <span className="context-attribute-sidepanel__content__existing-parameters__values">
                          - {item.valueTypeOptions?.length} {item.valueTypeOptions?.length > 1 ? 'values' : 'value'}
                        </span>
                      )}
                    </div>
                    <ClearIcon
                      onClick={e => {
                        e.stopPropagation();
                        handleDeleteParameter(item);
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
          <div
            className={
              attribute?.isReserved || isReadOnly || !!contextPlugin?.dataSource
                ? `mt-5 context-attribute-sidepanel__content__link mt-5 context-attribute-sidepanel__content__link--disabled`
                : `mt-5 context-attribute-sidepanel__content__link`
            }
            tabIndex={0}
            onKeyDown={(event) => {
             if(event.key === "Enter"){
               setOpenParameterModal(true);
             }
            }}
            onClick={() => setOpenParameterModal(true)}
          >
            + Add parameter
          </div>
        </Container>
      </>
    );
  };

  return (
    <div className={'context-attribute-sidepanel'}>
      <FlightModal
        isVisible={openParameterModal}
        toggleModalShown={() => setOpenParameterModal(isVisible => !isVisible)}
        size="large"
        header={!editParameter ? 'Add parameter' : 'Modify parameter'}
        content={parameterModalContent()}
        footer={
          <div className="modal-footer">
            <FlightButton
              theme="secondary"
              onClick={() => {
                setParameterKey('');
                setParameterDropDownValues(['']);
                setParameterDropDownDisplayNames(['']);
                setParameterInputType(defaultInputType);
                setOpenParameterModal(isVisible => !isVisible);
                setEditParameter(false);
                setIsDropDownError(false);
              }}
              label="Cancel"
            />
            <FlightButton
              type="submit"
              label={!editParameter ? 'Add' : 'Done'}
              disabled={
                attribute?.isReserved ||
                uniqParamError ||
                isEmpty(parameterKey) ||
                isDropDownError ||
                isReadOnly ||
                !!contextPlugin?.dataSource
              }
              onClick={handleCreateParameter}
            />
          </div>
        }
      />
      <SidePanel
        title={attribute?.name ? attribute?.name : 'Create attribute'}
        content={panelContent()}
        footer={footer}
        showPanel={showPanel}
        togglePanel={togglePanel}
      />
    </div>
  );
};
