import { ContextPlugin } from 'model/contextPlugins';

export const applyCombinationFilters = (
  filterBySearch: boolean,
  filterByDataType: boolean,
  filterByScope: boolean,
  filterByCategory: boolean,
  searchTermCondition: boolean,
  dataTypeCondition: boolean,
  categoryCondition: boolean,
  scopeCondition: boolean,
) => {
  let _attributesExist;
  // //if all filters are applied
  if (filterBySearch && filterByDataType && filterByScope && filterByCategory) {
    if (searchTermCondition && dataTypeCondition && categoryCondition && scopeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if search, type, category filters are applied
  if (filterBySearch && filterByDataType && filterByCategory) {
    if (searchTermCondition && dataTypeCondition && categoryCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if search, category, scope filters are applied
  if (filterBySearch && filterByCategory && filterByScope) {
    if (searchTermCondition && categoryCondition && scopeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if search, type, scope filters are applied
  if (filterBySearch && filterByDataType && filterByScope) {
    if (searchTermCondition && scopeCondition && dataTypeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if search, type filters are applied
  if (filterBySearch && filterByDataType) {
    if (searchTermCondition && dataTypeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if search, cat filters are applied
  if (filterBySearch && filterByCategory) {
    if (searchTermCondition && categoryCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }
  //if search, scope filters are applied
  if (filterBySearch && filterByScope) {
    if (searchTermCondition && dataTypeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if type, category, scope filters are applied
  if (filterByDataType && filterByCategory && filterByScope) {
    if (dataTypeCondition && categoryCondition && scopeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if type, category filters are applied
  if (filterByDataType && filterByCategory) {
    if (dataTypeCondition && categoryCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  // if type, scope filters are applied
  if (filterByDataType && filterByScope) {
    if (dataTypeCondition && scopeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if category, scope filters are applied
  if (filterByCategory && filterByScope) {
    if (categoryCondition && scopeCondition) {
      return (_attributesExist = true);
    }
    return (_attributesExist = false);
  }

  //if only search is applied
  if (filterBySearch) {
    if (searchTermCondition) {
      return (_attributesExist = true);
    }
  }
  //if only category is applied
  if (filterByCategory) {
    if (categoryCondition) {
      return (_attributesExist = true);
    }
  }
  //if only scope is applied
  if (filterByScope) {
    if (scopeCondition) {
      return (_attributesExist = true);
    }
  }
  //if only dataType is applied
  if (filterByDataType) {
    if (dataTypeCondition) {
      return (_attributesExist = true);
    }
  }
  return _attributesExist;
};

export const mapContextPluginToOption = (contextPlugin: ContextPlugin) => {
  const uidParts = contextPlugin.uid.split('.');
  const name = `${uidParts[uidParts.length - 2]}.${uidParts[uidParts.length - 1]}`;

  return { key: contextPlugin.uid, name };
};
