import React, { useState } from 'react';
import './PushTemplates.scss';
import {
  FlightSnackbar,
  FlightTag,
  FlightTable,
  FlightButton,
  FlightTooltip,
  FlightOverflowMenu,
  FlightModal,
} from '@flybits/design-system';
import { PUSH_TEMPLATES_INFO } from 'constants/banners/banners';
import { NotificationType, Schema } from 'model/pushSettings/settings';
import { cloneDeep, orderBy } from 'lodash';
import * as NotificationTypeActions from 'actions/pushSettings/notificationTypes';
import { useActions } from 'actions';

interface Props {
  notificationTypesFromState?: NotificationType[];
}

interface TableBody {
  templateName: JSX.Element;
  fields: string;
  requiredFields: string;
  createdBy: string;
  status: JSX.Element;
  moreOpts: JSX.Element;
  key: string;
}

interface IndexSignature {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function PushTemplatesCard(props: Props) {
  const { notificationTypesFromState = [] } = props;
  const [showBanner, setShowBanner] = useState(true);
  const TRACKED_KEYS = 'trackedKeys';
  const notificationTypeActions = useActions(NotificationTypeActions);
  const localNotificationTypesFromState = cloneDeep(notificationTypesFromState);

  const hideBanner = () => {
    setShowBanner(showBanner => !showBanner);
  };

  const tableHeaders = [
    {
      name: 'Template name',
      key: 'templateName',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Fields',
      key: 'fields',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Required fields',
      key: 'requiredFields',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Created by',
      key: 'createdBy',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Status',
      key: 'status',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Actions',
      key: 'moreOpts',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  const [isConfirm, setIsConfirm] = useState(false);
  const [idDelete, setIdForDelete] = useState('');
  const toggleDeleteConfirm = (id: string) => {
    if (idDelete === '') {
      setIdForDelete(id);
    } else {
      setIdForDelete('');
    }
    setIsConfirm(isConfirm => !isConfirm);
  };
  const handleDelete = async () => {
    await notificationTypeActions.deleteNotificationType(idDelete);
    setIdForDelete('');
    toggleDeleteConfirm('');
  };

  const moreOpts = (id: string) => {
    return [
      [
        {
          key: 1,
          name: 'Delete',
          disabled: false,
          onClick: () => toggleDeleteConfirm(id),
        },
      ],
    ];
  };

  localNotificationTypesFromState.push({
    id: 'basic-push-type',
    name: 'Basic',
    description: `When a message is opened, the user will be redirected to your app's default home screen`,
    isActive: true,
    isReserved: true,
    schema: {},
  });

  const getNumberOfFields = (item: Schema) => {
    if (Object.keys(item).length === 0) {
      return '0';
    } else if (item.hasOwnProperty(TRACKED_KEYS)) {
      return `${
        Object.keys(item).length - 1 === 1 ? `${Object.keys(item).length - 1}` : `${Object.keys(item).length - 1}`
      }`;
    } else {
      return `${Object.keys(item).length === 1 ? `${Object.keys(item).length}` : `${Object.keys(item).length}`}`;
    }
  };

  const getNumberOfRequiredFields = (item: IndexSignature) => {
    let numOfRequiredFields = 0;
    const keys = Object.keys(item);
    keys.forEach((key: string) => {
      if (item[key].isRequired) {
        numOfRequiredFields++;
      }
    });
    return numOfRequiredFields.toString();
  };

  const sortedSubscriptionState = orderBy(localNotificationTypesFromState, 'name', 'asc');
  const viewNotificationType = (id: string) => {
    const notificationTypeFromState = localNotificationTypesFromState.filter(x => x.id === id);
    let notificationTypeView: NotificationType = {
      isActive: false,
      id: '',
      isReserved: false,
      isView: false,
      schema: {},
      description: '',
      name: '',
    };
    notificationTypeFromState.map(type => {
      return (notificationTypeView = {
        id: type.id,
        isActive: type.isActive,
        isReserved: type.isReserved,
        name: type.name,
        description: type.description,
        schema: type.schema,
        isView: true,
      });
    });
    notificationTypeActions.setNotificationType(notificationTypeView);
  };

  const createPushTemplate = () => {
    const notificationTypeView: NotificationType = {
      isActive: false,
      id: '',
      isReserved: false,
      isView: true,
      schema: {},
      description: '',
      name: '',
      isCreate: true,
    };
    notificationTypeActions.setNotificationType(notificationTypeView);
  };

  const toggleStatus = (notification: NotificationType) => {
    const status = notification.isActive;
    const updateNotificationType: NotificationType = {
      id: notification.id,
      isReserved: notification.isReserved,
      isActive: !status,
      name: notification.name,
      description: notification.description,
      schema: notification.schema,
    };
    notificationTypeActions.updatePushNotificationType(updateNotificationType);
  };

  const tableBody: TableBody[] = [];
  sortedSubscriptionState.forEach(notification => {
    let statusClassName = 'push-templates__status';
    if (notification.isReserved) {
      statusClassName += ` ${statusClassName}--reserved`;
    }

    tableBody.push({
      templateName: (
        <FlightTooltip direction="right" isEnabled={true} description={notification.description} delay={0}>
          <span className="push-templates__name" onClick={() => viewNotificationType(notification.id)}>
            {notification.name}
          </span>
        </FlightTooltip>
      ),

      fields: getNumberOfFields(notification.schema),
      requiredFields: getNumberOfRequiredFields(notification.schema),
      createdBy: notification.isReserved ? 'Flybits' : 'User',
      status: (
        <span className={statusClassName} onClick={() => !notification.isReserved && toggleStatus(notification)}>
          <FlightTag
            label={notification.isActive ? 'Active' : 'Inactive'}
            type={notification.isActive ? 'success' : 'error'}
          />
        </span>
      ),
      moreOpts: (!notification.isReserved && (
        <FlightOverflowMenu
          className="push-templates__options"
          optionGroups={moreOpts(notification.id)}
          direction="bottom-left"
        />
      )) || <></>,
      key: notification.id,
    });
  });

  return (
    <div className="push-templates">
      <FlightModal
        isVisible={isConfirm}
        size="small"
        toggleModalShown={toggleDeleteConfirm}
        header={<span>Confirm Delete</span>}
        content={
          <>
            <div className="push-templates__delete-modal__text">Are you sure you want to delete notification type?</div>
            <div className="push-templates__delete-modal__text">You can&apos;t undo this.</div>
          </>
        }
        footer={
          <div className="modal-footer">
            <FlightButton theme="secondary" onClick={toggleDeleteConfirm} label="Cancel" />
            <FlightButton type="submit" label="Continue" onClick={handleDelete} />
          </div>
        }
      />
      <FlightSnackbar
        isVisible={showBanner}
        icon="infoFilled"
        className="push-templates__snack-bar"
        content={PUSH_TEMPLATES_INFO}
        type="info"
        isAutoDismiss={true}
        action={hideBanner}
        actionName="Got it!"
      />
      <div className="pt-3 pb-3">
        <FlightButton
          label={'New push template'}
          size="medium"
          theme="secondary"
          iconLeft={'add'}
          className="push-templates__btn"
          onClick={createPushTemplate}
        />
      </div>
      <div className="pt-5">
        <FlightTable
          className="push-templates__table"
          tableHeaders={tableHeaders}
          tableData={tableBody}
          hasPaginationBeforeTable={false}
          ariaLabel="push templates Table"
        />
      </div>
    </div>
  );
}
