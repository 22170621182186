import { ApiUsageActionTypes, Subscription } from 'model/apiUsage';
import SubscriptionService from 'services/subscription.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator, makeFetchActionCreator } from 'helpers/actionCreator';
import { showNotification } from './snackBarNotifications';

export const setSubscriptionState = makeActionCreator(ApiUsageActionTypes.SET_SUBSCRIPTIONS_STATE);

export const setRatelimitsState = makeActionCreator(ApiUsageActionTypes.SET_RATELIMITS_STATE);

export const fetchAllSubscriptions = makeFetchActionCreator(
  new SubscriptionService(),
  'getAllSubscriptions',
  setSubscriptionState,
  'data',
);

export function createNewSubscriptionAlert(params: Subscription) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new SubscriptionService();
      const response = await req.createNewSubscription(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Email alert added.',
            type: 'success',
          }),
        );
        dispatch(fetchAllSubscriptions());
      }
    } catch (e: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed to add email alert.',
          body: e.response.data.error.exceptionMessage,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function updateSubscriptionAlert(params: Subscription) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new SubscriptionService();
      const response = await req.updateSubscription(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Email alert modified.',
            type: 'success',
          }),
        );
        dispatch(fetchAllSubscriptions());
      }
    } catch (e: any) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed to modify email alert.',
          body: e.response.data.error.exceptionMessage,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function deleteSubscriptionAlert(params: Subscription) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new SubscriptionService();
      const response = await req.deleteSubscription(params);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Email alert deleted.',
            type: 'success',
          }),
        );
        dispatch(fetchAllSubscriptions());
      }
    } catch (e) {}
    return 'done';
  };
}

export function fetchRatelimitsForProject(args: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const params = {
        id: args,
        includeCatalogueUsages: true,
      };
      const req = new SubscriptionService();
      const response = await req.getRateLimits(params);
      if (response.status === 200) {
        dispatch(setRatelimitsState(response.data.rateLimit));
      }
    } catch (e) {
      const randomId = generateUniqueId();
      showNotification({
        id: randomId,
        showNotification: true,
        header: 'Error',
        body: 'Failed to fetch rate limits.',
        type: 'error',
      });
    }
    return 'done';
  };
}
