/**
 * This function is a reusable helper that takes in a string and masks the length except
 * the last four letters, if a string is less than last four letters, it
 * will only mask the last two :)
 * @export
 * @param {string} string
 * @returns masked type string;
 */
export function maskField(string: string) {
  if (string.length <= 8) {
    const masked =
      string.substring(0, string.length).replace(/.(?=.{2,}$)/g, '*') +
      string.substring(string.length - 1, string.length - 1);
    return masked as string;
  } else {
    const masked =
      string.substring(0, string.length).replace(/.(?=.{4,}$)/g, '*') +
      string.substring(string.length - 4, string.length - 4);
    return masked as string;
  }
}
