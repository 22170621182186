import React, { useState } from 'react';
import { FlightButton, getIcon } from '@flybits/design-system';
import { ListView } from './ConflictsList';

import './ConflictsBase.scss';
import {
  TemplateCategories,
  TemplateConflicts,
  TemplateExportedContent,
  TemplateImportContent,
  TemplatePayload,
} from 'model/manageTemplates/manageTemplates';
import ConflictsComponentsResolution from './ConflictsComponentsResolution';
interface ConflictsBaseProps {
  conflicts: TemplateConflicts;
  categories: TemplateCategories;
  importedTemplates: TemplateExportedContent;
  templateLibrary: TemplatePayload[];
  templatesPayload: TemplateImportContent;
}

export default function ConflictsBaseComponents(props: ConflictsBaseProps) {
  const { importedTemplates, conflicts, templatesPayload, templateLibrary } = props;
  const [currentListFilter, setCurrentListFilter] = useState('');
  return (
    <div className="resolve-conflicts-base">
      <div className="resolve-conflicts-base__top">
        <div className="resolve-conflicts-base__top-conflicts">
          Conflicts{' '}
          {conflicts?.templateConflicts.length > 0 && (
            <FlightButton
              onClick={() => null}
              label="Experience Templates"
              size={'small'}
              iconLeft={'error'}
              theme="secondary"
            />
          )}
          {conflicts?.componentConflicts.length > 0 && (
            <FlightButton
              onClick={() => null}
              label="Component Templates"
              size={'small'}
              iconLeft={'error'}
              theme="secondary"
            />
          )}
        </div>
        <div className="resolve-conflicts-base__top-info">
          <div>
            Which <strong>Component Templates</strong> would you like to keep?
          </div>
          <div className="info">
            If you select both versions, a new instance of it will be created {getIcon('infoFilled', {})}
          </div>
        </div>
      </div>
      <div className="resolve-conflicts-base__bottom">
        <ListView
          title={'Component Types'}
          type={'components'}
          filter={currentListFilter}
          library={templateLibrary}
          conflicts={conflicts}
          handleSelectFilter={setCurrentListFilter}
          categories={[
            { name: 'Rules', key: 'rule_template' },
            { name: 'Pushes', key: 'push_template' },
            { name: 'Content', key: 'content_template' },
            { name: 'Webhooks', key: 'webhook_template' },
          ]}
        />
        <div className="resolve-conflicts-base__content">
          <ConflictsComponentsResolution
            filter={currentListFilter}
            templatesJSON={importedTemplates}
            templatesPayload={templatesPayload}
            library={templateLibrary}
            conflicts={conflicts}
          />
        </div>
      </div>
    </div>
  );
}
