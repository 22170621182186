import { mapContextPluginToOption } from 'helpers/ctxFilter';
import { isEmpty } from 'lodash';
import { ContextPlugin } from 'model/contextPlugins';
import { FlightSelectOptions } from 'model/misc/flightSelectOptions';

const contextAttributesScopeOptions = [
  {
    key: 'default',
    name: 'Any scope',
  },
  {
    key: 'tenant',
    name: 'Tenant',
  },
];

const contextCategoryCreatedBy = [
  {
    key: 'default',
    name: 'Created by anyone',
  },
  {
    key: 'flybits',
    name: 'Created by Flybits',
  },
  {
    key: 'user',
    name: 'Created by user',
  },
];

const contextCategoryStatusOptions = [
  {
    key: 'default',
    name: 'Any status',
  },
  {
    key: 'active',
    name: 'Active',
  },
  {
    key: 'inActive',
    name: 'Inactive',
  },
];

const contextInputTypeOptions = [
  {
    key: 'freeform',
    name: 'Freeform',
  },
  {
    key: 'dropdown',
    name: 'Dropdown',
  },
];

const contextAttributeDataExpiryOptions = [
  {
    key: 'expiresEvery',
    name: 'Data expires every',
  },
  {
    key: 'neverExpires',
    name: 'Never expires',
  },
];

const contextExpiryUnitOptions = [
  {
    key: 'minutes',
    name: 'Minutes',
  },
  {
    key: 'hours',
    name: 'Hours',
  },
  {
    key: 'days',
    name: 'Days',
  },
  {
    key: 'weeks',
    name: 'Weeks',
  },
  {
    key: 'months',
    name: 'Months',
  },
  {
    key: 'years',
    name: 'Years',
  },
];

const contextDataFormatOptions = [
  {
    key: 'string',
    name: 'String',
  },
  {
    key: 'int',
    name: 'Integer',
  },
  {
    key: 'bool',
    name: 'Yes/No',
  },
  {
    key: 'float',
    name: 'Float',
  },
  {
    key: 'dateTime',
    name: 'Date Time',
  },
];

const contextDataTypeOptions = [
  {
    key: 'default',
    name: 'Any input type',
  },
  {
    key: 'string',
    name: 'String',
  },
  {
    key: 'bool',
    name: 'Yes/No',
  },
  {
    key: 'boolDropdown',
    name: 'Yes/No dropdown',
  },
  {
    key: 'stringDropDown',
    name: 'String dropdown',
  },
  {
    key: 'int',
    name: 'Int',
  },
  {
    key: 'intDropDown',
    name: 'Int Dropdown',
  },
  {
    key: 'float',
    name: 'Float',
  },
  {
    key: 'floatDropDown',
    name: 'Float dropdown',
  },
  {
    key: 'dateTime',
    name: 'Date time',
  },
];

const getContextAttributeCategoryOptions = (contextPlugins: ContextPlugin[], isFilter?: boolean) => {
  // adding default object to 'break' the filter
  const _catFilters: FlightSelectOptions[] = [
    {
      key: 'default',
      name: `${isFilter ? 'Any Category' : 'Create category'}`,
    },
  ];

  contextPlugins?.map(contextPlugin => {
    if (!isEmpty(contextPlugin)) {
      _catFilters.push(mapContextPluginToOption(contextPlugin));
    }
  });

  return _catFilters;
};

const contextDataExpiryNumOptions = () => {
  const _numOpts: FlightSelectOptions[] = [];
  Array.from({ length: 100 }, (_, k) => k + 1)?.map(item => {
    _numOpts.push({
      key: item.toString(),
      name: item.toString(),
    });
  });
  return _numOpts;
};

export {
  contextAttributesScopeOptions,
  contextDataTypeOptions,
  contextCategoryCreatedBy,
  contextAttributeDataExpiryOptions,
  contextExpiryUnitOptions,
  getContextAttributeCategoryOptions,
  contextCategoryStatusOptions,
  contextDataExpiryNumOptions,
  contextDataFormatOptions,
  contextInputTypeOptions,
};
