import React, { useEffect } from 'react';
import PageHeader from '../../components/Shared/PageHeader/PageHeader';
import SignInProviders from '../../components/AuthenticateComponents/SignInProviders';
import AccountDomainAccess from '../../components/AuthenticateComponents/AccountDomainAccess';
import * as AccountAccessActions from 'actions/accountAccess';
import { useActions } from 'actions';
import './AuthenticationPage.scss';
export default function AuthenticationPage() {
  const accountAccessActions = useActions(AccountAccessActions);

  async function fetchProjectDependencies() {
    await accountAccessActions.fetchApiKeySettings();
    await accountAccessActions.fetchOpenIDSettings();
    await accountAccessActions.fetchSamlSettings();
    await accountAccessActions.fetchSignedLoginSettings();
    await accountAccessActions.fetchJWTSignedLoginSettings();
    await accountAccessActions.fetchTenantAccessSettings();
  }

  useEffect(() => {
    document.title = "Authentication | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    fetchProjectDependencies();
    (document.getElementsByClassName('account-domain-access__btn')?.[0]  as HTMLElement)?.focus();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div role="main" className="authentication-page">
      <PageHeader title="Authentication" isLarge={true} />
      <PageHeader title="Sign-in providers" isLarge={false} />
      <div className="authentication-page__content">
        <div className="pt-3">
          <SignInProviders />
        </div>
        <div className="pt-5">
          <AccountDomainAccess />
        </div>
      </div>
    </div>
  );
}
