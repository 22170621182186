import { useContext, useEffect, useState } from 'react';
import flagsmith from 'flagsmith';
import { AuthContext } from 'auth/providers/AuthProvider';
import { FeatureFlagsContextValue, FeatureFlags } from './feature-flags.types';
import { FEATURE_FLAGS } from './feature-flags.constants';

export function useFeatureFlagsContextValue(): FeatureFlagsContextValue {
  const { isAuthenticated, userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);

      const { email } = userData;

      flagsmith.identify(email);
      flagsmith.init({
        environmentID: `${process.env.REACT_APP_BULLET_TRAIN_ID}`,
        onChange: flags => {
          const featureFlags: FeatureFlags = {};

          FEATURE_FLAGS.map(flag => {
            const devPortalFeatureFlag = flags[flag];
            if (devPortalFeatureFlag) {
              featureFlags[flag] = devPortalFeatureFlag.enabled;
            }

            return true;
          });

          setFeatureFlags(featureFlags);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  return {
    isLoading,
    featureFlags,
  };
}
