export const layout = [
  {
    id: '1',
    value: 'Attributes',
  },
  {
    id: '2',
    value: 'Categories',
  },
];
