import { ManageTemplatesActions, ManageTemplatesActionTypes, ManageTemplateState } from 'model/manageTemplates';
import createReducer from './createReducer';

const initialState = {} as ManageTemplateState;

export const manageTemplates = createReducer<ManageTemplateState>(initialState, {
  [ManageTemplatesActionTypes.SET_IMPORT_TEMPLATES_STATE](state: ManageTemplateState, action: ManageTemplatesActions) {
    return { ...state, importTemplatesPayload: action.payload };
  },
  // Categories
  [ManageTemplatesActionTypes.SET_IMPORT_CATEGORY_STATE](state: ManageTemplateState, action: ManageTemplatesActions) {
    return { ...state, importLibraryCategories: action.payload };
  },
  // Library
  [ManageTemplatesActionTypes.SET_IMPORT_LIBRARY_STATE](state: ManageTemplateState, action: ManageTemplatesActions) {
    return { ...state, importLibraryTemplates: action.payload };
  },
  [ManageTemplatesActionTypes.SET_ORIGINAL_IMPORT_TEMPLATES_STATE](
    state: ManageTemplateState,
    action: ManageTemplatesActions,
  ) {
    return { ...state, importedFile: action.payload };
  },
  [ManageTemplatesActionTypes.SET_IMPORTED_TEMPLATES_STATE](
    state: ManageTemplateState,
    action: ManageTemplatesActions,
  ) {
    return { ...state, importedTemplates: action.payload };
  },
  [ManageTemplatesActionTypes.SET_IMPORT_STATUS_STATE](state: ManageTemplateState, action: ManageTemplatesActions) {
    return { ...state, importStatus: action.payload };
  },
  [ManageTemplatesActionTypes.SET_IMPORT_STATUS_INTERVAL_STATE](
    state: ManageTemplateState,
    action: ManageTemplatesActions,
  ) {
    return { ...state, importStatusInterval: action.payload };
  },
  [ManageTemplatesActionTypes.SET_IMPORT_CONFLICTS_STATE](state: ManageTemplateState, action: ManageTemplatesActions) {
    return { ...state, importConflicts: action.payload };
  },
  [ManageTemplatesActionTypes.RESET_IMPORT_STATE](state: ManageTemplateState) {
    return {
      ...state,
      importConflicts: undefined,
      importedFile: undefined,
      importTemplatesPayload: undefined,
    };
  },
});
