import { PushSettingsActionTypes } from 'model/pushSettings';
import { HuaweiSettings, HuaweiSettingsPayload } from 'model/pushSettings/settings';
import { PushService } from 'services/push.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator } from 'helpers/actionCreator';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';
import { showNotification } from '../snackBarNotifications';

export const setHuaweiSettings = makeActionCreator(PushSettingsActionTypes.SET_HUAWEI_SETTINGS);

export function fetchHuaweiSettings() {
  const transformedResponse: HuaweiSettings = { appID: '', clientCredentials: '', clientSecretLast4: '' };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.getHuaweiSettings();
      dispatch(setHuaweiSettings(response.data));
    } catch (error: any) {
      const errorObjectFromResponse = error.response.data.error.exceptionType;
      if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(setHuaweiSettings(transformedResponse));
      }
    }
    return 'done';
  };
}

export function postHuaweiSettings(huaweiSettings: HuaweiSettingsPayload) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      const response = await req.createHuaweiSettings(huaweiSettings);
      dispatch(fetchHuaweiSettings());
      if (response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Huawei setting has been created successfully',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to add Huawei setting',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function patchHuaweiSettings(huaweiSettings: HuaweiSettingsPayload) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      const response = await req.updateHuaweiSettings(huaweiSettings);
      dispatch(fetchHuaweiSettings());
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Huawei setting has been updated successfully',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to update Huawei setting',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function deleteHuaweiSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      const response = await req.deleteHuaweiSetings();
      dispatch(fetchHuaweiSettings());
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Huawei setting has been deleted successfully',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not delete Huawei setting',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}
