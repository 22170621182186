export const EXP_STUDIO_2_ENABLED = 'ct_enable_experience_studio_2.0';
export const INTEGRATIONS_ENABLED = 'ct_enable_integrations';
export const SERVICE_ACCOUNTS_ENABLED = 'dp_enable_service_accounts';
export const HUAWEI_PUSH = 'dp_huawei_push';
export const FCMV1_PUSH = 'dp_fcmv1_push';
export const FLYBITS_LIFE_APP_ENABLED = 'dp_flybits_life_app_enabled';
export const MAKER_CHECKER_SETTINGS = 'dp_maker_checker_settings';

export const FEATURE_FLAGS = [
  EXP_STUDIO_2_ENABLED,
  INTEGRATIONS_ENABLED,
  SERVICE_ACCOUNTS_ENABLED,
  HUAWEI_PUSH,
  FCMV1_PUSH,
  FLYBITS_LIFE_APP_ENABLED,
  MAKER_CHECKER_SETTINGS,
];
