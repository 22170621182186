import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { RootState } from 'reducers';
import * as FcmActions from 'actions/pushSettings/fcmSettings';
import * as FcmV1Actions from 'actions/pushSettings/fcmV1Settings';
import * as ApnsActions from 'actions/pushSettings/apnsSettings';
import * as huaweiActions from 'actions/pushSettings/huaweiSettings';
import * as UserSubscriptionActions from 'actions/pushSettings/userSubscriptions';
import * as SilentPushSettingsActions from 'actions/pushSettings/silentPushSettings';
import * as NotificationTypesActions from 'actions/pushSettings/notificationTypes';
import * as PushLimitSettingActions from 'actions/pushSettings/pushLimitSettings';
import { useActions } from 'actions';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { layout } from 'constants/pushPageLayout';
import './PushPage.scss';
import AppConfigurations from 'components/PushComponents/AppConfigurations/AppConfigurations';
import PushTemplatesCard from 'components/PushComponents/PushTemplates/PushTemplates';
import ManagePushTemplates from 'components/PushComponents/PushTemplates/ManagePushTemplate';
import PushLimits, {LIMIT_TYPE} from 'components/PushComponents/PushLimits/PushLimits';
import { getTab, setTab } from 'helpers/rememberTab';

interface Layout {
  id: string;
  value: string;
}

export default function PushPage() {
  const fcmActions = useActions(FcmActions);
  const fcmV1Actions = useActions(FcmV1Actions);
  const apnsActions = useActions(ApnsActions);
  const huaweiAction = useActions(huaweiActions);
  const userSubscriptions = useActions(UserSubscriptionActions);
  const silentPushSettingsActions = useActions(SilentPushSettingsActions);
  const notificationTypesActions = useActions(NotificationTypesActions);
  const pushLimitSettingActions = useActions(PushLimitSettingActions);
  const notificationTypesFromState = useSelector((state: RootState) => state.pushSettings.notificationTypes);
  const notificationTypeFromState = useSelector((state: RootState) => state.pushSettings.notificationType);

  async function fetchProjectDependencies() {
    await fcmActions.fetchFcmSettings();
    await fcmV1Actions.fetchFcmV1Settings();
    await apnsActions.fetchApnsSettings();
    await apnsActions.fetchApnsTokenSettings();
    await huaweiAction.fetchHuaweiSettings();
    await userSubscriptions.fetchAllUserSubscriptions();
    await silentPushSettingsActions.fetchSilentPushSettings();
    await notificationTypesActions.fetchNotificationTypes();
    await pushLimitSettingActions.fetchPushLimitSettings(LIMIT_TYPE);
  }

  const isView = notificationTypeFromState?.isView || false;

  const getTabId = getTab('push');
  const [activeTab, setActiveTab] = useState(getTabId);

  useEffect(() => {
    document.title = "Push Settings | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    setTab('push', activeTab);
    //eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    fetchProjectDependencies();
    (document.getElementsByClassName('nav-link')?.[0] as HTMLElement)?.focus();
    //eslint-disable-next-line
  }, []);

  return (
    <div role="main" className="push-page">
      {!isView ? (
        <div>
          <PageHeader title="Push Settings" isLarge={true} />
          <div className="push-page__tabstrip">
            <div>
              <Row className="push-page__tabstrip__container__tabs">
                <Col>
                  <Nav tabs>
                    {layout.map((tab: Layout) => (
                      <NavItem key={tab.id}>
                        <NavLink
                          role="tab"
                          tabIndex={0}
                          aria-selected={activeTab === tab.id}
                          className={activeTab === tab.id ? 'active' : ''}
                          onKeyDown={event => {
                            if (event.key === 'Enter') {
                              setActiveTab(tab.id);
                            }
                          }}
                          onClick={() => setActiveTab(tab.id)}
                        >
                          <span
                            className={
                              activeTab === tab.id
                                ? 'push-page__tabstrip__container__tabs__label__active'
                                : 'push-page__tabstrip__container__tabs__label'
                            }
                          >
                            {tab.value}
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
              </Row>
            </div>
            <TabContent className="push-page__tabstrip__container__content" activeTab={activeTab}>
              <TabPane tabId="1">
                <AppConfigurations />
              </TabPane>
              <TabPane tabId="2">
                <PushTemplatesCard notificationTypesFromState={notificationTypesFromState} />
              </TabPane>
              <TabPane tabId="3">
                <PushLimits />
              </TabPane>
            </TabContent>
          </div>
        </div>
      ) : (
        <div className="push-page__manage">
          <ManagePushTemplates notificationTypeFromState={notificationTypeFromState} />
        </div>
      )}
    </div>
  );
}
