import React, { FunctionComponent } from 'react';
import { FlightModal } from '@flybits/design-system';
import CustomCategoriesContent from './CustomCategoriesContent/CustomCategoriesContent';
import './CustomCategoriesModal.scss';

interface Props {
  toggleModal: () => void;
}

const CustomCategoriesModal: FunctionComponent<Props> = ({ toggleModal }) => (
  <div className="custom-categories-modal">
    <FlightModal
      isVisible
      toggleModalShown={() => {
        toggleModal();
      }}
      scrollable={false}
      size="large"
      content={<CustomCategoriesContent />}
    />
  </div>
);

export default CustomCategoriesModal;
