import { PushSettingsActionTypes } from 'model/pushSettings';
import { PushService, PushUploadService } from 'services/push.service';
import { makeActionCreator } from 'helpers/actionCreator';
import { showNotification } from '../snackBarNotifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';

export const setFcmV1Settings = makeActionCreator(PushSettingsActionTypes.SET_FCM_V1_SETTINGS);

export function fetchFcmV1Settings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.getFcmV1Settings();
      dispatch(setFcmV1Settings(response.data));
    } catch (error: any) {
      const errorObjectFromResponse = error?.response.data?.error?.exceptionType || '';
      if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(setFcmV1Settings({ projectID: '' }));
      }
    }
    return 'done';
  };
}

export function uploadFCMServiceAccount(data: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushUploadService();
      const response = await req.uploadFCMServiceAccountJSON(data);
      if (response.status === 204) {
        return dispatch(fetchFcmV1Settings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not upload fcm service account',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function deleteFcmV1Settings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.deleteFcmV1Settings();
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Deleted',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchFcmV1Settings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not delete project',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}
