import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { RootState } from 'reducers';
import * as ContextAttributesActions from 'actions/contextPlugins';
import * as ContextPluginActions from 'actions/contextPlugins';
import { useActions } from 'actions';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { layout } from 'constants/contextAttributePageLayout';
import './ContextAttributesPage.scss';
import { getTab, setTab } from 'helpers/rememberTab';
import {
  Attribute,
  ContextPlugin,
  ContextPluginAttributes,
  ContextPluginsDownload,
  TPreFilterCategory,
} from 'model/contextPlugins';
import ContextAttributesTable from 'components/ContextAttributesComponents/ContextAttributesTable';
import ContextCategoriesTable from 'components/ContextAttributesComponents/ContextCategoriesTable';
import { FlightButton } from '@flybits/design-system';
import ContextCategoryModal from 'components/ContextAttributesComponents/ContextCategoryModal';
import { ContextAttributeSidePanel } from 'components/ContextAttributesComponents/ContextAttributeSidePanel';
import { getCtxPluginFromAttribute } from 'helpers/getCategoryFromString';
import { useDropzone } from 'react-dropzone';
import { cloneDeep, isEmpty } from 'lodash';
import ContextAttributesEmptyState from 'components/ContextAttributesEmptyState/ContextAttributesEmptyState';
import { mapContextPluginToOption } from 'helpers/ctxFilter';

interface Layout {
  id: string;
  value: string;
}

export default function ContexAttributesPage() {
  const contextAttributeActions = useActions(ContextAttributesActions);
  const contextPluginsState = useSelector((state: RootState) => state.contextPlugins.contextPlugins);
  const [contextPlugins, setContextPlugins] = useState<ContextPlugin[]>([]);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openAttributeSidePanel, setOpenAttributeSidePanel] = useState(false);
  const contextPluginActions = useActions(ContextPluginActions);
  const [isOnClickNewCategory, setIsOnClickNewCategory] = useState(false);

  async function fetchProjectDependencies() {
    await contextAttributeActions.fetchContextPlugins();
  }

  const getTabId = getTab('ctx.atributes');
  const [activeTab, setActiveTab] = useState(getTabId);
  const categories: string[] = contextPlugins?.map(plugin => {
    return plugin.category;
  });

  const userCreatedCategories = contextPlugins?.map(ctxPlugin => {
    if (!ctxPlugin.isReserved) {
      return ctxPlugin;
    }
  }) as ContextPlugin[];

  useEffect(() => {
    setTab('ctx.atributes', activeTab);
    //eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    setTimeout(() => {
      (document.getElementsByClassName('context-attributes-page__header__link')?.[0]  as HTMLElement)?.focus();
    }, 50);
    fetchProjectDependencies();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setContextPlugins(cloneDeep(contextPluginsState));
    //eslint-disable-next-line
  }, [contextPluginsState]);

  async function handleImport(args: string) {
    const parsedData: ContextPluginsDownload = JSON.parse(args);
    const contextPluginsForUpload: ContextPluginAttributes[] = parsedData.data.map(item => {
      return item.attributes;
    });

    contextPluginsForUpload.forEach(async plugin => {
      await contextPluginActions.createContextPlugin(plugin);
    });
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const parseUploadedFile = new FileReader();
      parseUploadedFile.readAsText(file);
      parseUploadedFile.onload = () => {
        if (!!parseUploadedFile.result) {
          handleImport(parseUploadedFile.result as string);
        }
      };
    });
    // eslint-disable-next-line
  }, []);

  const { getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: '.context-plugins',
  });

  const [contextPlugin, setContextPlugin] = useState<ContextPlugin>();
  const handleCategoryClicked = (category: ContextPlugin) => {
    setContextPlugin(category);
    setOpenCategoryModal(true);
  };

  const [attribute, setAttribute] = useState<Attribute>();
  const handleAttributeClicked = (attribute: Attribute) => {
    const contextPlugin = getCtxPluginFromAttribute(attribute, contextPlugins);

    setAttribute({ ...attribute, isReserved: contextPlugin?.isReserved });
    setOpenAttributeSidePanel(true);
  };

  useEffect(() => {
    document.title = "Context Attributes | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    if (!openCategoryModal) {
      setContextPlugin(undefined);
    }
  }, [openCategoryModal]);

  useEffect(() => {
    if (!openAttributeSidePanel) {
      setAttribute(undefined);
    }
  }, [openAttributeSidePanel]);

  const [preFilterCategory, setPrefilterCategory] = useState<TPreFilterCategory>({ key: '', name: '' });

  const handleViewAttributes = (contextPlugin: ContextPlugin) => {
    setPrefilterCategory(mapContextPluginToOption(contextPlugin));
    setActiveTab('1');
  };

  const createContextAttribute = () => {
    setOpenAttributeSidePanel(true);
  };

  const createCategory = () => {
    setOpenCategoryModal(true);
  };

  return (
    <div role="main" className="context-attributes-page">
      {isEmpty(contextPlugins) ? (
        <ContextAttributesEmptyState
          handleCreateContextAttribute={createContextAttribute}
          handleCreateCategory={createCategory}
        />
      ) : (
        <>
          <div className="context-attributes-page__header">
            <PageHeader title="Context Attributes" isLarge={true} />
            <div className={`context-attributes-page__header__links`}>
              <FlightButton
                label="Create context attribute"
                theme="secondary"
                className={`context-attributes-page__header__link`}
                onClick={createContextAttribute}
              />
              <FlightButton
                label="Create category"
                theme="secondary"
                className={`context-attributes-page__header__link`}
                onClick={() => {
                  createCategory();
                  setIsOnClickNewCategory(true);
                }}
              />
              <FlightButton
                className={`context-attributes-page__header__link`}
                label="Import"
                theme="secondary"
                onClick={open}
              />
              <input name="file" id="fileValidation" {...getInputProps()} />
            </div>
          </div>
          <div className="context-attributes-page__tabstrip">
            <Row className="context-attributes-page__tabstrip__container__tabs ">
              <Col>
                <Nav tabs>
                  {layout.map((tab: Layout) => (
                    <NavItem key={tab.id}>
                      <NavLink role="tab" tabIndex={0} aria-selected={activeTab === tab.id}
                                className={activeTab === tab.id ? 'active' : ''}
                                onKeyDown={(event) => {
                                 if(event.key === "Enter"){
                                   setActiveTab(tab.id);
                                 }
                                }}
                                onClick={() => setActiveTab(tab.id)}>
                        <span className="context-attributes-page__tabstrip__container__tabs__label">{tab.value}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
            <TabContent className="context-attributes-page__tabstrip__container__content" activeTab={activeTab}>
              <TabPane tabId="1">
                <ContextAttributesTable
                  preFilterCategory={preFilterCategory}
                  contextPlugins={contextPlugins}
                  handleAttributeClicked={handleAttributeClicked}
                />
              </TabPane>
              <TabPane tabId="2">
                <ContextCategoriesTable
                  onClickCategoryName={handleCategoryClicked}
                  onClickViewAttributes={handleViewAttributes}
                  contextPlugins={contextPlugins}
                  hasNewCategory={isOnClickNewCategory}
                />
              </TabPane>
            </TabContent>
          </div>
        </>
      )}
      <ContextAttributeSidePanel
        openCategoryModal={() => setOpenCategoryModal(true)}
        showPanel={openAttributeSidePanel}
        userCreatedCategories={userCreatedCategories}
        contextPlugins={contextPlugins}
        attribute={attribute}
        togglePanel={() => setOpenAttributeSidePanel(false)}
      />
      <ContextCategoryModal
        isVisible={openCategoryModal}
        contextPlugin={contextPlugin}
        categories={categories}
        onCancel={() => setOpenCategoryModal(false)}
      />
    </div>
  );
}
