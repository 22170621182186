import { FlightButton, FlightModal, FlightSelectSearchable } from '@flybits/design-system';
import { useActions } from 'actions';
import * as LanguageActions from 'actions/languages';
import * as LanguagesActions from 'actions/languages';
import clearIcon from 'assets/images/clear-icon.svg';
import warningIcon from 'assets/images/warning-icon.svg';
import ViewBoxLoader from 'components/Shared/ContentLoader/ViewBoxLoader';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import { DEFAULT_LANGUAGE_WARNING } from 'constants/banners/banners';
import langmap from 'langmap';
import { cloneDeep, differenceWith, isEmpty, isEqual, pickBy } from 'lodash';
import { Language } from 'model/language';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import './ChannelLanguagesPage.scss';

type TSelectOptions = {
  key: string;
  name: string;
};

const rtlLanguages = ['ar', 'fa', 'he', 'ur', 'yi', 'ff', 'ach'];

function ChannelLanguagesPage() {
  const languagesFromState = useSelector((state: RootState) => state.languages.languages);
  const languagesActions = useActions(LanguagesActions);

  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState('');
  const languageActions = useActions(LanguageActions);
  const [selected, setSelected] = useState<TSelectOptions>({ key: '', name: '' });

  const languageForDropdown: TSelectOptions[] = [];
  const sortedLanguages = languagesFromState?.sort((languageOne: Language, languageTwo: Language) =>
    languageOne.isDefault === languageTwo.isDefault ? 0 : languageOne.isDefault ? -1 : 1,
  );
  const allLanguages = langmap;
  // we ignore country specific languages
  const filteredLanguagues = pickBy(allLanguages, (_, key) => key.indexOf('-') < 1);
  delete filteredLanguagues['en@pirate'];
  const [languages, setLanguages] = useState<Language[]>([]);
  const mapForSearchableSelect = Object.entries(filteredLanguagues)
                                  .sort((lang1, lang2) => {
                                    const language1 = lang1[1].englishName.toUpperCase();
                                    const language2 = lang2[1].englishName.toUpperCase();
                                    return (language1 < language2) ? -1 : (language1 > language2) ? 1 : 0;
                                  });
  mapForSearchableSelect.forEach(language => {
    languageForDropdown.push({
      key: language[0],
      name: language[1]?.englishName,
    });
  });

  const handleOptionClick = (option: TSelectOptions) => {
    setErrorDialogState('');
    setSelected(option);
    const configLanguage: Language = {
      id: option.key,
      isDefault: false,
      name: option.name,
      direction: rtlLanguages.includes(option.key) ? 'rtl' : 'ltr',
    };
    if (languages.every(el => el.id !== configLanguage.id)) {
      setLanguages([...languages, configLanguage]);
    }
  };

  const [options, setOptions] = useState<TSelectOptions[]>([{ key: '', name: '' }]);
  const handleSearch = (value: string) => {
    const searchedTerm = languageForDropdown.filter(opt =>
      opt.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    );
    setOptions(searchedTerm);
  };

  function toggleModalState() {
    setErrorDialogState('');
    setSelected({ key: '', name: '' });
    setModal(modal => !modal);
  }

  async function handleSubmit() {
    toggleModalState();
    setIsLoading(true);

    await languageActions.addOrUpdateLanguage(languages);
  }

  function handleSetDefault(language: Language) {
    const _languages = [...languages];

    const replacePreviousDefaultValue = _languages.find(
      languageInArray => languageInArray.id !== language.id && languageInArray.isDefault === true,
    ) || {
      id: '',
      isDefault: false,
      name: '',
    };
    if (replacePreviousDefaultValue.isDefault) {
      replacePreviousDefaultValue.isDefault = false;
    } else {
      return;
    }

    const mutateDefaultValueToTrue = _languages.find(languageInArray => languageInArray.id === language.id) || {
      id: '',
      isDefault: false,
      name: '',
    };
    if (mutateDefaultValueToTrue.isDefault) {
      return;
    } else {
      mutateDefaultValueToTrue.isDefault = true;
    }

    setLanguages(_languages);
  }

  function handleDelete(language: Language) {
    const deleteLanguage = languages.filter(
      languageInArray => languageInArray.id === language.id && languageInArray.isDefault !== true,
    );
    if (deleteLanguage.length !== 0) {
      const languageToBeDeleted = differenceWith(languages, deleteLanguage, isEqual);
      setLanguages(languageToBeDeleted);
    }
  }

  const isDisabled = () => {
    if (isEqual(languagesFromState, languages)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    document.title = "Channel Languages | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    (async () => {
      await languagesActions.fetchAllLanguages();

      setIsLoading(false);
      setOptions(languageForDropdown);
      (document.getElementsByClassName('flight-button--manage')?.[0]  as HTMLElement)?.focus();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(languagesFromState)) {
      return;
    }

    setLanguages(cloneDeep(languagesFromState));
    setIsLoading(false);
  }, [languagesFromState]);

  return (
    <div role="main" className="channel-languages">
      <PageHeader title="Channel Languages" isLarge={true} />
      <div className="channel-languages__card">
        <ReusableCard>
          <div className="channel-languages__card__wrapper">
            <div>
              <h2 className="channel-languages__card__header">Channel languages</h2>
              <div className="channel-languages__card__subheader">
                Languages listed here will be reflected as localization options for content and push notifications in
                experience studio.
              </div>
              {isLoading ? (
                <div className="analytics-export__card__viewbox-loader">
                  <ViewBoxLoader height={50} width={200} />
                </div>
              ) : (
                <div className="channel-languages__card__container">
                  {sortedLanguages?.map((language, index) => (
                    <span key={index}>
                      <span className="channel-languages__card__container__tabs">
                        {language.name}
                        <span className="channel-languages__card__container__tabs--default">
                          {language.isDefault ? '(Default)' : ''}
                        </span>
                      </span>
                      <span className="channel-languages__card__container__tabs--spacer" />
                    </span>
                  ))}
                </div>
              )}
            </div>
            <FlightButton className="flight-button--manage" label="Manage" onClick={toggleModalState} theme="secondary" disabled={isLoading} />
          </div>
        </ReusableCard>
      </div>
      <FlightModal
        className="channel-languages__modal-class"
        isVisible={modal}
        toggleModalShown={toggleModalState}
        size="small"
        scrollable
        header={<span>Language settings</span>}
        content={
          <div className="channel-languages__modal">
            <div className="channel-languages__modal__warning-banner">
              <img className="channel-languages__modal__warning-banner__icon" src={warningIcon} alt="warningicon" />
              <span className="channel-languages__modal__warning-banner__text">{DEFAULT_LANGUAGE_WARNING}</span>
            </div>
            <div className="channel-languages__modal__description">
              Languages listed here will be reflected as localization options for content and push notifications on
              experience studio
            </div>
            <FlightSelectSearchable
              label="Select a language to add"
              options={options}
              className="channel-languages__modal__select"
              selected={selected}
              handleOptionClick={handleOptionClick}
              handleSearch={handleSearch}
            />
            <div className="channel-languages__modal__value">
              {languages.map((language: Language) => (
                <div key={language.id}>
                  <div
                    className={`${
                      language.isDefault
                        ? `channel-languages__modal__value__values`
                        : `channel-languages__modal__value__values--not-default`
                    }`}
                  >
                    {language.name} {language.isDefault ? '(Default)' : ''}
                    <img
                      className="channel-languages__modal__value__actions--delete"
                      src={clearIcon}
                      alt="clear"
                      onClick={() => handleDelete(language)}
                    />
                    <div
                      className="channel-languages__modal__value__actions--set-default"
                      onClick={() => handleSetDefault(language)}
                    >
                      Set as default
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
        footer={
          <div className="modal-footer">
            <div className="channel-languages__error">{errorDialogState}</div>
            <FlightButton onClick={toggleModalState} label="Cancel" theme="secondary" />
            <FlightButton type="submit" label="Apply changes" disabled={isDisabled()} onClick={handleSubmit} />
          </div>
        }
      />
    </div>
  );
}

export default ChannelLanguagesPage;
