import React, { useState, useEffect } from 'react';
import { useActions } from 'actions';
import * as ManageTemplatesActions from 'actions/manageTemplates';
import { TemplateImportContent, TemplateImportStatus } from 'model/manageTemplates/manageTemplates';
import LoadingIcon from '../../Shared/LoadingIcon/LoadingIcon';
import { ReactComponent as PlaceholderPicture } from '../../../assets/images/templates-upload-process.svg';
import './ProcessTemplates.scss';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  importStatus: TemplateImportStatus;
  importTemplatesPayload?: TemplateImportContent;
  onSuccess: () => void;
  onError: () => void;
}

export default function ProcessTemplates(props: Props) {
  const { importStatus, importTemplatesPayload, onError, onSuccess } = props;
  const [importError, setImportError] = useState('');
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const manageTemplatesActions = useActions(ManageTemplatesActions, []);

  // run only on first render
  useEffect(() => {
    const startChecking = async () => {
      if (importStatus?.status !== 'processing') {
        await manageTemplatesActions.requestTemplateImport(importTemplatesPayload);
        manageTemplatesActions.getLatestTemplateImportStatus();
      } else {
        dispatch(
          ManageTemplatesActions.setImportStatusInterval(
            setInterval(() => {
              manageTemplatesActions.getLatestTemplateImportStatus();
            }, 2000),
          ),
        );
      }
    };

    startChecking();
  }, [dispatch, manageTemplatesActions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (importStatus.status === 'succeeded') {
      onSuccess();
    } else if (importStatus.status === 'failed') {
      setImportError(importStatus?.errMsg);
      onError();
    }
  }, [importStatus, onSuccess, onError]);

  return (
    <div className="process-templates">
      <PlaceholderPicture className="process-templates__image" />
      <span className="process-templates__text">We are processing your imported templates. </span>
      <span className="process-templates__text">
        Once processing is complete, the templates will be active for use.
      </span>
      {importStatus?.status === 'failed' && importError ? (
        <div className="process-templates__error__container">
          <span className="process-templates__text">{importError}</span>
        </div>
      ) : (
        <>
          <LoadingIcon className="process-templates__loading-circle" width={60} height={60} visible={true} />
          <span className="process-templates__text">You can safely close this page and come back later</span>
        </>
      )}
    </div>
  );
}
