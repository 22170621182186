export interface ContextRule {
  head: object; // TODO: Better define this
  body: object; // TODO: Better define this
  id: string;
  name: string;
  tenantID: string;
  userID: string;
  creator: string;
  scope: string;
  description?: string;
  color: string;
  // ContextRule object has a lot of other fields that we don't necessarily
  // need to define right now.
}

export interface ContextRuleState {
  contextRules: ContextRule[];
}

export enum ContextRuleActionTypes {
  SET_CONTEXT_RULES_STATE = 'SET_CONTEXT_RULES_STATE',
}

interface ContextRuleActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type ContextRuleActions = ContextRuleActionCreatorTypes<
  typeof ContextRuleActionTypes.SET_CONTEXT_RULES_STATE,
  ContextRule
>;
