export function downloadAsJson(fileName: string, json: string, extention: string) {
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.' + extention;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
