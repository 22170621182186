import BaseService from './base.service';
import { SamlSettingsForApi } from 'model/accountAccess/settings';
import UploadBaseService from './uploadbase.service';

interface Params {
  projectId: string;
}

type Base64Certifcate = {
  certificate: string;
};

export default class AuthService extends BaseService {
  CURRENT_PATH: string;
  PRIMARY_OPTIONAL_RESOURCE: string;
  SECONDARY_OPTIONAL_RESOURCE: string;
  SAML_RESOURCE: string;
  SIGNEDLOGIN_RESOURCE: string;
  SIGNEDLOGIN_QUERY_RESOURCE: string;
  JWT_SIGNEDLOGIN_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'sso/auth/';
    this.PRIMARY_OPTIONAL_RESOURCE = 'me';
    this.SECONDARY_OPTIONAL_RESOURCE = 'project/';
    this.SAML_RESOURCE = 'saml/config';
    this.SIGNEDLOGIN_RESOURCE = 'signedLogin/publicKey';
    this.SIGNEDLOGIN_QUERY_RESOURCE = 'json=true';
    this.JWT_SIGNEDLOGIN_RESOURCE = 'jwtlogin/certificates';
  }

  async getSignedInUser() {
    return this.request('GET', this.CURRENT_PATH + this.PRIMARY_OPTIONAL_RESOURCE);
  }

  async getProjectToken(params: Params) {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_OPTIONAL_RESOURCE, params);
  }

  async getSamlSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SAML_RESOURCE);
  }

  async createOrUpdateSamlSetting(data: SamlSettingsForApi) {
    return this.request('POST', this.CURRENT_PATH + this.SAML_RESOURCE, data);
  }

  async getSignedLoginSettings() {
    return this.request('GET', this.CURRENT_PATH + this.SIGNEDLOGIN_RESOURCE + '?' + this.SIGNEDLOGIN_QUERY_RESOURCE);
  }

  async deleteSignedLoginSettings() {
    return this.request('DELETE', this.CURRENT_PATH + this.SIGNEDLOGIN_RESOURCE);
  }

  async putJWTSignedLoginCert(base64Cert: Base64Certifcate) {
    return this.request('PUT', this.CURRENT_PATH + this.JWT_SIGNEDLOGIN_RESOURCE, base64Cert);
  }

  async getJWTSignedLoginSettings() {
    return this.request('GET', this.CURRENT_PATH + this.JWT_SIGNEDLOGIN_RESOURCE);
  }

  async deleteJWTSignedLoginSettings() {
    return this.request('DELETE', this.CURRENT_PATH + this.JWT_SIGNEDLOGIN_RESOURCE);
  }
}

export class SignedUploadService extends UploadBaseService {
  CURRENT_PATH: string;
  SECONDARY_RESOURCE: string;
  CALLBACK_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'sso/auth/';
    this.SECONDARY_RESOURCE = 'signedLogin/publicKey';
    this.CALLBACK_RESOURCE = `?callback=${window.location.origin}/noop.html`;
  }

  async uploadSignedLoginCertificate(data: FormData) {
    return this.request('POST', this.CURRENT_PATH + this.SECONDARY_RESOURCE + this.CALLBACK_RESOURCE, data);
  }
}
