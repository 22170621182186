import { History } from 'history';
import { combineReducers } from 'redux';
import { ContentTemplateState } from '../model/contentTemplates';
import { contentTemplates } from './contentTemplates';
import { ContextPluginState } from '../model/contextPlugins';
import { contextPlugins } from './contextPlugins';
import { Project } from '../model/project';
import { project } from './project';
import { projects } from './projects';
import { snackBarNotifications } from './snackBarNotifications';
import { SnackBarNotification } from '../model/snackBarNotification';
import { LanguageState } from 'model/language';
import { languages } from './languages';
import { customEmails, customEmailHeaderLogo } from './customEmails';
import { CustomEmail, CustomEmailHeaderLogo } from 'model/customEmail';
import { accountAccess } from './accountAccess';
import { AccountAccess } from 'model/accountAccess';
import { DatasourcesState } from 'model/datasource';
import { datasources } from './datasources';
import { PushSettings } from 'model/pushSettings';
import { pushSettings } from './push';
import { ApiUsage } from '../model/apiUsage';
import { apiUsage } from './apiUsage';
import { WebhookState } from 'model/webhook';
import { webhooks } from './webhook';
import { ContextRuleState } from 'model/contextRule';
import { contextRules } from './contextRules';
import { AnalyticsExportState } from 'model/analyticsExport';
import { analyticsExport } from './analyticsExport';
import { ConciergeCategoriesState } from 'model/conciergeCategories';
import { conciergeCategories } from './conciergeCategories';
import { ManageTemplateState } from 'model/manageTemplates';
import { manageTemplates } from './manageTemplates';
import { ServiceAccountState } from 'model/serviceAccount';
import { serviceAccounts } from './serviceAccounts';

export interface RootState {
  accountAccess: AccountAccess;
  analyticsExport: AnalyticsExportState;
  apiUsage: ApiUsage;
  conciergeCategories: ConciergeCategoriesState;
  contentTemplates: ContentTemplateState;
  contextPlugins: ContextPluginState;
  contextRules: ContextRuleState;
  customEmails: CustomEmail[];
  customEmailHeaderLogo: CustomEmailHeaderLogo[];
  datasources: DatasourcesState;
  languages: LanguageState;
  manageTemplates: ManageTemplateState;
  snackBarNotifications: SnackBarNotification;
  projects: Project[];
  project: Project;
  pushSettings: PushSettings;
  serviceAccounts: ServiceAccountState;
  webhooks: WebhookState;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (history: History) =>
  combineReducers({
    accountAccess,
    analyticsExport,
    apiUsage,
    conciergeCategories,
    contentTemplates,
    contextPlugins,
    contextRules,
    customEmails,
    customEmailHeaderLogo,
    datasources,
    languages,
    manageTemplates,
    snackBarNotifications,
    projects,
    project,
    pushSettings,
    serviceAccounts,
    webhooks,
  });
