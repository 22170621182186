import BaseService from './base.service';
import { Webhook, GetWebhooksParams } from 'model/webhook';

export default class WebhookService extends BaseService {
  CURRENT_PATH: string;
  PRIMARY_RESOURCE: string;

  constructor() {
    super();
    this.CURRENT_PATH = 'integrations/webhook/';
    this.PRIMARY_RESOURCE = 'endpoint';
  }

  async getAllWebhooks(params: GetWebhooksParams) {
    return this.request('GET', this.CURRENT_PATH + this.PRIMARY_RESOURCE, params);
  }

  async createWebhook(webhook: Webhook) {
    return this.request('POST', this.CURRENT_PATH + this.PRIMARY_RESOURCE, webhook);
  }

  async updateWebhook(webhook: Webhook) {
    return this.request('PUT', this.CURRENT_PATH + this.PRIMARY_RESOURCE, webhook);
  }

  async deleteWebhook(webhookId: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.PRIMARY_RESOURCE + '/' + webhookId);
  }
}
