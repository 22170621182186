import React, { ReactNode } from 'react';
import { Card, CardBody } from 'reactstrap';
import './ReusableCard.scss';

interface Props {
  children: ReactNode;
  maxHeight?: string;
  noPadding?: boolean;
  className?: string;
}

export default function ReusableCard(props: Props) {
  const { children, maxHeight, noPadding, className } = props;
  const cardClassName = className ? className.trim() : '';
  return (
    <Card
      className={`reusable-card${noPadding ? '--no-padding' : ''} ${cardClassName}`}
      style={{ maxHeight: `${maxHeight}` }}
    >
      <CardBody>{children}</CardBody>
    </Card>
  );
}
