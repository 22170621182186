export const layout = [
  {
    id: '1',
    value: 'App configurations',
  },
  {
    id: '2',
    value: 'Push templates',
  },
  {
    id: '3',
    value: 'Push Limits',
  },
];
