export const LAYOUTS = [
  {
    id: '1',
    value: 'Gallery view',
  },
];
export const STEPS = [
  {
    id: '1',
    value: 'Upload your template file',
  },
  {
    id: '2',
    value: 'Resolve conflicts',
  },
  {
    id: '3',
    value: 'Update template details',
  },
  {
    id: '4',
    value: 'Review changes and import',
  },
];
