import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import ProjectDetails from 'components/AboutProjectComponents/ProjectDetails/ProjectDetails';
import './AboutProjectPage.scss';

export default function AboutProjectPage() {
  const projectFromState = useSelector((state: RootState) => state.project);

  useEffect(() => {
    document.title = "About Project | Developer Portal @ Flybits"
  }, []);

  return (
    <div role="main" className="about-project-page">
      <PageHeader title="About Project" isLarge={true} />
      <div className="about-project-page__content">
        <ProjectDetails projectFromState={projectFromState} />
      </div>
    </div>
  );
}
