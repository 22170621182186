import React, { useCallback, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { FcmV1Settings } from 'model/pushSettings/settings';
import * as FcmV1Actions from 'actions/pushSettings/fcmV1Settings';
import { useActions } from 'actions';
import { ReactComponent as FCMLogo } from 'assets/images/firebase.svg';
import { FlightButton, FlightModal } from '@flybits/design-system';
import { useDropzone } from 'react-dropzone';
import FCMV1SettingsModal from './FcmV1SettingsModal';

interface Props {
  fcmV1SettingsFromState?: FcmV1Settings;
}

export default function FcmV1SettingCard(props: Props) {
  const { fcmV1SettingsFromState } = props;
  const pushActions = useActions(FcmV1Actions);

  const [showModal, setShowModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const confirmToggle = () => setOpenConfirm(!openConfirm);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uploadedFiles = new FormData();
      acceptedFiles.forEach((file: File) => {
        uploadedFiles.append('file', file);
      }, pushActions.uploadFCMServiceAccount(uploadedFiles));
      toggleModal();
    },
    [pushActions, toggleModal],
  );

  const { getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: '.json',
  });

  function deleteFcmSettings() {
    pushActions.deleteFcmV1Settings();
    confirmToggle();
  }

  function handleUploadServiceAccount() {
    open()
  }

  const projectID = fcmV1SettingsFromState?.projectID || '';

  return (
    <div className="push-settings">
      <FCMV1SettingsModal
        showModal={showModal}
        closeModal={toggleModal}
        handleUploadServiceAccount={handleUploadServiceAccount}
      />
      <div>
        <FlightModal
          isVisible={openConfirm}
          size="medium"
          toggleModalShown={confirmToggle}
          className="push-settings__modal"
          header={<span>Confirm</span>}
          content={<p>Are you sure you want to delete your FCM service account?</p>}
          footer={
            <div className="modal-footer">
              <FlightButton theme="secondary" onClick={confirmToggle} label="Cancel" />
              <FlightButton type="submit" label="Continue" onClick={deleteFcmSettings} />
            </div>
          }
        />
      </div>
      <Container>
        <Row>
          <FCMLogo />
          <span id="fcm-container-heading" className="push-settings__header">
            <h2>FCM Service Account</h2>
          </span>
        </Row>
        <Row>
          {!projectID ? (
            <span className="push-settings__body">You haven&apos;t specified your FCM server account.</span>
          ) : (
            <div className="push-settings__info">
              <span className="property">Project ID</span>
              <div className="value key">
                {projectID}
              </div>
            </div>
          )}
        </Row>
        <Row>
          <FlightButton
            label={projectID === '' ? 'Add FCM service account' : 'Change'}
            size="medium"
            theme={projectID === '' ? 'secondary' : 'link'}
            iconRight={projectID === '' ? 'baselineArrowRight' : ''}
            className="push-settings__btn--blue"
            onClick={toggleModal}
            isAriaDescribedBy
            ariaDescribedBy={'fcm-container-heading'}
            ariaLabel={projectID === '' ? 'Add FCM service account' : 'Change'}
          />
          <FlightButton
            label={projectID === '' ? '' : 'Remove'}
            size="medium"
            theme="link"
            className="push-settings__btn--red"
            onClick={confirmToggle}
            isAriaDescribedBy
            ariaDescribedBy={'fcm-container-heading'}
            ariaLabel="Remove"
          />
        </Row>
      </Container>
      <input {...getInputProps()} />
    </div>
  );
}
