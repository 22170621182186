import './ContextCategoryModal.scss';

import * as ContextPluginActions from 'actions/contextPlugins';
import * as Yup from 'yup';

import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { ContextPlugin, CreateContextPlugin } from 'model/contextPlugins';
import { FlightButton, FlightModal, FlightSelect, FlightTextArea, FlightTextInput } from '@flybits/design-system';
import React, { useEffect, useState } from 'react';
import { beginsWithNumberOrLetter, containsNoSpecialCharacters } from 'helpers/stringValidators';

import { CONTEXT_ATTRIBUTES_SCOPES } from 'constants/contextAttributesScopes';
import { FlightSelectOptions } from 'model/misc/flightSelectOptions';
import { Formik } from 'formik';
import { ReactComponent as LockIcon } from 'assets/images/lock-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/images/edit-pencil.svg';
import ValidationMessages from 'components/Shared/ValidationMessages/ValidationMessages';
import { isEmpty } from 'lodash';
import { useActions } from 'actions';

interface Props {
  isVisible: boolean;
  onCancel: () => void;
  categories?: string[];
  contextPlugin?: ContextPlugin;
}

const scopeOptions: FlightSelectOptions[] = [
  {
    key: CONTEXT_ATTRIBUTES_SCOPES.DEVICE.key,
    name: CONTEXT_ATTRIBUTES_SCOPES.DEVICE.name,
  },
  {
    key: CONTEXT_ATTRIBUTES_SCOPES.USER.key,
    name: CONTEXT_ATTRIBUTES_SCOPES.USER.name,
  },
];

const validateImageRegex = /^https:\/\/.*\.(jpg|jpeg|png|gif|bmp|webp|svg)$/;
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  iconUrl: Yup.string().matches(validateImageRegex, 'Icons must be a valid image and hosted securely (https)'),
});

export default function ContextCategoryModal(props: Props) {
  const { isVisible, onCancel, contextPlugin, categories } = props;
  const contextPluginActions = useActions(ContextPluginActions);
  const [scopeOption, setScopeOption] = useState({
    key: CONTEXT_ATTRIBUTES_SCOPES.DEVICE.key,
    name: CONTEXT_ATTRIBUTES_SCOPES.DEVICE.name,
  });
  const isCreate = isEmpty(contextPlugin);
  const isReserved = contextPlugin?.isReserved;
  const isDatasourcePlugin = !!contextPlugin?.dataSource;

  const initialValues = {
    name: contextPlugin?.name || '',
    description: contextPlugin?.description || '',
    iconUrl:
      contextPlugin?.iconUrl ||
      'https://file-manager.development.flybits.com/file-manager-v3-dev/F9D27E99-FC24-44BC-B8ED-BF3AA6466903.png',
    category: contextPlugin?.category || '',
  };

  const isUniqueCategoryName = (suggestedCategory: string) => {
    return !isEmpty(categories?.filter(el => el === suggestedCategory));
  };

  const [showEdit, setShowEdit] = useState(false);
  const [suggestedCategory, setSuggestedCategory] = useState(contextPlugin?.category || '');
  const contextIdValidations = [
    { message: 'Must begin with a letter or a number.', isError: !beginsWithNumberOrLetter(suggestedCategory) },
    { message: 'Must be unique.', isError: isUniqueCategoryName(suggestedCategory) },
    {
      message: 'Cannot contain special characters or spaces.',
      isError: !containsNoSpecialCharacters(suggestedCategory),
    },
  ];

  const isContextIdValid = () => {
    if (!contextPlugin) {
      for (const validationObject of contextIdValidations) {
        if (validationObject.isError) {
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    setSuggestedCategory(contextPlugin?.category || '');
    const _scope = {
      key:
        (contextPlugin &&
          (contextPlugin?.userScope ? CONTEXT_ATTRIBUTES_SCOPES.USER.key : CONTEXT_ATTRIBUTES_SCOPES.DEVICE.key)) ||
        CONTEXT_ATTRIBUTES_SCOPES.USER.key,
      name:
        (contextPlugin &&
          (contextPlugin?.userScope ? CONTEXT_ATTRIBUTES_SCOPES.USER.name : CONTEXT_ATTRIBUTES_SCOPES.DEVICE.name)) ||
        CONTEXT_ATTRIBUTES_SCOPES.USER.name,
    };
    setScopeOption(_scope);
  }, [contextPlugin, isVisible]);
  return (
    <div className="context-category">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const _createContextCategory: CreateContextPlugin = {
            isEnabled: true,
            id: contextPlugin?.id,
            uid: contextPlugin?.uid,
            name: values.name,
            category: suggestedCategory,
            iconUrl: values.iconUrl,
            description: values.description,
            refreshRate: 60,
            userScope: scopeOption.key === CONTEXT_ATTRIBUTES_SCOPES.USER.key ? true : false,
            supportedPlatforms: [],
            values: contextPlugin?.values || {},
          };
          if (contextPlugin) {
            contextPluginActions.updateContextPlugin(_createContextCategory);
          } else {
            contextPluginActions.createContextPlugin(_createContextCategory);
          }
          resetForm();
          onCancel();
        }}
      >
        {({ values, errors, touched, isValid, handleChange, handleBlur, handleSubmit, handleReset }) => (
          <div>
            <FlightModal
              isVisible={isVisible}
              toggleModalShown={onCancel}
              size="large"
              className="context-category__modal"
              header={
                contextPlugin
                  ? isReserved || isDatasourcePlugin
                    ? 'View category'
                    : 'Edit category'
                  : 'Create context category'
              }
              content={
                <Container className="context-category__modal__content">
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Row xs="2">
                        <Col>
                          <Label className="context-category__modal__content__label">Category name*</Label>
                          <FlightTextInput
                            name="name"
                            width="360px"
                            className="context-category__modal__content__name"
                            value={values.name}
                            onBlur={handleBlur}
                            disabled={isReserved || isDatasourcePlugin}
                            errorMessage={errors.name}
                            hasError={touched.name && errors.name ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                              //only suggest categoryid on create
                              if (!contextPlugin) {
                                setSuggestedCategory(
                                  e.currentTarget.value
                                    .trim()
                                    .replace(/[^\w]/g, '-')
                                    .toLowerCase(),
                                );
                              }
                            }}
                          />
                          <Label style={{ marginTop: 25 }} className="context-category__modal__content__label">
                            Description
                          </Label>
                          <FlightTextArea
                            name="description"
                            className="context-category__modal__content__text-area"
                            label={''}
                            disabled={isReserved || isDatasourcePlugin}
                            value={values.description}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <Label className="context-category__modal__content__label">Icon URL</Label>
                        </Col>
                        <Col>
                          <Label className="context-category__modal__content__label">Context ID</Label>
                          {!showEdit ? (
                            <div className="context-category__modal__content__ctx-id">
                              {isCreate ? (
                                <PencilIcon
                                  className="context-category__modal__content__ctx-id__icon"
                                  onClick={() => setShowEdit(true)}
                                />
                              ) : (
                                <LockIcon
                                  className="context-category__modal__content__ctx-id__icon
                                  context-category__modal__content__ctx-id__icon--disabled"
                                />
                              )}
                              <span className="context-category__modal__content__ctx-id__text">
                                {!isEmpty(suggestedCategory) ? suggestedCategory : 'Generated ctx id will appear here'}
                              </span>
                            </div>
                          ) : (
                            <FlightTextInput
                              name="Context ID"
                              width="360px"
                              hasError={false}
                              value={suggestedCategory}
                              onBlur={handleBlur}
                              errorMessage={<span>{}</span>}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setSuggestedCategory(e.currentTarget.value)
                              }
                            />
                          )}
                          {isCreate && <ValidationMessages validationObjects={contextIdValidations} />}
                        </Col>{' '}
                      </Row>
                      <Row xs="2">
                        <Col>
                          <Row>
                            <FlightTextInput
                              name="iconUrl"
                              width="314px"
                              disabled={isReserved || isDatasourcePlugin}
                              errorMessage={<span>{errors.iconUrl}</span>}
                              className="context-category__modal__content__icon-url"
                              value={values.iconUrl}
                              hasError={touched.iconUrl && errors.iconUrl ? true : false}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <div className="context-category__modal__content__icon-url__icon-preview">
                              <img
                                src={values.iconUrl}
                                className="context-category__modal__content__icon-url__icon-preview__img"
                                alt="ctx.cat.attribute"
                              />
                            </div>
                          </Row>
                        </Col>
                        <Col>
                          <Col style={{ marginTop: '-30px' }}>
                            <Label className="context-attribute-sidepanel__content__label">Scope </Label>
                            <FlightSelect
                              width="355px"
                              selected={scopeOption}
                              disabled={isReserved || isDatasourcePlugin || !isCreate}
                              className="context-attribute-sidepanel__content__name"
                              handleOptionClick={(opt: FlightSelectOptions) => setScopeOption(opt)}
                              options={scopeOptions}
                              dropdownDirection="top"
                            />
                          </Col>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </Container>
              }
              footer={
                <div className="modal-footer">
                  <FlightButton
                    theme="secondary"
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                      onCancel();
                      handleReset(e);
                    }}
                    label="Cancel"
                  />
                  <FlightButton
                    disabled={isReserved || isDatasourcePlugin || !isValid || !isContextIdValid()}
                    type="submit"
                    label={contextPlugin ? 'Save' : 'Create'}
                    onClick={handleSubmit}
                  />
                </div>
              }
            />
          </div>
        )}
      </Formik>
    </div>
  );
}
