import React, { useState, useEffect } from 'react';
import { FlightModal, FlightButton, FlightTextInput, FlightToggleSwitch } from '@flybits/design-system';
import { ReactComponent as OpenIdLogo } from 'assets/images/idplogos/openid.svg';
import './IdpModal.scss';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import { OpenIDSettings, TenantAccessSettings, AllowedTenants } from 'model/accountAccess/settings';
import * as AccountAccessActions from 'actions/accountAccess';
import { useActions } from 'actions';
import * as Yup from 'yup';
import { ISSUER_REQUIRED } from 'constants/errors/errors';

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  openIDSettingsFromState?: OpenIDSettings;
  tenantAccessSettingsFromState?: TenantAccessSettings;
}

const validationSchema = Yup.object().shape({
  issuer: Yup.string().required(ISSUER_REQUIRED),
});

export default function OpenIdModal(props: Props) {
  const { openModal, toggleModal, openIDSettingsFromState, tenantAccessSettingsFromState } = props;
  const [checked, setChecked] = useState(false);
  const accountAccessActions = useActions(AccountAccessActions);
  const [checkedStateApi, setCheckedStateApi] = useState(false);

  const issuer = openIDSettingsFromState?.issuer || '';
  const audience = openIDSettingsFromState?.audience || '';
  const validation = openIDSettingsFromState?.validation || { jwksURI: '' };

  const allowed: AllowedTenants[] = tenantAccessSettingsFromState?.allowed || [{ type: 0, value: '' }];

  let allowedOidcSettings: AllowedTenants[] = [{ type: 0, value: '' }];
  if (allowed.length !== 0) {
    allowedOidcSettings = allowed.filter(item => item.value === 'oidc');
  }

  useEffect(() => {
    if (allowedOidcSettings.length !== 0 && allowedOidcSettings[0].value !== '') {
      setCheckedStateApi(true);
    }
    return () => {
      setCheckedStateApi(false);
    };
    // eslint-disable-next-line
  }, [allowedOidcSettings]);

  useEffect(() => {
    setChecked(checkedStateApi);
    return () => {
      setChecked(checkedStateApi);
    };
    // eslint-disable-next-line
  }, [checkedStateApi]);

  return (
    <div className="idp-modal">
      <Formik
        initialValues={{
          issuer: issuer,
          audience: audience,
          validation: validation,
        }}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        validateOnMount={true}
        onSubmit={(values, { resetForm }) => {
          accountAccessActions.createOrUpdateOpenIDSettings(values);
          const allowedTenants: AllowedTenants[] = allowed;
          allowedTenants.push({
            type: 1,
            value: 'oidc',
          });
          if (checked) {
            const enableAPKeySettings = {
              allowed: allowedTenants,
            };
            accountAccessActions.createOrUpdateTenantAccessSettings(enableAPKeySettings);
          } else {
            const disableApiKeySettings = {
              allowed: allowed.filter(item => item.value !== 'oidc'),
            };
            accountAccessActions.createOrUpdateTenantAccessSettings(disableApiKeySettings);
          }
          resetForm();
          toggleModal();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, isValid }) => (
          <FlightModal
            isVisible={openModal}
            toggleModalShown={() => {
              setChecked(checkedStateApi);
              handleReset();
              toggleModal();
            }}
            scrollable={false}
            size="small"
            content={
              <Col className="idp-modal__content">
                <Row className="idp-modal__content__content-header">
                  <OpenIdLogo className="idp-modal__content__content-header__header-logo" />
                  <span className="idp-modal__content__content-header__header-text">Open ID Connect</span>
                </Row>
                <Col className="idp-modal__content__toggle">
                  <Row>
                    <FlightToggleSwitch checked={checked} onChange={setChecked} label={'Enable '} disabled={!isValid} />
                  </Row>
                </Col>
                <Col className="idp-modal__content__text-field-container">
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="issuer"
                      label="Issuer"
                      width="345px"
                      hasError={touched.issuer && errors.issuer ? true : false}
                      value={values.issuer}
                      onBlur={handleBlur}
                      errorMessage={<span>{errors.issuer}</span>}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="audience"
                      label="Audience"
                      width="345px"
                      value={values.audience}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                  <Row className="idp-modal__content__text-field-container__text-field">
                    <FlightTextInput
                      type="text"
                      name="validation.jwksURI"
                      label="JSON Web Key Set (JSWKS) URL"
                      width="345px"
                      value={values.validation.jwksURI}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />{' '}
                  </Row>
                </Col>
              </Col>
            }
            footer={
              <div className="modal-footer">
                <FlightButton
                  onClick={() => {
                    setChecked(checkedStateApi);
                    handleReset();
                    toggleModal();
                  }}
                  label="Cancel"
                  theme="secondary"
                />
                <FlightButton type="primary" label="Apply Changes" onClick={handleSubmit} disabled={!isValid} />{' '}
              </div>
            }
          />
        )}
      </Formik>
    </div>
  );
}
