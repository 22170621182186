import { PushSettingsActionTypes } from 'model/pushSettings';
import { FcmSettings, FcmSettingsPayload } from 'model/pushSettings/settings';
import { PushService } from 'services/push.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator } from 'helpers/actionCreator';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';
import { showNotification } from '../snackBarNotifications';

export const setFcmSettings = makeActionCreator(PushSettingsActionTypes.SET_FCM_SETTINGS);

export function fetchFcmSettings() {
  const transformedResponse: FcmSettings = { key: '' };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.getFcmSettings();
      transformedResponse.key = response.data?.last4 || response.data?.Key;
      dispatch(setFcmSettings(transformedResponse));
    } catch (error) {
      // @ts-expect-error: TODO: fix the typescript error properly
      const errorObjectFromResponse = error?.response?.data.error.exceptionType;
      if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(addDefaultFcmSetting());
      }
    }
    return 'done';
  };
}

export function addOrUpdateFcmServerKey(fcmSettings: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      const objectForApi: FcmSettingsPayload = {
        Key: fcmSettings,
      };
      const response = await req.createFcmSettings(objectForApi);
      if (response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchFcmSettings());
      }
    } catch (error) {
      // TODO: Handle error
    }
    return 'done';
  };
}

export function deleteFcmSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const deleteSettings: FcmSettingsPayload = { Key: '' };
      const req = new PushService();
      const response = await req.deleteFcmSetings(deleteSettings);
      if (response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Deleted',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchFcmSettings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not delete project',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function addDefaultFcmSetting() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const defaultFcmSettings: FcmSettingsPayload = { Key: '' };
      const req = new PushService();
      const response = await req.createFcmSettings(defaultFcmSettings);
      if (response.status === 200) {
        dispatch(fetchFcmSettings());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not set defaults',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}
