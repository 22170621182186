import React from 'react';
import { Router } from 'react-router-dom';
import { history } from './configureStore';
import { Routes } from './Router/Routes';

function App() {
  return (
    <div>
      <Router history={history}>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
