import { ServiceAccountAction, ServiceAccountActionTypes, ServiceAccountState } from 'model/serviceAccount';
import createReducer from './createReducer';

const initialState: ServiceAccountState = [];

export const serviceAccounts = createReducer<ServiceAccountState>(initialState, {
  [ServiceAccountActionTypes.SET_SERVICE_ACCOUNTS_STATE](state: ServiceAccountState, action: ServiceAccountAction) {
    return [...action.payload];
  },
});
