import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, TabContent, TabPane, NavLink, NavItem, Nav } from 'reactstrap';
import './ImportTemplateModal.scss';
import { FlightModal, FlightButton } from '@flybits/design-system';

import { RootState } from 'reducers';

import { STEPS } from 'constants/manageTemplatesLayout';
import UploadTemplate from 'components/ImportTemplateComponents/UploadTemplate/UploadTemplate';

// State/Redux
import { useActions } from 'actions';
import * as ManageTemplatesActions from 'actions/manageTemplates';
import { TemplateExportedContent, TemplatePayload, TemplatesEntityImport } from 'model/manageTemplates';
import { useSelector } from 'react-redux';
import ConflictsBaseJourney from 'components/ImportTemplateComponents/Conflicts/ConflictsBaseJourney';
import ConflictsBaseComponents from 'components/ImportTemplateComponents/Conflicts/ConflictsBaseComponents';
import { TemplateReviewTable } from 'components/TemplateReviewTable/TemplateReviewTable';
import UpdateTemplateDetails from 'components/ImportTemplateComponents/UpdateTemplateDetails/UpdateTemplateDetails';
import ProcessTemplates from 'components/ImportTemplateComponents/ProcessTemplates/ProcessTemplates';

interface Props {
  toggleModal: () => void;
  onImportSuccess: () => void;
  onImportError: () => void;
}
interface Layout {
  id: string;
  value: string;
}

export function ImportTemplateModal(props: Props) {
  const { onImportError, onImportSuccess, toggleModal } = props;
  const [activeStep, setActiveStep] = useState('1');
  const [disableContinue, setDisableContinue] = useState(true);
  const [tryToDiscardChanges, setTryToDiscardChanges] = useState(false);
  const manageTemplatesActions = useActions(ManageTemplatesActions, []);
  const [templatesReview, setTemplatesReview] = useState<TemplatePayload[]>([]);

  // State
  const {
    importedFile,
    importTemplatesPayload,
    importConflicts,
    importLibraryCategories,
    importLibraryTemplates,
    importStatus,
  } = useSelector((state: RootState) => state?.manageTemplates);

  const resetImportData = () => {
    manageTemplatesActions.resetImportInfo();
  };
  const isValidJourney = () => !importTemplatesPayload?.templates?.some(t => !t.mergeStrategy);

  const isValidComponents = () => {
    let isValid = true;
    Object.entries(importTemplatesPayload?.components)?.map(([type, componentData]) => {
      const allowedCompnents = [
        'ruleTemplates',
        'pushTemplates',
        'contentTemplates',
        'contentTemplatePrototypes',
        'contextPlugins',
      ];
      if (componentData?.length && allowedCompnents.includes(type)) {
        if (componentData.some((comp: any) => !comp.mergeStrategy)) {
          isValid = false;
          return;
        }
      }
      return;
    });
    return isValid;
  };
  useEffect(() => {
    setDisableContinue(false);
    if (importConflicts?.templateConflicts?.length > 0 && activeStep === '2') {
      setDisableContinue(!isValidJourney());
    }
    if (importConflicts?.componentConflicts?.length > 0 && activeStep === '3') {
      setDisableContinue(!isValidComponents());
    }
    if (activeStep === '1') {
      // Has valid uploaded file
      if (importedFile) {
        if (importConflicts?.templateConflicts?.length > 0) {
          // Import has journey conflicts after dryrun => go to step 2
          setActiveStep('2');
          setDisableContinue(true);
          return;
        }
        if (importConflicts?.templateConflicts?.length === 0 && importConflicts?.componentConflicts?.length > 0) {
          // Import has no journey conflicts
          // and has component conflicts after dryrun => go to step 3
          setActiveStep('3');
          setDisableContinue(true);
          return;
        }
        // Update template details after upload if no conflicts found
        setDisableContinue(false);
        setActiveStep('4');
        return;
      }
    } else if (activeStep === '5') {
      setDisableContinue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, importConflicts]);

  useEffect(() => {
    setDisableContinue(false);
    if (importConflicts?.templateConflicts?.length > 0 && activeStep === '2') {
      setDisableContinue(!isValidJourney());
    }
    if (importConflicts?.componentConflicts?.length > 0 && activeStep === '3') {
      setDisableContinue(!isValidComponents());
    }
    if (importTemplatesPayload?.templates?.length) {
      const _templatesReview: TemplatePayload[] = [];
      importTemplatesPayload?.templates?.map((template: TemplatesEntityImport) => {
        _templatesReview.push(template.payload);
      });

      setTemplatesReview(_templatesReview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTemplatesPayload]);

  const validateImportedTemplate = useCallback(async (templatesData: TemplateExportedContent, cb?: () => void) => {
    await manageTemplatesActions.checkForTemplateConflicts(templatesData, cb);
  }, []);

  const goToNextStep = () => {
    setDisableContinue(false);
    if (activeStep === '1') {
      setDisableContinue(true);
      // has journey template conflicts
      if (importConflicts?.templateConflicts?.length !== 0) {
        setActiveStep('2');
        return;
      }
      if (importConflicts?.templateConflicts?.length === 0 && importConflicts?.componentConflicts?.length !== 0) {
        setActiveStep('3');
        return;
      }
      setActiveStep('4');
      setDisableContinue(false);
      return;
    }
    if (activeStep === '2') {
      if (importConflicts?.componentConflicts?.length !== 0) {
        setActiveStep('3');
        setDisableContinue(true);
        return;
      }
      setActiveStep('4');
      setDisableContinue(false);
      return;
    }
    if (activeStep === '3') {
      setActiveStep('4');
      setDisableContinue(false);
      return;
    }
    if (+activeStep >= 4) {
      setDisableContinue(false);
      setActiveStep(`${+activeStep + 1}`);
    }
  };

  const handleCancelEdits = () => {
    resetImportData();
    setActiveStep('1');
    setDisableContinue(false);
    setTryToDiscardChanges(false);
  };
  const goToPreviousStep = () => {
    if (+activeStep - 1 === 1 || (+activeStep - 1 === 2 && importConflicts?.templateConflicts?.length === 0)) {
      resetImportData();
      setActiveStep(`1`);
      return;
    } else if (+activeStep - 1 === 3 && importConflicts?.componentConflicts?.length === 0) {
      if (importConflicts?.templateConflicts?.length === 0) {
        // no journey conflicts, go to upload
        resetImportData();
        setActiveStep(`1`);
      } else {
        // still has journey conflicts
        setActiveStep('2');
      }
      return;
    } else {
      setActiveStep(`${+activeStep - 1}`);
    }
  };

  useEffect(() => {
    manageTemplatesActions.getLatestTemplateImportStatus();

    return () => manageTemplatesActions.resetImportInfo();
  }, [manageTemplatesActions]);

  useEffect(() => {
    if (importStatus && importStatus?.status === 'processing') {
      setActiveStep('6');
    }
  }, [importStatus, manageTemplatesActions]);

  useEffect(() => {
    if (activeStep === '1') {
      // Has valid uploaded file
      if (importedFile) {
        if (importConflicts?.templateConflicts?.length > 0) {
          // Import has conflicts after dryrun => go to step 2
          setActiveStep('2');
          return;
        }
        if (importConflicts?.componentConflicts?.length > 0) {
          setActiveStep('3');
          return;
        }
        // Update template details after upload if no conflicts found
        setActiveStep('4');
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, importConflicts]);

  useEffect(() => {
    (document.getElementsByClassName('nav-link')?.[0] as HTMLElement)?.focus();
  }, []);

  return (
    <div className="import-page__modal">
      {/* {Begin Import Modal} */}
      <FlightModal
        isVisible={true}
        toggleModalShown={() => {
          toggleModal();
        }}
        // scrollable={false}
        size="large"
        header={<span>Import templates</span>}
        content={
          <div className="import-page__modal__content">
            <div>
              <Col>
                <Row>
                  <Nav tabs>
                    {STEPS.map((tab: Layout) => (
                      <NavItem
                        key={tab.id}
                        className={`${+activeStep > +tab.id ? 'done' : ''}${activeStep === tab.id ? 'active' : ''}${
                          ['2', '3'].includes(tab.id) && ['2', '3'].includes(activeStep) ? ' conflicts' : ''
                        }`}
                      >
                        <div className="tab-shadow">
                          <NavLink
                            role="tab"
                            tabIndex={0}
                            aria-selected={activeStep === tab.id}
                            className={`${+activeStep > +tab.id ? 'done' : ''} ${
                              ['2', '3'].includes(tab.id) && ['2', '3'].includes(activeStep) ? ' conflicts' : ''
                            }`}
                            aria-label={tab.value}
                            disabled={true}
                            active={activeStep === tab.id}
                            onClick={() => setActiveStep(tab.id)}
                          >
                            <span className="import-page__modal__content__tabstrip__container__tabs__label">
                              {tab.value}
                            </span>
                          </NavLink>
                        </div>
                      </NavItem>
                    ))}
                  </Nav>
                </Row>
                <Row className="import-page__modal__row">
                  <TabContent
                    className="import-page__modal__content__tabstrip__container__content"
                    activeTab={activeStep}
                  >
                    {activeStep === '1' && (
                      <TabPane tabId="1">
                        <div className="import-page__modal__content__tabstrip__container__content__upload-file">
                          {/* upload template file drag & drop */}
                          <UploadTemplate handleCheckForConflicts={validateImportedTemplate} />
                        </div>
                      </TabPane>
                    )}
                    {activeStep === '2' && (
                      <TabPane tabId="2">
                        <div className="import-page__modal__content__tabstrip__container__content__conflicts">
                          {/* resolve journey conflicts */}
                          <ConflictsBaseJourney
                            conflicts={importConflicts}
                            categories={importLibraryCategories}
                            templateLibrary={importLibraryTemplates}
                            importedTemplates={importedFile}
                            templatesPayload={importTemplatesPayload}
                          />
                        </div>
                      </TabPane>
                    )}
                    {activeStep === '3' && (
                      <TabPane tabId="3">
                        <div className="import-page__modal__content__tabstrip__container__content__conflicts">
                          {/* resolve components conflicts */}
                          <ConflictsBaseComponents
                            conflicts={importConflicts}
                            categories={importLibraryCategories}
                            templateLibrary={importLibraryTemplates}
                            importedTemplates={importedFile}
                            templatesPayload={importTemplatesPayload}
                          />
                        </div>
                      </TabPane>
                    )}
                    {activeStep === '4' && (
                      <TabPane tabId="4">
                        <div className="import-page__modal__content__tabstrip__container__content__update-details">
                          {/* update template details */}
                          <UpdateTemplateDetails />
                        </div>
                      </TabPane>
                    )}
                    {activeStep === '5' && (
                      <TabPane tabId="5">
                        <div className="import-page__modal__content__tabstrip__container__content__content-review">
                          {/* review changes and import */}
                          {/* <TemplateReviewTable templates={importTemplatesPayload?.templates || []} /> */}
                          <TemplateReviewTable templates={templatesReview} />
                        </div>
                      </TabPane>
                    )}
                    {activeStep === '6' && (
                      <TabPane tabId="6">
                        <div className="import-page__modal__content__tabstrip__container__content__process-template">
                          {/* process templates */}
                          <ProcessTemplates
                            importStatus={importStatus}
                            importTemplatesPayload={importTemplatesPayload}
                            onSuccess={onImportSuccess}
                            onError={onImportError}
                          />
                        </div>
                      </TabPane>
                    )}
                  </TabContent>
                </Row>
              </Col>
            </div>
          </div>
        }
        footer={
          <div className="modal-footer">
            {+activeStep === 5 && (
              <span className="modal-footer__template-amount">
                Total templates: {importTemplatesPayload?.templates?.length}
              </span>
            )}
            {+activeStep > 1 && +activeStep < 6 && (
              <>
                <FlightButton onClick={goToNextStep} label="Continue" theme="primary" disabled={disableContinue} />
                <FlightButton onClick={goToPreviousStep} label="Back" theme="secondary" />
              </>
            )}
            {+activeStep === 6 && (
              <FlightButton
                onClick={() => {
                  resetImportData();
                  toggleModal();
                }}
                label="Close"
                theme="secondary"
              />
            )}
            {+activeStep === 1 && (
              <FlightButton
                onClick={() => {
                  resetImportData();
                  toggleModal();
                }}
                label="Cancel"
                theme="secondary"
              />
            )}
          </div>
        }
      />
      <FlightModal
        isVisible={tryToDiscardChanges}
        toggleModalShown={() => setTryToDiscardChanges(false)}
        scrollable={false}
        size="small"
        className={'import-page__modal-alert'}
        warning={true}
        header={<span>Are you sure you want to discard all unsaved changes?</span>}
        content={
          <div>
            <FlightButton
              type="button"
              theme="secondary"
              label={'Cancel'}
              onClick={() => {
                setTryToDiscardChanges(false);
              }}
            />
            <FlightButton
              type="button"
              theme="primary"
              className="delete-full"
              label={'Discard changes'}
              onClick={handleCancelEdits}
            />
          </div>
        }
      />
    </div>
  );
}
