import React, { useEffect, useState } from 'react';
import { FlightOverflowMenu, FlightTable } from '@flybits/design-system';
import { ServiceAccount } from 'model/serviceAccount';
import './ServiceAccountsTable.scss';
import moment from 'moment';
import { TableHeaderProps } from 'model/misc/flightTable';

interface Props {
  serviceAccounts: ServiceAccount[];
  handleDeleteServiceAccount: (serviceAccount: ServiceAccount) => void;
}

interface TableData {
  key: string;
  name: JSX.Element;
  createdAt?: string;
}

export default function ServiceAccountsTable({ serviceAccounts, handleDeleteServiceAccount }: Props) {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const tableHeaders: TableHeaderProps[] = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Created at',
      key: 'createdAt',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'options',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  useEffect(() => {
    setTableData(
      serviceAccounts.map(serviceAccount => {
        const optionsGroup = [
          [
            {
              key: 1,
              name: 'Delete',
              onClick: () => handleDeleteServiceAccount(serviceAccount),
            },
          ],
        ];

        return {
          key: serviceAccount.id,
          name: (
            <div className="service-accounts-table__name">
              <p>
                <strong>ID:</strong> {serviceAccount.id}
              </p>
              <p>
                <strong>Name:</strong> {serviceAccount.name}
              </p>
              {!!serviceAccount.description && (
                <p>
                  <strong>Description:</strong> {serviceAccount.description}
                </p>
              )}
            </div>
          ),
          createdAt: moment(serviceAccount.createdAt).format('MMMM Do, Y'),
          options: (
            <FlightOverflowMenu
              className="service-accounts-table__options"
              optionGroups={optionsGroup}
              direction="bottom-left"
            />
          ),
        };
      }),
    );
  }, [handleDeleteServiceAccount, serviceAccounts]);

  if (tableData.length === 0) {
    tableData.push({
      key: '',
      name: <span>No service accounts found for this project.</span>,
    });
  }

  return (
    <div aria-live="assertive">
      <FlightTable
        className="service-accounts-table"
        tableHeaders={tableHeaders}
        tableData={tableData}
        hasPaginationBeforeTable={false}
        ariaLabel="service accounts Table"
    />
    </div>
  );
}
