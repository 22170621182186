import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { FlightButton, FlightModal, FlightTable, FlightSelect } from '@flybits/design-system';
import { Subscription } from 'model/apiUsage';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import './EmailUsageAlerts.scss';
import * as Yup from 'yup';
import * as ApiUsageActions from 'actions/apiUsage';
import { useActions } from 'actions';
import { orderBy, uniqBy } from 'lodash';
import { PERCENTAGE_REQUIRED, EMAIL_REQUIRED, EMAIL_VALIDATION } from 'constants/errors/errors';
import ArrayFields from 'components/Shared/ArrayFields/ArrayFields';

interface Props {
  subscriptionsFromState?: Subscription[];
}

interface SelectOptionProps {
  key: number;
  name: string;
}

/* Validations */
Yup.addMethod(Yup.array, 'unique', function(message, mapper = (a: any) => a) {
  return this.test('unique', message, function(list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const validationSchema = Yup.object().shape({
  values: Yup.array().of(
    Yup.string()
      .required(EMAIL_REQUIRED)
      .email(EMAIL_VALIDATION),
  ),
  percentage: Yup.string().required(PERCENTAGE_REQUIRED),
});

const options = [
  {
    key: 50,
    name: '50%',
  },
  {
    key: 80,
    name: '80%',
  },
  {
    key: 90,
    name: '90%',
  },
];

export default function EmailUsageAlerts(props: Props) {
  const { subscriptionsFromState = [] } = props;
  const apiUsageActions = useActions(ApiUsageActions);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [percentageOptions, setPercentageOptions] = useState<SelectOptionProps>({
    key: 50,
    name: '50%',
  });
  const triggerModal = () => {
    setPercentageOptions({ key: 50, name: '50%' });
    toggle();
  };

  const sortedSubscriptionState = orderBy(uniqBy(subscriptionsFromState, 'percentage'), 'percentage', 'asc');
  const tableHeaders = [
    {
      name: 'Percentage',
      key: 'percentage',
      isVisible: true,
    },
    {
      name: 'Email',
      key: 'email',
      isVisible: true,
    },
  ];

  const tableBody = sortedSubscriptionState?.map((item: Subscription) => {
    return {
      key: item.id,
      percentage: `${item.percentage}%`,
      email: item.emails.map((email: string) => {
        return <div key={`${email}--key`}>{email}</div>;
      }),
    };
  });

  const handleSelectOptionClick = (args: SelectOptionProps) => {
    setPercentageOptions(args);
  };

  const [valuesFromArrayFields, setValuesFromArrayFields] = useState(['']);
  const [initialValues, setInitialValues] = useState<string[]>([]);
  const getValuesFromArrayFields = (data: string) => {
    setValuesFromArrayFields([...valuesFromArrayFields.splice(0)]);
    setValuesFromArrayFields([data]);
  };

  const [hasError, setHasError] = useState(false);
  const getErrorState = (data: boolean) => {
    setHasError(data);
  };

  useEffect(() => {
    const existingSubscriptions = subscriptionsFromState.filter(
      (item: Subscription) => item?.percentage === percentageOptions.key,
    );
    setInitialValues(existingSubscriptions[0]?.emails);
  }, [percentageOptions, subscriptionsFromState]);

  const handleSubmit = () => {
    const existingSubscriptions = subscriptionsFromState.filter(
      (item: Subscription) => item?.percentage === percentageOptions.key,
    )?.[0];
    const emails = valuesFromArrayFields.includes('') ? existingSubscriptions.emails : valuesFromArrayFields.flat();
    const params: Subscription = {
      id: existingSubscriptions.id,
      percentage: existingSubscriptions.percentage,
      emails: emails,
    };
    apiUsageActions.updateSubscriptionAlert(params);
    toggle();
  };

  const getReceipientsCount = () => {
    if (valuesFromArrayFields?.length > initialValues?.length) {
      return valuesFromArrayFields?.length;
    }
    return initialValues?.length;
  };

  useEffect(() => {
    apiUsageActions.fetchAllSubscriptions();
    //eslint-disable-next-line
  }, [modal]);

  return (
    <div className="email-usage-alerts">
      <FlightModal
        isVisible={modal}
        size="medium"
        className="email-usage-alerts__modal"
        toggleModalShown={toggle}
        header={<span>Manage usage alert</span>}
        content={
          <>
            <div>Send email alerts to the listed recipients when API usage reaches..</div>
            <FlightSelect
              label="percentage"
              className="email-usage-alerts__percentage"
              handleOptionClick={handleSelectOptionClick}
              selected={percentageOptions}
              width="86px"
              options={options}
            />
            <div className="email-usage-alerts__receipients">Recepients ({getReceipientsCount()})</div>
            <ArrayFields
              className="email-usage-alerts__arr-fields"
              initialValues={{
                values: initialValues,
              }}
              callback={getValuesFromArrayFields}
              placeHolderText="user@example.com"
              validationSchema={validationSchema}
              hasError={getErrorState}
              width={'400px'}
            />
          </>
        }
        footer={
          <div className="modal-footer">
            <FlightButton theme="secondary" onClick={toggle} label="Cancel" />
            <FlightButton type="submit" onClick={handleSubmit} disabled={hasError} label="Save" />
          </div>
        }
      />
      <ReusableCard>
        <Col>
          <Row>
            <h2 className="email-usage-alerts__header">API usage email alerts</h2>
          </Row>
          <Row>
            <div className="email-usage-alerts__title">Get notified when approaching a set api call threshold</div>
          </Row>
        </Col>
        <FlightButton theme="secondary" className="email-usage-alerts__addbtn" onClick={triggerModal} label="Manage" />
        <FlightTable
          className="email-usage-alerts__table"
          tableHeaders={tableHeaders}
          tableData={tableBody}
          isShowHeader={false}
          hasPaginationBeforeTable={false}
          role="presentation"
        />
      </ReusableCard>
    </div>
  );
}
