import { trimStringToLowercase } from './stringUtils';

export function filterFromArrayOfObjects<T extends object, U extends keyof T>(
  originalArray: Array<T>,
  key: U,
  searchText: string,
): Array<T> {
  return originalArray.filter((value: T) =>
    trimStringToLowercase((value[key] as unknown) as string).includes(trimStringToLowercase(searchText)),
  );
}
