import { ContextRuleActions, ContextRuleActionTypes, ContextRuleState } from 'model/contextRule';
import createReducer from './createReducer';

const initialState = {} as ContextRuleState;

export const contextRules = createReducer<ContextRuleState>(initialState, {
  [ContextRuleActionTypes.SET_CONTEXT_RULES_STATE](state: ContextRuleState, action: ContextRuleActions) {
    return { ...state, contextRules: action.payload };
  },
});
