import { PushSettingsActionTypes } from 'model/pushSettings';
import { PushService } from 'services/push.service';
import { makeActionCreator, makeFetchActionCreator, makeAPIActionsCreator } from 'helpers/actionCreator';

export const setNotificationTypes = makeActionCreator(PushSettingsActionTypes.SET_NOTIFICATION_TYPES_STATE);
export const setNotificationType = makeActionCreator(PushSettingsActionTypes.SET_NOTIFICATION_TYPE_STATE);

export const fetchNotificationTypes = makeFetchActionCreator(
  new PushService(),
  'getPushPayloadTypes',
  setNotificationTypes,
  'data',
);

/**
 * createNotificationType(params)
 * @param {CreateNotificationType} params
 */
export const createNotificationType = makeAPIActionsCreator(
  new PushService(),
  'createPushPayloadTypes',
  fetchNotificationTypes,
  200,
  {
    successMessage: 'Push template created.',
    errorMessage: 'Error creating notification type.',
  },
);

/**
 * updatePushNotificationType(params)
 * @param {NotificationType} params
 */
export const updatePushNotificationType = makeAPIActionsCreator(
  new PushService(),
  'updatePushPayLoadTypes',
  fetchNotificationTypes,
  200,
  {
    successMessage: 'Status updated.',
    errorMessage: 'Error updating status.',
  },
);

/**
 * @param {string} notificationTypeId
 */
export const deleteNotificationType = makeAPIActionsCreator(
  new PushService(),
  'deletePushPayLoadTypes',
  fetchNotificationTypes,
  204,
  {
    successMessage: 'Notification type deleted.',
    errorMessage: 'Failed to delete type.',
  },
);
