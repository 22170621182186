import React from 'react';
import moment from 'moment';
import { FlightTable, FlightOverflowMenu, FlightTooltip } from '@flybits/design-system';
import { Webhook } from 'model/webhook';
import './WebhooksTable.scss';

interface Props {
  localWebhooksFromState: Webhook[];
  handleHeaderSort: (isDesc: boolean) => void;
  handleViewWebhook: (webhook: Webhook) => void;
  handleDeleteWebhook: (webhook: Webhook) => void;
}

interface TableData {
  key: string;
  name: JSX.Element;
  lastModified?: JSX.Element;
  options?: JSX.Element;
}

export default function WebhooksTable(props: Props) {
  const { localWebhooksFromState, handleHeaderSort, handleViewWebhook, handleDeleteWebhook } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleHeaderSortClick(header: any, isDesc: boolean) {
    // Based on the current webhooks design, this sort handles just sorting by 'name'.
    handleHeaderSort(isDesc);
  }

  const tableHeaders = [
    {
      name: 'Name',
      key: 'name',
      isSortable: true,
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Last modified',
      key: 'lastModified',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'options',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  const getOptionGroups = (webhook: Webhook) => {
    return [
      [
        {
          key: 1,
          name: 'View details',
          onClick: () => handleViewWebhook(webhook),
        },
        {
          key: 2,
          name: 'Delete',
          onClick: () => handleDeleteWebhook(webhook),
        },
      ],
    ];
  };

  const tableData: TableData[] =
    localWebhooksFromState?.map((webhook: Webhook) => {
      const formattedDate = moment.unix(webhook.lastUpdatedAt).format('MMMM Do, Y');
      const optionGroups = getOptionGroups(webhook);
      return {
        key: webhook.id,
        name: (
          <FlightTooltip
            className="webhooks-table__tooltip"
            direction="right"
            isEnabled={!!webhook.description}
            description={webhook.description || ''}
            delay={0}
            tabIndex={-1}
          >
            <span className="webhooks-table__name"
                  onClick={() => handleViewWebhook(webhook)}
                  tabIndex={0}
                  onKeyDown={(event) => {
                   if(event.key === "Enter"){
                     handleViewWebhook(webhook);
                   }
                  }}
                  >
              {webhook.name}1
            </span>
          </FlightTooltip>
        ),
        lastModified: <span className="webhooks-table__last-modified">{formattedDate}</span>,
        options: (
          <FlightOverflowMenu className="webhooks-table__options" optionGroups={optionGroups} direction="bottom-left" />
        ),
      };
    }) || [];

  if (tableData.length === 0) {
    tableData.push({
      key: '',
      name: <span>No results found</span>,
    });
  }

  return (
    <div aria-live="assertive">
      <FlightTable
        className="webhooks-table"
        tableHeaders={tableHeaders}
        tableData={tableData}
        hasPaginationBeforeTable={false}
        handleHeaderSort={handleHeaderSortClick}
        ariaLabel="webhooks Table"
      />
    </div>
  );
}
