import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router';
import { FlightButton, FlightModal } from '@flybits/design-system';
import { Location } from 'history';
import './FloatingSaveBar.scss';

interface Props {
  isVisible: boolean;
  numUnsavedChanges: number;
  primaryAction: { label: string; handleAction: () => void; isDisabled?: boolean; isLoading?: boolean };
  secondaryAction: { label: string; handleAction: () => void };
  showConfirmNavigationModal?: boolean;
  modalContent?: JSX.Element | string;
  modalClassname?: string;
  modalSize?: string;
}

export default function FloatingSaveBar(props: Props) {
  const {
    isVisible,
    numUnsavedChanges,
    primaryAction: {
      label: primaryActionLabel,
      handleAction: primaryActionHandler,
      isDisabled: primaryActionDisabled,
      isLoading: primaryActionLoading,
    },
    secondaryAction: { label: secondaryActionLabel, handleAction: secondaryActionHandler },
    showConfirmNavigationModal,
    modalContent,
    modalClassname,
    modalSize,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation<Location>();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState<Location>(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: Location) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: Location<any>) => {
    if (!confirmedNavigation && showConfirmNavigationModal) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      history.push(lastLocation.pathname);

      setShouldUnload(true);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const unload = (event: BeforeUnloadEvent) => {
      if (showConfirmNavigationModal && !shouldUnload) {
        event.returnValue = modalContent;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isVisible, showConfirmNavigationModal, modalContent, shouldUnload]);

  return isVisible ? (
    <>
      <Prompt when message={handleBlockedRoute} />
      {isModalOpen && (
        <FlightModal
          isVisible={isModalOpen}
          size={modalSize}
          className={modalClassname}
          toggleModalShown={closeModal}
          header="Confirm navigation"
          content={modalContent}
          footer={
            <div className="modal-footer">
              <FlightButton theme="secondary" onClick={handleConfirmNavigationClick} label="Leave this page" />
              <FlightButton type="submit" onClick={closeModal} label="Stay on this page" />
            </div>
          }
        />
      )}
      <div className="floating-save-bar">
        <span className="floating-save-bar__text">{`You have ${numUnsavedChanges} unsaved change${
          numUnsavedChanges > 1 ? 's' : ''
        }.`}</span>
        <div>
          <FlightButton
            label={primaryActionLabel}
            onClick={primaryActionHandler}
            disabled={primaryActionDisabled}
            loading={primaryActionLoading}
            className="floating-save-bar__submit-btn"
          />
          <FlightButton label={secondaryActionLabel} onClick={secondaryActionHandler} theme="secondary" />
        </div>
      </div>
    </>
  ) : null;
}
