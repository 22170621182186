import { AnalyticsExportActions, AnalyticsExportActionTypes, AnalyticsExportState } from 'model/analyticsExport';
import createReducer from './createReducer';

const initialState = {} as AnalyticsExportState;

export const analyticsExport = createReducer<AnalyticsExportState>(initialState, {
  [AnalyticsExportActionTypes.SET_WHITELISTED_DOMAINS_STATE](
    state: AnalyticsExportState,
    action: AnalyticsExportActions,
  ) {
    return { ...state, whitelistedDomains: action.payload };
  },
});
