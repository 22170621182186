import React, { useEffect, useState } from 'react';
import { FlightButton, FlightSnackbar } from '@flybits/design-system';
import { Datasource } from 'model/datasource';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import * as ContextPluginsActions from 'actions/contextPlugins';
import { useActions } from 'actions';
import { Attribute, ContextPlugin } from 'model/contextPlugins';
import { isEmpty } from 'lodash';
import ContextAttributesTable from 'components/ContextAttributesComponents/ContextAttributesTable';
import { getCtxCategoryFromString } from 'helpers/getCategoryFromString';
import { ContextAttributeSidePanel } from 'components/ContextAttributesComponents/ContextAttributeSidePanel';
import ViewBoxLoader from 'components/Shared/ContentLoader/ViewBoxLoader';
import { ReactComponent as DefaultDatasourceIcon } from '../../assets/images/connected-datasource-icon.svg';
import './DatasourceInfo.scss';
import { BACK_TO_DATASOURCES_PAGE_LINK_TXT } from 'constants/datasources';

interface TDatasourceInfoProps {
  datasource: Datasource;
  onClickGoBack: () => void;
}

export default function DatasourceInfo(props: TDatasourceInfoProps) {
  const { datasource, onClickGoBack } = props;

  const ctxPluginActions = useActions(ContextPluginsActions);

  const ctxPluginsState: ContextPlugin[] = useSelector((state: RootState) => state.contextPlugins.contextPlugins);
  const ctxPluginState: ContextPlugin = useSelector((state: RootState) => state.contextPlugins.contextPlugin);

  const [datasourceDefaultPlugin, setDatasourceDefaultPlugin] = useState('');
  const [ctxAttribute, setCtxAttribute] = useState<Attribute>();
  const [openAttributeSidePanel, setOpenAttributeSidePanel] = useState(false);
  const [userCreatedCtxCategories, setUserCreatedCtxCategories] = useState<ContextPlugin[]>([]);
  const [areCtxPluginsLoading, setAreCtxPluginsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await ctxPluginActions.fetchContextPlugins();
      await ctxPluginActions.fetchContextPlugin(datasource.defaultPluginId);

      setAreCtxPluginsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(ctxPluginsState)) {
      return;
    }

    setUserCreatedCtxCategories(
      ctxPluginsState?.map(ctxPlugin => {
        if (!ctxPlugin.isReserved) {
          return ctxPlugin;
        }
      }) as ContextPlugin[],
    );
  }, [ctxPluginsState]);

  useEffect(() => {
    if (isEmpty(ctxPluginState)) {
      return;
    }

    setDatasourceDefaultPlugin(ctxPluginState.category);
  }, [ctxPluginState]);

  function handleContactSupport() {
    window.location.href = `mailto:${datasource.supportEmail}`;
  }

  function handleLearnMore() {
    window.open(datasource.documentationUrl, '_blank');
  }

  function handleAttributeClicked(attribute: Attribute) {
    const contextIsReserved = ctxPluginsState.find(el =>
      el.category.includes(getCtxCategoryFromString(attribute.uid) || ''),
    );
    let _isReserved;
    if (contextIsReserved === undefined) {
      _isReserved = true;
    } else {
      _isReserved = contextIsReserved?.isReserved;
    }
    setCtxAttribute({ ...attribute, isReserved: _isReserved });
    setOpenAttributeSidePanel(true);
  }

  const NoContextAttributesContent = (
    <>
      No context attributes found. If you believe that context attributes should be available as part of this connected
      data source, please{' '}
      <FlightButton
        label="contact us"
        theme="link"
        onClick={handleContactSupport}
        className="datasource-info__no-ctx-attr-email-link"
      />{' '}
      for additional support.
    </>
  );

  function areCtxAttributesEmpty() {
    return isEmpty(ctxPluginsState.find(ctxPlugin => ctxPlugin.category === datasourceDefaultPlugin)?.values);
  }

  return (
    <div className="datasource-info">
      <div className="datasource-info__go-back" role="button" tabIndex={0} onClick={onClickGoBack}>
        <span className="datasource-info__go-back__link">{BACK_TO_DATASOURCES_PAGE_LINK_TXT}</span>
      </div>
      <div className="datasource-info__header">
        {datasource.imageUrl ? (
          <img src={datasource.imageUrl} alt={datasource.owner.name} className="datasource-info__header__image" />
        ) : (
          <DefaultDatasourceIcon className="datasource-info__header__image" />
        )}
        <div className="datasource-info__header__content">
          <div className="datasource-info__header__content__name">{datasource.name}</div>
          {datasource.owner.name && (
            <div className="datasource-info__header__content__text">By {datasource.owner.name}</div>
          )}
          {datasource.description && (
            <div className="datasource-info__header__content__text">{datasource.description}</div>
          )}
          <div className="datasource-info__header__content__links">
            {datasource.documentationUrl ? (
              <FlightButton
                label="Learn more"
                theme="link"
                onClick={handleLearnMore}
                className="datasource-info__header__content__links__link-button"
              />
            ) : null}
            {datasource.supportEmail ? (
              <FlightButton
                label="Contact support"
                theme="link"
                onClick={handleContactSupport}
                className="datasource-info__header__content__links__link-button"
              />
            ) : null}
          </div>
        </div>
      </div>
      <hr />
      {areCtxPluginsLoading ? (
        <ViewBoxLoader width={500} height={100} />
      ) : datasourceDefaultPlugin && !areCtxAttributesEmpty() ? (
        <ContextAttributesTable
          contextPlugins={[ctxPluginState]}
          handleAttributeClicked={handleAttributeClicked}
          isNoFilters
          isReadOnly
        />
      ) : (
        <FlightSnackbar
          className="datasource-info__snackbar"
          type="info"
          icon="infoFilled"
          isVisible
          isAutoDismiss
          actionName={null}
          content={NoContextAttributesContent}
        />
      )}
      <ContextAttributeSidePanel
        contextPlugins={ctxPluginsState}
        attribute={ctxAttribute}
        userCreatedCategories={userCreatedCtxCategories}
        showPanel={openAttributeSidePanel}
        togglePanel={() => setOpenAttributeSidePanel(false)}
        isReadOnly
      />
    </div>
  );
}
