import {
  CreateServiceAccountResponse,
  GetServiceAccountsParams,
  GetServiceAccountsResponse,
  ServiceAccount,
} from 'model/serviceAccount';
import BaseService from './base.service';

export default class ServiceAccountService extends BaseService {
  CURRENT_PATH: string;
  PRIMARY_RESOURCE: string;

  constructor() {
    super();
    this.CURRENT_PATH = 'sso/auth/';
    this.PRIMARY_RESOURCE = 'sa';
  }

  async getAllServiceAccounts(params: GetServiceAccountsParams) {
    return this.request<GetServiceAccountsResponse>('GET', this.CURRENT_PATH + this.PRIMARY_RESOURCE, params);
  }

  async createServiceAccount(serviceAccount: ServiceAccount) {
    return this.request<CreateServiceAccountResponse>(
      'POST',
      this.CURRENT_PATH + this.PRIMARY_RESOURCE,
      serviceAccount,
    );
  }

  async deleteServiceAccount(serviceAccountId: string) {
    return this.request('DELETE', `${this.CURRENT_PATH}${this.PRIMARY_RESOURCE}/${serviceAccountId}`);
  }
}
