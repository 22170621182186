import React, { useState, useEffect } from 'react';
import { FlightTable } from '@flybits/design-system';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import './TemplateReviewTable.scss';

import { TemplatePayload, TemplatesEntityImport } from 'model/manageTemplates/manageTemplates';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

interface Props {
  templates: TemplatesEntityImport[] | TemplatePayload[];
}

interface TableData {
  key: string | undefined;
  image?: JSX.Element;
  name?: string;
  category?: string;
  subcategory?: string;
  type?: JSX.Element;
  dateAdded?: string;
}

const tableHeaders = [
  {
    name: '',
    key: 'image',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Template Name',
    key: 'name',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Category',
    key: 'category',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Subcategory',
    key: 'subcategory',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Type',
    key: 'type',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Action',
    key: 'action',
    isVisible: false,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Date added',
    key: 'dateAdded',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
];

export function TemplateReviewTable(props: Props) {
  const { templates } = props;
  const [tableData, setTableData] = useState<TableData[]>([]);
  const categories = useSelector(
    (state: RootState) => state?.manageTemplates?.importLibraryCategories?.settings?.categories || [],
  );

  useEffect(() => {
    const _tableData: TableData[] = [];
    const newTemplates: any = cloneDeep(templates);

    // hide action column if this is an export templates list
    if (!newTemplates[0].payload) tableHeaders[5].isVisible = false;
    else tableHeaders[5].isVisible = true;

    _tableData.push(
      ...(newTemplates?.map((template: TemplatesEntityImport | TemplatePayload) => {
        const temp = {
          payload: (template as TemplatesEntityImport).payload
            ? (template as TemplatesEntityImport).payload
            : (template as TemplatePayload),
          mergeStrategy: (template as TemplatesEntityImport).mergeStrategy
            ? (template as TemplatesEntityImport).mergeStrategy
            : null,
        };

        const cat = categories.find(
          category => category.key === temp.payload?.categories?.find(c => c.startsWith('category-'))?.substring(9),
        );
        const subcat = cat?.subcategories?.find(
          subcategory =>
            subcategory.key === temp.payload?.categories?.find(c => c.startsWith('subcategory-'))?.substring(12),
        );
        const action = temp?.mergeStrategy?.replace('_', ' ') || '-';

        return {
          key: temp.payload?.id,
          image: temp.payload?.imageUrl ? (
            <img
              className="content-review__table__table__icon"
              src={temp.payload?.imageUrl}
              alt="template"
              width="48px"
            />
          ) : (
            ''
          ),
          name: temp.payload?.name,
          category: cat?.name,
          subcategory: subcat?.name || '-',
          type: (
            <div
              className={`content-review__table__type content-review__table__type--${temp.payload?.provider?.toLowerCase()}`}
            >
              {temp.payload?.provider}
            </div>
          ),
          action: <span className="content-review__table__action">{action}</span>,
          dateAdded: moment(temp.payload?.createdAt * 1000).format('MMM D, YYYY'),
        };
      }) || []),
    );

    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
    //eslint-disable-next-line
  }, [templates]);

  return (
    <div className="content-review">
      {tableData.length > 0 ? (
        <FlightTable
          className="content-review__table"
          tableHeaders={tableHeaders}
          tableData={tableData}
          hasPaginationBeforeTable={false}
          ariaLabel="content review Table"
        />
      ) : (
        <span className="content-review__missing-templates">No templates</span>
      )}
    </div>
  );
}
