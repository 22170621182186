import { PushSettingsActionTypes } from 'model/pushSettings';
import { SilentPushSettings, ObjectForSilentPushApi } from 'model/pushSettings/settings';
import SettingsService from 'services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator } from 'helpers/actionCreator';
import { showNotification } from '../snackBarNotifications';

interface Entities {
  actions: SilentPushSettings[];
  entityKey?: string;
}

export const setSilentPushSettings = makeActionCreator(PushSettingsActionTypes.SET_SILENT_PUSH_SETTINGS);

export function fetchSilentPushSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getSilentPushSettings();
      const flattenedResponse = response.data.settings.entities.flatMap((entity: Entities) =>
        entity.actions.map(action => action),
      );
      dispatch(setSilentPushSettings(flattenedResponse[0]));
    } catch (error) {}
    return 'done';
  };
}

export function addOrUpdateSilentPushSettings(subscribed: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForSilentPushApi: ObjectForSilentPushApi = {
        settings: {
          entities: [{ actions: [{ action: 'updated', subscribed: subscribed }], entityKey: 'relevantContent' }],
        },
        configName: {
          configName: 'entity-event-silent-push',
        },
      };
      const response = await req.createSilentPushSetting(objectForSilentPushApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Silent push settings saved.',
            type: 'success',
          }),
        );
        dispatch(fetchSilentPushSettings());
      }
    } catch (error) {}
    return 'done';
  };
}
