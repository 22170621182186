import { DatasourceAction, DatasourceActionTypes, DatasourcesState } from 'model/datasource';
import createReducer from './createReducer';

const initialState = {} as DatasourcesState;

export const datasources = createReducer<DatasourcesState>(initialState, {
  [DatasourceActionTypes.SET_DATASOURCES_STATE](state: DatasourcesState, action: DatasourceAction) {
    return { ...state, datasources: action.payload };
  },
});
