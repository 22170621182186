import React from 'react';
import { FlightModal, FlightButton } from '@flybits/design-system';

interface Props {
  title: string;
  message: JSX.Element | string;
  isVisible: boolean;
  continueCallback: (data: any) => void;
  cancelCallback: (data: any) => void;
}
export default function ConfirmModal(props: Props) {
  const { title, message, continueCallback, cancelCallback, isVisible } = props;
  return (
    <div>
      <FlightModal
        isVisible={isVisible}
        size="medium"
        toggleModalShown={cancelCallback}
        header={<span>{title}</span>}
        content={<span>{message}</span>}
        footer={
          <div className="modal-footer">
            <FlightButton theme="secondary" onClick={cancelCallback} label="Cancel" />
            <FlightButton type="submit" label="Continue" onClick={continueCallback} />
          </div>
        }
      />
    </div>
  );
}
