import React from 'react';
import { createRoot } from 'react-dom/client';
import { ReduxRoot } from './ReduxRoot';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from 'auth/providers/AuthProvider';
import { FeatureFlagsProvider } from 'providers/flagsmith/FeatureFlagsProvider';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Only initialize Sentry for builds
if (process.env.REACT_APP_BUILD_ENVIRONMENT && process.env.SENTRY_STATUS === 'enabled') {
  Sentry.init({
    release: `${process.env.REACT_APP_SENTRY_RELEASE}`,
    environment: `${process.env.REACT_APP_BUILD_ENVIRONMENT}`,
    dsn: 'https://27cafa4fbeb440f58a450334d02ae697@o426285.ingest.sentry.io/5743421',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AuthProvider>
    <FeatureFlagsProvider>
      <ReduxRoot />
    </FeatureFlagsProvider>
  </AuthProvider>
);


// comment in for PWA with service worker in production mode
// registerServiceWorker();
