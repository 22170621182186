import { showNotification } from 'actions/snackBarNotifications';
import { ObjectForApi } from 'model/accountAccess';
import SettingsService from 'services/settings.service';
import { generateUniqueId } from './randomIdGenerator';

interface SnackBarNotificationMessage {
  successMessage?: string;
  errorMessage?: string;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeActionCreator(type: any) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(payload?: any) {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action: any = { type };
    if (payload) {
      action.payload = payload;
    }
    return action;
  };
}

export function makeFetchActionCreator(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  service: any,
  requestName: string,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSettingCallback: (payload: any) => void,
  property?: string,
  notification?: SnackBarNotificationMessage,
) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(params?: any) {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
      try {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = await (service as any)[requestName](params);
        const data = property ? response.data[property] : response.data;
        dispatch(setSettingCallback(data));
      } catch (error) {
        if (notification?.errorMessage) {
          const randomId = generateUniqueId();
          dispatch(
            showNotification({
              id: randomId,
              showNotification: true,
              header: 'Failed',
              body: notification.errorMessage,
              type: 'error',
            }),
          );
        }
      }
      return 'done';
    };
  };
}

export function makeAPIActionsCreator(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  service: any,
  requestName: string,
  fetchCallback: () => void,
  successCode: number,
  notification?: SnackBarNotificationMessage,
) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(params?: any) {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
      try {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = await (service as any)[requestName](params);
        if (response.status === successCode) {
          dispatch(fetchCallback());
          if (notification?.successMessage) {
            const randomId = generateUniqueId();
            dispatch(
              showNotification({
                id: randomId,
                showNotification: true,
                header: 'Success',
                body: notification.successMessage,
                type: 'success',
              }),
            );
          }
        }
      } catch (error) {
        // @ts-expect-error: TODO: fix the typescript error properly
        if (notification?.errorMessage || error?.response?.data?.error.exceptionMessage) {
          const randomId = generateUniqueId();

          // @ts-expect-error: TODO: fix the typescript error properly
          const errorMessage = notification?.errorMessage + ' ' + (error?.response?.data?.error.exceptionMessage || '');
          dispatch(
            showNotification({
              id: randomId,
              showNotification: true,
              header: 'Failed',
              body: errorMessage,
              type: 'error',
            }),
          );
        }
      }
      return 'done';
    };
  };
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeCreateOrUpdateSettingsAction(
  configName: string,
  fetchCallback: () => void,
  createSettingForApi = false,
) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(setting: any) {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
      try {
        const req = new SettingsService();

        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        let styledSetting: any = setting;
        if (createSettingForApi) {
          // Capitalize first letter of property key for backend
          styledSetting = {};
          for (const property in setting) {
            const styledProperty = property.charAt(0).toUpperCase() + property.slice(1);
            styledSetting[styledProperty] = setting[property];
          }
        }

        const objectForApi: ObjectForApi = {
          config: JSON.stringify(styledSetting),
          configName: {
            configName: configName,
          },
        };
        const response = await req.createOrUpdateSetting(objectForApi);
        if (response.status === 200) {
          if (configName !== 'tenant-access') {
            const randomId = generateUniqueId();
            dispatch(
              showNotification({
                id: randomId,
                showNotification: true,
                header: 'Success',
                body: '',
                type: 'success',
              }),
            );
          }
          dispatch(fetchCallback());
        }
      } catch (error) {}
      return 'done';
    };
  };
}
