import { AccountAccess, AccountAccessAction, AccountAccessActionTypes } from 'model/accountAccess';
import createReducer from './createReducer';

const initialState = {} as AccountAccess;

export const accountAccess = createReducer<AccountAccess>(initialState, {
  [AccountAccessActionTypes.SET_APIKEYSETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, apiKeySettings: action.payload };
  },
  [AccountAccessActionTypes.SET_FACEBOOKSETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, facebookSettings: action.payload };
  },
  [AccountAccessActionTypes.SET_GOOGLESETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, googleSettings: action.payload };
  },
  [AccountAccessActionTypes.SET_OKTASETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, oktaSettings: action.payload };
  },
  [AccountAccessActionTypes.SET_OPENIDSETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, openIDSettings: action.payload };
  },
  [AccountAccessActionTypes.SET_SAMLSETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, samlSettings: action.payload };
  },
  [AccountAccessActionTypes.SET_SIGNEDLOGINSETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, signedLoginSettings: action.payload };
  },
  [AccountAccessActionTypes.SET_JWTSIGNEDLOGINSETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, jwtSignedLoginSettings: action.payload };
  },
  [AccountAccessActionTypes.SET_TENANTACCESSSETTINGS_STATE](state: AccountAccess, action: AccountAccessAction) {
    return { ...state, tenantAccessSettings: action.payload };
  },
});
