import { PushSettings, PushSettingsAction, PushSettingsActionTypes } from 'model/pushSettings';
import createReducer from './createReducer';

const initialState = {} as PushSettings;
export const pushSettings = createReducer<PushSettings>(initialState, {
  [PushSettingsActionTypes.SET_APNS_SETTINGS](state: PushSettings, action: PushSettingsAction) {
    return { ...state, apnsSettings: action.payload };
  },
  [PushSettingsActionTypes.SET_UPLOAD_APNS_SETTING](state: PushSettings, action: PushSettingsAction) {
    return { ...state, uploadApnsSetting: action.payload };
  },
  [PushSettingsActionTypes.SET_APNS_TOKEN_SETTINGS](state: PushSettings, action: PushSettingsAction) {
    return { ...state, apnsTokenSettings: action.payload };
  },
  [PushSettingsActionTypes.SET_FCM_SETTINGS](state: PushSettings, action: PushSettingsAction) {
    return { ...state, fcmSettings: action.payload };
  },
  [PushSettingsActionTypes.SET_FCM_V1_SETTINGS](state: PushSettings, action: PushSettingsAction) {
    return { ...state, fcmV1Settings: action.payload };
  },
  [PushSettingsActionTypes.SET_HUAWEI_SETTINGS](state: PushSettings, action: PushSettingsAction) {
    return { ...state, huaweiSettings: action.payload };
  },
  [PushSettingsActionTypes.SET_NOTIFICATION_TYPES_STATE](state: PushSettings, action: PushSettingsAction) {
    return { ...state, notificationTypes: action.payload };
  },
  [PushSettingsActionTypes.SET_NOTIFICATION_TYPE_STATE](state: PushSettings, action: PushSettingsAction) {
    return { ...state, notificationType: action.payload };
  },
  [PushSettingsActionTypes.SET_SILENT_PUSH_SETTINGS](state: PushSettings, action: PushSettingsAction) {
    return { ...state, silentPushSettings: action.payload };
  },
  [PushSettingsActionTypes.SET_USER_SUBSCRIPTIONS_STATE](state: PushSettings, action: PushSettingsAction) {
    return { ...state, userSubscriptions: action.payload };
  },
  [PushSettingsActionTypes.SET_PUSH_LIMIT_STATE](state: PushSettings, action: PushSettingsAction) {
    return { ...state, pushLimitSettngs: action.payload };
  },
});
