import React, { useEffect, useState } from 'react';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import DatasourceInfo from 'components/DatasourcesComponents/DatasourceInfo';
import { Datasource } from 'model/datasource';
import { filterFromArrayOfObjects } from 'helpers/searchFilters';
import { cloneDeep, isEmpty } from 'lodash';
import { FlightSnackbar } from '@flybits/design-system';
import { sortArrayOfObjects, SORT_ORDER, SORT_VALUE_TYPES } from 'helpers/sortArray';
import { ReactComponent as DefaultDatasourceIcon } from '../../assets/images/connected-datasource-icon.svg';
import * as DatasourcesActions from 'actions/datasources';
import { useActions } from 'actions';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { NO_DATA_SOURCE, NO_DATA_SOURCE_FOUND } from 'constants/datasources';
import './DatasourcesPage.scss';

export default function DatasourcesPage() {
  const datasourcesActions = useActions(DatasourcesActions);
  const datasourcesState = useSelector((state: RootState) => state.datasources.datasources);
  const [datasources, setDatasources] = useState<Datasource[]>([]);
  const [datasourcesSearchFilter, setDatasourcesSearchFilter] = useState('');
  const [showDatasourceInfo, setShowDatasourceInfo] = useState(false);
  const [currentDatasource, setCurrentDatasource] = useState<Datasource | null>(null);
  const [searchBarHasError, setSearchBarHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [areDatasourcesEmpty, setAreDatasourcesEmpty] = useState(false);

  function handleDatasourcesSearchFilter(filterText: string) {
    setDatasourcesSearchFilter(filterText);
  }

  function handleOnClickGoBack() {
    setShowDatasourceInfo(false);
  }

  function handleOnClickDatasourceTitle(datasource: Datasource) {
    setCurrentDatasource(datasource);
    setShowDatasourceInfo(true);
  }

  useEffect(() => {
    document.title = "Connected Data Sources | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    (async () => await datasourcesActions.fetchAllDatasources())();
    (document.getElementsByClassName('flight-text-input__input')?.[0]  as HTMLElement)?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(datasourcesSearchFilter)) {
      setSearchBarHasError(false);

      if (isEmpty(datasourcesState)) {
        setErrorMessage(NO_DATA_SOURCE);
        setAreDatasourcesEmpty(true);

        return;
      }

      setDatasources(cloneDeep(datasourcesState) as Datasource[]);
      setErrorMessage('');
      setAreDatasourcesEmpty(false);

      return;
    }

    const filteredDatasourcesList = filterFromArrayOfObjects(
      datasourcesState as Datasource[],
      'name',
      datasourcesSearchFilter,
    );
    const sortedDatasourcesList = sortArrayOfObjects(
      filteredDatasourcesList,
      'updatedAt',
      SORT_VALUE_TYPES.NUMBER,
      SORT_ORDER.DESC,
    );

    if (isEmpty(sortedDatasourcesList)) {
      setSearchBarHasError(true);
      setErrorMessage(NO_DATA_SOURCE_FOUND);
      setAreDatasourcesEmpty(true);

      return;
    }

    setSearchBarHasError(false);
    setErrorMessage('');
    setAreDatasourcesEmpty(false);
    setDatasources(cloneDeep(sortedDatasourcesList));
  }, [datasourcesState, datasourcesSearchFilter]);

  return (


    <div
      role="main"
      className={
        showDatasourceInfo ? 'connected-datasources connected-datasources--datasource-info' : 'connected-datasources'
      }
    >
      {showDatasourceInfo ? (
        <DatasourceInfo datasource={currentDatasource as Datasource} onClickGoBack={handleOnClickGoBack} />
      ) : (
        <>
          <PageHeader title="Connected Data Sources" isLarge={true} className="connected-datasources__title" />
          <SearchBar
            className="connected-datasources__search-bar"
            initialValue={datasourcesSearchFilter}
            handleSearchTermChange={handleDatasourcesSearchFilter}
            width="296px"
            label="Search"
            hasError={searchBarHasError}
            placeholderText={'Name of the connected datasource'}
            labelId="connected-datasources__search-bar"
          />
          {areDatasourcesEmpty ? (
            <FlightSnackbar
              className="connected-datasources__snackbar"
              type="info"
              icon="infoFilled"
              isVisible
              isAutoDismiss
              actionName={null}
              content={errorMessage}
            />
          ) : (
            <div className="connected-datasources__list">
              {datasources.map((datasource: Datasource, key: number) => (
                <ReusableCard key={key} className="connected-datasources__card-wrapper">
                  <div className="connected-datasources__card">
                    {datasource.imageUrl ? (
                      <img
                        src={datasource.imageUrl}
                        alt={datasource.owner.name}
                        className="connected-datasources__card__image"
                      />
                    ) : (
                      <DefaultDatasourceIcon className="connected-datasources__card__image" />
                    )}
                    <div className="connected-datasources__card__content">
                      <div className="connected-datasources__card__content__header">
                        <button
                          className="connected-datasources__card__content__header__title"
                          tabIndex={0}
                          onClick={() => handleOnClickDatasourceTitle(datasource)}
                        >
                          {datasource.name}
                        </button>
                      </div>
                      <div className="connected-datasources__card__content__subheader">{`By ${datasource.owner.name}`}</div>
                      <div className="connected-datasources__card__content__description">{datasource.description}</div>
                    </div>
                  </div>
                </ReusableCard>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
