import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';
import { makeActionCreator } from 'helpers/actionCreator';
import { Category, ConciergeCategoriesActionTypes, ConciergeCategoriesAPIPayload } from 'model/conciergeCategories';
import SettingsService from 'services/settings.service';

const setConciergeCategories = makeActionCreator(ConciergeCategoriesActionTypes.SET_CONCIERGE_CATEGORIES);

export function getCategories() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getConciergeCategories();
      dispatch(setConciergeCategories(response?.data?.settings?.categories));
    } catch (error) {
      // @ts-expect-error: TODO: fix the typescript error properly
      if (error.response?.status === 404) {
        // @ts-expect-error: TODO: fix the typescript error properly
        const errorObjectFromResponse = error.response?.data?.error?.exceptionType;
        if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
          dispatch(setConciergeCategories([]));
        }
      }
    }

    return 'done';
  };
}

export function updateCategories(tenantId: string, categories: Category[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const conciergeCategoriesAPIPayload: ConciergeCategoriesAPIPayload = {
        configName: {
          configName: 'sdk-categories',
          tenant: {
            tenantId,
          },
        },
        settings: {
          categories,
        },
      };

      await req.updateConciergeCategories(conciergeCategoriesAPIPayload);
      dispatch(getCategories());
    } catch (error) {}

    return 'done';
  };
}
