import React, { useState, FocusEvent, useRef } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FormGroup } from 'reactstrap';
import { AxiosError } from 'axios';
import { FlightButton, FlightTextArea, FlightTextInput } from '@flybits/design-system';
import { useActions } from 'actions';
import { NAME_REQUIRED } from 'constants/errors/errors';
import * as ServiceAccountActions from 'actions/serviceAccount';
import { CreateServiceAccountResponse } from 'model/serviceAccount';
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg';
import './CreateServiceAccountModal.scss';

interface Props {
  toggleCreateServiceAccountModal: () => void;
}

interface ServiceAccountValues {
  name: string;
  description: string;
}

export default function CreateServiceAccountModal({ toggleCreateServiceAccountModal }: Props) {
  const [createdData, setCreatedData] = useState<CreateServiceAccountResponse | null>(null);
  const [responseError, setResponseError] = useState<string | null>(null);
  const [showCopiedToClipboard, setShowCopiedToClipboard] = useState<'secret' | 'serviceAccountId' | null>(null);
  const serviceAccountActions = useActions(ServiceAccountActions);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
  });
  const timeout = useRef<NodeJS.Timeout>();

  const initialValues = {
    name: '',
    description: '',
  };

  const handleSubmit = async (values: ServiceAccountValues, { setSubmitting }: FormikHelpers<ServiceAccountValues>) => {
    try {
      setSubmitting(true);
      const data = await serviceAccountActions.createServiceAccount(values);
      if (data) setCreatedData(data);
    } catch (err) {
      setResponseError(
        // @ts-expect-error: TODO: fix the typescript error properly
        (err as AxiosError).response?.data?.error?.exceptionMessage ||
          `Failed to create service account "${values.name}".`,
      );
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setResponseError(null);
      }, 4000);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFocus = (evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    evt.target.readOnly = true;
  };

  const handleCopyClick = (key: keyof CreateServiceAccountResponse) => {
    navigator.clipboard.writeText((createdData as CreateServiceAccountResponse)[key]);
    setShowCopiedToClipboard(key);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setShowCopiedToClipboard(null);
    }, 5000);
  };

  return (
    <div className="create-service-account-modal">
      {!createdData && (
        <Formik<ServiceAccountValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, values, isValid, isSubmitting }) => (
            <Form>
              <h2 className="create-service-account-modal__header__details__name">Create service account</h2>
              {responseError && (
                <FormGroup className="create-service-account-modal__field">
                  <p className="create-service-account-modal__error-message">{responseError}</p>
                </FormGroup>
              )}
              <FormGroup className="create-service-account-modal__field">
                <label className="create-service-account-modal__label" htmlFor="name">
                  Service account name
                </label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  as={FlightTextInput}
                  hasError={!!errors.name}
                  errorMessage={errors.name}
                  value={values.name}
                  width="100%"
                />
              </FormGroup>
              <FormGroup className="create-service-account-modal__field">
                <label className="create-service-account-modal__label" htmlFor="description">
                  Service account description (optional)
                </label>
                <Field
                  type="text"
                  name="description"
                  id="description"
                  as={FlightTextInput}
                  hasError={!!errors.description}
                  errorMessage={errors.description}
                  value={values.description}
                  width="100%"
                />
              </FormGroup>
              <FormGroup className="create-service-account-modal__footer">
                <FlightButton
                  label="Cancel"
                  theme="secondary"
                  onClick={toggleCreateServiceAccountModal}
                  disabled={isSubmitting}
                />
                <FlightButton
                  className="create-service-account-modal__submit-button"
                  label="Create Service Account"
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  onClick={() => null}
                  loading={isSubmitting}
                />
              </FormGroup>
            </Form>
          )}
        </Formik>
      )}
      {createdData !== null && (
        <div>
          <h2 className="create-service-account-modal__header__details__name">Service account has been created</h2>
          <p>
            Please make sure you copy the <strong>Secret</strong> as this is the only time you will see it.
          </p>
          <FormGroup className="create-service-account-modal__field">
            <label htmlFor="account-secret" className="create-service-account-modal__label">
              Service Account Secret{' '}
              <CopyIcon className="create-service-account-modal__copy-img" onClick={() => handleCopyClick('secret')} />
              {showCopiedToClipboard === 'secret' && (
                <span className="create-service-account-modal__copied-message">Copied to clipboard</span>
              )}
            </label>
            <FlightTextArea
              value={createdData.secret}
              className="create-service-account-modal__textarea"
              width="100%"
              hideCharCounter
              name="account-secret"
              label={null}
              onFocus={handleFocus}
            />
          </FormGroup>
          <FormGroup className="create-service-account-modal__field">
            <label htmlFor="account-id" className="create-service-account-modal__label">
              Service Account ID{' '}
              <CopyIcon
                className="create-service-account-modal__copy-img"
                onClick={() => handleCopyClick('serviceAccountId')}
              />
              {showCopiedToClipboard === 'serviceAccountId' && (
                <span className="create-service-account-modal__copied-message">Copied to clipboard</span>
              )}
            </label>
            <FlightTextInput
              value={createdData.serviceAccountId}
              width="100%"
              onFocus={handleFocus}
              name="account-id"
            />
          </FormGroup>
        </div>
      )}
    </div>
  );
}
