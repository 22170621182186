import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import jwt_decode, { JwtDecodeOptions } from 'jwt-decode';
import { IDTokenDecoded } from 'auth/types/auth.types';
import LocalStore from 'services/localstore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertToBase64(wordarray: any) {
  return Base64.stringify(wordarray)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

function generateRandomString(length: number) {
  let randomString = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return randomString;
}

export function getCodeVerifier() {
  return generateRandomString(32);
}

export function getCodeChallenge(codeVerifier: string) {
  return convertToBase64(sha256(codeVerifier));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFormUrlEncodedParams(params: any) {
  const formUrlEncodedParams = new URLSearchParams();

  for (const key in params) {
    formUrlEncodedParams.append(key, params[key]);
  }

  return formUrlEncodedParams;
}

export function getJWTDecoded(token: string, options?: JwtDecodeOptions): IDTokenDecoded {
  return jwt_decode(token, options);
}

export function cleanUpLocalForage(...items: string[]) {
  const localStore = new LocalStore();
  const cleanUpPromises: Promise<void>[] = [];

  items.map(item => cleanUpPromises.push(localStore.removeItem(item)));

  return Promise.all(cleanUpPromises);
}
