export function getTab(page: string) {
  const tabId = localStorage.getItem(page);
  if (tabId) {
    return tabId;
  } else {
    return '1';
  }
}

export function setTab(page: string, id: string) {
  const tabId = localStorage.setItem(page, id);
  return tabId;
}
