export interface ContentTemplateState {
  contentTemplates: ContentTemplate[];
  contentTemplateSettings: ContentTemplateSettings;
  contentTemplate: ContentTemplate;
  contentTemplateLabels: {
    data: string[];
  };
}

export interface Properties {
  isRequired: boolean;
  isUniqueIdentifier: boolean;
}
export interface Fields {
  name: string;
  displayName: {};
  properties: Properties;
  type: string;
  subtype?: string;
}

export interface Schema {
  fields: Fields[];
}

interface Localizations {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: any;
  name?: string;
}

type ContentUiConfig = {
  layout: string;
  layoutHTML: string;
  styleCSS: string;
  styleCSSURL: string;
};

type DynamicDefaults = {
  defaultUIConfig?: ContentUiConfig;
  detailsUIConfig?: ContentUiConfig;
  renderType?: string;
};

export interface ContentTemplate {
  id: string;
  tenantId: string;
  localizations: Localizations;
  type: string;
  labels: Array<string>;
  internal: boolean;
  schema: Schema;
  iconUrl: string;
  createdAt: number;
  modifiedAt: number;
  defaultValues?: DynamicDefaults;
}

export interface ContentTemplateAttributes {
  createdAt: number;
  iconUrl: string;
  localizations: {};
  modifiedAt: number;
  schema: Schema;
  templateId: string;
  tenantId: string;
  type: string;
  labels: string[];
  defaultValues?: DynamicDefaults;
  projectTokenForApiCall?: string; //front-end only property for base service
}

export interface ContentTemplateData {
  id: string;
  attributes: ContentTemplateAttributes;
  type: string;
}

export interface ContentTemplatesDownload {
  data: ContentTemplateData[];
  meta: {
    downloadedAt: number;
    totalRecords: number;
  };
}

export interface ProjectSetting {
  displayName: string;
  labels: Array<string>;
}
export interface ContentTemplateSettings {
  categories: Array<ProjectSetting>;
}

export enum ContentTemplatesActionTypes {
  SET_CONTENT_TEMPLATES_STATE = 'SET_CONTENT_TEMPLATES_STATE',
  SET_CONTENT_TEMPLATE_SETTINGS_STATE = 'SET_CONTENT_TEMPLATE_SETTINGS_STATE',
  SET_SINGLE_CONTENT_TEMPLATE_STATE = 'SET_SINGLE_CONTENT_TEMPLATE_STATE',
  SET_CONTENT_TEMPLATE_LABELS_STATE = 'SET_CONTENT_TEMPLATE_LABELS_STATE',
}

interface ContentTemplatesActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type ContentTemplatesActions =
  | ContentTemplatesActionCreatorTypes<typeof ContentTemplatesActionTypes.SET_CONTENT_TEMPLATES_STATE, ContentTemplate>
  | ContentTemplatesActionCreatorTypes<
      typeof ContentTemplatesActionTypes.SET_SINGLE_CONTENT_TEMPLATE_STATE,
      ContentTemplate
    >
  | ContentTemplatesActionCreatorTypes<
      typeof ContentTemplatesActionTypes.SET_CONTENT_TEMPLATE_LABELS_STATE,
      ContentTemplate
    >
  | ContentTemplatesActionCreatorTypes<
      typeof ContentTemplatesActionTypes.SET_CONTENT_TEMPLATE_SETTINGS_STATE,
      ContentTemplateSettings
    >;
