import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlightModal, FlightButton } from '@flybits/design-system';
import { TemplateReviewTable } from '../TemplateReviewTable/TemplateReviewTable';
import { TemplatePayload } from 'model/manageTemplates';
import { ExportDownload } from './ExportDownload/ExportDownload';
import './ExportTemplateModal.scss';
import ManageTemplatesService from 'services/manageTemplate.service';
import { showNotification } from 'actions/snackBarNotifications';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { Buffer } from 'buffer';
interface Props {
  templates: TemplatePayload[];
  openModal: boolean;
  directExport?: boolean;
  toggleModal: () => void;
  onSuccess: () => void;
}

export function ExportTemplateModal(props: Props) {
  const { templates, openModal, directExport, onSuccess, toggleModal } = props;
  const [activeStep, setActiveStep] = useState(1);
  const dispatch = useDispatch();

  const downloadExport = useCallback(async () => {
    try {
      if (templates.length < 1) return;
      const exportPayload = templates.map((template: TemplatePayload) => ({ id: template.id }));
      const req = new ManageTemplatesService();
      const { data } = await req.exportFullTemplates(exportPayload);
      data.name = `templates-${new Date().toJSON().slice(0, 10)}`;
      const buf = Buffer.from(JSON.stringify(data));
      const blob = new Blob([buf], { type: 'application/json' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${data.name}.json`;
      link.click();
      onSuccess();
    } catch (error) {
      dispatch(
        showNotification({
          id: generateUniqueId(),
          showNotification: true,
          header: 'Failed to Export',
          body: String(error),
          type: 'error',
        }),
      );
    }
  }, [templates, dispatch, onSuccess]);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (openModal && activeStep === 2) downloadExport();
  }, [activeStep, openModal, downloadExport]);

  useEffect(() => {
    if (openModal && directExport) setActiveStep(2);
  }, [directExport, openModal, templates]);

  return (
    <div className="export-template-modal">
      <FlightModal
        isVisible={openModal}
        toggleModalShown={() => {
          toggleModal();
        }}
        scrollable={false}
        size="large"
        header={<span>Export Templates</span>}
        content={
          activeStep === 1 ? (
            <TemplateReviewTable templates={templates} />
          ) : (
            <ExportDownload exportDownload={downloadExport} />
          )
        }
        footer={
          <div className="export-template-modal__footer">
            {templates.length > 0 && (
              <span className="export-template-modal__footer__template-amount">
                Total templates: {templates?.length}
              </span>
            )}
            {activeStep === 1 ? (
              <>
                <FlightButton onClick={nextStep} disabled={templates.length < 1} label="Confirm" theme="primary" />
                <FlightButton
                  onClick={() => {
                    toggleModal();
                    setActiveStep(1);
                  }}
                  label="Cancel"
                  theme="secondary"
                />
              </>
            ) : (
              <>
                <FlightButton
                  onClick={() => {
                    toggleModal();
                    setActiveStep(1);
                  }}
                  label="Close"
                  theme="secondary"
                />
              </>
            )}
          </div>
        }
      />
    </div>
  );
}
