import React, { useCallback, useEffect, useState } from 'react';
import './ConflictsComponentsResolution.scss';
import { ReactComponent as JSONIcon } from 'assets/images/json-icon.svg';
import { ReactComponent as ComputerIcon } from 'assets/images/computer-icon.svg';
import {
  Components,
  TemplateConflicts,
  TemplateExportedContent,
  TemplateImportContent,
  TemplatePayload,
} from 'model/manageTemplates/manageTemplates';
import { FlightRadioButton, getIcon } from '@flybits/design-system';
import { useActions } from 'actions';
import * as ManageTemplatesActions from 'actions/manageTemplates';
import { ReactComponent as RulesIcon } from 'assets/images/icon-c-rules.svg';
import { ReactComponent as PushesIcon } from 'assets/images/icon-c-pushes.svg';
import { ReactComponent as ContentIcon } from 'assets/images/icon-c-content.svg';
interface ConflictsComponentsResolutionProps {
  templatesJSON: TemplateExportedContent;
  templatesPayload: TemplateImportContent;
  conflicts: TemplateConflicts;
  library: TemplatePayload[];
  filter: string | null;
}
interface ListCardProps {
  currentAction: string;
  data: {
    id: string;
    name: string;
    parents: string[];
  };
  strategy: string;
  type: string;
  disabled?: boolean;
  selected?: boolean;
  changeStrategyForCompnentHandler: (arg0: string, arg1: string, arg2: string) => void;
}
const ItemIcon = ({ icon }: { icon: string }) => {
  if (icon) {
    switch (icon) {
      case 'rule_template':
      case 'context_plugin':
        return <RulesIcon />;
      case 'push_template':
        return <PushesIcon />;
      case 'webhook_template':
        return getIcon('nodeConnect', {});
      case 'content_template_prototype':
      case 'content_template':
        return <ContentIcon />;
      default:
        return null;
    }
  }
  return null;
};
export const ListCard = (props: ListCardProps) => {
  const {
    currentAction,
    data: { name, id, parents },
    strategy,
    type,
    selected,
    disabled,
    changeStrategyForCompnentHandler,
  } = props;

  return (
    <div className={`conflicts-resolution__column ${disabled ? 'disabled' : ''}`}>
      <div className="conflicts-resolution__column-content">
        {currentAction === 'manual' && (
          <label htmlFor={`manual-select-${id}`} className="template-manual-select">
            <input
              type="checkbox"
              name={`manual-select-${id}`}
              checked={selected}
              onChange={() => changeStrategyForCompnentHandler(id, strategy, type)}
            />
          </label>
        )}
        <div className="component-infos">
          <h5>
            {name}{' '}
            {type && (
              <span className={`conflicts-resolution__column-content-icon`}>
                <ItemIcon icon={type} />
              </span>
            )}
          </h5>
          <ul>
            <li>
              {type.split('_')[0]} ID: {id}
            </li>
          </ul>
          {parents?.length > 0 && (
            <details className={`conflicts-resolution__column-content-details`}>
              <summary>Used in Experience Templates:</summary>
              <ul>
                {parents.map(parent => (
                  <li key={parent}>{parent}</li>
                ))}
              </ul>
            </details>
          )}
        </div>
      </div>
    </div>
  );
};
export default function ConflictsComponentsResolution(props: ConflictsComponentsResolutionProps) {
  const { conflicts, filter, library, templatesPayload } = props;

  const [currentAction, setCurrentAction] = useState(''); //manual, skip, overwrite
  const [skippedContextPlugins, setSkippedContextPlugins] = useState<string[]>([]);

  const manageTemplatesActions = useActions(ManageTemplatesActions);
  const updateImportPayload = (templatesData: TemplateImportContent) => {
    manageTemplatesActions.setImportTemplatePayloadState(templatesData);
  };

  const componentHasConflicts = (componentID: string) =>
    !!conflicts?.componentConflicts?.find(t => t.id === componentID);

  const getParentIds = (componentId: string, type: string, parentComps?: any) => {
    let ids: string[] = [];
    const payload = parentComps || templatesPayload.components;
    switch (type) {
      case 'rule_template':
        ids = payload.relationships?.ruleTemplatesParents[componentId]?.journeyIDs || [];
        break;
      case 'push_template':
        ids = payload.relationships?.pushTemplatesParents[componentId]?.journeyIDs || [];
        break;
      case 'webhook_template':
        ids = payload.relationships?.webhookTemplatesParents[componentId]?.journeyIDs || [];
        break;
      case 'content_template_prototype':
        ids = payload.relationships?.contentTemplatePrototypesParents[componentId]?.journeyIDs || [];
        break;
      case 'content_template':
        ids = payload.relationships?.contentTemplatesParents[componentId]?.contentTemplatePrototypeIDs || [];
        break;
      case 'context_plugin':
        ids = payload.relationships?.contextPluginsParents[componentId]?.ruleTemplateIDs || [];
        break;
      default:
        break;
    }
    return ids;
  };
  // Return all parents names
  const getParentNames = (parents: string[]) => {
    return library.filter(tpl => parents.includes(tpl.id) && tpl.name).map(({ name = '' }) => name);
  };
  const areAllParentsSkipped = (parents: string[], type: string, updatedParents?: Components) => {
    const payload = updatedParents || templatesPayload.components;

    if ('context_plugin' === type) {
      return !!payload.ruleTemplates
        ?.filter(tpl => parents.includes(tpl.payload.id))
        .every(tpl => tpl.mergeStrategy === 'skip');
    }
    if ('content_template' === type) {
      return !!payload.contentTemplatePrototypes
        ?.filter(tpl => parents.includes(tpl.payload.id))
        .every(tpl => tpl.mergeStrategy === 'skip');
    }
    return !!templatesPayload.templates
      ?.filter(tpl => parents.includes(tpl.payload.id))
      .every(tpl => tpl.mergeStrategy === 'skip');
  };
  // Filter
  const filteredComponents = useCallback(() => {
    if (!templatesPayload) {
      return [];
    }
    // For filtering rules, refer to https://flybits.atlassian.net/browse/EC-204
    const filteredComponentsData: any[] = [];

    Object.entries(templatesPayload?.components)?.map(([type, componentData]) => {
      const allowedCompnents = [
        'ruleTemplates',
        'pushTemplates',
        'webhookTemplates',
        'contentTemplates',
        'contentTemplatePrototypes',
        'contextPlugins',
      ];
      let currentType = '';
      switch (type) {
        case 'ruleTemplates':
          currentType = 'rule_template';
          break;
        case 'pushTemplates':
          currentType = 'push_template';
          break;
        case 'webhookTemplates':
          currentType = 'webhook_template';
          break;
        case 'contentTemplates':
          currentType = 'content_template';
          break;
        case 'contentTemplatePrototypes':
          currentType = 'content_template_prototype';
          break;
        case 'contextPlugins':
          currentType = 'context_plugin';
          break;
        default:
          break;
      }
      if (componentData?.length && allowedCompnents.includes(type)) {
        componentData.map((comp: any) => {
          // has conflicts?
          if (componentHasConflicts(comp.id)) {
            // Check for skipped parents

            const parentIds = getParentIds(comp.id, currentType);
            if (!areAllParentsSkipped(parentIds, currentType)) {
              filteredComponentsData.push({ ...comp, type: currentType });
            }
          }
          return comp;
        });
      }
    });
    if (!filter) return filteredComponentsData || [];
    return (
      filteredComponentsData.filter(component => {
        return component.type === filter;
      }) || []
    );
    // eslint-disable-next-line
  }, [filter, templatesPayload]);

  const getAutomaticMergeStrategy = (
    hasConflict: boolean,
    exists: boolean,
    allParentsSkipped: boolean,
    isContextPlugin: boolean,
  ) => {
    if (allParentsSkipped) {
      return 'skip';
    }
    if (isContextPlugin) {
      if (hasConflict || exists) {
        return 'skip'; // alert user about this
      }
      return 'create_new'; // it doesn't exist so we create
    }
    // only !isContextPlugin from this point on
    return 'overwrite';
  };

  const getOriginalName = (componentID: string) =>
    conflicts?.componentConflicts?.find(t => t.id === componentID)?.originalName || '';

  const componentAlreadyExists = (componentID: string) =>
    componentHasConflicts(componentID) ||
    Object.values(conflicts?.componentsWithoutConflicts)?.some(componentData => componentData?.includes(componentID));

  const processComponent = (
    component: any,
    isContextPlugin: boolean,
    type: string,
    parentsData?: Components,
    currentAction = '',
  ) =>
    component?.map((c: any) => {
      const parentIds = getParentIds(c.id, type, parentsData);

      let strategy = getAutomaticMergeStrategy(
        componentHasConflicts(c.id),
        componentAlreadyExists(c.id),
        areAllParentsSkipped(parentIds, type, parentsData),
        isContextPlugin,
      );

      if ((componentHasConflicts(c.id) && !isContextPlugin) || !areAllParentsSkipped(parentIds, type, parentsData)) {
        // user decides
        if (currentAction === 'manual') {
          if (areAllParentsSkipped(parentIds, type, parentsData) || !componentHasConflicts(c.id)) {
            strategy = c.mergeStrategy || strategy;
          } else {
            strategy = '';
          }
        }
      }
      return {
        ...c,
        mergeStrategy: strategy,
      };
    }) || component;
  const addMergeStrategyToAllComponents = (componentsObject: Components, currentAction = '') => {
    const componentParents = {
      ...componentsObject,
      ruleTemplates: processComponent(componentsObject.ruleTemplates, false, 'rule_template', undefined, currentAction),
      contentTemplatePrototypes: processComponent(
        componentsObject.contentTemplatePrototypes,
        false,
        'content_template_prototype',
      ),
      pushTemplates: processComponent(componentsObject.pushTemplates, false, 'push_template', undefined, currentAction),
      webhookTemplates: processComponent(
        componentsObject.webhookTemplates,
        false,
        'webhook_template',
        undefined,
        currentAction,
      ),
    };
    const componentChildren = {
      contentTemplates: processComponent(
        componentParents.contentTemplates,
        false,
        'content_template',
        componentParents,
      ),
      contextPlugins: processComponent(
        componentParents.contextPlugins,
        true,
        'context_plugin',
        componentParents,
        currentAction,
      ),
    };

    return {
      ...componentsObject,
      ...componentParents,
      ...componentChildren,
    };
  };
  const changeAction = (method: string) => {
    setCurrentAction(method);
    updateImportPayload({
      name: templatesPayload.name,
      templates: templatesPayload.templates,
      components: addMergeStrategyToAllComponents(templatesPayload.components, method),
    });
  };
  useEffect(() => {
    // set default mergeStrategy to all components on first load
    if (templatesPayload) {
      updateImportPayload({
        name: templatesPayload.name,
        templates: templatesPayload.templates,
        components: addMergeStrategyToAllComponents(templatesPayload.components, 'default'),
      });

      setSkippedContextPlugins(
        conflicts?.componentConflicts?.filter(t => t.component === 'context_plugin')?.map(t => t.id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // set default mergeStrategy to all components on first load
    if (!currentAction) {
      if (templatesPayload) {
        if (!skippedContextPlugins || filter) return;
        const filteredComponentsLength = filteredComponents().length;
        if (filteredComponentsLength && filteredComponentsLength === skippedContextPlugins.length) {
          changeAction('skip');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredComponents]);

  const setCorrectStrategy = (oldMergeStrategy: string, newStrategy: string, isContextPlugin?: boolean) => {
    // current can be:
    // '' => not set
    // 'create_new' => both selected
    // 'overwrite' => left only
    // 'skip' => right only
    let correctStrategy = 'create_new';
    if (!oldMergeStrategy) correctStrategy = newStrategy;
    if (oldMergeStrategy === newStrategy) correctStrategy = '';
    if (oldMergeStrategy === 'create_new') {
      if (newStrategy === 'overwrite') correctStrategy = 'skip';
      correctStrategy = isContextPlugin ? '' : 'overwrite';
    }
    return correctStrategy;
  };
  const changeStrategyForComponent = useCallback(
    (componentId: string, strategy: string, type: string) => {
      if (!templatesPayload) return;
      if (!templatesPayload.components) return;
      let updatedComponentData = { ...templatesPayload.components };

      switch (type) {
        case 'rule_template':
          updatedComponentData = {
            ...updatedComponentData,
            ruleTemplates: updatedComponentData.ruleTemplates?.map(t => {
              if (t.id === componentId)
                return { ...t, mergeStrategy: setCorrectStrategy(t.mergeStrategy || '', strategy) };
              return t;
            }),
          };
          break;
        case 'push_template':
          updatedComponentData = {
            ...updatedComponentData,
            pushTemplates: updatedComponentData.pushTemplates?.map(t => {
              if (t.id === componentId)
                return { ...t, mergeStrategy: setCorrectStrategy(t.mergeStrategy || '', strategy) };
              return t;
            }),
          };
          break;
        case 'webhook_template':
          updatedComponentData = {
            ...updatedComponentData,
            webhookTemplates: updatedComponentData.webhookTemplates?.map(t => {
              if (t.id === componentId)
                return { ...t, mergeStrategy: setCorrectStrategy(t.mergeStrategy || '', strategy) };
              return t;
            }),
          };
          break;
        case 'context_plugin':
          updatedComponentData = {
            ...updatedComponentData,
            contextPlugins: updatedComponentData.contextPlugins?.map(t => {
              if (t.id === componentId) {
                return {
                  ...t,
                  mergeStrategy: setCorrectStrategy(t.mergeStrategy || '', strategy, true),
                };
              }
              return t;
            }),
          };
          break;
        case 'content_template':
          updatedComponentData = {
            ...updatedComponentData,
            contentTemplates: updatedComponentData.contentTemplates?.map(t => {
              if (t.id === componentId)
                return { ...t, mergeStrategy: setCorrectStrategy(t.mergeStrategy || '', strategy) };
              return t;
            }),
          };
          break;
        case 'content_template_prototype':
          updatedComponentData = {
            ...updatedComponentData,
            contentTemplatePrototypes: updatedComponentData.contentTemplatePrototypes?.map(t => {
              if (t.id === componentId)
                return { ...t, mergeStrategy: setCorrectStrategy(t.mergeStrategy || '', strategy) };
              return t;
            }),
          };
          break;
        default:
          break;
      }
      // now we update the children
      const componentChildren = {
        contentTemplates: processComponent(
          updatedComponentData.contentTemplates,
          false,
          'content_template',
          updatedComponentData,
        ),
        contextPlugins: processComponent(
          updatedComponentData.contextPlugins,
          true,
          'context_plugin',
          updatedComponentData,
        ),
      };
      updateImportPayload({
        name: templatesPayload.name,
        templates: templatesPayload.templates,
        components: { ...updatedComponentData, ...componentChildren },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templatesPayload],
  );

  return (
    <div className="conflicts-resolution__list">
      <div className="conflicts-resolution__title">
        <h4>
          <JSONIcon /> Components from JSON file:{' '}
          <strong title={templatesPayload?.name}>{templatesPayload?.name}</strong>
        </h4>
        <div className="conflict-list-item"></div>
        <h4>
          <ComputerIcon /> Components already in your <strong>Library</strong>
        </h4>
      </div>
      <div className="conflicts-resolution__templates">
        {/* Title */}

        {filteredComponents()?.map(({ mergeStrategy, type, id, payload: { name, Name } }) => {
          const parentNames = getParentNames(getParentIds(id, type));
          const correctname = name?.en || name || Name;
          const importData = {
            id,
            name: correctname,
            type: type.split('_')[0],
            parents: parentNames,
          };

          // get conflicts
          const conflictsData = conflicts?.componentConflicts?.find(t => t.id === id);
          return (
            <div key={correctname + type + id} className={`conflicts-resolution__row`}>
              <ListCard
                currentAction={currentAction}
                data={{
                  ...importData,
                  name: correctname + (mergeStrategy === 'create_new' && getOriginalName(id) ? '-1' : ''),
                }}
                strategy={type === 'context_plugin' ? 'create_new' : 'overwrite'}
                type={type}
                changeStrategyForCompnentHandler={changeStrategyForComponent}
                selected={mergeStrategy === 'overwrite' || mergeStrategy === 'create_new'}
                disabled={
                  currentAction === 'skip' ||
                  (currentAction === 'manual' && (mergeStrategy === '' || mergeStrategy === 'skip'))
                }
              />

              <ListCard
                data={{ ...importData, name: conflictsData?.originalName || correctname, parents: [] }}
                currentAction={currentAction}
                strategy={'skip'}
                type={type}
                changeStrategyForCompnentHandler={changeStrategyForComponent}
                selected={mergeStrategy === 'skip' || mergeStrategy === 'create_new'}
                disabled={
                  currentAction === 'overwrite' ||
                  (currentAction === 'manual' && (mergeStrategy === '' || mergeStrategy === 'overwrite'))
                }
              />
              {conflictsData && (
                <div className="conflict-list-item">
                  {getIcon('error', {})} <strong>Conflicts:</strong> {conflictsData?.conflicts?.join(', ') || ''}
                </div>
              )}
            </div>
          );
        })}
        {filteredComponents().length === 0 && !filter && (
          <h3 className="conflicts-resolution__templates-type-title">
            All components were skipped because their parents were skipped
          </h3>
        )}
        {skippedContextPlugins.length > 0 && (
          <h4 className="conflicts-resolution__templates-type-subtitle">
            These plugins will be automatically skipped because they contain conflicts:{' '}
            <strong>{skippedContextPlugins.join(', ')}</strong>
          </h4>
        )}
      </div>
      <div className="conflicts-resolution__actions">
        <FlightRadioButton
          label="Resolve manually"
          checked={currentAction === 'manual' ? true : false}
          className="conflicts-resolution__actions-item"
          onSelect={(method: string) => changeAction(method)}
          value={'manual'}
          disabled={filteredComponents().length === 0 && !filter}
        />
        <FlightRadioButton
          label="Replace all with new"
          checked={currentAction === 'overwrite' ? true : false}
          className="conflicts-resolution__actions-item"
          onSelect={(method: string) => changeAction(method)}
          value={'overwrite'}
          disabled={filteredComponents().length === 0 && !filter}
        />
        <FlightRadioButton
          label="Skip all new with same IDs"
          checked={currentAction === 'skip' ? true : false}
          className="conflicts-resolution__actions-item"
          onSelect={(method: string) => changeAction(method)}
          value={'skip'}
        />
      </div>
    </div>
  );
}
