import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/images/check-outline.svg';
import { ReactComponent as ClearIcon } from 'assets/images/clear-icon-outline-red.svg';
import './ValidationMessages.scss';

interface ValidationObject {
  message: string;
  isError: boolean;
}

interface ValidationMessagesProps {
  className?: string;
  validationObjects: ValidationObject[];
}

export default function ValidationMessages(props: ValidationMessagesProps) {
  const { className, validationObjects } = props;
  const MAIN_CLASS = 'validation-messages';

  return (
    <div className={MAIN_CLASS + (className ? ` ${className}` : '')}>
      {validationObjects.map(validation => (
        <div key={validation.message} className={`${MAIN_CLASS}__validation` + (validation.isError ? ' error' : '')}>
          {validation.isError ? (
            <ClearIcon className={`${MAIN_CLASS}__icon`} />
          ) : (
            <CheckIcon className={`${MAIN_CLASS}__icon`} />
          )}
          <span>{validation.message}</span>
        </div>
      ))}
    </div>
  );
}
