import React, { useState, useEffect } from 'react';
import './SilentPushSettings.scss';
import { SilentPushSettings } from 'model/pushSettings/settings';
import { cloneDeep } from 'lodash';
import * as SilentPushActions from 'actions/pushSettings/silentPushSettings';
import { useActions } from 'actions';
import { FlightToggleSwitch } from '@flybits/design-system';

interface Props {
  silentPushSettingsFromState?: SilentPushSettings;
}

export default function SilentPushSettingsCard(props: Props) {
  const { silentPushSettingsFromState } = props;
  const silentPushActions = useActions(SilentPushActions);
  const [subscribe, setSubscribe] = useState(false);
  const localSilentPushSettingsState = cloneDeep(silentPushSettingsFromState);
  const subscribed = localSilentPushSettingsState?.subscribed || false;

  useEffect(() => {
    setSubscribe(subscribed);
    //eslint-disable-next-line
  }, [subscribed]);

  const handleChange = () => {
    setSubscribe(!subscribe);
    silentPushActions.addOrUpdateSilentPushSettings(!subscribe);
  };

  return (
    <div className="silent-push-settings">
      <h2 className="silent-push-settings__header">Silent push notifications</h2>
      <div className="silent-push-settings__toggle">
        <FlightToggleSwitch title="Toggle" className="silent-push-settings__btn" checked={subscribe} label={subscribe ? "On" : "Off"} onChange={handleChange} />
      </div>
      <div className="silent-push-settings__description">
        Send silent push notifications where there are relevant content changes
      </div>
    </div>
  );
}
