export interface DatasourcesState {
  datasources: Datasource[] | undefined;
}

export interface Datasource {
  id: string;
  connectorId: string;
  name: string;
  description: string;
  imageUrl: string;
  documentationUrl: string;
  supportEmail: string;
  defaultPluginId: string;
  owner: {
    name: string;
    imageUrl: string;
    externalUrl: string;
    verified: boolean;
  };
  createdAt: number;
  updatedAt: number;
}

export interface GetDatasourcesParams {
  limit?: number;
  offset?: number;
  sort?: string;
  search?: string;
}

export enum DatasourceActionTypes {
  SET_DATASOURCES_STATE = 'SET_DATASOURCES_STATE',
}

interface DatasourceActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type DatasourceAction = DatasourceActionCreatorType<
  typeof DatasourceActionTypes.SET_DATASOURCES_STATE,
  Datasource
>;
