import { ContentTemplatesActions, ContentTemplatesActionTypes, ContentTemplateState } from 'model/contentTemplates';
import createReducer from './createReducer';

const initialState = {} as ContentTemplateState;

export const contentTemplates = createReducer<ContentTemplateState>(initialState, {
  [ContentTemplatesActionTypes.SET_CONTENT_TEMPLATES_STATE](
    state: ContentTemplateState,
    action: ContentTemplatesActions,
  ) {
    return { ...state, contentTemplates: action.payload };
  },
  [ContentTemplatesActionTypes.SET_SINGLE_CONTENT_TEMPLATE_STATE](
    state: ContentTemplateState,
    action: ContentTemplatesActions,
  ) {
    return { ...state, contentTemplate: action.payload };
  },
  [ContentTemplatesActionTypes.SET_CONTENT_TEMPLATE_LABELS_STATE](
    state: ContentTemplateState,
    action: ContentTemplatesActions,
  ) {
    return { ...state, contentTemplateLabels: action.payload };
  },
  [ContentTemplatesActionTypes.SET_CONTENT_TEMPLATE_SETTINGS_STATE](
    state: ContentTemplateState,
    action: ContentTemplatesActions,
  ) {
    return { ...state, contentTemplateSettings: action.payload };
  },
});
