import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Notifications from 'components/Shared/Notifications/Notifications';
import PushPage from 'pages/PushPage/PushPage';
import CombinedNavBar from 'components/CombinedNavBar/CombinedNavBar';
import AuthenticationPage from 'pages/AuthenticationPage/AuthenticationPage';
import AboutProjectPage from 'pages/AboutProjectPage/AboutProjectPage';
import ApiUsagePage from 'pages/ApiUsagePage/ApiUsagePage';
import ContentTemplatesPage from 'pages/ContentTemplatesPage/ContentTemplatesPage';
import CreateOrManageContentTemplate from 'pages/ContentTemplatesPage/CreateOrManageContentTemplate';
import AuthCallback from 'auth/components/AuthCallback';
import ContextAttributesPage from 'pages/ContextAttributesPage/ContextAttributesPage';
import WebhooksPage from 'pages/WebhooksPage/WebhooksPage';
import CreateOrManageWebhookPage from 'pages/WebhooksPage/CreateOrManageWebhookPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import PrivateRoute from 'Router/PrivateRoute';
import AnalyticsExportPage from 'pages/AnalyticsExportPage/AnalyticsExportPage';
import ChannelLanguagesPage from 'pages/ChannelLanguagesPage/ChannelLanguagesPage';
import ExternalAuthRedirect from 'auth/components/ExternalAuthRedirect';
import DatasourcesPage from 'pages/DatasourcesPage/DatasourcesPage';
import ManageTemplatesPage from 'pages/ManageTemplatesPage/ManageTemplatesPage';
import ServiceAccountsPage from 'pages/ServiceAccountsPage/ServiceAccountsPage';
import FlybitsLifeAppPage from 'pages/FlybitsLifeAppPage/FlybitsLifeAppPage';
import MakerCheckerPage from 'pages/MakerCheckerPage/MakerCheckerPage';

export function Routes() {
  return (
    <div>
      <Notifications />
      <Switch>
        <Route exact={true} path="/auth-callback/:projectId" component={AuthCallback} />
        <Route exact={true} path="/external-auth-redirect/:projectId" component={ExternalAuthRedirect} />
        <Route exact={true} path="/project" component={NotFoundPage} />
        <Route
          exact={true}
          path="/"
          component={() => {
            window.location.assign(process.env.REACT_APP_CONTROL_TOWER_ORGS_PAGE || '');
            return null;
          }}
        />
        <CombinedNavBar>
          <Switch>
            <PrivateRoute exact={true} path="/project/:id" component={AboutProjectPage} />
            <PrivateRoute exact={true} path="/project/:id/api-usage" component={ApiUsagePage} />
            <PrivateRoute exact={true} path="/project/:id/content-templates" component={ContentTemplatesPage} />
            <PrivateRoute
              exact={true}
              path="/project/:id/content-templates/:contentTemplatesId"
              component={CreateOrManageContentTemplate}
            />
            <PrivateRoute exact={true} path="/project/:id/context-attributes" component={ContextAttributesPage} />
            <PrivateRoute exact={true} path="/project/:id/push" component={PushPage} />
            <PrivateRoute exact={true} path="/project/:id/channel-languages" component={ChannelLanguagesPage} />
            <PrivateRoute exact={true} path="/project/:id/authentication" component={AuthenticationPage} />
            <PrivateRoute exact={true} path="/project/:id/webhooks" component={WebhooksPage} />
            <PrivateRoute exact={true} path="/project/:id/webhooks/:webhookId" component={CreateOrManageWebhookPage} />
            <PrivateRoute exact={true} path="/project/:id/analytics-export" component={AnalyticsExportPage} />
            <PrivateRoute exact={true} path="/project/:id/service-accounts" component={ServiceAccountsPage} />
            <PrivateRoute exact={true} path="/project/:id/experience-templates" component={ManageTemplatesPage} />
            <PrivateRoute exact={true} path="/project/:id/datasources" component={DatasourcesPage} />
            <PrivateRoute exact={true} path="/project/:id/flybits-life-app" component={FlybitsLifeAppPage} />
            <PrivateRoute exact={true} path="/project/:id/maker-checker" component={MakerCheckerPage} />
          </Switch>
        </CombinedNavBar>
      </Switch>
    </div>
  );
}
