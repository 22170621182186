import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { RootState } from 'reducers';
import * as ContentTemplatesActions from 'actions/contentTemplates';
import { useActions } from 'actions';
import { useHistory } from 'react-router-dom';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { layout } from 'constants/contentTemplatesLayout';
import ContentTemplatesList from 'components/ContentTemplateComponents/ContentTemplates';
import ConciergeCategoryFilters from 'components/ConciergeCategoryComponents/ConciergeCategoryFilters';
import './ContentTemplatesPage.scss';
interface Layout {
  id: string;
  value: string;
}

export default function ContentTemplatesPage() {
  const history = useHistory();
  const contentTemplatesActions = useActions(ContentTemplatesActions);
  const contentTemplatesFromState = useSelector((state: RootState) => state.contentTemplates.contentTemplates);
  async function fetchProjectDependencies() {
    await contentTemplatesActions.fetchContentTemplateSettings();
    await contentTemplatesActions.fetchContentTemplates({ limit: 1000000 });
  }

  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    fetchProjectDependencies();
    (document.getElementsByClassName('nav-link')?.[0]  as HTMLElement)?.focus();
    //eslint-disable-next-line
  }, []);

  const openContentTemplateBuilder = () => {
    if (
      !document.getElementsByClassName('flight-nav__sidebar')[0]?.classList.contains('flight-nav__sidebar--collapsed')
    ) {
      const element: HTMLElement = document.getElementsByClassName('flight-button')[0] as HTMLElement;
      element.click();
    }
    history.push('content-templates/create');
  };
  return (
    <div role="main" className="content-templates-page">
      <PageHeader title="Content Templates" isLarge={true} />
      <div className="content-templates-page__tabstrip">
        <div>
          <Row className="content-templates-page__tabstrip__container__tabs ">
            <Col>
              <Nav tabs>
                {layout.map((tab: Layout) => (
                  <NavItem key={tab.id}>
                    <NavLink role="tab" tabIndex={0} aria-selected={activeTab === tab.id} className={activeTab === tab.id ? 'active' : ''}
                             onKeyDown={(event) => {
                              if(event.key === "Enter"){
                                setActiveTab(tab.id);
                              }
                             }}
                             onClick={() => setActiveTab(tab.id)}>
                      <span className="content-templates-page__tabstrip__container__tabs__label">{tab.value}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
        </div>
        <TabContent className="content-templates-page__tabstrip__container__content" activeTab={activeTab}>
          <TabPane tabId="1">
            <ContentTemplatesList
              contentTemplates={contentTemplatesFromState}
              handleCreateContentTemplate={openContentTemplateBuilder}
            />
          </TabPane>
          <TabPane tabId="2">
            <ConciergeCategoryFilters />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
