import BaseService from './base.service';
import { TemplateCategories, TemplateImportContent } from 'model/manageTemplates';

export default class ManageTemplatesService extends BaseService {
  CURRENT_CONFIGS_PATH: string;
  CURRENT_PATH: string;
  CURRENT_STATUS: string;
  CURRENT_RESOURCE: string;
  CURRENT_RESOURCE_DRYRUN: string;
  CURRENT_LIBRARY: string;
  CURRENT_CATEGORIES_PATH: string;
  CURRENT_EXPORTS: string;

  constructor() {
    super();
    this.CURRENT_CONFIGS_PATH = 'sso/settings/configs';
    this.CURRENT_CATEGORIES_PATH = 'point-sol-categories';
    this.CURRENT_PATH = 'kernel/journey/';
    this.CURRENT_STATUS = 'importstatuses/fulltemplates?limit=1&sort=desc';
    this.CURRENT_LIBRARY = 'templates';
    this.CURRENT_RESOURCE = 'fulltemplates?dryrun=false';
    this.CURRENT_RESOURCE_DRYRUN = 'fulltemplates?dryrun=true';
    this.CURRENT_EXPORTS = 'exports/fulltemplates';
  }
  // Library
  async getTemplateLibrary(query?: string) {
    return this.request('GET', this.CURRENT_PATH + this.CURRENT_LIBRARY + query);
  }
  async getTemplateCategories() {
    return this.request('GET', `${this.CURRENT_CONFIGS_PATH}/${this.CURRENT_CATEGORIES_PATH}`);
  }
  async updateTemplateCategories(payload: TemplateCategories) {
    return this.request('POST', `${this.CURRENT_CONFIGS_PATH}`, payload);
  }

  // Import
  async getLatestImportTemplateStatus() {
    return this.request('GET', this.CURRENT_PATH + this.CURRENT_STATUS);
  }

  async requestTemplateImport(data: TemplateImportContent) {
    return this.request('POST', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }

  async checkTemplateForConflicts(data: TemplateImportContent) {
    return this.request('POST', this.CURRENT_PATH + this.CURRENT_RESOURCE_DRYRUN, data);
  }

  // Export
  async exportFullTemplates(data: { id: string }[]) {
    return this.request('POST', `${this.CURRENT_PATH}${this.CURRENT_EXPORTS}`, data);
  }
}
