import React, { createContext } from 'react';
import { FeatureFlagsContextValue } from './feature-flags.types';
import { useFeatureFlagsContextValue } from './useFeatureFlagsContext';

const featureFlagsDefaultValue: FeatureFlagsContextValue = {
  isLoading: false,
  featureFlags: {},
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>(featureFlagsDefaultValue);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FeatureFlagsProvider({ children }: { children: any }) {
  const featureFlagsValue = useFeatureFlagsContextValue();

  return <FeatureFlagsContext.Provider value={featureFlagsValue}>{children}</FeatureFlagsContext.Provider>;
}
