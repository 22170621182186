import React, { useEffect, useCallback, useState } from 'react';
import { FlightTextInput } from '@flybits/design-system';
import { ReactComponent as ClearIcon } from 'assets/images/clear-icon-outline.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import debounce from 'lodash/debounce';
import './SearchBar.scss';

interface Props {
  initialValue?: string;
  handleSearchTermChange(value: string): void;
  className?: string;
  width?: string;
  hasError?: boolean;
  errorMessage?: string;
  placeholderText?: string;
  labelId?: string;
  label?: string;
}

export default function SearchBar(props: Props) {
  const {
    initialValue,
    handleSearchTermChange,
    className,
    width,
    hasError,
    errorMessage,
    placeholderText,
    labelId,
    label,
  } = props;
  const [searchTerm, setSearchTerm] = useState(initialValue || '');
  const MAIN_CLASS = 'search-bar';

  useEffect(() => {
    setSearchTerm(initialValue || '');
  }, [initialValue]);

  const debouncedSearch = useCallback(
    debounce((value: string) => handleSearchTermChange(value), 500),
    [handleSearchTermChange]
  );

  function handleSearchInput(value: string) {
    setSearchTerm(value);
    debouncedSearch(value);
  }

  return (
    <div className={MAIN_CLASS + (className ? ` ${className}` : '')}>
      <FlightTextInput
        type="text"
        width={width}
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchInput(e.target.value)}
        hasError={hasError}
        errorMessage={errorMessage}
        placeholderText={placeholderText}
        labelId={labelId}
        isAriaAutocomplete
        label={label}
        isLabelAlwaysOn={label ? true : false}
      />
      {searchTerm?.length > 0 ? (
        <ClearIcon
          className={hasError ? `${MAIN_CLASS}__icon ${MAIN_CLASS}__icon--with-error` : `${MAIN_CLASS}__icon`}
          onClick={() => {
            handleSearchInput('');
          }}
        />
      ) : (
        <SearchIcon className={`${MAIN_CLASS}__icon`} />
      )}
    </div>
  );
}
