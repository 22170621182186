import React, { useCallback, useRef } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { FlightTextInput } from '@flybits/design-system';
import { FormGroup, Button } from 'reactstrap';
import { ReactComponent as ClearIcon } from 'assets/images/clear-icon.svg';
import './DoubleArrayFields.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data: any, nameData: any) => void;
  initialValues: { values: Array<string>; names: Array<string> };
  placeHolderText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preDefinedOptions?: any[];
  secondPlaceHolderText?: string;
  validationSchema?: {};
  removeListener?: boolean;
  isDisabled?: boolean;
  className?: string;
  hasError: (data: boolean) => void;
  headers?: { key?: string; value?: string };
}

interface ArrayForm {
  values: Array<string>;
  names: Array<string>;
}
export const DoubleArrayFields = (props: Props) => {
  const {
    callback,
    removeListener,
    initialValues,
    preDefinedOptions,
    placeHolderText,
    secondPlaceHolderText,
    isDisabled,
    hasError,
    validationSchema,
    className,
    headers,
  } = props;

  const onFormSubmit = (values: ArrayForm) => {
    // const removeStrays = values.values.filter(function(str: string) {
    //   return /\S/.test(str);
    // });
    // const removeStraysNames = values.names.filter(function(str: string) {
    //   return /\S/.test(str);
    // });
    callback(values.values, values.names);
  };

  function setUpdatedValue(values: ArrayForm) {
    onFormSubmit(values);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const secondInputRef = useRef<any>();
  if (preDefinedOptions?.length !== 0 && secondInputRef.current) {
    secondInputRef.current.onClick();
  }

  function handleDelete(values: ArrayForm, index: number) {
    values.values.splice(index, 1);
    values.names.splice(index, 1);
    setUpdatedValue(values);
  }

  const triggerAdd = () => {
    if (inputRef.current && inputRef.current?.onClick) {
      inputRef.current.onClick();
    }
  };

  const keyHandler = useCallback((event: { key: string }) => {
    if (event.key === "Enter" && !removeListener) {
      triggerAdd();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`double-array-fields${className ? ' ' + className : ''}`}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async () => onFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, setFieldError, touched, setFieldValue }) => (
          <div>
            <Form onBlur={() => setUpdatedValue(values)} onKeyDown={keyHandler}>
              <FormGroup>
                <FieldArray
                  name="values"
                  render={arrayHelpers => {
                    if (errors?.values) hasError(true);
                    else  hasError(false);

                    return (
                      <div>
                        <div>
                          <Button
                            type="button"
                            className="double-array-fields__btn-no-display"
                            ref={inputRef}
                            onClick={() => (errors.values ? null : arrayHelpers.push(''))}
                          />
                          <div className="double-array-fields__header">
                            <div className="double-array-fields__header__text">{headers?.key || 'Key'}</div>
                            <div className="double-array-fields__header__text">{headers?.value || 'Value'}</div>
                          </div>
                          {values.values && values.values.length > 0 ? (
                            <>
                              {values.values.map((_, index) => (
                                <div key={index} className="double-array-fields__field-array">
                                  <div
                                    className="flight-text-input__input__double_clear-icon"
                                    tabIndex={0}
                                    role="button"
                                    aria-label={"delete row " +  (index+1)}
                                    onKeyDown={(e:React.KeyboardEvent<HTMLElement>) => {
                                      if(e.key === "Enter") {
                                        if (index === 0 && values.values.length === 1) {
                                          if (values.values[0] === '') {
                                            return;
                                          } else {
                                            arrayHelpers.handleRemove(index);
                                            handleDelete(values, 0);
                                            arrayHelpers.push('');
                                          }
                                        } else {
                                          setFieldError(`values.${index}`, '');
                                          arrayHelpers.handleRemove(index);
                                          handleDelete(values, index);
                                        }
                                      }
                                    }}
                                    onClick={() => {
                                      if (index === 0 && values.values.length === 1) {
                                        if (values.values[0] === '') {
                                          return;
                                        } else {
                                          arrayHelpers.handleRemove(index);
                                          handleDelete(values, 0);
                                          arrayHelpers.push('');
                                        }
                                      } else {
                                        setFieldError(`values.${index}`, '');
                                        arrayHelpers.handleRemove(index);
                                        handleDelete(values, index);
                                      }
                                    }}
                                    >
                                    <ClearIcon/>
                                  </div>
                                  <div className="double-array-fields__container">
                                    <div className="double-array-fields__index-field-wrapper">
                                      <div className="double-array-fields__index-box">{index + 1}</div>
                                      <Field
                                        as={FlightTextInput}
                                        name={`values.${index}`}
                                        id={`values.${index}`}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                          handleChange(e);
                                          if (!touched.names) {
                                            setFieldValue(`names.${index}`, e.currentTarget.value);
                                          }
                                        }}
                                        className="double-array-fields__index-text-field"
                                        hasError={errors?.values?.[index] ? true : false}
                                        placeholderText={placeHolderText || ''}
                                        width="230px"
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <div className="double-array-fields__text-field">
                                      <Field
                                        as={FlightTextInput}
                                        name={`names.${index}`}
                                        id={`names.${index}`}
                                        isDisabled={isDisabled}
                                        onChange={handleChange}
                                        className="double-array-fields__second-field"
                                        placeholderText={secondPlaceHolderText || ''}
                                        width="230px"
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="double-array-fields__error">
                                {errors.values
                                  ?.toString()
                                  .replace(/,/g, '')
                                  .split('.', 1)}
                              </div>
                              <button
                                className="double-array-fields__add-field"
                                ref={inputRef}
                                tabIndex={0}
                                aria-label="add another row"
                                onKeyDown={(e:React.KeyboardEvent<HTMLElement>) => {
                                  if(e.key === "Enter") {
                                    for (const value of values.values) {
                                      if (!value[0]) {
                                        return;
                                      }
                                    }

                                    arrayHelpers.push('');
                                  }
                                }}
                                onClick={() => {
                                  for (const value of values.values) {
                                    if (!value[0]) {
                                      return;
                                    }
                                  }

                                  arrayHelpers.push('');
                                }}
                              >
                                + Add another
                              </button>
                            </>
                          ) : (
                            <button
                              className="double-array-fields__add-field"
                              ref={inputRef}
                              onClick={() => arrayHelpers.push('')}
                              tabIndex={0}
                              aria-label="add field"
                              onKeyDown={(e:React.KeyboardEvent<HTMLElement>) => {
                                if(e.key === "Enter") {
                                  arrayHelpers.push('')
                                }
                              }}
                            >
                              Add field
                            </button>
                          )}
                        </div>
                      </div>
                    )
                  }}
                />
              </FormGroup>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default DoubleArrayFields;
