import { CustomEmailActionTypes, CustomEmailActions, CustomEmail, CustomEmailHeaderLogo } from 'model/customEmail';
import createReducer from './createReducer';

export const customEmails = createReducer<CustomEmail[]>([], {
  [CustomEmailActionTypes.SET_CUSTOM_EMAIL_STATE](state: CustomEmail[], action: CustomEmailActions) {
    return [...state, action.payload];
  },

  [CustomEmailActionTypes.RESET_CUSTOM_EMAIL_STATE](state: CustomEmail[]) {
    return state.filter(item => !item);
  },
});

export const customEmailHeaderLogo = createReducer<CustomEmailHeaderLogo[]>([], {
  [CustomEmailActionTypes.SET_CUSTOM_EMAIL_HEADER_LOGO](state: CustomEmailHeaderLogo[], action: CustomEmailActions) {
    return [...state, action.payload];
  },

  [CustomEmailActionTypes.RESET_CUSTOM_EMAIL_HEADER_LOGO](state: CustomEmailHeaderLogo[]) {
    return state.filter(item => !item);
  },
});
