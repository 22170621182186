import axios from 'axios';
import LocalStore from 'services/localstore';
import { getCodeChallenge, getCodeVerifier, getFormUrlEncodedParams, getJWTDecoded } from 'auth/utils';

const localStore = new LocalStore();

const CONTROL_TOWER_API_URI = process.env.REACT_APP_CONTROL_TOWER_API || 'https://api.controltower.flybits.com';
const FLYBITS_CORE_URI = process.env.REACT_APP_HOST_URL || 'https://api.development.flybits.com';
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || 'developer.development.flybits.com';

export async function generateAuthCode(projectId: string) {
  const codeVerifier = getCodeVerifier();
  const codeChallenge = getCodeChallenge(codeVerifier);
  const origin = window.location.origin;
  const authRedirectUri = `${origin}/auth-callback/${projectId}`;

  await localStore.setItem('auth_redirect_uri', authRedirectUri);

  const params = {
    scope: 'openid',
    response_type: 'code',
    code_challenge_method: 'S256',
    client_id: CLIENT_ID,
    code_challenge: codeChallenge,
    redirect_uri: authRedirectUri,
    state: codeVerifier,
  };

  const authRequestUrl = new URL(`${CONTROL_TOWER_API_URI}/auth/request`);
  Object.entries(params).forEach(param => {
    authRequestUrl.searchParams.append(param[0], param[1]);
  });

  window.location.assign(authRequestUrl.href);
}

export async function generateIdToken(authCode: string | null, codeVerifier: string | null) {
  const authRedirectUri = await localStore.getItem('auth_redirect_uri');

  const tokenParams = {
    grant_type: 'authorization_code',
    client_id: CLIENT_ID,
    code_verifier: codeVerifier,
    code: authCode,
    redirect_uri: authRedirectUri,
  };

  return axios.post(`${CONTROL_TOWER_API_URI}/auth/token`, getFormUrlEncodedParams(tokenParams), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export async function oidcLogin(idToken: string) {
  const { aud } = getJWTDecoded(idToken);

  const payload = {
    projectId: aud,
    idToken,
    provider: 'flybits',
  };

  return await axios.post(`${FLYBITS_CORE_URI}/sso/auth/oidc`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

export async function getUserData(accessToken: string) {
  return await axios.get(`${FLYBITS_CORE_URI}/sso/auth/me`, {
    headers: {
      Accept: 'application/json',
      'X-Authorization': accessToken,
    },
  });
}
