import * as React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';

const { persistor, store } = configureStore();

export function ReduxRoot() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingIcon width={80} height={80} fullScreen visible />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}
