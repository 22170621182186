export const layout = [
  {
    id: '1',
    value: 'In-app templates',
  },
  {
    id: '2',
    value: 'Concierge category filters',
  },
];
