import React from 'react';
import { FlightButton } from '@flybits/design-system';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { ReactComponent as ContextAttributesEmptyStateImage } from 'assets/images/ctx-attributes-graphic.svg';
import './ContextAttributesEmptyState.scss';

interface TProps {
  handleCreateContextAttribute: () => void;
  handleCreateCategory: () => void;
}

export default function ContextAttributesEmptyState(props: TProps) {
  const { handleCreateContextAttribute, handleCreateCategory } = props;

  return (
    <div className="ctx-attributes-empty-state">
      <div className="ctx-attributes-empty-state__content">
        <PageHeader
          title="Context Attributes"
          isLarge={true}
          className="ctx-attributes-empty-state__content__page-header"
        />
        <span className="ctx-attributes-empty-state__content__description">
          Context attributes are data points about your customer or evironments around your customer that enable
          personalized exeperiences to be delivered.
        </span>
        <div className="ctx-attributes-empty-state__content__links">
          <FlightButton label="Create context attribute" theme="primary" onClick={handleCreateContextAttribute} />
          <FlightButton label="Create category" theme="secondary" onClick={handleCreateCategory} />
        </div>
      </div>
      <ContextAttributesEmptyStateImage />
    </div>
  );
}
