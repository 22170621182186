import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';
import { makeActionCreator } from 'helpers/actionCreator';
import { AnalyticsExportActionTypes, SettingsAPIPayload } from 'model/analyticsExport';
import SettingsService from 'services/settings.service';

const setWhitelistedDomains = makeActionCreator(AnalyticsExportActionTypes.SET_WHITELISTED_DOMAINS_STATE);

export function getWhitelistedDomains() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getAnalyticsExportWhitelistedDomains();
      dispatch(setWhitelistedDomains(response?.data?.settings?.key));
    } catch (error) {
      // @ts-expect-error: TODO: fix the typescript error properly
      if (error.response.status === 404) {
        // @ts-expect-error: TODO: fix the typescript error properly
        const errorObjectFromResponse = error.response.data.error.exceptionType;
        if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
          dispatch(setWhitelistedDomains([]));
        }
      }
    }

    return 'done';
  };
}

export function updateWhitelistedDomains(tenantId: string, whitelistedDomains: string[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();

      const settingsAPIPayload: SettingsAPIPayload = {
        configName: {
          configName: 'analyticsExportEmailDomainWhitelist',
          tenant: {
            tenantId,
          },
        },
        settings: {
          key: whitelistedDomains,
        },
      };

      await req.updateAnalyticsExportWhitelistedDomains(settingsAPIPayload);
      dispatch(getWhitelistedDomains());
    } catch (error) {}

    return 'done';
  };
}
