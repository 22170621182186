import { useState, useEffect } from 'react';
import * as ContentTemplatesActions from 'actions/contentTemplates';
import { useActions } from 'actions';

const useLabelsInfiniteScroll = (labelName: string, page: number) => {
  const contentTemplatesActions = useActions(ContentTemplatesActions);
  const [options, setOptions] = useState<string[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const limit = 15;

  useEffect(() => {
    const handleLabelChange = async () => {
      if (labelName && !options.length) return;
      const response = await contentTemplatesActions.fetchContentTemplateLabels(labelName, limit, 0);

      if (!response?.data) {
        return;
      }

      const labels = response.data.data;
      const newLabels = labels.filter((label: string) => label.startsWith(labelName));
      setOptions(newLabels);
      const length = response.data.pagination.totalRecords;
      setTotalPage(Math.ceil(length / limit) - 1);
      setCurrentPage(1);
    };
    handleLabelChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelName]);

  useEffect(() => {
    const handleDropdownScroll = async () => {
      if (totalPage && currentPage > totalPage) return;
      if (currentPage > 0) {
        const response = await contentTemplatesActions.fetchContentTemplateLabels(
          labelName,
          limit,
          limit * currentPage,
        );
        
        if (!response?.data) {
          return;
        }

        const labels = response.data.data;
        const newLabels = labels.filter((label: string) => label.startsWith(labelName));
        setOptions(options => [...options, ...newLabels]);
        setCurrentPage(currentPage => currentPage + 1);
      }
    };
    handleDropdownScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return [options];
};

export default useLabelsInfiniteScroll;
