import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { FlightSelectSearchable, getIcon } from '@flybits/design-system';
import { ReactComponent as ClearIcon } from 'assets/images/clear-icon-outline.svg';
import { Rule } from 'model/webhook';
import './EventTrigger.scss';

interface Props {
  iconName: string;
  eventText: string[];
  selectedRules: Rule[];
  rulesFromState: Rule[];
  showContentTemplateText?: boolean;
  handleSelectRule: (rule: Rule) => void;
  handleRemoveRule: (ruleId: string) => void;
}

interface SelectOptionProps {
  key: string;
  name: string;
}

export default function EventTrigger(props: Props) {
  const {
    iconName,
    eventText,
    selectedRules,
    rulesFromState,
    showContentTemplateText,
    handleSelectRule,
    handleRemoveRule,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [contextRuleOptions, setContextRuleOptions] = useState<SelectOptionProps[]>([]);

  function handleOptionClick(option: SelectOptionProps) {
    const name = option?.name?.substring(0, option.name.indexOf('\n'));
    handleSelectRule({ id: option?.key, name: name });
  }

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 500),
    [],
  );

  function updateFilteredOptions() {
    const updatedOptions: SelectOptionProps[] = rulesFromState
      .filter(
        rule =>
          !selectedRules.find(selectedRule => selectedRule.id === rule.id) &&
          rule.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .map(rule => ({
        key: rule.id,
        name: rule.name + '\n' + rule.id,
      }));
    setContextRuleOptions(updatedOptions);
  }

  useEffect(() => {
    updateFilteredOptions();
    //eslint-disable-next-line
  }, [selectedRules, searchTerm]);

  return (
    <div className="event-trigger">
      {getIcon(iconName, { className: 'event-trigger__event-icon' })}
      <span className="event-trigger__event-text" tabIndex={0} aria-label={eventText?.join()}>
        {eventText.map((text, index) => {
          const formattedText = `${index > 0 ? ' ' : ''}${text}`;
          return index % 2 === 0 ? <span key={index}>{formattedText}</span> : <b key={index}>{formattedText}</b>;
        })}
      </span>
      <div className="event-trigger__rules">
        {selectedRules.map(rule => (
          <div key={rule.id} className="event-trigger__rule">
            <div className="event-trigger__rule__info">
              <span className="event-trigger__rule__name">{rule.name}</span>
              <span className="event-trigger__rule__id">{rule.id}</span>
            </div>
            <ClearIcon className="event-trigger__clear-icon" onClick={() => handleRemoveRule(rule.id)} />
          </div>
        ))}
        <FlightSelectSearchable
          className={`event-trigger__rule-select${selectedRules.length > 0 ? ' has-selected-rules' : ''}`}
          label={showContentTemplateText ? 'Select content templates' : 'Select context rules'}
          options={contextRuleOptions}
          handleOptionClick={handleOptionClick}
          handleSearch={debouncedSearch}
        />
      </div>
    </div>
  );
}
