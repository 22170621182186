export interface ServiceAccount {
  id: string;
  name: string;
  description?: string;
  createdAt: string;
}

export type ServiceAccountState = ServiceAccount[];

export interface GetServiceAccountsParams {
  limit?: number;
  offset?: number;
  id?: string;
  search?: string;
  sort?: string;
}

export interface GetServiceAccountsResponse {
  data: ServiceAccount[];
  pagination: {
    totalRecords: number;
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: string;
  };
}

export interface CreateServiceAccountResponse {
  secret: string;
  serviceAccountId: string;
}

export enum ServiceAccountActionTypes {
  SET_SERVICE_ACCOUNTS_STATE = 'SET_SERVICE_ACCOUNTS_STATE',
}

interface ServiceAccountCreatorType<T, P> {
  type: T;
  payload: P[];
}

export type ServiceAccountAction = ServiceAccountCreatorType<
  typeof ServiceAccountActionTypes.SET_SERVICE_ACCOUNTS_STATE,
  ServiceAccount
>;
