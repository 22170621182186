/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { getCurrentProjectId } from 'helpers/getCurrentProjectId';
import LocalStore from './localstore';

const localStore = new LocalStore();

const AXIOS_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  timeout: 100000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// For Requests
AXIOS_SERVICE.interceptors.request.use(
  async function(config) {
    const projectId = getCurrentProjectId();
    config.headers['X-Authorization'] = await localStore.getItem(`token+${projectId}`);

    return config;
  },
  function(error) {
    return error;
  },
);

// For Responses
AXIOS_SERVICE.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    const controlTowerOrgsPage = process.env.REACT_APP_CONTROL_TOWER_ORGS_PAGE;

    if (controlTowerOrgsPage) {
      window.location.assign(controlTowerOrgsPage);
    }
  }

  return Promise.reject(error);
});

class BaseService {
  axios: any;
  constructor() {
    this.axios = AXIOS_SERVICE;
  }
  get<T = any, R = AxiosResponse<T>>(endpoint: string, data: any): Promise<R> {
    return AXIOS_SERVICE.get(endpoint, data);
  }

  post<T = any, R = AxiosResponse<T>>(endpoint: string, data: any): Promise<R> {
    return AXIOS_SERVICE.post(endpoint, data);
  }

  put<T = any, R = AxiosResponse<T>>(endpoint: string, data: any): Promise<R> {
    return AXIOS_SERVICE.put(endpoint, data);
  }

  patch<T = any, R = AxiosResponse<T>>(endpoint: string, data: any): Promise<R> {
    return AXIOS_SERVICE.patch(endpoint, data);
  }

  delete<T = any, R = AxiosResponse<T>>(endpoint: string, data: any): Promise<R> {
    return AXIOS_SERVICE.delete(endpoint, data);
  }

  request<T = any, R = AxiosResponse<T>>(type: any, url: string, data?: any): Promise<R> {
    let promise = null;
    switch (type) {
      case 'GET':
        promise = this.get<T, R>(url, { params: data });
        break;
      case 'POST':
        promise = this.post<T, R>(url, data);
        break;
      case 'PUT':
        promise = this.put<T, R>(url, data);
        break;
      case 'PATCH':
        promise = this.patch<T, R>(url, data);
        break;
      case 'DELETE':
        promise = this.delete<T, R>(url, { params: data });
        break;
      default:
        promise = this.get<T, R>(url, { params: data });
        break;
    }
    return promise;
  }
}

export default BaseService;
