import React, { useContext, useEffect, useState } from 'react';
import LocalStore from 'services/localstore';
import { AuthContext } from 'auth/providers/AuthProvider';
import { cleanUpLocalForage } from 'auth/utils';
import { useHistory } from 'react-router-dom';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';

const localStore = new LocalStore();

type TProps = {
  match: {
    params: { projectId: string };
  };
};

function AuthCallback({
  match: {
    params: { projectId },
  },
}: TProps) {
  const { generateIdToken, oidcLogin, setIsAuthenticated, setUserData, setToken } = useContext(AuthContext);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  const authCode = urlParams.get('code');
  const codeVerifier = urlParams.get('state');
  const error = urlParams.get('error');

  useEffect(() => {
    if (!authCode || error) {
      /* TODO: ashuflybits - Make error handling specific to error message after API implements the same */
      setIsError(true);
    } else {
      (async () => {
        try {
          const {
            data: { id_token: idToken },
          } = await generateIdToken(authCode, codeVerifier);

          if (idToken) {
            const { data, headers } = await oidcLogin(idToken);

            const accessToken = headers['x-authorization'];
            await localStore.setItem(`token+${projectId}`, accessToken);

            setToken(accessToken);
            setUserData(data);
            setIsAuthenticated(true);

            const gotoPathAfterAuth = await localStore.getItem('goto_path_after_auth');
            history.push(gotoPathAfterAuth);
          } else {
            throw new Error();
          }
        } catch (error) {
          await cleanUpLocalForage(`token+${projectId}`, 'goto_path_after_auth', 'auth_redirect_uri', 'id_token');

          setIsError(true);
        }
      })();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <LoadingIcon width={80} height={80} fullScreen visible={!isError} />
      {isError && <NotFoundPage isError />}
    </>
  );
}

export default AuthCallback;
