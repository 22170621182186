import React, { useContext, useRef } from 'react';
import { AuthContext } from 'auth/providers/AuthProvider';
import './MakerCheckerPage.scss';

const appURL = process.env.REACT_APP_EXPERIENCE_BUILDER + '/#/manage/reviewers';
const host = process.env.REACT_APP_HOST_URL;

export default function MakerCheckerPage() {
  const { token } = useContext(AuthContext);
  const frameRef = useRef(null);

  function loadHandler(evt: any){
    const frame = frameRef?.current ? (frameRef.current as HTMLIFrameElement) : null;
    if (
      evt.source !== frame?.contentWindow ||
      evt.origin !== process.env.REACT_APP_EXPERIENCE_BUILDER ||
      (!evt?.data?.flb && evt?.data?.type !== 'flb.makerchecker.ready')
    ) {
      return;
    }

    if (!frame || !token) {
      console.error('> Missing required frame inputs', frame, token?.slice(token?.length - 5));
      return;
    }
    frame.contentWindow?.postMessage(
      {
        flb: true,
        type: 'flb.session',
        token: token,
        host: host,
      },
      appURL,
    );
    
    window.removeEventListener('message', loadHandler);
  };

  window.addEventListener('message', loadHandler);
  return (
    <div role="main" className="maker-checker-page">
      <iframe src={appURL} ref={frameRef} className="app-frame"></iframe>
    </div>
  );
}
