import { isEmpty } from 'lodash';

interface ValueOptions {
  displayName: string;
  value: string;
}

export const isFreeForm = (valueOpts?: ValueOptions[]) => {
  //dp 1.0 creates freeform fields with blank value options
  if (isEmpty(valueOpts)) return true;
  if (valueOpts && valueOpts.length === 1) {
    return valueOpts.length === 1 && valueOpts[0]?.value === '';
  } else if (!isEmpty(valueOpts)) {
    return false;
  }
};

export const getCtxInputType = (type: string, options?: ValueOptions[], timeContext?: boolean) => {
  switch (type) {
    case 'string':
      if (isFreeForm(options)) {
        return 'String';
      }
      return 'String dropdown';
    case 'int':
      if (isFreeForm(options)) {
        return 'Integer';
      } else if (timeContext) {
        return 'Date time';
      } else {
        return 'Integer dropdown';
      }
    case 'float':
      if (isFreeForm(options)) {
        return 'Float';
      }
      return 'Float dropdown';
    case 'bool':
      if (isFreeForm(options)) {
        return 'Yes/No';
      }
      return 'Yes/No dropdown';
    default:
      return type;
  }
};
