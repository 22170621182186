import { Attribute, ContextPlugin } from 'model/contextPlugins';

export function getCtxCategoryFromString(ctxString: string) {
  // https://stackoverflow.com/questions/53014806/using-regex-to-extract-from-second-period-to-end-of-a-string
  // this function is used to retrieve category when passed uid or id of a ctx attribute
  const regex = /^(?:.*?\.){2}(.*)$/gm;
  let m;
  let n;
  while ((m = regex.exec(ctxString)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    n = m[1]?.split('.')?.[0];
  }
  return n;
}

export function getCtxAttributeFromString(ctxString: string) {
  //check if ctxString points to parameterized context
  const _isParameterizedContext = ctxString.includes('query');
  const regex = _isParameterizedContext ? /^(?:.*?\.){4}(.*)$/gm : /^(?:.*?\.){3}(.*)$/gm;
  let m;
  let n;
  while ((m = regex.exec(ctxString)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    n = m[1]?.split('.')?.[0];
  }
  return n;
}

export function getCtxPluginFromAttribute(ctxAttribute: Attribute, ctxPlugins: ContextPlugin[]) {
  const ctxAttributeUid = ctxAttribute.uid;
  const lastIndexOfDot = ctxAttributeUid?.lastIndexOf('.');

  let ctxPluginUid = ctxAttributeUid?.slice(0, lastIndexOfDot);
  const lastIdxOfDot = ctxPluginUid.lastIndexOf('.');
  const lastPartOfCtxPluginUid = ctxPluginUid?.slice(lastIdxOfDot + 1);

  // Check if query then remove it from the uid
  if (lastPartOfCtxPluginUid.toLowerCase() === 'query') {
    ctxPluginUid = ctxPluginUid?.slice(0, lastIdxOfDot);
  }

  return ctxPlugins?.find(ctxPlugin => ctxPlugin.uid === ctxPluginUid);
}
