/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Attribute {
  id: string;
  uid: string;
  valueType: string;
  valueTypeOptions: any[];
  valueKeys: any[];
  defaultExpDuration: number;
  inputAttrExpiryRate?: number;
  inputAttrExpiryRateUnit?: string;
  isNeverExpires: boolean;
  name: string;
  description: string;
  parameters?: any[] | null;
  isTenantScoped: boolean;
  isTimeContext: boolean;
  shouldBeSaved: boolean;
  isShowValue: boolean;
  isShowDisplayName: boolean;
  remapping?: Remapping;
  isView?: boolean; //FE only property
  isCreate?: boolean; //FE only property
  category?: string; //FE only property
  isReserved?: boolean; //FE only property
  scope?: string; //FE only property
}

export interface Attributes {
  [name: string]: Attribute;
}

export interface Remapping {
  from: string[];
  to: object;
}
export interface TenantConfig {
  id: string;
  tenantId: string;
  contextPluginId: string;
  isEnabled: boolean;
  refreshRate: number;
}

export interface ContextPlugins {
  uid: string;
  id: string;
  tenantId: string;
  isReserved: boolean;
  refreshRate: number;
  supportedPlatforms: Array<string>;
  provider: string;
  category: string;
  userScope: boolean;
  isSaRestricted: boolean;
  name: string;
  description: string;
  iconUrl: string;
  values: Attributes;
  tenantConfig: TenantConfig;
  dataSource: string;
  isCreateAttribute?: boolean;
  isViewAttribute?: string;
}

export interface ContextPlugin {
  uid: string;
  id: string;
  isEnabled?: boolean;
  tenantId: string;
  isReserved: boolean;
  refreshRate: number;
  supportedPlatforms: Array<string>;
  provider?: string;
  category: string;
  userScope: boolean;
  isSaRestricted?: boolean;
  name: string;
  description: string;
  iconUrl: string;
  values: Attributes;
  tenantConfig: TenantConfig;
  dataSource?: string;
  isPreview: boolean;
  isViewAttribute?: string;
  isChanged?: boolean;
  isCreateAttribute?: boolean;
  isView: boolean; //FE only property
  isCreate?: boolean; //FE only property
}

export type TPreFilterCategory = {
  key: string;
  name: string;
};

export interface ContextPluginAttributes {
  tenantId?: string;
  uniqueId?: string;
  id?: string;
  uid?: string;
  isReserved?: boolean;
  projectTokenForApiCall?: string;
  refreshRate: number;
  supportedPlatforms: Array<string>;
  provider?: string;
  category: string;
  userScope: boolean;
  isSaRestricted?: boolean;
  name: string;
  tenantConfig?: TenantConfig;
  dataSource?: string;
  description: string;
  iconUrl: string;
  values: Attributes;
  tenantConfigId?: string;
  token?: any;
  projectId?: any;
  isEnabled?: boolean;
}

export interface ContextPluginData {
  id: string;
  attributes: ContextPluginAttributes;
  type: string;
}

export interface ContextPluginsDownload {
  data: ContextPluginData[];
  meta: {
    downloadedAt: number;
    totalRecords: number;
  };
}

export interface Parameter {
  index: number;
  key: string;
  valueTypeOptions: any[];
  valueKeys: any;
  valueType: string;
  isTenantScoped: boolean;
  shouldBeSaved: boolean;
  isTimeContext: boolean;
  performsExtraProcessing: boolean;
  extraProcessingAddress: string;
}

export interface CreateContextPlugin {
  uid?: string;
  id?: string;
  isEnabled: boolean;
  projectId?: string;
  token?: Promise<unknown>;
  isReserved?: boolean;
  refreshRate: number;
  refreshRateUnit?: string;
  supportedPlatforms: Array<string>;
  category: string;
  userScope: boolean;
  name: string;
  description: string;
  iconUrl: string;
  values?: Attributes;
}

export interface ContextPluginValueRemappingData {
  op: 'add' | 'remove' | string;
  fromId: string;
  toIds: string[];
}

export enum ContextPluginsActionTypes {
  SET_CONTEXT_PLUGINS_STATE = 'SET_CONTEXT_PLUGINS_STATE',
  RESET_CONTEXT_PLUGINS_STATE = 'RESET_CONTEXT_PLUGINS_STATE',
  SET_CONTEXT_PLUGIN_STATE = 'SET_CONTEXT_PLUGIN_STATE',
  RESET_CONTEXT_PLUGIN_STATE = 'RESET_CONTEXT_PLUGIN_STATE',
  SET_CONTEXT_PLUGIN_ATTRIBUTES = 'SET_CONTEXT_PLUGIN_ATTRIBUTES',
  SET_CREATE_ATTRIBUTE = 'SET_CREATE_ATTRIBUTE',
  SET_VIEW_ATTRIBUTE = 'SET_VIEW_ATTRIBUTE',
  SET_CHANGE_PLUGIN = 'SET_CHANGE_PLUGIN',
}

interface ContextPluginsActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export interface ContextPluginState {
  contextPlugin: ContextPlugin;
  contextPlugins: ContextPlugin[];
}

export type ContextPluginsActions =
  | ContextPluginsActionCreatorTypes<typeof ContextPluginsActionTypes.SET_CONTEXT_PLUGINS_STATE, ContextPlugin>
  | ContextPluginsActionCreatorTypes<typeof ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_STATE, ContextPlugin>
  | ContextPluginsActionCreatorTypes<typeof ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_ATTRIBUTES, Attributes>
  | ContextPluginsActionCreatorTypes<typeof ContextPluginsActionTypes.SET_CREATE_ATTRIBUTE, ContextPlugin>
  | ContextPluginsActionCreatorTypes<typeof ContextPluginsActionTypes.SET_VIEW_ATTRIBUTE, ContextPlugin>
  | ContextPluginsActionCreatorTypes<typeof ContextPluginsActionTypes.SET_CHANGE_PLUGIN, ContextPlugin>;
