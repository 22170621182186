import React from 'react';
import { FlightButton, FlightModal } from '@flybits/design-system';
import { ReactComponent as Upload } from 'assets/images/upload.svg';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  handleUploadServiceAccount: () => void;
}

export default function FCMV1SettingsModal(props: Props) {
  const { showModal, closeModal, handleUploadServiceAccount: handleUploadCertificate } = props;

  function handleCloseModal() {
    closeModal();
  }

  const addNewSettingContent = (
    <div>
      <div className="dropzone" onClick={handleUploadCertificate}>
        <div className="dropzone__content">
          <Upload />
          <span className="dropzone__text">Upload account</span>
        </div>
      </div>
      <span className="apns-settings-modal__certificate-req">Only .json files are accepted</span>
    </div>
  );

  const header = 'New FCM Service Account';

  const footer = (
    <div className="modal-footer">
      <FlightButton theme="secondary" label="Cancel" onClick={handleCloseModal} />
    </div>
  )

  return (
    <div className="apns-settings-modal">
      <FlightModal
        isVisible={showModal}
        className={'apns-settings-modal__modal'}
        toggleModalShown={handleCloseModal}
        header={header}
        content={addNewSettingContent}
        footer={footer}
      />
    </div>
  );
}
