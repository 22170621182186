const ACCOUNT_DOMAINS_SETTINGS_WARNING = 'Changing this setting will affect what user accounts are valid.';
const API_KEY_DELETION_WARNING =
  'Once deleted, the API key cannot be restored. Any apps that are using the will not be able to access Flybits.';
const DEFAULT_LANGUAGE_WARNING = 'Deleting languages will erase localized content for that language.';
const PUSH_TEMPLATES_INFO =
  'Push templates allow you to specify custom fields beyond the base title & body for your push messages in Experience Studio.';
const CONTENT_TEMPLATE_DUPLICATION =
  'NOTE: You will only be copying the structure/layout of the Content templates, not their associated data.';
export {
  ACCOUNT_DOMAINS_SETTINGS_WARNING,
  API_KEY_DELETION_WARNING,
  CONTENT_TEMPLATE_DUPLICATION,
  DEFAULT_LANGUAGE_WARNING,
  PUSH_TEMPLATES_INFO,
};
