export const EXPECTED_SCHEMA = {
  type: 'object',
  title: 'Import Schema',
  required: ['name', 'templates'],
  properties: {
    name: {
      type: 'string',
      title: 'The name field',
    },
    templates: {
      type: 'array',
      title: 'The templates field',
      items: {
        type: 'object',
        title: 'A field',
        required: ['payload'],
        properties: {
          payload: {
            type: 'object',
            title: 'The payload field',
            required: ['id', 'name', 'steps', 'audienceOptimizationEligible', 'createdAt', 'updatedAt', 'metadata'],
            properties: {
              id: {
                type: 'string',
                title: 'The id field',
              },
              name: {
                type: 'string',
                title: 'The name field',
              },
              desc: {
                type: 'string',
                title: 'The description field',
              },
              categories: {
                type: 'array',
                items: {
                  type: 'string',
                },
                title: 'The categories field',
              },
              imageUrl: {
                type: 'string',
                title: 'The image field',
              },
              steps: {
                type: 'array',
                title: 'The steps field',
                items: {
                  type: 'object',
                  title: 'A Step',
                  required: ['audience', 'actions'],
                  properties: {
                    audience: {
                      type: 'object',
                      title: 'The audience field',
                      required: [],
                    },
                    actions: {
                      type: 'array',
                      title: 'The actions field',
                      items: {
                        type: 'object',
                        title: 'A field',
                        required: ['templateId', 'actionType'],
                        properties: {
                          refId: {
                            type: 'string',
                            title: 'The refId field',
                          },
                          templateId: {
                            type: 'string',
                            title: 'The templateId field',
                          },
                          actionType: {
                            type: 'string',
                            title: 'The actionType field',
                          },
                        },
                      },
                    },
                  },
                },
              },
              audienceOptimizationEligible: {
                type: 'boolean',
                title: 'The audienceOptimizationEligible field',
              },
              createdAt: {
                type: 'integer',
                title: 'The createdAt field',
              },
              updatedAt: {
                type: 'integer',
                title: 'The updatedAt field',
              },
              metadata: {
                type: 'object',
                title: 'The metadata field',
                nullable: true,
              },
            },
          },
        },
      },
    },
  },
  additionalProperties: false,
};
