import BaseService from './base.service';
import { CreateContextPlugin, ContextPlugin, ContextPluginValueRemappingData } from 'model/contextPlugins';
import { RuleForwardingData } from 'model/webhook';

interface GetContextPlugins {
  limit: number;
}

export default class ContextService extends BaseService {
  CURRENT_PATH: string;
  CONTEXT_PLUGINS_RESOURCE: string;
  CONTEXT_PLUGIN_VALUE_REMAPPING_RESOURCE: string;
  CONTEXT_RULES_RESOURCE: string;
  CONTEXT_RULES_FORWARDING_RESOURCE: string;

  constructor() {
    super();
    this.CURRENT_PATH = 'context/';
    this.CONTEXT_PLUGINS_RESOURCE = 'ctxschema/contextplugins';
    this.CONTEXT_PLUGIN_VALUE_REMAPPING_RESOURCE = 'ctxschema/contextpluginvalues/remapping';
    this.CONTEXT_RULES_RESOURCE = 'rules';
    this.CONTEXT_RULES_FORWARDING_RESOURCE = '/forwarding';
  }

  async getAllContextPlugins(params: GetContextPlugins) {
    return this.request('GET', this.CURRENT_PATH + this.CONTEXT_PLUGINS_RESOURCE, params);
  }
  async getContextPlugin(contextPluginId: string) {
    return this.request('GET', this.CURRENT_PATH + this.CONTEXT_PLUGINS_RESOURCE + `/${contextPluginId}`);
  }
  async createContextPlugin(data: CreateContextPlugin) {
    return this.request('POST', this.CURRENT_PATH + this.CONTEXT_PLUGINS_RESOURCE, data);
  }
  async deleteContextPlugin(id: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.CONTEXT_PLUGINS_RESOURCE + '/' + id);
  }
  async updateContextPlugin(data: ContextPlugin) {
    return this.request('PUT', this.CURRENT_PATH + this.CONTEXT_PLUGINS_RESOURCE, data);
  }
  async disableContextPluginStatus(data: ContextPlugin) {
    return this.request('PUT', this.CURRENT_PATH + this.CONTEXT_PLUGINS_RESOURCE + '/' + data.id + '/disable', data);
  }
  async enableContextPluginStatus(data: ContextPlugin) {
    return this.request('PUT', this.CURRENT_PATH + this.CONTEXT_PLUGINS_RESOURCE + '/' + data.id + '/enable', data);
  }
  async getAllContextRules() {
    return this.request('GET', this.CURRENT_PATH + this.CONTEXT_RULES_RESOURCE);
  }
  async updateRuleForwarding(ruleId: string, data: RuleForwardingData[]) {
    return this.request(
      'PATCH',
      this.CURRENT_PATH + this.CONTEXT_RULES_RESOURCE + '/' + ruleId + this.CONTEXT_RULES_FORWARDING_RESOURCE,
      data,
    );
  }
  async updateContextPluginValueRemapping(data: ContextPluginValueRemappingData[]) {
    return this.request('PATCH', this.CURRENT_PATH + this.CONTEXT_PLUGIN_VALUE_REMAPPING_RESOURCE, data);
  }
}
