/* eslint-disable @typescript-eslint/no-explicit-any */
export function searchWithRegExp(array: any, keyword: string) {
  const regExp = new RegExp(keyword, 'gi');
  const check = (obj: any) => {
    if (obj !== null && typeof obj === 'object') {
      return Object.values(obj).some(check);
    }
    if (Array.isArray(obj)) {
      return obj.some(check);
    }
    return typeof obj === 'string' && regExp.test(obj);
  };
  return array.filter(check);
}
