import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import './AppConfigurations.scss';
import ApnsSettingsCard from '../ApnsSettings/ApnsSettings';
import FcmSettingCard from '../FcmSettings/FcmSettings';
import HuaweiSettingCard from '../HuaweiSettings/HuaweiSettings';
import FcmV1SettingCard from '../FcmV1Settings/FcmV1Settings';
import { RootState } from 'reducers';
import SilentPushSettingsCard from '../SilentPushSettings/SilentPushSettings';
import UserSubscriptionsCard from '../UserSubscriptions/UserSubscriptions';
import { useFeatureFlagsContextValue } from 'providers/flagsmith/useFeatureFlagsContext';
import { FCMV1_PUSH, HUAWEI_PUSH } from 'providers/flagsmith/feature-flags.constants';
import LoadingIcon from '../../Shared/LoadingIcon/LoadingIcon';

export default function AppConfigurations() {
  const fcmSettingsFromState = useSelector((state: RootState) => state.pushSettings.fcmSettings);
  const apnsSettingsFromState = useSelector((state: RootState) => state.pushSettings.apnsSettings);
  const apnsTokenSettingsFromState = useSelector((state: RootState) => state.pushSettings.apnsTokenSettings);
  const huaweiSettingsFromState = useSelector((state: RootState) => state.pushSettings.huaweiSettings);
  const fcmV1SettingsFromState = useSelector((state: RootState) => state.pushSettings.fcmV1Settings);
  const uploadApnsSettingFromState = useSelector((state: RootState) => state.pushSettings.uploadApnsSetting);
  const silentPushSettingsFormState = useSelector((state: RootState) => state.pushSettings.silentPushSettings);
  const userSubscriptionsFromState = useSelector((state: RootState) => state.pushSettings.userSubscriptions);
  const { isLoading: isFeatureFlagsLoading, featureFlags } = useFeatureFlagsContextValue();
  const [isHuaweiPushEnabled, setIsHuaweiPushEnabled] = useState(false);
  const [isFCMV1PushEnabled, setIsFCMV1PushEnabled] = useState(false);
  // Stop users from seeing the GCM option if a key isn't configured already
  // or if the current datetime is past March 1st, 2024 12:00AM GMT
  const isFCMEnabled = fcmSettingsFromState?.key && Date.now() < 1709251200000;

  useEffect(() => {
    if (!isFeatureFlagsLoading) {
      featureFlags[HUAWEI_PUSH] && setIsHuaweiPushEnabled(true);
      featureFlags[FCMV1_PUSH] && setIsFCMV1PushEnabled(true);
    }
  }, [featureFlags, isFeatureFlagsLoading]);

  return isFeatureFlagsLoading ? (
    <LoadingIcon width={80} height={80} fullScreen visible={isFeatureFlagsLoading} />
  ) : (
    <div className="app-config-wrapper">
      <div className="app-config">
        {isFCMEnabled && <FcmSettingCard fcmSettingsFromState={fcmSettingsFromState} />}
        <ApnsSettingsCard
          apnsSettingsFromState={apnsSettingsFromState}
          apnsTokenSettingsFromState={apnsTokenSettingsFromState}
          uploadApnsSettingFromState={uploadApnsSettingFromState}
        />
        {isHuaweiPushEnabled && <HuaweiSettingCard huaweiSettingsFromState={huaweiSettingsFromState} />}
        {isFCMV1PushEnabled && <FcmV1SettingCard fcmV1SettingsFromState={fcmV1SettingsFromState} />}
      </div>
      <div className="pt-5">
        <div className="silent-push-settings-card">
          <ReusableCard>
            <SilentPushSettingsCard silentPushSettingsFromState={silentPushSettingsFormState} />
          </ReusableCard>
        </div>
        <div className="user-subscriptions-card">
          <ReusableCard>
            <UserSubscriptionsCard userSubscriptionsFromState={userSubscriptionsFromState} />
          </ReusableCard>
        </div>
      </div>
    </div>
  );
}
