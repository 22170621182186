import { ContextRuleActionTypes } from 'model/contextRule';
import ContextService from 'services/context.service';
import { makeActionCreator } from 'helpers/actionCreator';

export const setContextRulesState = makeActionCreator(ContextRuleActionTypes.SET_CONTEXT_RULES_STATE);

export function fetchContextRules() {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ContextService();
      const response = await req.getAllContextRules();
      dispatch(setContextRulesState(response.data.data));
    } catch (error) {}
  };
}
