import React, { useEffect } from 'react';
import ReusableCard from 'components/Shared/ReusableCard/ReusableCard';
import { Col, Row, Container } from 'reactstrap';
import { Rate, Ratelimits } from 'model/apiUsage';
import moment from 'moment';
import { FlightTable } from '@flybits/design-system';
import './Subscriptions.scss';

interface Props {
  ratelimitsFromState?: Ratelimits;
}

export default function Subscriptions(props: Props) {
  const { ratelimitsFromState } = props;

  let ssoUsage = '';
  let kernelUsage = '';
  let contextUsage = '';
  let pushUsage = '';
  let analyticsUsage = '';
  let integrationsUsage = '';
  let resetDate = '';
  let daysRemaining = '';

  const usageLimit = ratelimitsFromState?.usageLimit || '';
  const resetUsageAt = ratelimitsFromState?.resetUsageAt || '';
  const CatalogueUsages: Rate[] = ratelimitsFromState?.CatalogueUsages || [
    { tenant: { tenantID: '' }, catalogueID: '', usage: '' },
  ];

  CatalogueUsages.map(apiUsage => {
    switch (apiUsage.catalogueID) {
      case 'sso':
        ssoUsage = apiUsage.usage;
        break;
      case 'kernel':
        kernelUsage = apiUsage.usage;
        break;
      case 'context':
        contextUsage = apiUsage.usage;
        break;
      case 'push':
        pushUsage = apiUsage.usage;
        break;
      case 'analytics':
        analyticsUsage = apiUsage.usage;
        break;
      case 'integrations':
        integrationsUsage = apiUsage.usage;
        break;
    }
    return 'done';
  });
  const intResetUsdage = parseInt(resetUsageAt);
  resetDate = moment.unix(intResetUsdage).format('MMMM Do YYYY, h:mm:ss a');
  daysRemaining = moment(resetDate, 'MMMM Do YYYY, h:mm:ss a').from(moment());

  const tableHeaders = [
    {
      name: '',
      key: 'catalogue',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'usage',
      isVisible: true,
      hideTooltip: true,
    },
  ];
  const tableBody = [
    {
      catalogue: <span>Single sign-on</span>,
      usage: <span className="status-text">{ssoUsage}</span>,
      key: 1,
    },
    {
      catalogue: <span>Kernel</span>,
      usage: <span className="status-text">{kernelUsage}</span>,
      key: 2,
    },
    {
      catalogue: <span>Context</span>,
      usage: <span className="status-text">{contextUsage}</span>,
      key: 3,
    },
    {
      catalogue: <span>Push</span>,
      usage: <span className="status-text">{pushUsage}</span>,
      key: 4,
    },
    {
      catalogue: <span>Analytics</span>,
      usage: <span className="status-text">{analyticsUsage}</span>,
      key: 5,
    },
    {
      catalogue: <span>Integrations</span>,
      usage: <span className="status-text">{integrationsUsage}</span>,
      key: 6,
    },
  ];

  useEffect(() => {
    (document.getElementsByClassName('subscriptions-card__footer__link')?.[0]  as HTMLElement).focus();
  }, []);

  return (
    <div className="subscriptions-card" role="presentation" tabIndex={0}>
      <ReusableCard>
        <Container>
          <Col>
            <Row>
              <h2 className="subscriptions-card__header">Total used</h2>
            </Row>
            <Row>
              <div className="subscriptions-card__title">
                <div className="subscriptions-card__value">
                  {parseInt(ssoUsage) +
                    parseInt(kernelUsage) +
                    parseInt(contextUsage) +
                    parseInt(pushUsage) +
                    parseInt(analyticsUsage) +
                    parseInt(integrationsUsage)}{' '}
                </div>
                <div className="subscriptions-card__total">/ {usageLimit}</div>
              </div>
            </Row>
            <Row>
              <FlightTable
                className="subscriptions-card__table"
                tableHeaders={tableHeaders}
                tableData={tableBody}
                isShowHeader={false}
                hasPaginationBeforeTable={false}
                ariaLabel="Subscriptions Card Table"
              />
            </Row>
            <Row>
              <div className="subscriptions-card__footer">Resets {daysRemaining}</div>
            </Row>
            <Row>
              <div className="subscriptions-card__footer">
                If you would need to increase your api call limit please contact{' '}
                <a className="subscriptions-card__footer__link" href="mailto:support@flybits.com" aria-label="If you would need to increase your api call limit please contact support@flybits.com" >support@flybits.com</a>
              </div>
            </Row>
          </Col>
        </Container>
      </ReusableCard>
    </div>
  );
}
