import { AccountAccessActionTypes } from '../model/accountAccess';
import {
  ApiKeySettings,
  ApiKeySettingsFromApi,
  OktaSettings,
  OktaSettingsForApi,
  SamlConfigs,
  SamlSettingsForApi,
} from 'model/accountAccess/settings';
import AuthService, { SignedUploadService } from 'services/auth.service';
import SettingsService from 'services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator, makeCreateOrUpdateSettingsAction, makeFetchActionCreator } from 'helpers/actionCreator';
import { showNotification } from './snackBarNotifications';

/**
 *  Redux Actions
 */
export const setApiKeySettings = makeActionCreator(AccountAccessActionTypes.SET_APIKEYSETTINGS_STATE);
export const setFacebookSettings = makeActionCreator(AccountAccessActionTypes.SET_FACEBOOKSETTINGS_STATE);
export const setGoogleSettings = makeActionCreator(AccountAccessActionTypes.SET_GOOGLESETTINGS_STATE);
export const setOktaSettings = makeActionCreator(AccountAccessActionTypes.SET_OKTASETTINGS_STATE);
export const setOpenIDSettings = makeActionCreator(AccountAccessActionTypes.SET_OPENIDSETTINGS_STATE);
export const setSamlSettings = makeActionCreator(AccountAccessActionTypes.SET_SAMLSETTINGS_STATE);
export const setSignedLoginSettings = makeActionCreator(AccountAccessActionTypes.SET_SIGNEDLOGINSETTINGS_STATE);
export const setJWTSignedLoginSettings = makeActionCreator(AccountAccessActionTypes.SET_JWTSIGNEDLOGINSETTINGS_STATE);
export const setTenantAccessSettings = makeActionCreator(AccountAccessActionTypes.SET_TENANTACCESSSETTINGS_STATE);

/**
 *  Fetch Settings Actions
 */
export function fetchApiKeySettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getApiKeySettings();
      const responseObj: ApiKeySettingsFromApi = response.data.settings;
      let transformResponse: ApiKeySettings;
      if (responseObj) {
        transformResponse = {
          apiKey: responseObj.apikey,
        };
      } else {
        transformResponse = {
          apiKey: '',
        };
      }
      dispatch(setApiKeySettings(transformResponse));
    } catch (error) {}
    return 'done';
  };
}

export const fetchFacebookSettings = makeFetchActionCreator(
  new SettingsService(),
  'getFacebookSettings',
  setFacebookSettings,
  'settings',
);

export const fetchGoogleSettings = makeFetchActionCreator(
  new SettingsService(),
  'getGoogleSettings',
  setGoogleSettings,
  'settings',
);

export function fetchOktaSettings() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getOktaSettings();
      const responseFromServer: OktaSettingsForApi = response.data.settings;
      const transformObject: OktaSettings = {
        authorizationServerID: responseFromServer.AuthorizationServerID,
        clientID: responseFromServer.ClientID,
        host: responseFromServer.Host,
        secretKey: responseFromServer.SecretKey,
      };
      dispatch(setOktaSettings(transformObject));
    } catch (error) {}
    return 'done';
  };
}

export const fetchOpenIDSettings = makeFetchActionCreator(
  new SettingsService(),
  'getOpenIDSettings',
  setOpenIDSettings,
  'settings',
);

export const fetchSamlSettings = makeFetchActionCreator(
  new AuthService(),
  'getSamlSettings',
  setSamlSettings,
  'samlConfigs',
);

export function fetchSignedLoginSettings(isJustUploaded?: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new AuthService();
      const response = await req.getSignedLoginSettings();
      dispatch(setSignedLoginSettings({ content: response.data.data, isJustUploaded: isJustUploaded || false }));
    } catch (error) {
      if (isJustUploaded) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Failed',
            body: 'Something went wrong while uploading the certificate',
            type: 'error',
            isErrorInModal: true,
          }),
        );
      }
    }
    return 'done';
  };
}

export function fetchJWTSignedLoginSettings(isJustUploaded?: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new AuthService();
      const response = await req.getJWTSignedLoginSettings();
      dispatch(
        setJWTSignedLoginSettings({ certificate: response.data.certificate, isJustUploaded: isJustUploaded || false }),
      );
    } catch (error) {
      if (isJustUploaded) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Failed',
            body: 'Something went wrong while uploading the certificate',
            type: 'error',
            isErrorInModal: true,
          }),
        );
      }
    }
    return 'done';
  };
}

export const fetchTenantAccessSettings = makeFetchActionCreator(
  new SettingsService(),
  'getTenantAccessSettings',
  setTenantAccessSettings,
  'settings',
);

/**
 *  Create or Update Settings Actions
 */
export const createOrUpdateApiKeySettings = makeCreateOrUpdateSettingsAction('apikey', fetchApiKeySettings);
export const createOrUpdateFacebookSettings = makeCreateOrUpdateSettingsAction('facebook', fetchFacebookSettings);
export const createOrUpdateGoogleSettings = makeCreateOrUpdateSettingsAction('gplus', fetchGoogleSettings);
export const createOrUpdateOktaSetting = makeCreateOrUpdateSettingsAction('okta', fetchOktaSettings, true);
export const createOrUpdateOpenIDSettings = makeCreateOrUpdateSettingsAction('oidc', fetchOpenIDSettings);
export const createOrUpdateTenantAccessSettings = makeCreateOrUpdateSettingsAction(
  'tenant-access',
  fetchTenantAccessSettings,
);

export function createOrUpdateSamlSettings(samlConfigs: SamlConfigs) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new AuthService();
      const samlConfigsToServer: SamlConfigs[] = [
        {
          acsURL: samlConfigs.acsURL,
          entityID: 'personal-test-sai.flybits.com',
          idpIssuer: samlConfigs.idpIssuer,
          idpMetadataURL: samlConfigs.idpMetadataURL,
          idpSSOURL: samlConfigs.idpSSOURL,
          publicCert: samlConfigs.publicCert,
          skipSignatureValidation: samlConfigs.skipSignatureValidation,
          tenantID: samlConfigs.tenantID,
        },
      ];
      const samlSettings: SamlSettingsForApi = { samlConfigs: samlConfigsToServer };
      const response = await req.createOrUpdateSamlSetting(samlSettings);
      if (response.status === 204) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchSamlSettings());
      }
    } catch (error) {}
    return 'done';
  };
}

export function uploadSignedLoginCertificate(data: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SignedUploadService();
      await req.uploadSignedLoginCertificate(data);
    } catch (error) {
      /*TODO: sai.karthik@flybits.com:The api has a success status where it returns a 404
      this request will be caught, this will be addressed and this will
      later have to be fixed*/
      dispatch(fetchSignedLoginSettings(true));
    }
    return 'done';
  };
}

export function deleteSignedLoginCertificate() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new AuthService();
      await req.deleteSignedLoginSettings();
      dispatch(setSignedLoginSettings({}));
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Something went wrong while deleting the certificate',
          type: 'error',
          isErrorInModal: true,
        }),
      );
    }
    return 'done';
  };
}

export function uploadJWTSignedLoginCertificate(data: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SignedUploadService();
      await req.uploadSignedLoginCertificate(data);
    } catch (error) {
      /*TODO: sai.karthik@flybits.com:The api has a success status where it returns a 404
      this request will be caught, this will be addressed and this will
      later have to be fixed*/
      try {
        const req = new AuthService();
        const response = await req.getSignedLoginSettings();
        const base64Cert = {
          certificate: response.data?.data?.content,
        };
        await req.putJWTSignedLoginCert(base64Cert);

        dispatch(fetchJWTSignedLoginSettings(true));
      } catch (error) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Failed',
            body: 'Something went wrong while uploading the certificate',
            type: 'error',
            isErrorInModal: true,
          }),
        );
      }
    }
    return 'done';
  };
}

export function deleteJWTSignedLoginCertificate() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new AuthService();
      await req.deleteJWTSignedLoginSettings();
      dispatch(setJWTSignedLoginSettings({}));
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Something went wrong while deleting the certificate',
          type: 'error',
          isErrorInModal: true,
        }),
      );
    }
    return 'done';
  };
}
