const CREATE_PROJECT_JDENTICOON = 'We generate a unique project identifier based on your project name. Pretty cool eh?';
const CREATE_PROJECT_PERSONAL_ENV = 'Your own lil baby.';
const CREATE_PROJECT_DEVELOPMENT_ENV = 'A developer sandbox. Go play!';
const CREATE_PROJECT_STAGING_ENV = 'This should be a mirror of a production environment.';
const CREATE_PROJECT_PRODUCTION_ENV = 'This project serves end-users/clients.';
const PUSH_NOTIF_MODAL_KEY = 'The key is an unique identifier to retrieve the value of a field in your application';
const PUSH_NOTIF_MODAL_LABEL =
  'The field name will be displayed to the user on Experience Studio. It should explain to the user in as few words as possible what the field is used for.';
const PUSH_NOTIF_MODAL_REQUIRED = 'The field will be marked as required on the experience studio.';
const PUSH_NOTIF_MODAL_TRACKED =
  'Tracking a field allows you to gain data insight on your end-users interactions with that field. Enabling this will also allow engagement conditions in Rule Builder to be based on the usage of this field.';

export {
  CREATE_PROJECT_JDENTICOON,
  CREATE_PROJECT_DEVELOPMENT_ENV,
  CREATE_PROJECT_PERSONAL_ENV,
  CREATE_PROJECT_PRODUCTION_ENV,
  CREATE_PROJECT_STAGING_ENV,
  PUSH_NOTIF_MODAL_KEY,
  PUSH_NOTIF_MODAL_LABEL,
  PUSH_NOTIF_MODAL_REQUIRED,
  PUSH_NOTIF_MODAL_TRACKED,
};
