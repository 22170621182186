import React, { useEffect, useState, useCallback } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { FlightButton, FlightModal, FlightTextInput, FlightCheckbox } from '@flybits/design-system';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useActions } from 'actions';
import * as ApnsActions from 'actions/pushSettings/apnsSettings';
import { ReactComponent as Upload } from 'assets/images/upload.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { BUNDLE_REQUIRED, KEY_REQUIRED, TEAM_REQUIRED } from 'constants/errors/errors';
import { ApnsTokenSettings } from 'model/pushSettings/settings';
import './ApnsSettingsModal.scss';

interface Props {
  showModal: boolean;
  apnsTokenSettingsFromState?: ApnsTokenSettings;
  closeModal: () => void;
}

export default function ApnsTokenSettingsModal(props: Props) {
  const apnsActions = useActions(ApnsActions);
  const { showModal, apnsTokenSettingsFromState, closeModal } = props;
  const [localApnsTokenSettings, setLocalApnsTokenSettings] = useState(cloneDeep(apnsTokenSettingsFromState));
  const [uploadedFile, setUploadedFile] = useState<FormData>(new FormData());
  const [productionCheckState, setProductionCheckState] = useState(
    localApnsTokenSettings?.isProduction ? 'SELECTED' : 'UNSELECTED',
  );

  const validationSchema = Yup.object().shape({
    keyID: Yup.string().required(KEY_REQUIRED),
    teamID: Yup.string().required(TEAM_REQUIRED),
    bundleID: Yup.string().required(BUNDLE_REQUIRED),
  });

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const fileData = new FormData();
    acceptedFiles.forEach((file: File) => {
      fileData.append('file', file);
    });

    setUploadedFile(fileData);
  }, []);

  const { getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    noKeyboard: true,
    noClick: true,
    accept: '.p8',
  });

  function handleCloseModal() {
    setLocalApnsTokenSettings(cloneDeep(apnsTokenSettingsFromState));
    closeModal();
  }

  async function createUpdateApnsToken(newApnsTokenSetting: ApnsTokenSettings) {
    const { file, keyID, teamID, bundleID, isProduction } = newApnsTokenSetting;

    file.append('keyID', keyID);
    file.append('teamID', teamID);
    file.append('bundleID', bundleID);
    file.append('isProd', String(isProduction));
    await apnsActions.postApnsTokenSettings(file);
    handleCloseModal();
  }

  useEffect(() => {
    setLocalApnsTokenSettings(cloneDeep(apnsTokenSettingsFromState));
    //eslint-disable-next-line
  }, [apnsTokenSettingsFromState]);

  const handleRemoveUpload = () => {
    setUploadedFile(new FormData());
  };

  const uploadedFileInfo = uploadedFile?.get('file');
  const uploadedFileName = uploadedFileInfo ? (uploadedFileInfo instanceof File ? uploadedFileInfo.name : '') : '';

  // Formik Content for Adding New APNS Certificate
  const addNewSettingContent = (
    errors: any,
    values: { [field: string]: any },
    handleChange: (e: React.ChangeEvent<any>) => void,
    handleBlur: (e: any) => void,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  ) => (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          {uploadedFileInfo ? (
            <div className="apns-settings-modal__certificate-container">
              <span className="apns-settings-modal__certificate-title">Token</span>
              <div className="apns-settings-modal__certificate">
                <div>
                  <CheckIcon className="apns-settings-modal__check" />
                  <span className="apns-settings-modal__certificate-name">{uploadedFileName}</span>
                </div>
                <FlightButton
                  className="apns-settings-modal__certificate-btn"
                  theme="link"
                  label="Remove"
                  onClick={handleRemoveUpload}
                />
              </div>
            </div>
          ) : (
            <div className="dropzone" onClick={open}>
              <div className="dropzone__content">
                <Upload />
                <span className="dropzone__text">Upload Token</span>
              </div>
            </div>
          )}
          <span className="apns-settings-modal__certificate-req">Only .p8 files are accepted</span>
        </FormGroup>
        <FormGroup row>
          <Field
            type="text"
            placeholderText="Type key ID"
            name="keyID"
            id="keyID"
            label="Key ID"
            as={FlightTextInput}
            width="385px"
            hasError={!!errors?.keyID}
            errorMessage={errors.keyID}
            value={values.keyID}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup row>
          <Field
            type="text"
            placeholderText="Type Team ID"
            name="teamID"
            id="teamID"
            label="Team ID"
            as={FlightTextInput}
            width="385px"
            hasError={!!errors?.teamID}
            errorMessage={errors.teamID}
            value={values.teamID}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup row>
          <Field
            type="text"
            placeholderText="Type key ID"
            name="bundleID"
            id="bundleID"
            label="Bundle ID"
            as={FlightTextInput}
            width="385px"
            hasError={!!errors?.bundleID}
            errorMessage={errors.bundleID}
            value={values.bundleID}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup row>
          <Field
            type="checkbox"
            name="isProduction"
            id="isProduction"
            label="This certificate is for a production environment"
            placeholder="isProduction"
            as={FlightCheckbox}
            checkState={productionCheckState}
            onChange={handleChange}
            onBlur={handleBlur}
            onSelect={() => {
              const isProduction = !values.isProduction;
              values.isProduction = isProduction;
              isProduction ? setProductionCheckState('SELECTED') : setProductionCheckState('UNSELECTED');
            }}
          />
        </FormGroup>
      </Form>
    </div>
  );

  const tokenFormInitialValue = {
    keyID: localApnsTokenSettings?.keyID,
    teamID: localApnsTokenSettings?.teamID,
    bundleID: localApnsTokenSettings?.bundleID,
    isProduction: localApnsTokenSettings?.isProduction,
  };

  return (
    <div className="apns-settings-modal token">
      <Formik
        enableReinitialize
        initialValues={tokenFormInitialValue}
        isInitialValid={false}
        validationSchema={validationSchema}
        validateOnMount
        validateOnChange
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (uploadedFileInfo) {
            const newApnsTokenSetting = {
              file: uploadedFile,
              keyID: values.keyID || '',
              teamID: values.teamID || '',
              bundleID: values.bundleID || '',
              isProduction: productionCheckState === 'SELECTED',
              name: uploadedFileName
            };
            setLocalApnsTokenSettings({ ...localApnsTokenSettings, ...newApnsTokenSetting });
            setSubmitting(true);
            resetForm();            
            await createUpdateApnsToken(newApnsTokenSetting);
          }
        }}
      >
        {({ errors, values, handleChange, handleBlur, handleSubmit, isValid }) => {
          const isFieldsAreNotValid = Object.keys(errors).length > 0;
          return (
            <FlightModal
              isVisible={showModal}
              className={'apns-settings-modal__modal'}
              toggleModalShown={handleCloseModal}
              header={'New APNS Token'}
              content={addNewSettingContent(errors, values, handleChange, handleBlur, handleSubmit)}
              footer={
                <div className="modal-footer">
                  <FlightButton theme="secondary" label="Cancel" onClick={handleCloseModal} />
                  <FlightButton
                    type="primary"
                    label="Add"
                    disabled={!uploadedFileInfo || isFieldsAreNotValid}
                    onClick={handleSubmit}
                  />
                </div>
              }
            />
          );
        }}
      </Formik>
      <input name="file" {...getInputProps()} />
    </div>
  );
}
