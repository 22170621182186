import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlightButton, FlightModal } from '@flybits/design-system';
import { useActions } from 'actions';
import { RootState } from 'reducers';
import * as ServiceAccountActions from 'actions/serviceAccount';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import { ServiceAccount } from 'model/serviceAccount';
import ServiceAccountsTable from './ServiceAccountsTable/ServiceAccountsTable';
import CreateServiceAccountModal from './CreateServiceAccountModal/CreateServiceAccountModal';
import './ServiceAccountPages.scss';

export default function ServiceAccountsPage() {
  const [isCreateServiceAccountModalVisible, setIsCreateServiceAccountModalVisible] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState<ServiceAccount | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [localServiceAccounts, setLocalServiceAccounts] = useState<ServiceAccount[]>([]);
  const serviceAccountActions = useActions(ServiceAccountActions);
  const serviceAccounts = useSelector((state: RootState) => state.serviceAccounts);

  const toggleCreateServiceAccountModal = () => {
    setIsCreateServiceAccountModalVisible(!isCreateServiceAccountModalVisible);
  };

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
  };

  const showDeleteConfirmation = (serviceAccount: ServiceAccount) => {
    setSelectedForDeletion(serviceAccount);
  };

  const hideDeleteConfirmation = () => {
    setSelectedForDeletion(null);
  };

  const handleDeleteServiceAccount = () => {
    serviceAccountActions.deleteServiceAccount(selectedForDeletion);
    hideDeleteConfirmation();
  };

  useEffect(() => {
    document.title = "Service Accounts | Developer Portal @ Flybits"
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setLocalServiceAccounts(serviceAccounts);
    } else {
      setLocalServiceAccounts(
        serviceAccounts.filter(
          sa => sa.name.includes(searchTerm) || sa.description?.includes(searchTerm) || sa.id?.includes(searchTerm),
        ),
      );
    }
  }, [searchTerm, serviceAccounts]);

  useEffect(() => {
    (document.getElementsByClassName('service-accounts__create-btn')?.[0]  as HTMLElement)?.focus();
    serviceAccountActions.fetchAllServiceAccounts({});
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div role="main" className="service-accounts">
      <FlightModal
        content={<CreateServiceAccountModal toggleCreateServiceAccountModal={toggleCreateServiceAccountModal} />}
        isVisible={isCreateServiceAccountModalVisible}
        size="small"
        toggleModalShown={toggleCreateServiceAccountModal}
      />
      <ConfirmModal
        isVisible={selectedForDeletion !== null}
        title="Confirm delete"
        message={
          <span>
            Are you sure you want to delete service account &ldquo;
            <strong>{selectedForDeletion?.name || selectedForDeletion?.id}</strong>&rdquo;?
          </span>
        }
        continueCallback={handleDeleteServiceAccount}
        cancelCallback={hideDeleteConfirmation}
      />
      <div className="service-accounts__header">
        <PageHeader className="service-accounts__page-header" title="Service Accounts" isLarge={true} />
        <FlightButton className="service-accounts__create-btn" label="Create Service Account" onClick={toggleCreateServiceAccountModal} />
      </div>
      <SearchBar
        className="service-accounts__search-bar"
        handleSearchTermChange={handleSearchTermChange}
        width="296px"
        label="Search"
        labelId="service-accounts__search-bar"
      />
      <ServiceAccountsTable
        serviceAccounts={localServiceAccounts}
        handleDeleteServiceAccount={serviceAccount => showDeleteConfirmation(serviceAccount)}
      />
    </div>
  );
}
