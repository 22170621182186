import React, { useEffect } from 'react';
import { Project } from 'model/project';
import LocalStore from 'services/localstore';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFrameHelper } from 'helpers/iframe.helper';
import { getCurrentProjectId } from 'helpers/getCurrentProjectId';
interface ContentIframeProps {
  templateId: string;
  onLoad?: (iframe: any) => void;
  isVisible: boolean;
  projectsFromState?: Project[];
}

export default function ContentIframe(props: ContentIframeProps) {
  let iframe: any;
  const localStore = new LocalStore();

  async function openIframe() {
    const projectId = getCurrentProjectId();
    const token = await localStore.getItem(`token+${projectId}`);
    const searchParams = new URLSearchParams();
    const search: any = {
      host: process.env.REACT_APP_HOST_URL,
      projectToken: token,
      templateId: props.templateId,
    };
    Object.keys(search).forEach((key: any) => searchParams.append(key, search[key]));
    const URL = `${process.env.PUBLIC_URL}/content_template_builder/index.html?` + searchParams.toString();
    if (!iframe) {
      iframe = new IFrameHelper(`id-contentIframe`, document.querySelector(`.iframe-contentIframe`), URL);
    } else {
      iframe.setURL(URL);
    }
    return iframe.loadIframe().then(() => {
      props.onLoad && props.onLoad(iframe);
      iframe.sendApply('fromReactDevPortal');
    });
  }

  useEffect(() => {
    openIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`template-iframe ${!props.isVisible ? 'hidden' : ''}`}>
      <div className={`iframe-container iframe-contentIframe`}></div>
    </div>
  );
}
