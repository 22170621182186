import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { RootState } from 'reducers';
import { Formik, Field } from 'formik';
import { omit, cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { FlightButton, FlightSelect, FlightTextArea, FlightTextInput } from '@flybits/design-system';
import { history } from 'configureStore';
import { useActions } from 'actions';
import * as ContentTemplateActions from 'actions/contentTemplates';
import * as ContextRuleActions from 'actions/contextRules';
import * as WebhookActions from 'actions/webhook';
import { ReactComponent as PenIcon } from 'assets/images/pen.svg';
import DoubleArrayFields from 'components/Shared/DoubleArrayFields/DoubleArrayFields';
import FloatingSaveBar from 'components/Shared/FloatingSaveBar/FloatingSaveBar';
import JsonEditor from 'components/Shared/JsonEditor/JsonEditor';
import EventTrigger from 'components/WebhookComponents/EventTrigger';
import { JSON_VALIDATION, NAME_REQUIRED, VALID_URL } from 'constants/errors/errors';
import { isValidJson } from 'helpers/validJson';
import { ContextRule } from 'model/contextRule';
import { ContentTemplate } from 'model/contentTemplates';
import { Webhook, Triggers, Rule, WebhookFormikValues } from 'model/webhook';
import './CreateOrManageWebhookPage.scss';

interface Localizations {
  description: string;
  name: string;
}

export default function CreateOrManageWebhookPage() {
  const contentTemplatesFromState = useSelector((state: RootState) => state.contentTemplates.contentTemplates);
  const contextRulesFromState = useSelector((state: RootState) => state.contextRules.contextRules);
  const webhookFromState = useSelector((state: RootState) => state.webhooks.webhook);
  const contentTemplateActions = useActions(ContentTemplateActions);
  const contextRuleActions = useActions(ContextRuleActions);
  const webhookActions = useActions(WebhookActions);
  const [editingName, setEditingName] = useState(false);
  const isCreating = isEmpty(webhookFromState);

  const localWebhookFromState: Webhook = cloneDeep(webhookFromState) || {};
  const localContextRulesFromState: ContextRule[] = cloneDeep(contextRulesFromState) || [];
  const localContentTemplatesFromState: ContentTemplate[] = cloneDeep(contentTemplatesFromState) || [];

  const formattedDate = moment.unix(localWebhookFromState.lastUpdatedAt).format('MM/DD/YY');
  const headers = localWebhookFromState.headers || {};
  const initialPayload =
    isEmpty(localWebhookFromState.payloads) && isEmpty(localWebhookFromState.payload)
      ? {}
      : !isEmpty(localWebhookFromState.payloads)
      ? localWebhookFromState.payloads
      : localWebhookFromState.payload;

  const initialValues: WebhookFormikValues = {
    name: localWebhookFromState.name || '',
    description: localWebhookFromState.description || '',
    url: localWebhookFromState.url || '',
    method: localWebhookFromState.method || 'POST',
    payloadCode: JSON.stringify(initialPayload, null, '  '),
    headerKeys: Object.keys(headers).concat(''),
    headerValues: Object.values(headers).concat(''),
    triggers: {
      onRuleEvaluatedForDevice: cloneDeep(localWebhookFromState.triggers?.onRuleEvaluatedForDevice || []),
      onRuleEvaluatedForDeviceTrue: cloneDeep(localWebhookFromState.triggers?.onRuleEvaluatedForDeviceTrue || []),
      onRuleEvaluatedForDeviceFalse: cloneDeep(localWebhookFromState.triggers?.onRuleEvaluatedForDeviceFalse || []),
      onRuleEvaluatedForUser: cloneDeep(localWebhookFromState.triggers?.onRuleEvaluatedForUser || []),
      onRuleEvaluatedForUserTrue: cloneDeep(localWebhookFromState.triggers?.onRuleEvaluatedForUserTrue || []),
      onRuleEvaluatedForUserFalse: cloneDeep(localWebhookFromState.triggers?.onRuleEvaluatedForUserFalse || []),
      onInstanceCreated: cloneDeep(localWebhookFromState.triggers?.onInstanceCreated || []),
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(NAME_REQUIRED),
    url: Yup.string()
      .required(VALID_URL)
      .url(),
    payloadCode: Yup.string()
      .required(JSON_VALIDATION)
      .test('payload-json-validation', 'Payload must be a valid JSON object or array', (value: string) => {
        return isValidJson(value);
      }),
  });

  const methodOptions = [
    {
      key: 'POST',
      name: 'POST',
    },
  ];

  const rulesFromState: Rule[] = localContextRulesFromState.map(rule => ({
    id: rule.id,
    name: rule.name,
  }));
  const contentTemplates = localContentTemplatesFromState.map(template => {
    const localization: Array<Localizations> = Object.values(template.localizations);
    return { id: template.id, name: localization[0]?.name };
  });

  function goBack() {
    webhookActions.setWebhookState(undefined);
    history.goBack();
  }

  function handleDeleteWebhook() {
    if (localWebhookFromState) {
      webhookActions.deleteWebhook(cloneDeep(localWebhookFromState));
      goBack();
    }
  }

  function handleSelectRule(
    rule: Rule,
    triggers: Triggers,
    triggerField: string,
    setFieldValue: (field: string, value: object) => void,
  ) {
    setFieldValue('triggers', {
      ...triggers,
      [triggerField]: triggers[triggerField].concat(rule),
    });
  }

  function handleRemoveRule(
    ruleId: string,
    triggers: Triggers,
    triggerField: string,
    setFieldValue: (field: string, value: object) => void,
  ) {
    setFieldValue('triggers', {
      ...triggers,
      [triggerField]: triggers[triggerField].filter((rule: Rule) => rule.id !== ruleId),
    });
  }

  async function updateRuleForwarding(
    ruleId: string,
    actions: string[],
    webhookId: string,
    op: string,
    isContentTemplateRule = false,
  ) {
    const reduxActions = isContentTemplateRule ? contentTemplateActions : webhookActions;
    return new Promise(async resolve => {
      const response = await reduxActions.updateRuleForwarding(ruleId, [
        {
          op: op,
          dest: 'webhook',
          id: webhookId,
          actions: actions,
        },
      ]);
      resolve(response);
    });
  }

  function handleRuleForwarding(webhookId: string, triggers: Triggers) {
    const originalTriggers = cloneDeep(localWebhookFromState.triggers);
    const contentTemplateRulesToUpdate: { [id: string]: 'add' | 'remove' } = {};
    const rulesToAdd: { [id: string]: string[] } = {};
    const rulesToDelete: { [id: string]: string[] } = {};

    for (const triggerAction in triggers) {
      for (const rule of triggers[triggerAction]) {
        if (triggerAction === 'onInstanceCreated') {
          contentTemplateRulesToUpdate[rule.id] = 'add';
        } else {
          rulesToAdd[rule.id] = !rulesToAdd[rule.id] ? [triggerAction] : rulesToAdd[rule.id].concat(triggerAction);
        }
      }
    }

    for (const triggerAction in originalTriggers) {
      if (!!originalTriggers[triggerAction]) {
        for (const originalRule of originalTriggers[triggerAction]) {
          if (triggers[triggerAction].filter((rule: Rule) => rule.id === originalRule.id).length === 0) {
            if (triggerAction === 'onInstanceCreated') {
              contentTemplateRulesToUpdate[originalRule.id] = 'remove';
            } else {
              rulesToDelete[originalRule.id] = !rulesToDelete[originalRule.id]
                ? [triggerAction]
                : rulesToDelete[originalRule.id].concat(triggerAction);
            }
          }
        }
      }
    }

    const promises = [];
    for (const ruleId in rulesToAdd) {
      promises.push(updateRuleForwarding(ruleId, rulesToAdd[ruleId], webhookId, 'add'));
    }
    for (const ruleId in rulesToDelete) {
      promises.push(updateRuleForwarding(ruleId, rulesToDelete[ruleId], webhookId, 'remove'));
    }
    for (const contentTemplateId in contentTemplateRulesToUpdate) {
      promises.push(
        updateRuleForwarding(
          contentTemplateId,
          ['onInstanceCreated'],
          webhookId,
          contentTemplateRulesToUpdate[contentTemplateId],
          true,
        ),
      );
    }

    Promise.all(promises).then(() => {
      webhookActions.fetchAllWebhooks({});
    });
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getDifferences(initialValues: any, values: any) {
    return Object.keys(initialValues).filter(property => !isEqual(initialValues[property], values[property]));
  }

  function getNumChanges(initialValues: WebhookFormikValues, values: WebhookFormikValues) {
    const diffs = getDifferences(omit(initialValues, ['triggers']), values);
    const triggerDiffs = getDifferences(initialValues.triggers, values.triggers);
    return diffs.length + triggerDiffs.length;
  }

  function fetchDependencies() {
    contextRuleActions.fetchContextRules();
    contentTemplateActions.fetchContentTemplates({ limit: 1000000 });
  }

  useEffect(() => {
    fetchDependencies();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="create-or-manage-webhook">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const payload = JSON.parse(values.payloadCode);
          const webhook = {
            id: localWebhookFromState.id || '',
            ...omit(values, ['triggers', 'headerKeys', 'headerValues', 'payloadCode']),
            headers: values.headerKeys.reduce(
              (obj, key, index) =>
                values.headerValues[index] === '' ? obj : { ...obj, [key]: values.headerValues[index] },
              {},
            ),
            ...(Array.isArray(payload) ? { payloads: payload } : { payload: payload }),
          };

          setSubmitting(true);
          if (isCreating) {
            webhookActions.createWebhook(webhook).then((res: Webhook) => {
              handleRuleForwarding(res.id, cloneDeep(values.triggers));
              resetForm();
              goBack();
            });
          } else {
            webhookActions.updateWebhook(webhook).then(() => {
              handleRuleForwarding(webhook.id, cloneDeep(values.triggers));
              webhookActions.setWebhookState({ ...webhook, triggers: values.triggers });
            });
          }
        }}
      >
        {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, isValid }) => (
          <div
            className={`create-or-manage-webhook__form${
              !isCreating && getNumChanges(initialValues, values) > 0 ? ' save-bar-visible' : ''
            }`}
          >
            {isCreating ? (
              <div>
                <FormGroup>
                  <span className="create-or-manage-webhook__header__details__name">Create Webhook</span>
                </FormGroup>
                <FormGroup className="create-or-manage-webhook__field">
                  <label className="create-or-manage-webhook__label" tabIndex={0}>Name*</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    as={FlightTextInput}
                    width="600px"
                    hasError={!!errors.name}
                    errorMessage={errors.name}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
            ) : (
              <FormGroup className="create-or-manage-webhook__header">
                <div className="create-or-manage-webhook__header__details">
                  {editingName ? (
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      as={FlightTextInput}
                      width="300px"
                      hasError={!!errors.name}
                      errorMessage={errors.name}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={() => {
                        if (values.name !== '') {
                          setEditingName(false);
                        }
                      }}
                    />
                  ) : (
                    <span className="create-or-manage-webhook__header__details__name">{values.name}</span>
                  )}
                  <PenIcon
                    className="create-or-manage-webhook__header__details__pen-icon"
                    onClick={() => setEditingName(true)}
                  />
                  <span className="create-or-manage-webhook__header__details__date">{`Last updated ${formattedDate}`}</span>
                </div>
                <FlightButton label="Delete" theme="minor" iconLeft="trashCan" onClick={handleDeleteWebhook} />
              </FormGroup>
            )}
            <FormGroup className="create-or-manage-webhook__field">
              <label className="create-or-manage-webhook__label" tabIndex={0}>Description</label>
              <Field
                className="create-or-manage-webhook__description"
                type="text"
                name="description"
                label=""
                id="description"
                as={FlightTextArea}
                maxLength={180}
                width="600px"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup className="create-or-manage-webhook__field">
              <label aria-label="request url" tabIndex={0} className="create-or-manage-webhook__label">Request URL*</label>
              <div className="create-or-manage-webhook__request-url">
                <FlightSelect
                  label=""
                  width="100px"
                  selected={{ key: values.method, name: values.method }}
                  options={methodOptions}
                  handleOptionClick={(option: { key: string }) => setFieldValue('method', option.key)}
                />
                <Field
                  type="text"
                  name="url"
                  id="url"
                  label="Enter URL"
                  as={FlightTextInput}
                  width="488px"
                  value={values.url}
                  hasError={!!errors.url}
                  errorMessage={errors.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </FormGroup>
            <FormGroup className="create-or-manage-webhook__field">
              <label className="create-or-manage-webhook__label" tabIndex={0}>Request Headers</label>
              <DoubleArrayFields
                // className="create-or-manage-webhook__request-headers"
                initialValues={{ values: values.headerKeys, names: values.headerValues }}
                placeHolderText="New key"
                secondPlaceHolderText="Value"
                callback={(keyData: string[], valueData: string[]) => {
                  setFieldValue('headerKeys', keyData);
                  setFieldValue('headerValues', valueData);
                }}
                hasError={() => {
                  // TODO: Should assert that every key has a value and vice versa
                  return false;
                }}
              />
            </FormGroup>
            <FormGroup>
              <label aria-label="payload" tabIndex={0} className="create-or-manage-webhook__label">Payload</label>
              <JsonEditor
                code={values.payloadCode}
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                handleChange={(event: React.ChangeEvent<any>) => {
                  setFieldValue('payloadCode', event.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="create-or-manage-webhook__field">
              <label className="create-or-manage-webhook__label">Event Triggers</label>
              <div>
                <EventTrigger
                  iconName="phoneSetup"
                  eventText={['Whenever a context rule', 'evaluates', `for a user's`, 'device']}
                  selectedRules={values.triggers.onRuleEvaluatedForDevice}
                  rulesFromState={rulesFromState}
                  handleSelectRule={(rule: Rule) =>
                    handleSelectRule(rule, values.triggers, 'onRuleEvaluatedForDevice', setFieldValue)
                  }
                  handleRemoveRule={(ruleId: string) =>
                    handleRemoveRule(ruleId, values.triggers, 'onRuleEvaluatedForDevice', setFieldValue)
                  }
                />
                <EventTrigger
                  iconName="phoneSetup"
                  eventText={['Whenever a context rule evaluates', 'true', `for a user's`, 'device']}
                  selectedRules={values.triggers.onRuleEvaluatedForDeviceTrue}
                  rulesFromState={rulesFromState}
                  handleSelectRule={(rule: Rule) =>
                    handleSelectRule(rule, values.triggers, 'onRuleEvaluatedForDeviceTrue', setFieldValue)
                  }
                  handleRemoveRule={(ruleId: string) =>
                    handleRemoveRule(ruleId, values.triggers, 'onRuleEvaluatedForDeviceTrue', setFieldValue)
                  }
                />
                <EventTrigger
                  iconName="phoneSetup"
                  eventText={['Whenever a context rule evaluates', 'false', `for a user's`, 'device']}
                  selectedRules={values.triggers.onRuleEvaluatedForDeviceFalse}
                  rulesFromState={rulesFromState}
                  handleSelectRule={(rule: Rule) =>
                    handleSelectRule(rule, values.triggers, 'onRuleEvaluatedForDeviceFalse', setFieldValue)
                  }
                  handleRemoveRule={(ruleId: string) =>
                    handleRemoveRule(ruleId, values.triggers, 'onRuleEvaluatedForDeviceFalse', setFieldValue)
                  }
                />
                <EventTrigger
                  iconName="person"
                  eventText={['Whenever a context rule', ' evaluates', 'for a', 'user']}
                  selectedRules={values.triggers.onRuleEvaluatedForUser}
                  rulesFromState={rulesFromState}
                  handleSelectRule={(rule: Rule) =>
                    handleSelectRule(rule, values.triggers, 'onRuleEvaluatedForUser', setFieldValue)
                  }
                  handleRemoveRule={(ruleId: string) =>
                    handleRemoveRule(ruleId, values.triggers, 'onRuleEvaluatedForUser', setFieldValue)
                  }
                />
                <EventTrigger
                  iconName="person"
                  eventText={['Whenever a context rule evaluates', 'true', 'for a', 'user']}
                  selectedRules={values.triggers.onRuleEvaluatedForUserTrue}
                  rulesFromState={rulesFromState}
                  handleSelectRule={(rule: Rule) =>
                    handleSelectRule(rule, values.triggers, 'onRuleEvaluatedForUserTrue', setFieldValue)
                  }
                  handleRemoveRule={(ruleId: string) =>
                    handleRemoveRule(ruleId, values.triggers, 'onRuleEvaluatedForUserTrue', setFieldValue)
                  }
                />
                <EventTrigger
                  iconName="person"
                  eventText={['Whenever a context rule evaluates', 'false', 'for a', 'user']}
                  selectedRules={values.triggers.onRuleEvaluatedForUserFalse}
                  rulesFromState={rulesFromState}
                  handleSelectRule={(rule: Rule) =>
                    handleSelectRule(rule, values.triggers, 'onRuleEvaluatedForUserFalse', setFieldValue)
                  }
                  handleRemoveRule={(ruleId: string) =>
                    handleRemoveRule(ruleId, values.triggers, 'onRuleEvaluatedForUserFalse', setFieldValue)
                  }
                />
                <EventTrigger
                  iconName="report"
                  eventText={['Whenever a content instance is created from template']}
                  selectedRules={values.triggers.onInstanceCreated}
                  rulesFromState={contentTemplates}
                  showContentTemplateText
                  handleSelectRule={(rule: Rule) =>
                    handleSelectRule(rule, values.triggers, 'onInstanceCreated', setFieldValue)
                  }
                  handleRemoveRule={(ruleId: string) =>
                    handleRemoveRule(ruleId, values.triggers, 'onInstanceCreated', setFieldValue)
                  }
                />
              </div>
            </FormGroup>
            {isCreating && (
              <FormGroup className="create-or-manage-webhook__footer">
                <FlightButton
                  className="create-or-manage-webhook__submit-btn"
                  label="Create Webhook"
                  disabled={!isValid}
                  onClick={(e: React.FormEvent<HTMLFormElement>) => {
                    handleSubmit(e);
                    history.goBack();
                  }}
                />
                <FlightButton label="Cancel" theme="secondary" onClick={goBack} />
              </FormGroup>
            )}
            <FloatingSaveBar
              isVisible={!isCreating && getNumChanges(initialValues, values) > 0}
              numUnsavedChanges={getNumChanges(initialValues, values)}
              primaryAction={{
                label: 'Save Changes',
                handleAction: () => {
                  handleSubmit();
                  history.goBack();
                },
                isDisabled: !isValid,
              }}
              secondaryAction={{ label: 'Discard', handleAction: resetForm }}
            />
          </div>
        )}
      </Formik>
    </div>
  );
}
