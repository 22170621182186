import './FlybitsLifeAppPage.scss';

import { FlightButton } from '@flybits/design-system';
import { RootState } from 'reducers';
import { useActions } from 'actions';
import { useSelector } from 'react-redux';
import * as AccountAccessActions from 'actions/accountAccess';
import ApiKeyModal from 'components/AuthenticateComponents/Modals/ApiKeyModal';
import QRCode from 'react-qr-code';
import React, { useEffect } from 'react';
import FlybitsLifeAppScreen from 'assets/images/flybits_life_app.png';

const MAIN_CLASS = 'flybits-life-app-page';
const CLASSES = {
  CONTENT: `${MAIN_CLASS}__content`,
  INSTRUCTIONS_CONTAINER: `${MAIN_CLASS}__content__instructions-container`,
  SCREENSHOT_CONTAINER: `${MAIN_CLASS}__content__screenshot-container`,
};

export default function FlybitsLifeAppPage() {
  const [apiKey, setAPIKey] = React.useState('');
  const [isAPIKeyEnabled, setIsAPIKeyEnabled] = React.useState(false);
  const [isAPIModalOpen, setIsAPIModalOpen] = React.useState(false);
  const [isFetchingAPIKeySettings, setIsFetchingAPIKeySettings] = React.useState(false);
  const [isFetchingTenantAccessSettings, setIsFetchingTenantAccessSettings] = React.useState(false);
  const [mobileAuthUrl, setMobileAuthUrl] = React.useState('');
  const { id: projectId } = useSelector((state: RootState) => state.project);
  const accountAccessActions = useActions(AccountAccessActions);
  const apiKeySettings = useSelector((state: RootState) => state.accountAccess.apiKeySettings);
  const flybitsLifeAppUrl = 'itms-beta://testflight.apple.com/join/ekMqe2ip';
  const mailToUrl =
    'mailto:michael.hollis@flybits.com?subject=I%20need%20an%20invitation%20to%20test%20Flybits%20Life%20app&body=Hi%20Michael%2C%0D%0A%0D%0ACould%20you%20please%20send%20me%20an%20invitation%20to%20TestFlight%20so%20that%20I%20can%20access%20the%20Flybits%20Life%20app%3F%0D%0A%0D%0AThank%20you.%0D%0A';
  const tenantAccessSettings = useSelector((state: RootState) => state.accountAccess.tenantAccessSettings);
  const testFlightAppUrl = 'https://apps.apple.com/us/app/testflight/id899247664?mt=8';

  useEffect(() => {
    if (!isFetchingAPIKeySettings && accountAccessActions) {
      accountAccessActions.fetchApiKeySettings();
      setIsFetchingAPIKeySettings(true);
    }

    if (!isFetchingTenantAccessSettings && accountAccessActions) {
      accountAccessActions.fetchTenantAccessSettings();
      setIsFetchingTenantAccessSettings(true);
    }
  }, [isFetchingAPIKeySettings, isFetchingTenantAccessSettings, accountAccessActions]);

  useEffect(() => {
    if (apiKeySettings?.apiKey) {
      setAPIKey(apiKeySettings.apiKey);
    }
  }, [apiKeySettings]);

  useEffect(() => {
    const apiKeyItem = tenantAccessSettings?.allowed?.find(item => item.value === 'apikey');

    if (apiKey && apiKeyItem) {
      setIsAPIKeyEnabled(true);
    }
  }, [apiKey, tenantAccessSettings]);

  useEffect(() => {
    const gatewayURL = process.env.REACT_APP_HOST_URL || '';

    if (apiKey && gatewayURL && projectId) {
      const urlSearchParams = new URLSearchParams({
        apiKey,
        gatewayURL,
        ibi: 'com.flybits.flybitsLifeApp',
        isi: '1635197135',
        link: 'https://flybits-flutter-office.firebaseapp.com',
        projectId,
        webService: 'https://static-files-concierge.development.flybits.com/latest',
      });

      setMobileAuthUrl(`https://flybits.page.link/?${urlSearchParams.toString()}`);
    }
  }, [apiKey, projectId]);

  return (
    <div className={MAIN_CLASS}>
      <div className="page-header">
        <h1 className="large">Flybits Life App</h1>
      </div>
      <div className={CLASSES.CONTENT}>
        <div className={CLASSES.INSTRUCTIONS_CONTAINER}>
          <h2>
            A <strong>mobile app</strong> that allows you to view and interact with the expereince that you have created
            in your Flybits project.
          </h2>
          <p>Below are the steps for iOS device on how to get the app installed and connected to your project.</p>
          <ol>
            <li>
              <strong>Download TestFlight app</strong>
              <div className="qr-code-container" data-url={testFlightAppUrl}>
                <QRCode className="qr-code" size={150} value={testFlightAppUrl} />
              </div>
            </li>

            <li>
              <strong>Download Flybits Life app</strong>
              <div className="note">
                Note that you must accept an invitation either via email or a public link from the developer. If you
                haven't received an invitation yet, please reach out to a Flybits{' '}
                <a href={mailToUrl} rel="noopener noreferrer" target="_blank">
                  representative
                </a>
              </div>
              <div className="qr-code-container" data-url={flybitsLifeAppUrl}>
                <QRCode className="qr-code" size={150} value={flybitsLifeAppUrl} />
              </div>
            </li>
            <li>
              <strong>Enable API Key authentication</strong>
              <table>
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>
                      <span className={isAPIKeyEnabled ? 'is-enabled' : 'is-disabled'}>
                        {isAPIKeyEnabled ? 'Enabled' : 'Disabled'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>API Key</td>
                    <td>{apiKey ? apiKey : <span className="not-set">Not Set</span>}</td>
                  </tr>
                </tbody>
              </table>
              <div className="button-container">
                <FlightButton
                  label="Enable API Key"
                  disabled={isAPIKeyEnabled}
                  onClick={() => setIsAPIModalOpen(!isAPIModalOpen)}
                />
              </div>
            </li>
            <li>
              <strong>Authenticate</strong>
              <div className={`qr-code-container ${!isAPIKeyEnabled ? 'is-disabled' : ''}`} data-url={mobileAuthUrl}>
                <QRCode className="qr-code" size={150} value={mobileAuthUrl} />
              </div>
            </li>
          </ol>
        </div>
        <div className={CLASSES.SCREENSHOT_CONTAINER}>
          <img src={FlybitsLifeAppScreen} alt="Flybits Life App" />
        </div>
      </div>

      <ApiKeyModal
        apiKeySettingsFromState={apiKeySettings}
        openModal={isAPIModalOpen}
        tenantAccessSettingsFromState={tenantAccessSettings}
        toggleModal={() => setIsAPIModalOpen(!isAPIModalOpen)}
      />
    </div>
  );
}
