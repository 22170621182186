export interface LanguageState {
  languages: Language[];
}

export interface Language {
  id: string;
  isDefault: boolean;
  name: string;
  direction?: string;
}

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export enum LanguageActionTypes {
  SET_LANGUAGES_STATE = 'SET_LANGUAGES_STATE',
}

interface LanguageActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type LanguageActions = LanguageActionCreatorType<typeof LanguageActionTypes.SET_LANGUAGES_STATE, Language>;
