import React, { useCallback, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { FlightButton, FlightRadioButton, FlightModal } from '@flybits/design-system';
import { useActions } from 'actions';
import * as ApnsActions from 'actions/pushSettings/apnsSettings';
import { ApnsSettings, ApnsTokenSettings, UploadApnsSetting } from 'model/pushSettings/settings';
import { ReactComponent as AppleLogo } from 'assets/images/ios.svg';
import ApnsSettingsModal from './ApnsSettingsModal';
import ApnsTokenSettingsModal from './ApnsTokenSettingsModal';

// Style
import './ApnsSettings.scss';

type APNSInfoBoxProps = {
  labelName: string;
  value: string;
  isEnabled?: boolean;
  isProduction?: boolean;
};

interface Props {
  apnsSettingsFromState?: ApnsSettings[];
  apnsTokenSettingsFromState?: ApnsTokenSettings;
  uploadApnsSettingFromState?: UploadApnsSetting;
}

interface RadioButtonStates {
  state: 'cert' | 'token' | string;
}

export default function ApnsSettingsCard(props: Props) {
  const { apnsSettingsFromState = [], apnsTokenSettingsFromState, uploadApnsSettingFromState } = props;
  const apnsActions = useActions(ApnsActions);
  const [showCertModal, setShowCertModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [apnsSettingsChecked, setApnsSettingsChecked] = useState<RadioButtonStates>({ state: 'cert' });
  const [openConfirmTokenRemove, setOpenConfirmTokenRemove] = useState(false);

  const localApnsSettingsState = cloneDeep(apnsSettingsFromState);
  const localApnsTokenSettingsState = cloneDeep(apnsTokenSettingsFromState);

  const hasCertificates = localApnsSettingsState.length > 0;
  const hasToken = Object.keys(localApnsTokenSettingsState || {}).length > 0;
  const isCertificateShowing = apnsSettingsChecked.state === 'cert';

  const activeCertificate = localApnsSettingsState.find(setting => setting.isEnabled === true);

  const handleDropZone = useCallback(
    (acceptedFiles: File[]) => {
      const uploadedFiles = new FormData();
      acceptedFiles.forEach((file: File) => {
        uploadedFiles.append('assetFile', file);
        uploadedFiles.append('isPrivate', 'true');
      });
      apnsActions.uploadApnsCertificate(uploadedFiles);
    },
    [apnsActions],
  );

  const { getInputProps, open } = useDropzone({
    onDrop: handleDropZone,
    noKeyboard: true,
    noClick: true,
    accept: '.p12',
  });

  function handleOpenCertModal() {
    if (!hasCertificates) {
      open();
    }
    setShowCertModal(prev => !prev);
  }

  function handleOpenTokenModal() {
    setShowTokenModal(prev => !prev);
  }

  const isConfigEmpty = !hasToken && !hasCertificates;
  const showCertificateButton = isCertificateShowing || isConfigEmpty;
  const showTokenButton = !isCertificateShowing || isConfigEmpty;

  const certBtnProps = {
    label: hasCertificates ? 'Manage' : 'Add a certificate',
    theme: hasCertificates ? 'link' : 'secondary',
    icon: hasCertificates ? '' : 'baselineArrowRight',
    class: hasCertificates ? 'apns-settings__btn apns-settings__btn__link' : 'apns-settings__btn',
  };

  const tokenBtnProps = {
    label: hasToken ? 'Change' : 'Add a token',
    theme: hasToken ? 'link' : 'secondary',
    icon: hasToken ? '' : 'baselineArrowRight',
    class: hasToken ? 'apns-settings__btn apns-settings__btn__link' : 'apns-settings__btn',
  };

  const APNSInfoBox = ({ apnsInfoData }: { apnsInfoData: APNSInfoBoxProps[] }): JSX.Element => {
    return (
      <div className="push-settings__content">
        {apnsInfoData?.map((apnsInfo, id) => {
          const { labelName = '', value = '', isEnabled = true, isProduction = true } = apnsInfo;
          return (
            <div key={`apnsSettingsInfo-${id}`} className="push-settings__info">
              <span className="property">{labelName}</span>{' '}
              <span className={isEnabled ? 'value' : ''}>
                {value}
                <span className="helper">{isProduction ? 'Production' : 'Test'}</span>
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const confirmTokenRemoveToggle = () => setOpenConfirmTokenRemove(openConfirmTokenRemove => !openConfirmTokenRemove);

  const deleteAPNSTokenSettings = () => {
    apnsActions.deleteApnsTokenSetting();
    confirmTokenRemoveToggle();
  };

  return (
    <div className="push-settings apns-settings">
      {showCertModal && (
        <ApnsSettingsModal
          showModal={showCertModal}
          uploadApnsSettingFromState={uploadApnsSettingFromState}
          apnsSettingsFromState={apnsSettingsFromState}
          closeModal={() => setShowCertModal(prev => !prev)}
          handleUploadCertificate={open}
        />
      )}
      {showTokenModal && (
        <ApnsTokenSettingsModal
          showModal={showTokenModal}
          apnsTokenSettingsFromState={apnsTokenSettingsFromState}
          closeModal={() => setShowTokenModal(prev => !prev)}
        />
      )}
      {!isCertificateShowing && (
        <FlightModal
          isVisible={openConfirmTokenRemove}
          size="medium"
          toggleModalShown={confirmTokenRemoveToggle}
          className="push-settings__modal"
          header={<span>Confirm</span>}
          content={<p>Are you sure you want to delete your APNS token?</p>}
          footer={
            <div className="modal-footer">
              <FlightButton theme="secondary" onClick={confirmTokenRemoveToggle} label="Cancel" />
              <FlightButton type="submit" label="Continue" onClick={deleteAPNSTokenSettings} />
            </div>
          }
        />
      )}

      <Container>
        <Row>
          <AppleLogo />
          <span id="apns-container-heading" className="push-settings__header">
            <h2>APNS Certificate</h2>
          </span>
        </Row>
        {isConfigEmpty ? (
          <Row>
            <span className="push-settings__body">You don't have any APNS certificates or token.</span>
          </Row>
        ) : (
          <>
            <Row>Select the way to activate APNS settings</Row>
            <div>
              <FlightRadioButton
                label="Certificate"
                checked={isCertificateShowing}
                className="account-settings-modal__content__radio-btn-grp__radio-btn"
                onSelect={() =>
                  setApnsSettingsChecked({
                    state: 'cert',
                  })
                }
                value={apnsSettingsChecked.state}
              />
              <FlightRadioButton
                label="Token"
                checked={!isCertificateShowing}
                className="account-settings-modal__content__radio-btn-grp__radio-btn"
                onSelect={() =>
                  setApnsSettingsChecked({
                    state: 'token',
                  })
                }
                value={apnsSettingsChecked.state}
              />
            </div>
            <Row>
              <>
                {isCertificateShowing ? (
                  <>
                    {activeCertificate?.isEnabled ? (
                      <div className="push-settings__content">
                        <APNSInfoBox
                          apnsInfoData={[
                            {
                              labelName: 'Certificate',
                              value: activeCertificate.name,
                              isEnabled: activeCertificate?.isEnabled,
                              isProduction: activeCertificate.isProduction,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      <div className="push-settings__body">No active APNS certificate</div>
                    )}
                  </>
                ) : (
                  <>
                    {hasToken ? (
                      <div className="push-settings__content">
                        <APNSInfoBox
                          apnsInfoData={[
                            {
                              labelName: 'Token',
                              value: localApnsTokenSettingsState?.name || '',
                              isProduction: localApnsTokenSettingsState?.isProduction,
                            },
                            {
                              labelName: 'Key ID',
                              value: localApnsTokenSettingsState?.keyID || '',
                              isProduction: localApnsTokenSettingsState?.isProduction,
                            },
                            {
                              labelName: 'Team ID',
                              value: localApnsTokenSettingsState?.teamID || '',
                              isProduction: localApnsTokenSettingsState?.isProduction,
                            },
                            {
                              labelName: 'Bundle ID',
                              value: localApnsTokenSettingsState?.bundleID || '',
                              isProduction: localApnsTokenSettingsState?.isProduction,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      <div className="push-settings__body">No active APNS token</div>
                    )}
                  </>
                )}
              </>
            </Row>
          </>
        )}
        <Row className={'apns-settings-button-row'}>
          {isCertificateShowing && <input name="file" id="fileValidation" {...getInputProps()} />}
          {showCertificateButton && (
            <FlightButton
              label={certBtnProps.label}
              size="medium"
              theme={certBtnProps.theme}
              iconRight={certBtnProps.icon}
              className={certBtnProps.class}
              onClick={handleOpenCertModal}
              isAriaDescribedBy
              ariaDescribedBy={'apns-container-heading'}
            />
          )}
          {showTokenButton && (
            <FlightButton
              label={tokenBtnProps.label}
              size="medium"
              theme={tokenBtnProps.theme}
              iconRight={tokenBtnProps.icon}
              className={tokenBtnProps.class}
              onClick={handleOpenTokenModal}
              isAriaDescribedBy
              ariaDescribedBy={'apns-container-heading'}
            />
          )}
          {!isCertificateShowing && hasToken && (
            <FlightButton
              label={'Remove'}
              size="medium"
              theme="link"
              className="push-settings__btn--red"
              onClick={confirmTokenRemoveToggle}
              isAriaDescribedBy
              ariaDescribedBy={'apns-container-heading'}
              ariaLabel="Remove"
            />
          )}
        </Row>
      </Container>
    </div>
  );
}
