export interface SnackBarNotification {
  id: number;
  showNotification: boolean;
  header: string;
  body: string;
  type: 'primary' | 'danger' | 'success' | string;
  isErrorInModal?: boolean;
}

export enum SnackBarNotificationActionTypes {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
  HIDE_NOTIFICATION_BY_ID = 'HIDE_NOTIFICATION_BY_ID',
}

interface SnackBarNotificationActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type SnackBarNotificationAction =
  | SnackBarNotificationActionCreatorType<
      typeof SnackBarNotificationActionTypes.SHOW_NOTIFICATION,
      SnackBarNotification
    >
  | SnackBarNotificationActionCreatorType<
      typeof SnackBarNotificationActionTypes.HIDE_NOTIFICATION_BY_ID,
      SnackBarNotification
    >
  | SnackBarNotificationActionCreatorType<
      typeof SnackBarNotificationActionTypes.HIDE_NOTIFICATION,
      SnackBarNotification
    >;
