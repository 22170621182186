import { AxiosError } from 'axios';
import { ApnsSettings } from 'model/pushSettings/settings';
import { PushSettingsActionTypes } from 'model/pushSettings';
import { PushService, PushUploadService } from 'services/push.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator, makeFetchActionCreator } from 'helpers/actionCreator';
import { showNotification } from '../snackBarNotifications';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';

interface UploadResponseBody {
  id: string;
  friendlyName: string;
}

// Certificate settings

export const setApnsSettings = makeActionCreator(PushSettingsActionTypes.SET_APNS_SETTINGS);
export const setUploadApnsSetting = makeActionCreator(PushSettingsActionTypes.SET_UPLOAD_APNS_SETTING);

export function uploadApnsCertificate(data: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushUploadService();
      const response = await req.uploadApnsCertificate(data);
      if (response.status === 200) {
        response.data.map((x: UploadResponseBody) => {
          return dispatch(setUploadApnsSetting({ fileID: x.id, name: x.friendlyName, isUploaded: true }));
        });
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Could not upload Apns certificate',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export const fetchApnsSettings = makeFetchActionCreator(
  new PushService(),
  'getApnsSettings',
  setApnsSettings,
  undefined,
  {
    errorMessage: 'Could not fetch apns settings',
  },
);

export function postApnsSettings(apnsSettings: ApnsSettings) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      await req.createApnsSettings(apnsSettings);
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to add Apns setting',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function patchApnsSettings(apnsSettings: ApnsSettings) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      await req.updateApnsSettings(apnsSettings);
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to update Apns setting',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function deleteApnsSetting(fileID: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      await req.deleteApnsSettings(fileID);
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: 'Failed to delete Apns setting',
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

// Token settings
export const setApnsTokenSettings = makeActionCreator(PushSettingsActionTypes.SET_APNS_TOKEN_SETTINGS);

export function fetchApnsTokenSettings() {
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new PushService();
      const response = await req.getApnsTokenSettings();
      dispatch(setApnsTokenSettings(response.data));
    } catch (error) {
      const fetchError = error as AxiosError;
      // @ts-expect-error: TODO: fix the typescript error properly
      const errorObjectFromResponse = fetchError?.response?.data?.error?.exceptionType || '';
      if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
        dispatch(setApnsTokenSettings({}));
      }
    }
    return 'done';
  };
}

export function postApnsTokenSettings(apnsTokenSettings: FormData) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushUploadService();
      const response = await req.createUpdateApnsTokenSettings(apnsTokenSettings);
      dispatch(fetchApnsTokenSettings());

      if ([200, 201].includes(response?.status)) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Apns token setting has been created successfully',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      const fetchError = error as AxiosError;
      // @ts-expect-error: TODO: fix the typescript error properly
      const errorMessage = fetchError?.response?.data.error.exceptionMessage || 'Failed to add Apns token setting';
      const randomId = generateUniqueId();

      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: errorMessage,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function deleteApnsTokenSetting() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new PushService();
      const response = await req.deleteApnsTokenSettings();
      dispatch(fetchApnsTokenSettings());

      if ([200, 204].includes(response?.status)) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'APNS token setting has been deleted successfully',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      const fetchError = error as AxiosError;

      // @ts-expect-error: TODO: fix the typescript error properly
      const errorMessage = fetchError?.response?.data.error.exceptionMessage || 'Failed to delete Apns token setting';
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: errorMessage,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}
