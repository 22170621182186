import React from 'react';
import './PageHeader.scss';
interface Props {
  title: string;
  isLarge?: boolean;
  className?: string;
}

export default function PageHeader(props: Props) {
  const { title, isLarge, className } = props;
  const pageHeaderClasses = 'page-header' + (className ? ` ${className}` : '');

  return (
    <div className={pageHeaderClasses}>
      {isLarge ? <h1 className='large'>{title}</h1>
        : <h2 className='small'>{title}</h2> }
    </div>
  );
}
