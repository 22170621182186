import { ContextPluginsActions, ContextPluginsActionTypes, ContextPluginState } from 'model/contextPlugins';
import createReducer from './createReducer';

const initialState = {} as ContextPluginState;
export const contextPlugins = createReducer<ContextPluginState>(initialState, {
  [ContextPluginsActionTypes.SET_CONTEXT_PLUGINS_STATE](state: ContextPluginState, action: ContextPluginsActions) {
    return { ...state, contextPlugins: action.payload };
  },
  [ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_STATE](state: ContextPluginState, action: ContextPluginsActions) {
    return { ...state, contextPlugin: action.payload };
  },
  [ContextPluginsActionTypes.SET_CONTEXT_PLUGIN_ATTRIBUTES](state: ContextPluginState, action: ContextPluginsActions) {
    return { ...state, contextPlugin: { ...state.contextPlugin, values: action.payload } };
  },
  // TODO: Should refactor this action to just take isCreateAttribute as payload instead of entire ContextPlugin
  [ContextPluginsActionTypes.SET_CREATE_ATTRIBUTE](state: ContextPluginState, action: ContextPluginsActions) {
    return {
      ...state,
      contextPlugin: { ...state.contextPlugin, isCreateAttribute: action.payload?.isCreateAttribute },
    };
  },
  // TODO: Should refactor this action to just take isViewAttribute as payload instead of entire ContextPlugin
  [ContextPluginsActionTypes.SET_VIEW_ATTRIBUTE](state: ContextPluginState, action: ContextPluginsActions) {
    return { ...state, contextPlugin: { ...state.contextPlugin, isViewAttribute: action.payload?.isViewAttribute } };
  },
  // TODO: Revisit this to see if SET_CHANGE_PLUGIN should be taking a boolean value or if no payload is needed
  [ContextPluginsActionTypes.SET_CHANGE_PLUGIN](state: ContextPluginState) {
    return { ...state, contextPlugin: { ...state.contextPlugin, isChanged: true } };
  },
});
