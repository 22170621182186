import { PushSettingsActionTypes } from 'model/pushSettings';
import { UserSubscription, UserSubscriptionsForApi } from 'model/pushSettings/settings';
import SettingsService from 'services/settings.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator } from 'helpers/actionCreator';
import { showNotification } from '../snackBarNotifications';

export const setUserSubscriptions = makeActionCreator(PushSettingsActionTypes.SET_USER_SUBSCRIPTIONS_STATE);

export function fetchAllUserSubscriptions() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getUserSettings();
      dispatch(setUserSubscriptions(response.data.settings.subscriptions.data));
    } catch (error) {}
    return 'done';
  };
}

export function addOrUpdateUserSubscription(userSubscriptions: UserSubscription[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const addTypesObject = {
        data: [],
      };

      const objectForApi: UserSubscriptionsForApi = {
        config:
          '{"subscriptions":{"data":' +
          JSON.stringify(userSubscriptions) +
          '}' +
          ', "types":' +
          JSON.stringify(addTypesObject) +
          '}',
        configName: {
          configName: 'push-settings',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: '',
            type: 'success',
          }),
        );
        dispatch(fetchAllUserSubscriptions());
      }
    } catch (error) {
      const randomId = generateUniqueId();
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: '',
          type: 'error',
        }),
      );
      dispatch(fetchAllUserSubscriptions());
    }
    return 'done';
  };
}
