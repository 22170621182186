import React from 'react';
import { FlightButton } from '@flybits/design-system';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { ReactComponent as WebhooksEmptyStateImage } from 'assets/images/webhooks-graphic.svg';
import './WebhookEmptyState.scss';

interface TProps {
  handleCreateWebhook: () => void;
}

export default function WebhookEmptyState(props: TProps) {
  const { handleCreateWebhook } = props;

  return (
    <div className="webhook-empty-state">
      <div className="webhook-empty-state__content">
        <PageHeader title="Webhooks" isLarge={true} className="webhook-empty-state__content__page-header" />
        <span className="webhook-empty-state__content__description">
          Webhooks are way to connect your applications with Flybits.
        </span>
        <FlightButton label="Create a Webhook" onClick={handleCreateWebhook} />
      </div>
      <WebhooksEmptyStateImage />
    </div>
  );
}
