import {
  ApiKeySettings,
  FacebookSettings,
  GoogleSettings,
  OktaSettings,
  OpenIDSettings,
  SamlConfigs,
  SignedLoginSettings,
  JWTSignedLoginSettings,
  TenantAccessSettings,
} from './settings';

interface ConfigName {
  configName: string;
}

export interface ObjectForApi {
  config: string;
  configName: ConfigName;
}

export interface AccountAccess {
  apiKeySettings: ApiKeySettings;
  facebookSettings: FacebookSettings;
  googleSettings: GoogleSettings;
  oktaSettings: OktaSettings;
  openIDSettings: OpenIDSettings;
  samlSettings: SamlConfigs[];
  signedLoginSettings: SignedLoginSettings;
  jwtSignedLoginSettings: JWTSignedLoginSettings;
  tenantAccessSettings: TenantAccessSettings;
}

export enum AccountAccessActionTypes {
  SET_APIKEYSETTINGS_STATE = 'SET_APIKEYSETTINGS_STATE',
  SET_FACEBOOKSETTINGS_STATE = 'SET_FACEBOOKSETTINGS_STATE',
  SET_GOOGLESETTINGS_STATE = 'SET_GOOGLESETTINGS_STATE',
  SET_OKTASETTINGS_STATE = 'SET_OKTASETTINGS_STATE',
  SET_OPENIDSETTINGS_STATE = 'SET_OPENIDSETTINGS_STATE',
  SET_SAMLSETTINGS_STATE = 'SET_SAMLSETTINGS_STATE',
  SET_SIGNEDLOGINSETTINGS_STATE = 'SET_SIGNEDLOGINSETTINGS_STATE',
  SET_JWTSIGNEDLOGINSETTINGS_STATE = 'SET_JWTSIGNEDLOGINSETTINGS_STATE',
  SET_TENANTACCESSSETTINGS_STATE = 'SET_TENANTACCESSSETTINGS_STATE',
}

interface AccountAccessActionCreator<T, P> {
  type: T;
  payload?: P;
}

export type AccountAccessAction =
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_APIKEYSETTINGS_STATE, ApiKeySettings>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_FACEBOOKSETTINGS_STATE, FacebookSettings>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_GOOGLESETTINGS_STATE, GoogleSettings>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_OKTASETTINGS_STATE, OktaSettings>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_OPENIDSETTINGS_STATE, OpenIDSettings>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_SAMLSETTINGS_STATE, SamlConfigs>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_SIGNEDLOGINSETTINGS_STATE, SignedLoginSettings>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_JWTSIGNEDLOGINSETTINGS_STATE, JWTSignedLoginSettings>
  | AccountAccessActionCreator<typeof AccountAccessActionTypes.SET_TENANTACCESSSETTINGS_STATE, TenantAccessSettings>;
