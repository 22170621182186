import BaseService from './base.service';
import { ContentTemplateAttributes } from 'model/contentTemplates';
import { RuleForwardingData } from 'model/webhook';

type TContentTemplatesParams = {
  search?: string;
  limit?: number;
  offset?: number;
};

export default class ContentService extends BaseService {
  CURRENT_PATH: string;
  CURRENT_RESOURCE: string;
  RULE_FORWARDING_RESOURCE: string;
  constructor() {
    super();
    this.CURRENT_PATH = 'kernel/';
    this.CURRENT_RESOURCE = 'content/templates';
    this.RULE_FORWARDING_RESOURCE = '/forwarding';
  }

  async getAllContentTemplates(params: TContentTemplatesParams) {
    return this.request('GET', this.CURRENT_PATH + this.CURRENT_RESOURCE, params);
  }

  async createContentTemplate(data: ContentTemplateAttributes) {
    return this.request('POST', this.CURRENT_PATH + this.CURRENT_RESOURCE, data);
  }

  async getContentTemplate(id: string) {
    return this.request('GET', this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + id);
  }

  async getContentTemplateLabels(params: TContentTemplatesParams) {
    return this.request('GET', this.CURRENT_PATH + 'labels', params);
  }

  async updateContentTemplate(data: ContentTemplateAttributes, id: string) {
    return this.request('PUT', this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + id, data);
  }

  async deleteContentTemplates(id: string) {
    return this.request('DELETE', this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + id);
  }

  async updateRuleForwarding(ruleId: string, data: RuleForwardingData[]) {
    return this.request(
      'PATCH',
      this.CURRENT_PATH + this.CURRENT_RESOURCE + '/' + ruleId + this.RULE_FORWARDING_RESOURCE,
      data,
    );
  }
}
