import React, { createContext } from 'react';
import { useAuthContextValue } from 'auth/hooks/useAuthContext';
import { AuthContextValue } from 'auth/types/auth.types';

const authContextDefaultValue: AuthContextValue = {
  token: '',
  isLoading: false,
  isAuthenticated: false,
  userData: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    date: '',
    currentDeviceID: '',
    projectId: '',
  },
  generateAuthCode: () => new Promise(() => null),
  generateIdToken: () => new Promise(() => null),
  oidcLogin: () => new Promise(() => null),
  setToken: () => null,
  setIsAuthenticated: () => null,
  setUserData: () => null,
};

export const AuthContext = createContext<AuthContextValue>(authContextDefaultValue);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AuthProvider({ children }: { children: any }) {
  const authContextValue = useAuthContextValue();

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
