import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { FlightButton, FlightSelect, FlightTextArea, FlightTextInput, getIcon } from '@flybits/design-system';
import { RootState } from 'reducers';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import {
  CategoriesEntity,
  Components,
  TemplatePayload,
  TemplatesEntityExport,
  TemplatesEntityImport,
} from 'model/manageTemplates';
import { setImportTemplatePayloadState } from 'actions/manageTemplates';
import './UpdateTemplateDetails.scss';
import NewCategoryModal from './NewCategoryModal/NewCategoryModal';

const selectorData = (state: RootState) => ({
  importedFile: state?.manageTemplates?.importedFile,
  importTemplatesPayload: state?.manageTemplates?.importTemplatesPayload,
  importLibraryCategories: state?.manageTemplates?.importLibraryCategories,
  importConflicts: state?.manageTemplates?.importConflicts,
});

export default function UpdateTemplateDetails() {
  const [selectedTemplateID, setSelectedTemplateID] = useState<string>();
  const [categoryModalData, setCategoryModalData] = useState<{ type?: 'category' | 'subcategory'; visible: boolean }>({
    visible: false,
  });

  const { importedFile, importTemplatesPayload, importLibraryCategories, importConflicts } = useSelector(selectorData);
  const dispatch = useDispatch();

  const templateList = useMemo(() => cloneDeep(importTemplatesPayload?.templates || []), [importTemplatesPayload]);
  const categories = useMemo(() => [...(importLibraryCategories?.settings?.categories || [])], [
    importLibraryCategories,
  ]);
  const categoryList: { key: string; name: string | ReactElement }[] = categories
    .filter(category => category.key !== 'all')
    .map(category => ({
      key: category.key,
      name: category.name,
    }));
  const subcategoryList: { key: string; name: string | ReactElement }[] = [];

  let defaultSelectedTemplate: TemplatesEntityExport | undefined;
  let selectedTemplateIndex: number | undefined;
  let selectedTemplate: TemplatesEntityImport | undefined;
  let selectedCategoryKey: string | undefined;
  let selectedCategory: CategoriesEntity | undefined;
  if (selectedTemplateID) {
    selectedTemplateIndex = templateList.findIndex(template => template.payload.id === selectedTemplateID);
    defaultSelectedTemplate = cloneDeep(
      importedFile?.templates?.find(template => template.payload.id === selectedTemplateID),
    );
    selectedTemplate = cloneDeep(templateList.find(template => template.payload.id === selectedTemplateID));
    selectedCategoryKey = selectedTemplate?.payload?.categories?.find(c => c.startsWith('category-'))?.substring(9);
    selectedCategory = categories.find(category => category.key === selectedCategoryKey);
    subcategoryList.push(
      ...(selectedCategory?.subcategories?.map(subcategory => ({ key: subcategory.key, name: subcategory.name })) ||
        []),
    );
  }
  categoryList.unshift({
    key: 'add-new',
    name: <span>{getIcon('addTag', { style: { marginRight: '10px' } })}Add new</span>,
  });

  if (selectedCategoryKey) {
    subcategoryList.unshift({
      key: 'add-new',
      name: <span>{getIcon('addTag', { style: { marginRight: '10px' } })}Add new</span>,
    });
  }

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!selectedTemplate) return;
    const { name, value } = evt.target;
    selectedTemplate.payload = { ...selectedTemplate.payload, [name]: value };
    templateList.splice(selectedTemplateIndex as number, 1, selectedTemplate);
    dispatch(
      setImportTemplatePayloadState({
        ...importTemplatesPayload,
        templates: templateList,
      }),
    );
  };

  const handleImageUploadSuccess = (url?: string) => {
    if (!selectedTemplate) return;
    selectedTemplate.payload = { ...selectedTemplate.payload, imageUrl: url };
    templateList.splice(selectedTemplateIndex as number, 1, selectedTemplate);
    dispatch(
      setImportTemplatePayloadState({
        ...importTemplatesPayload,
        templates: templateList,
      }),
    );
  };

  const handleCategoryChange = (category: { key: string; name: string }, type?: 'category' | 'subcategory') => {
    if (!selectedTemplate) return;

    if (category.key === 'add-new') {
      setCategoryModalData({ type, visible: true });
      return;
    }

    const categories = [];
    if (type === 'category') {
      categories.push(`category-${category.key}`);
    } else {
      categories.push(`category-${selectedCategory?.key}`, `subcategory-${category.key}`);
    }

    selectedTemplate.payload = { ...selectedTemplate.payload, categories };
    templateList.splice(selectedTemplateIndex as number, 1, selectedTemplate);
    dispatch(
      setImportTemplatePayloadState({
        ...importTemplatesPayload,
        templates: templateList,
      }),
    );
  };

  const handleCategoryCreateSuccess = (category: { key: string; name: string }, type?: 'category' | 'subcategory') => {
    handleCategoryChange(category, type);
    setCategoryModalData({ visible: false });
  };

  const resetTemplateDetails = () => {
    if (!selectedTemplate) return;
    templateList.splice(selectedTemplateIndex as number, 1, {
      ...selectedTemplate,
      payload: {
        ...(defaultSelectedTemplate?.payload as TemplatePayload),
        name:
          selectedTemplate.mergeStrategy === 'create_new'
            ? `${defaultSelectedTemplate?.payload?.name}-1`
            : defaultSelectedTemplate?.payload?.name,
      },
    });
    dispatch(
      setImportTemplatePayloadState({
        ...importTemplatesPayload,
        templates: templateList,
      }),
    );
  };

  const hasComponentConflicts = () => !!importConflicts?.componentConflicts?.length;

  const templateHasConflicts = (templateID: string) =>
    !!importConflicts?.templateConflicts?.find(t => t.id === templateID);

  const componentHasConflicts = (componentID: string) =>
    !!importConflicts?.componentConflicts?.find(t => t.id === componentID);

  const componentAlreadyExists = (componentID: string) =>
    componentHasConflicts(componentID) ||
    Object.values(importConflicts?.componentsWithoutConflicts)?.some(componentData =>
      componentData?.includes(componentID),
    );

  const getAutomaticMergeStrategy = (
    hasConflict: boolean,
    exists: boolean,
    allParentsSkipped: boolean,
    isContextPlugin: boolean,
  ) => {
    if (allParentsSkipped) {
      return 'skip';
    }
    if (isContextPlugin) {
      if (hasConflict || exists) {
        return 'skip'; // alert user about this
      }
      return 'create_new'; // it doesn't exist so we create
    }
    // only !isContextPlugin from this point on
    return 'overwrite';
  };

  const getParentIds = (componentId: string, type: string, parentComps?: any) => {
    let ids: string[] = [];
    const payload = parentComps || importTemplatesPayload.components;
    switch (type) {
      case 'rule_template':
        ids = payload.relationships?.ruleTemplatesParents[componentId]?.journeyIDs || [];
        break;
      case 'push_template':
        ids = payload.relationships?.pushTemplatesParents[componentId]?.journeyIDs || [];
        break;
      case 'webhook_template':
        ids = payload.relationships?.webhookTemplatesParents[componentId]?.journeyIDs || [];
        break;
      case 'content_template_prototype':
        ids = payload.relationships?.contentTemplatePrototypesParents[componentId]?.journeyIDs || [];
        break;
      case 'content_template':
        ids = payload.relationships?.contentTemplatesParents[componentId]?.contentTemplatePrototypeIDs || [];
        break;
      case 'context_plugin':
        ids = payload.relationships?.contextPluginsParents[componentId]?.ruleTemplateIDs || [];
        break;
      default:
        break;
    }
    return ids;
  };
  const getComponentType = (componentID: string) =>
    importConflicts?.componentConflicts?.find(t => t.id === componentID)?.component || '';
  const getOriginalName = (templateID: string) =>
    importTemplatesPayload.templates?.find(t => t.payload.id === templateID)?.payload.name || '';

  const areAllParentsSkipped = (parents: string[], type: string, updatedParents?: Components) => {
    const payload = updatedParents || importTemplatesPayload.components;
    if ('context_plugin' === type) {
      return !!payload.ruleTemplates
        ?.filter(tpl => parents.includes(tpl.payload.id))
        .every(tpl => tpl.mergeStrategy === 'skip');
    }
    if ('content_template' === type) {
      return !!payload.contentTemplatePrototypes
        ?.filter(tpl => parents.includes(tpl.payload.id))
        .every(tpl => tpl.mergeStrategy === 'skip');
    }
    return !!importTemplatesPayload.templates
      ?.filter(tpl => parents.includes(tpl.payload.id))
      .every(tpl => tpl.mergeStrategy === 'skip');
  };
  // FOR COMPONENTS

  const addMergeStrategyToAllComponents = (componentsObject: Components) => {
    const processComponent = (component: any, isContextPlugin: boolean, type: string, parentsData?: Components) =>
      component?.map((c: any) => {
        const parentIds = getParentIds(c.id, getComponentType(c.id) || type, parentsData);

        let strategy = getAutomaticMergeStrategy(
          componentHasConflicts(c.id),
          componentAlreadyExists(c.id),
          areAllParentsSkipped(parentIds, getComponentType(c.id) || type, parentsData),
          isContextPlugin,
        );

        if (hasComponentConflicts()) {
          // has conflicts, lets check for this component
          if (
            (componentHasConflicts(c.id) && !isContextPlugin) ||
            !areAllParentsSkipped(parentIds, type, parentsData)
          ) {
            // user decided with fallback
            strategy = c.mergeStrategy || strategy;
          }
        }

        return {
          ...c,
          mergeStrategy: strategy,
        };
      }) || component;

    const componentParents = {
      ...componentsObject,
      ruleTemplates: processComponent(componentsObject.ruleTemplates, false, 'rule_template'),
      contentTemplatePrototypes: processComponent(
        componentsObject.contentTemplatePrototypes,
        false,
        'content_template_prototype',
      ),
      pushTemplates: processComponent(componentsObject.pushTemplates, false, 'push_template'),
      webhookTemplates: processComponent(componentsObject.webhookTemplates, false, 'webhook_template'),
    };
    const componentChildren = {
      contentTemplates: processComponent(
        componentParents.contentTemplates,
        false,
        'content_template',
        componentParents,
      ),
      contextPlugins: processComponent(componentParents.contextPlugins, true, 'context_plugin', componentParents),
    };

    return {
      ...componentsObject,
      ...componentParents,
      ...componentChildren,
    };
  };
  useEffect(() => {
    // update template names and strategy on first load
    const importTemplates = templateList.map(t => {
      return {
        ...t,
        mergeStrategy: templateHasConflicts(t.payload.id)
          ? t.mergeStrategy
          : importConflicts.existingTemplatesWithoutConflicts?.includes(t.payload.id) ||
            !templateHasConflicts(t.payload.id)
          ? 'overwrite'
          : 'create_new',
        payload: {
          ...t.payload,
          name:
            t.mergeStrategy === 'create_new'
              ? `${t.payload.name}${getOriginalName(t.payload.id) !== t.payload.name ? '' : '-1'}`
              : t.payload.name,
        },
      };
    });
    // Adds mergeStrategy to the ones missing
    const importComponents = !hasComponentConflicts()
      ? addMergeStrategyToAllComponents(importTemplatesPayload.components)
      : importTemplatesPayload.components;
    dispatch(
      setImportTemplatePayloadState({
        ...importTemplatesPayload,
        templates: importTemplates,
        components: importComponents,
      }),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!selectedTemplateID) return;
    setSelectedTemplateID(templateList?.[0]?.payload?.id);
  }, [selectedTemplateID, templateList]);

  return (
    <div className="update-template-details">
      {!!categoryModalData.visible && (
        <NewCategoryModal
          onSuccess={handleCategoryCreateSuccess}
          categoryType={categoryModalData.type}
          handleClose={() => setCategoryModalData({ visible: false })}
          parentCategory={selectedCategory}
        />
      )}
      <div className="update-template-details__custom-template">
        <h3 className="update-template-details__column-title">Custom Template</h3>
        <ul className="update-template-details__template-list">
          {templateList.map(template => (
            <li key={template.payload.id}>
              <button
                type="button"
                className={`update-template-details__template-list__button${
                  selectedTemplateID === template.payload.id
                    ? ' update-template-details__template-list__button--is-selected'
                    : ''
                }`}
                onClick={() => setSelectedTemplateID(template.payload.id)}
              >
                <small className="update-template-details__template-list__category">
                  {
                    categories.find(
                      category =>
                        category.key ===
                        template?.payload?.categories?.find(c => c.startsWith('category-'))?.substring(9),
                    )?.name
                  }
                </small>
                <span className="update-template-details__template-list__name">{template.payload.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="update-template-details__template-details">
        <h3 className="update-template-details__column-title">Details</h3>
        <div className="update-template-details__form">
          <div className="update-template-details__form__column">
            <div className="update-template-details__field">
              <label>Template name</label>
              <FlightTextInput
                iconInput="editOutline"
                width="100%"
                name="name"
                value={selectedTemplate?.payload?.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="update-template-details__field">
              <label>Template category</label>
              <FlightSelect
                width="100%"
                className="update-template-details__select"
                label="Select a category"
                options={categoryList}
                selected={categoryList.find(
                  category =>
                    category.key ===
                    selectedTemplate?.payload?.categories?.find(c => c.startsWith('category-'))?.substring(9),
                )}
                iconLeft="categories"
                handleOptionClick={(value: { key: string; name: string }) => handleCategoryChange(value, 'category')}
              />
            </div>
            <div className="update-template-details__field">
              <label>Template subcategory</label>
              <FlightSelect
                width="100%"
                className="update-template-details__select"
                label="Select a subcategory"
                options={subcategoryList}
                selected={subcategoryList.find(
                  subcategory =>
                    subcategory.key ===
                    selectedTemplate?.payload?.categories?.find(c => c.startsWith('subcategory-'))?.substring(12),
                )}
                iconLeft="subcategoryTree"
                handleOptionClick={(value: { key: string; name: string }) => handleCategoryChange(value, 'subcategory')}
              />
            </div>
          </div>
          <div className="update-template-details__form__column">
            <div className="update-template-details__field">
              <label htmlFor="template-image">Template image</label>
              <ImageUpload
                defaultValue={defaultSelectedTemplate?.payload?.imageUrl}
                value={selectedTemplate?.payload?.imageUrl}
                maxWidth={750}
                onSuccess={handleImageUploadSuccess}
              />
            </div>
            <div className="update-template-details__field">
              <label>Description</label>
              <FlightTextArea
                maxLength={500}
                label=""
                name="desc"
                value={selectedTemplate?.payload?.desc}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="update-template-details__reset-template">
          Reset this template&apos;s details to original state at the time of import.
          <FlightButton theme="secondary" label="Reset Details" iconLeft="reset" onClick={resetTemplateDetails} />
        </div>
      </div>
    </div>
  );
}
