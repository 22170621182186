function beginsWithNumberOrLetter(value: string) {
  const reg = RegExp(/^[A-Z0-9]/i);
  return value.length > 0 && reg.test(value);
}

function containsNoSpecialCharacters(value: string) {
  const reg = RegExp(/^[-A-Z0-9]*$/i);
  return reg.test(value);
}

export { beginsWithNumberOrLetter, containsNoSpecialCharacters };
