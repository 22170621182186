import React from 'react';
import { ReactComponent as ExportPlaceholder } from '../../../assets/images/export-download-placeholder.svg';
import './ExportDownload.scss';

interface Props {
  exportDownload: () => void;
}

export function ExportDownload(props: Props) {
  const { exportDownload } = props;
  return (
    <div className="export-download">
      <ExportPlaceholder />
      <span>Your templates will start downloading shortly. </span>
      <span className="export-download__clickable-text" onClick={exportDownload}>
        Click here{' '}
      </span>
      <span className="export-download__text">&nbsp;if the download doesn’t start in the next 10 seconds.</span>
    </div>
  );
}
