export interface WebhookState {
  webhook: Webhook;
  webhooks: Webhook[];
}

export interface Webhook {
  id: string;
  name: string;
  description?: string;
  url: string;
  method: string;
  payload?: Payload;
  payloads?: Payload[];
  headers?: object;
  certificateName?: string;
  triggers?: Triggers;
  lastUpdatedAt: number;
}

interface Payload {
  [field: string]: string;
}

export interface Triggers {
  [name: string]: Rule[];
}

export interface Rule {
  id: string;
  name: string;
}

export interface WebhookFormikValues {
  name: string;
  description: string;
  url: string;
  method: string;
  payloadCode: string;
  headerKeys: string[];
  headerValues: string[];
  triggers: Triggers;
}

export interface RuleForwardingData {
  op: 'add' | 'remove' | string;
  dest: 'webhook' | string;
  id: string;
  actions?: string[];
}

export interface GetWebhooksParams {
  limit?: number;
  offset?: number;
  id?: string;
  search?: string;
  sort?: string;
}

export enum WebhookActionTypes {
  SET_WEBHOOKS_STATE = 'SET_WEBHOOKS_STATE',
  SET_WEBHOOK_STATE = 'SET_WEBHOOK_STATE',
}

interface WebhookActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type WebhookAction =
  | WebhookActionCreatorType<typeof WebhookActionTypes.SET_WEBHOOKS_STATE, Webhook>
  | WebhookActionCreatorType<typeof WebhookActionTypes.SET_WEBHOOK_STATE, Webhook>;
