const ACS_URL_REQUIRED = 'Required.';
const ARRAY_FIELD_REQUIRED = 'Required.';
const API_DEFAULT_ERROR_RESPONSE_TYPE = 'NotFound';
const APPID_REQUIRED = 'Required.';
const APP_SECRET_REQUIRED = 'Required.';
const BODY_REQUIRED = 'Required';
const BUNDLE_REQUIRED = 'Please enter bundle ID';
const CATEGORY_REQUIRED = 'Required';
const CERTIFICATE_REQUIRED = 'Required.';
const CLIENT_REQUIRED = 'Required.';
const DESCRIPTION_REQUIRED = 'Required.';
const EMAIL_REQUIRED = 'Required.';
const EMAIL_VALIDATION = 'Please enter a valid email address.';
const GENERIC_REQUIRED = 'Required.';
const HOST_REQUIRED = 'Required.';
const IMAGE_VALIDATION = 'Please upload header logo image.';
const ISSUER_REQUIRED = 'Required.';
const INVALID_DOMAIN = 'Invalid Domain.';
const JSON_VALIDATION = 'Please provide valid JSON input.';
const KEY_REQUIRED = 'Required.';
const KEY_CANNOT_BEGIN = 'Key cannot begin with a number.';
const MUST_BE_INT = 'Must be an integer.';
const MUST_BE_FLOAT = 'Must be a float value.';
const MUST_BE_BOOLEAN = 'Must be either true or false.';
const VALID_ICON = 'Must be a valid icon url';
const NO_SPECIAL_CHARACTERS = 'Only letters numbers and dashes allowed';
const NAME_REQUIRED = 'Required.';
const NAME_TWO_CHARS_REQUIRED = 'Name must be atleast two characters.';
const ONLY_LOWER_CASE = 'Only lowercase, numbers and dashes allowed.';
const PASSWORD_REQUIRED = 'Required.';
const PERCENTAGE_REQUIRED = 'Please enter a limit %.';
const PUBLIC_CERT_REQUIRED = 'Required.';
const REDIRECT_URI_REQUIRED = 'Required.';
const SERVER_KEY_REQUIRED = 'Enter a server key';
const SERVER_ID_REQUIRED = 'Required.';
const SECRET_KEY_REQUIRED = 'Required.';
const SIGNATURE_REQUIRED = 'Please enter a signature';
const SUBDOMAIN_REQUIRED = 'Required';
const SUBDOMAIN_TWO_CHARS_REQUIRED = 'Subdomain must be atleast two characters.';
const SUBJECT_REQUIRED = 'Required';
const TEAM_REQUIRED = 'Please enter Team ID';
const VALID_URL = 'Please enter a valid URL';
const VALUE_REQUIRED = 'Required';

export {
  ACS_URL_REQUIRED,
  ARRAY_FIELD_REQUIRED,
  API_DEFAULT_ERROR_RESPONSE_TYPE,
  APPID_REQUIRED,
  APP_SECRET_REQUIRED,
  BODY_REQUIRED,
  BUNDLE_REQUIRED,
  CATEGORY_REQUIRED,
  CERTIFICATE_REQUIRED,
  CLIENT_REQUIRED,
  DESCRIPTION_REQUIRED,
  EMAIL_REQUIRED,
  EMAIL_VALIDATION,
  GENERIC_REQUIRED,
  HOST_REQUIRED,
  ISSUER_REQUIRED,
  IMAGE_VALIDATION,
  INVALID_DOMAIN,
  JSON_VALIDATION,
  KEY_REQUIRED,
  KEY_CANNOT_BEGIN,
  MUST_BE_INT,
  MUST_BE_FLOAT,
  MUST_BE_BOOLEAN,
  VALID_ICON,
  NO_SPECIAL_CHARACTERS,
  NAME_REQUIRED,
  NAME_TWO_CHARS_REQUIRED,
  ONLY_LOWER_CASE,
  PASSWORD_REQUIRED,
  PUBLIC_CERT_REQUIRED,
  PERCENTAGE_REQUIRED,
  REDIRECT_URI_REQUIRED,
  SERVER_KEY_REQUIRED,
  SERVER_ID_REQUIRED,
  SECRET_KEY_REQUIRED,
  SIGNATURE_REQUIRED,
  SUBDOMAIN_REQUIRED,
  SUBDOMAIN_TWO_CHARS_REQUIRED,
  SUBJECT_REQUIRED,
  TEAM_REQUIRED,
  VALID_URL,
  VALUE_REQUIRED,
};
