import React, { useState, useRef } from 'react';
import { Form, FormGroup, Container, Row } from 'reactstrap';
import { HuaweiSettings } from 'model/pushSettings/settings';
import './HuaweiSettings.scss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { VALUE_REQUIRED } from 'constants/errors/errors';
import * as HuaweiActions from 'actions/pushSettings/huaweiSettings';
import { useActions } from 'actions';
import { ReactComponent as HuaweiLogo } from 'assets/images/huawei.svg';
import { FlightButton, FlightModal, FlightTextArea, FlightTextInput, getIcon } from '@flybits/design-system';
import { trimSpaceRegExp } from 'helpers/trimSpaceRegExp';
interface Props {
  huaweiSettingsFromState?: HuaweiSettings;
}

const validationSchema = Yup.object().shape({
  key1: Yup.string().required(VALUE_REQUIRED),
  key2: Yup.string().required(VALUE_REQUIRED),
});

export default function HuaweiSettingCard(props: Props) {
  const { huaweiSettingsFromState } = props;
  const pushActions = useActions(HuaweiActions);
  const textInputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isSecretFieldDisplay, setSecretFieldDisplay] = useState(false);

  const key1 = huaweiSettingsFromState?.appID || '';
  const key2 = huaweiSettingsFromState?.clientCredentials || '';
  const key3 = huaweiSettingsFromState?.clientSecretLast4 || '';

  const maskKey = '000000';
  function maskKeyField() {
    return maskKey.replace(/.(?=.{0,}$)/g, '*');
  }

  const toggle = () => {
    setOpen(!open);
    setSecretFieldDisplay(false);
  };
  const confirmToggle = () => setOpenConfirm(!openConfirm);

  function deleteHuaweiSettings() {
    pushActions.deleteHuaweiSettings();
    confirmToggle();
  }

  return (
    <div className="push-settings">
      <Formik
        enableReinitialize
        initialValues={{ key1: '' || key1, key2: '' || key2, key3: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const appID = trimSpaceRegExp(values.key1);
          const clientCredentials = trimSpaceRegExp(values.key2);
          const clientSecret = trimSpaceRegExp(values.key3);
          if (huaweiSettingsFromState !== undefined && !key1) {
            pushActions.postHuaweiSettings({
              appID,
              clientCredentials,
              clientSecret,
            });
          } else {
            const payload = { appID, clientCredentials };
            if (values.key3) {
              Object.assign(payload, { clientSecret });
            }
            if (values.key1 !== key1 || values.key2 !== key2 || values.key3) {
              pushActions.patchHuaweiSettings(payload);
            }
          }
          setSubmitting(true);
          resetForm();
          toggle();
        }}
      >
        {({ errors, values, touched, handleChange, handleBlur, handleSubmit, handleReset }) => (
          <div>
            <FlightModal
              isVisible={open}
              size="medium"
              toggleModalShown={(e: any) => {
                toggle();
                handleReset(e);
              }}
              className="push-settings__modal"
              header={<span>EMUI Credentials</span>}
              content={
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="push-settings__modal__content field-group">
                    <Field
                      type="text"
                      name="key1"
                      id="appId"
                      hideCharCounter={true}
                      label="Input your App ID"
                      as={FlightTextArea}
                      width="100%"
                      hasError={errors.key1 && touched.key1}
                      errorMessage={errors.key1}
                      placeholderText="App ID"
                      defaultValue={values.key1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="text"
                      name="key2"
                      id="clientCredential"
                      hideCharCounter={true}
                      label="Input your Client ID"
                      as={FlightTextArea}
                      width="100%"
                      hasError={errors.key2 && touched.key2}
                      errorMessage={errors.key2}
                      placeholderText="Client ID"
                      defaultValue={values.key2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {key3 ? (
                      <div className="clientSecret">
                        <label className="clientSecret--label">Client Secret</label>
                        {!isSecretFieldDisplay ? (
                          <div className="clientSecret--value">
                            {maskKeyField()}
                            {key3}
                            <span onClick={() => setSecretFieldDisplay(true)}>
                              {getIcon('pencil', { className: 'clientSecret--edit' })}
                            </span>
                          </div>
                        ) : (
                          <span ref={textInputRef} className="clientSecret--field">
                            <Field
                              type="text"
                              name="key3"
                              id="clientSecret"
                              as={FlightTextInput}
                              placeholderText="Client Secret"
                              defaultValue={values.key3}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </span>
                        )}
                      </div>
                    ) : (
                      <Field
                        type="text"
                        name="key3"
                        id="clientSecret"
                        hideCharCounter={true}
                        label="Input your Client Secret"
                        as={FlightTextArea}
                        width="100%"
                        hasError={huaweiSettingsFromState !== undefined && !key1 && !values.key3 && touched.key3}
                        errorMessage={'Required'}
                        placeholderText="Client Secret"
                        defaultValue={values.key3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormGroup>
                </Form>
              }
              footer={
                <div className="modal-footer">
                  <FlightButton
                    theme="secondary"
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                      toggle();
                      handleReset(e);
                    }}
                    label="Cancel"
                  />
                  <FlightButton
                    type="submit"
                    label="Save"
                    onClick={handleSubmit}
                    disabled={!values.key1 || !values.key2 || (!key1 && !values.key3)}
                  />
                </div>
              }
            />
          </div>
        )}
      </Formik>
      <div>
        <FlightModal
          isVisible={openConfirm}
          size="medium"
          toggleModalShown={confirmToggle}
          className="push-settings__modal"
          header={<span>Confirm</span>}
          content={<p>Are you sure you want to delete your EMUI credentials?</p>}
          footer={
            <div className="modal-footer">
              <FlightButton theme="secondary" onClick={confirmToggle} label="Cancel" />
              <FlightButton type="submit" label="Continue" onClick={deleteHuaweiSettings} />
            </div>
          }
        />
      </div>
      <Container>
        <Row>
          <HuaweiLogo />
          <span id="emui-container-heading" className="push-settings__header">
            <h2>EMUI Authentication</h2>
          </span>
        </Row>
        <Row>
          {!key1 && !key2 && !key3 ? (
            <span className="push-settings__body">You haven&apos;t specified your EMUI credentials.</span>
          ) : (
            <div className="push-settings__content">
              {key1 && (
                <div className="push-settings__info">
                  <span className="property">App ID</span> <span className="value">{key1}</span>
                </div>
              )}
              {key2 && (
                <div className="push-settings__info">
                  <span className="property">Client ID</span>
                  <span className="value">{key2}</span>
                </div>
              )}
              {key3 && (
                <div className="push-settings__info">
                  <span className="property">Client Secret</span>
                  <div className="value key">
                    {maskKeyField()}
                    {key3}
                  </div>
                </div>
              )}
            </div>
          )}
        </Row>
        <Row>
          <FlightButton
            label={!key1 && !key2 && !key3 ? 'Add Credentials' : 'Change'}
            size="medium"
            theme={!key1 ? 'secondary' : 'link'}
            iconRight={!key1 ? 'baselineArrowRight' : ''}
            className="push-settings__btn--blue"
            onClick={toggle}
            isAriaDescribedBy
            ariaDescribedBy={'emui-container-heading'}
          />
          <FlightButton
            label={!key1 ? '' : 'Remove'}
            size="medium"
            theme="link"
            className="push-settings__btn--red"
            onClick={confirmToggle}
            isAriaDescribedBy
            ariaDescribedBy={'emui-container-heading'}
            ariaLabel="Remove"
          />
        </Row>
      </Container>
    </div>
  );
}
