import React, { useState, useEffect } from 'react';
import './CreatePushTemplate.scss';
import {
  FlightModal,
  FlightButton,
  FlightTextInput,
  FlightRadioButton,
  FlightTooltip,
  FlightCheckbox,
} from '@flybits/design-system';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  NAME_REQUIRED,
  GENERIC_REQUIRED,
  MUST_BE_INT,
  MUST_BE_FLOAT,
  KEY_REQUIRED,
  KEY_CANNOT_BEGIN,
} from 'constants/errors/errors';
import { Row, Col } from 'reactstrap';
import { ReactComponent as QuestionMark } from 'assets/images/question-mark.svg';
import ArrayFields from 'components/Shared/ArrayFields/ArrayFields';
import {
  PUSH_NOTIF_MODAL_LABEL,
  PUSH_NOTIF_MODAL_KEY,
  PUSH_NOTIF_MODAL_REQUIRED,
  PUSH_NOTIF_MODAL_TRACKED,
} from 'constants/tooltips/tooltips';

const REGEX_NO_NUMBER_START = /[a-zA-Z_][a-zA-Z0-9_]*/;
const validationSchema = Yup.object().shape({
  fieldName: Yup.string().required(NAME_REQUIRED),
  key: Yup.string()
    .required(KEY_REQUIRED)
    .matches(REGEX_NO_NUMBER_START, KEY_CANNOT_BEGIN),
});

const integerRegex = /^\+?\d+$/;
const validateInteger = Yup.object().shape({
  values: Yup.array().of(
    Yup.string()
      .required(GENERIC_REQUIRED)
      .matches(integerRegex, MUST_BE_INT),
  ),
});

const validateString = Yup.object().shape({
  values: Yup.array().of(Yup.string().required(GENERIC_REQUIRED)),
});

const floatRegex = /^[+-]?\d+(\.\d+)?$/;
const validateFloat = Yup.object().shape({
  values: Yup.array().of(
    Yup.string()
      .required(GENERIC_REQUIRED)
      .matches(floatRegex, MUST_BE_FLOAT),
  ),
});

interface ModalState {
  header: string;
  type: 'primitive' | 'dropdown' | ' url' | 'entity' | 'image' | 'json' | string;
  description: string;
  isDropdown: boolean;
  isBool?: boolean;
  fieldName: string;
  valueType?: string;
  valueOptions?: Array<string>;
}

interface CheckboxState {
  state: 'SELECTED' | 'UNSELECTED' | string;
}

interface RadioButtonState {
  state: 'string' | 'int' | 'float' | string;
}

interface SchemaType {
  description: string;
  isRequiredField: boolean;
  type: string | undefined;
  valueType: string | undefined;
  valueOptions: Array<string>;
  isTracked: boolean;
}

interface LocalSchema {
  [key: string]: SchemaType;
}

interface Props {
  openModal: boolean;
  modalState: ModalState;
  toggleModal: () => void;
  callback: (data: LocalSchema) => void;
  listOfKeys: Array<string>;
}

export default function CreatePushTemplateModal(props: Props) {
  const { openModal, toggleModal, modalState, callback, listOfKeys } = props;
  const arrValues = modalState?.valueOptions || [''];
  const [valuesFromArrayFields, setValuesFromArrayFields] = useState<Array<string>>([]);
  const getValuesFromArrayFields = (data: string) => {
    setValuesFromArrayFields([...valuesFromArrayFields.splice(0)]);
    setValuesFromArrayFields([data]);
  };
  const initialFormValues = {
    fieldName: modalState?.fieldName,
    fieldDescription: '',
    key: '',
  };

  const [hasError, setHasError] = useState(false);
  const getErrorState = (data: boolean) => {
    setHasError(data);
  };

  const [isRequiredField, setIsRequiredField] = useState<CheckboxState>();
  const [isTracked, setIsTracked] = useState<CheckboxState>();
  const [radioButtonState, setRadioButtonState] = useState<RadioButtonState>({
    state: 'string',
  });

  useEffect(() => {
    setRadioButtonState({ state: modalState.valueType || 'string' });
  }, [modalState.valueType]);

  const handleSetRequiredField = () => {
    if (isRequiredField?.state === 'SELECTED') {
      setIsRequiredField({ state: 'UNSELECTED' });
    } else {
      setIsRequiredField({ state: 'SELECTED' });
    }
  };

  const handleSetTrackedField = () => {
    if (isTracked?.state === 'SELECTED') {
      setIsTracked({ state: 'UNSELECTED' });
    } else {
      setIsTracked({ state: 'SELECTED' });
    }
  };

  const handleRadioButtonState = (type: string) => {
    setRadioButtonState({ state: type });
  };

  const handleCancel = () => {
    setIsTracked({ state: 'UNSELECTED' });
    setIsRequiredField({ state: 'UNSELECTED' });
    setRadioButtonState({ state: 'string' });
    toggleModal();
  };

  const setValidationSchema = () => {
    if (radioButtonState?.state === 'float') {
      return validateFloat;
    } else if (radioButtonState?.state === 'int') {
      return validateInteger;
    } else {
      return validateString;
    }
  };

  return (
    <div className="create-push-template">
      <Col>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            const fieldValues = {
              description: values.fieldName,
              isRequiredField: isRequiredField?.state === 'SELECTED' ? true : false,
              type: modalState?.type,
              valueType: !modalState?.isBool ? radioButtonState.state : 'bool',
              valueOptions: modalState?.type === 'dropdown' ? valuesFromArrayFields.flat() : [],
              isTracked: isTracked?.state === 'SELECTED' ? true : false,
            };
            callback({ [values.key]: fieldValues });
            resetForm();
            setIsTracked({ state: 'UNSELECTED' });
            setIsRequiredField({ state: 'UNSELECTED' });
            setRadioButtonState({ state: 'string' });
            toggleModal();
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, isValid, errors, touched }) => (
            <div>
              <FlightModal
                isVisible={openModal}
                size="large"
                className="create-push-template__modal"
                toggleModalShown={() => {
                  resetForm();
                  handleCancel();
                }}
                header={<span>{modalState?.header}</span>}
                content={
                  <div className="create-push-template__modal__content">
                    <div className="pb-3">{modalState?.description}</div>
                    <span className="pt-3 create-push-template__fields">
                      Label
                      <FlightTooltip direction="right bottom" isEnabled={true} description={PUSH_NOTIF_MODAL_LABEL}>
                        <QuestionMark className="ml-2" />
                      </FlightTooltip>
                    </span>
                    <Row xs={12}>
                      <Field
                        type="text"
                        name="fieldName"
                        className="mt-2"
                        as={FlightTextInput}
                        width="332px"
                        hasError={touched.fieldName && errors.fieldName ? true : false}
                        value={values.fieldName}
                        errorMessage={<span>{errors.fieldName}</span>}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          handleChange(e);
                          if (!touched.key) {
                            setFieldValue(
                              'key',
                              e.currentTarget.value.replace(/[^a-zA-Z ]/g, 'f') +
                                Math.random()
                                  .toString(36)
                                  .substring(7),
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        ariaLabel="Label"
                      />
                    </Row>
                    <span className="pt-3 create-push-template__fields">
                      Key
                      <FlightTooltip direction="right bottom" isEnabled={true} description={PUSH_NOTIF_MODAL_KEY}>
                        <QuestionMark className="ml-2" />
                      </FlightTooltip>
                    </span>
                    <Row xs={12}>
                      <Field
                        type="text"
                        name="key"
                        className="mt-2"
                        as={FlightTextInput}
                        width="332px"
                        hasError={(touched.key && errors.key) || listOfKeys.includes(values.key) ? true : false}
                        value={values.key}
                        errorMessage={<span>{errors.key || 'Key already exists'}</span>}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ariaLabel="key"
                      />
                    </Row>
                    {(!modalState?.isBool && modalState?.type === 'primitive') || modalState?.type === 'dropdown' ? (
                      <div>
                        <div className="pt-3 create-push-template__fields">Input Type</div>
                        <Row xs={6} className="pt-3">
                          <FlightRadioButton
                            label="String"
                            checked={radioButtonState?.state === 'string' ? true : false}
                            onSelect={() => handleRadioButtonState('string')}
                            value={radioButtonState?.state}
                          />
                          <FlightRadioButton
                            label="Integer"
                            checked={radioButtonState?.state === 'int' ? true : false}
                            onSelect={() => handleRadioButtonState('int')}
                            value={radioButtonState?.state}
                          />
                          <FlightRadioButton
                            label="Float"
                            checked={radioButtonState?.state === 'float' ? true : false}
                            onSelect={() => handleRadioButtonState('float')}
                            value={radioButtonState?.state}
                          />
                        </Row>
                      </div>
                    ) : null}
                    {modalState?.isDropdown ? (
                      <div className="create-push-template__modal__content__array-fields">
                        <ArrayFields
                          callback={getValuesFromArrayFields}
                          initialValues={{ values: arrValues }}
                          hasError={getErrorState}
                          validationSchema={setValidationSchema}
                          width={'550px'}
                        />
                      </div>
                    ) : null}
                    <Row>
                      <FlightCheckbox
                        label="This is a required field"
                        className="create-push-template__modal__content__checkbox"
                        checkState={isRequiredField?.state}
                        onSelect={handleSetRequiredField}
                      />{' '}
                      <FlightTooltip direction="right bottom" isEnabled={true} description={PUSH_NOTIF_MODAL_REQUIRED}>
                        <QuestionMark className="create-push-template__modal__content__question-mark" />
                      </FlightTooltip>
                    </Row>
                    <Row>
                      <FlightCheckbox
                        label="Track this field for analytics"
                        className="create-push-template__modal__content__checkbox"
                        checkState={isTracked?.state}
                        onSelect={handleSetTrackedField}
                      />{' '}
                      <FlightTooltip direction="top" isEnabled={true} description={PUSH_NOTIF_MODAL_TRACKED}>
                        <QuestionMark className="create-push-template__modal__content__question-mark" />
                      </FlightTooltip>
                    </Row>
                  </div>
                }
                footer={
                  <div className="create-push-template__modal__footer">
                    <FlightButton
                      onClick={() => {
                        resetForm();
                        handleCancel();
                      }}
                      label="Cancel"
                      theme="secondary"
                    />
                    <FlightButton
                      type="submit"
                      label="Add Field"
                      disabled={hasError || !isValid || listOfKeys.includes(values.key)}
                      className="create-push-template__modal__footer__save-btn"
                      onClick={handleSubmit}
                    />
                  </div>
                }
              />
            </div>
          )}
        </Formik>
      </Col>
    </div>
  );
}
