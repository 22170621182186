import { DatasourceActionTypes } from 'model/datasource';
import DatasourcesService from 'services/datasources.service';
import { makeActionCreator, makeFetchActionCreator } from 'helpers/actionCreator';

export const setDatasourcesState = makeActionCreator(DatasourceActionTypes.SET_DATASOURCES_STATE);

export const fetchAllDatasources = makeFetchActionCreator(
  new DatasourcesService(),
  'getAllDatasources',
  setDatasourcesState,
  'data',
);
