import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty, isEqual } from 'lodash';
import {
  ContentTemplate,
  ContentTemplatesDownload,
  ContentTemplateData,
  ContentTemplateAttributes,
} from 'model/contentTemplates';
import './ContentTemplates.scss';
import { FlightButton, FlightOverflowMenu, FlightSelect, FlightTable } from '@flybits/design-system';
import ContentIframeParent from './ContentIframeParent';
import Megaphone from 'assets/images/mega-icon.svg';
import FullPageOverlay from './FullPageOverlay';
import { searchWithRegExp } from 'helpers/searchWithRegExp';
import { useActions } from 'actions';
import * as ContentTemplatesActions from 'actions/contentTemplates';
import { useDropzone } from 'react-dropzone';
import { downloadAsJson } from 'helpers/downloadAsJson';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import { FlightSelectOptions } from 'model/misc/flightSelectOptions';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import { useFeatureFlagsContextValue } from 'providers/flagsmith/useFeatureFlagsContext';
import { useHistory } from 'react-router-dom';

interface Props {
  contentTemplates?: ContentTemplate[];
  handleCreateContentTemplate: () => void;
}

interface TableData {
  key: string | undefined;
  icon?: JSX.Element;
  name?: JSX.Element;
  description?: JSX.Element;
  moreOpts?: JSX.Element;
  hidenVal?: ContentTemplate;
  metadata?: {
    isMultiSelectDisabled?: boolean;
  };
}

const categoriesOptions = [
  {
    key: 'default',
    name: 'All templates',
  },
  {
    key: 'starter',
    name: 'Starter templates',
  },
  {
    key: 'custom',
    name: 'My templates',
  },
];

const tableHeaders = [
  {
    name: 'Name',
    key: 'icon',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: '',
    key: 'name',
    isVisible: true,
    hideTooltip: true,
  },

  {
    name: 'Description',
    key: 'description',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Options',
    key: 'moreOpts',
    isVisible: true,
    hideTooltip: true,
  },
];

const confirmModalTitle = 'Confirm delete';
const confirmModalMessage = 'Are you sure you want to delete this template?';

enum ConfirmModalState {
  HIDDEN = 'HIDDEN',
  STATUS = 'STATUS',
  DELETE = 'DELETE',
}

export default function ContentTemplatesList(props: Props) {
  const { contentTemplates = [], handleCreateContentTemplate } = props;
  const [isTemplateEditIframeVisible, setIsTemplateEditIframeVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const contentTemplatesActions = useActions(ContentTemplatesActions);
  const [templateId, setTemplateId] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<FlightSelectOptions>({ key: 'default', name: 'All templates' });
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [multiSelectTemplate, setMultiSelectTemplate] = useState<ContentTemplate[]>([]);
  const [tableSelectedData, setTableSelectedData] = useState(['']);
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(ConfirmModalState.HIDDEN);
  const [selectedTemplate, setSelectedTemplate] = useState<ContentTemplate[]>([]);
  const { isLoading: isFeatureFlagsLoading, featureFlags } = useFeatureFlagsContextValue();
  const history = useHistory();
  const environment = process.env.REACT_APP_BUILD_ENVIRONMENT;

  useEffect(() => {
    document.title = 'Content Templates | Developer Portal @ Flybits';
  }, []);

  const handleExport = (exports: Array<string>) => {
    const exportables: ContentTemplate[] = contentTemplates.filter(item => exports.includes(item.id));
    const template: ContentTemplateData[] = [];
    exportables.forEach(item => {
      template.push({
        id: item.id,
        attributes: {
          createdAt: item.createdAt,
          iconUrl: item?.iconUrl,
          localizations: item?.localizations,
          modifiedAt: item?.modifiedAt,
          schema: item?.schema,
          templateId: item?.id,
          tenantId: item?.tenantId,
          type: item?.type,
          labels: item?.labels,
          defaultValues: item?.defaultValues,
        },
        type: 'content-template',
      });
    });

    const downloadData: ContentTemplatesDownload = {
      data: template,
      meta: {
        downloadedAt: Date.now(),
        totalRecords: template.length,
      },
    };
    downloadAsJson(Date.now().toString(), JSON.stringify(downloadData), 'content-templates');
  };

  const handleImport = async (args: string) => {
    const parseData: ContentTemplatesDownload = JSON.parse(args);
    const contentForUpload: ContentTemplateAttributes[] = [];
    parseData.data.forEach(item => {
      contentForUpload.push({
        createdAt: Date.now(),
        iconUrl: item.attributes.iconUrl,
        localizations: item.attributes.localizations,
        modifiedAt: Date.now(),
        schema: item.attributes.schema,
        type: item.attributes.type,
        tenantId: item.attributes.tenantId,
        templateId: item.attributes.templateId,
        labels: item.attributes.labels,
        defaultValues: item.attributes.defaultValues,
      });
    });

    if (contentForUpload.length !== 0) {
      contentForUpload.forEach(async template => {
        await contentTemplatesActions.createNewContentTemplate(template);
      });
    }
  };

  const handleDelete = () => {
    selectedTemplate?.map(item => {
      contentTemplatesActions.deleteContentTemplates(item.id);
      setConfirmModalState(ConfirmModalState.HIDDEN);
      return setSelectedTemplate([]);
    });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const parseUploadedFile = new FileReader();
      parseUploadedFile.readAsText(file);
      parseUploadedFile.onload = () => {
        if (!!parseUploadedFile.result) {
          handleImport(parseUploadedFile.result as string);
        }
      };
    });
    // eslint-disable-next-line
  }, []);

  const { getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
    accept: ['.content-templates', '.json'],
  });

  const tableDataKey = new Set(tableSelectedData);
  const onIframeSubmit = () => {
    setIsTemplateEditIframeVisible(false);
    contentTemplatesActions.fetchContentTemplates({ limit: 1000000 });
    setTemplateId('');
  };
  const onIframeCancel = () => {
    setIsTemplateEditIframeVisible(false);
    setTemplateId('');
  };

  const toggleEdit = (id: string) => {
    if (
      !document.getElementsByClassName('flight-nav__sidebar')[0]?.classList.contains('flight-nav__sidebar--collapsed')
    ) {
      const element: HTMLElement = document.getElementsByClassName('flight-button')[0] as HTMLElement;
      element.click();
    }
    history.push('content-templates/' + id);
  };

  const createTemplate = () => {
    handleCreateContentTemplate();
  };

  const handleTableDataSelect = (item: TableData) => {
    // dont select reserved plugins
    if (item?.hidenVal) {
      if (item.key) {
        if (tableSelectedData?.includes(item.key)) {
          const removeEl = tableSelectedData.filter(el => el !== item.key);
          const removedTemplates = multiSelectTemplate.filter(el => el.id !== item?.hidenVal?.id);
          setTableSelectedData(removeEl);
          setMultiSelectTemplate(removedTemplates);
        } else {
          const selectedPlugins = contentTemplates.filter(el => el.id === item?.hidenVal?.id);
          setTableSelectedData([...tableSelectedData, item.key]);
          setMultiSelectTemplate([...multiSelectTemplate, ...selectedPlugins]);
        }
      }
    }
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleTableSelectAll = (item: any) => {
    if (!isEmpty(multiSelectTemplate)) {
      setTableSelectedData(['']);
      setMultiSelectTemplate([]);
    } else {
      const selectedTemplates = contentTemplates.filter(item => item.internal !== true);
      setMultiSelectTemplate(selectedTemplates);
      setTableSelectedData(Array.from(item));
    }
  };

  const createOptionGroups = (template: ContentTemplate) => {
    return [
      [
        {
          key: `opt3`,
          name: template.internal ? 'View' : 'Edit',
          onClick: () => {
            toggleEdit(template.id);
          },
        },
        {
          key: `opt4`,
          name: 'Export',
          disabled: template.internal,
          onClick: () => {
            handleExport([template.id]);
          },
        },
        {
          key: `opt2`,
          name: 'Delete',
          disabled: template.internal,
          onClick: () => {
            setSelectedTemplate([template]);
            handleDelete();
            setConfirmModalState(ConfirmModalState.DELETE);
          },
        },
      ],
    ];
  };

  const getTemplatesForCategory = (_templateData: ContentTemplate[]) => {
    if (categoryFilter.key === 'default') {
      return _templateData;
    } else if (categoryFilter.key === 'starter') {
      return _templateData.filter(el => el.labels?.includes('starter'));
    } else {
      return _templateData.filter(el => !el.labels?.includes('starter'));
    }
  };

  const handleExportAll = () => {
    const exportAllIds = multiSelectTemplate.map(el => el.id);
    handleExport(exportAllIds);
    setMultiSelectTemplate([]);
  };

  useEffect(() => {
    const _tableData: TableData[] = [];
    let _templateData: ContentTemplate[] = [];

    if (isEmpty(searchTerm)) {
      _templateData = contentTemplates;
    } else {
      _templateData = searchWithRegExp(contentTemplates, searchTerm);
    }
    _templateData = getTemplatesForCategory(_templateData);

    _templateData
      //eslint-disable-next-line
      .map((item, i) => {
        _tableData.push({
          key: i?.toString(),
          icon: (
            <img
              className="content-templates__table__icon"
              src={item.iconUrl || Megaphone}
              alt={`item?.localizations?.['en']?.name + ' icon'`}
              height="40px"
              width="40px"
            />
          ),
          name: (
            <div className="content-templates__table__name-container" onClick={() => toggleEdit(item.id)}>
              <div className="content-templates__table__name-container__name">{item?.localizations?.['en']?.name}</div>
              {item.labels?.map(label => (
                <span className="content-templates__table__name-container__name__label" key={label}>
                  {label}{' '}
                </span>
              ))}
            </div>
          ),
          description: (
            <div className="content-templates__table__description">{item?.localizations?.['en']?.description}</div>
          ),
          moreOpts: (
            <FlightOverflowMenu
              className="content-templates__table__select"
              optionGroups={createOptionGroups(item)}
              direction="bottom-left"
            />
          ),
          hidenVal: item,
          metadata: {
            isMultiSelectDisabled: item.labels?.includes('starter'),
          },
        });
      });

    if (isEmpty(_tableData) && isEmpty(searchTerm)) {
      _tableData.push({
        key: '',
        name: <span>No content templates have been added to this project.</span>,
      });
    }
    if (isEmpty(_tableData) && (!isEmpty(searchTerm) || categoryFilter.key !== 'default')) {
      _tableData.push({
        key: '',
        name: <span>No results found.</span>,
      });
    }
    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
    //eslint-disable-next-line
  }, [contentTemplates, searchTerm, categoryFilter, isFeatureFlagsLoading]);

  useEffect(() => {
    return () => {
      setTableData([]);
      setSearchTerm('');
      setCategoryFilter({
        key: 'default',
        name: 'All templates',
      });
      setMultiSelectTemplate([]);
    };
  }, []);

  return (
    <div className="content-templates">
      <span className="content-templates__greeting-text">
        Manage the way you can make customer facing content on Experience Studio.
      </span>
      <div className="content-templates__header">
        <SearchBar
          initialValue={searchTerm}
          handleSearchTermChange={e => setSearchTerm(e)}
          width="220px"
          label="Search"
          labelId="content-templates__search-bar"
        />
        <FlightSelect
          label="Select an option"
          hasLabelAnimation
          width="220px"
          selected={categoryFilter}
          className="content-templates__header__btn"
          handleOptionClick={setCategoryFilter}
          options={categoriesOptions}
        />
        <span className="content-templates__header__right">
          {!isEmpty(multiSelectTemplate) && (
            <FlightButton
              label={`Export ${multiSelectTemplate?.length} selected`}
              theme="primary"
              className="content-templates__header__right__btn"
              onClick={handleExportAll}
            />
          )}
          <FlightButton
            label="Create content template"
            theme="secondary"
            className="content-templates__header__right__btn"
            onClick={createTemplate}
          />
          <input name="file" id="fileValidation" {...getInputProps()} />
          <FlightButton
            label={'Import'}
            size="medium"
            theme="secondary"
            className="content-templates__header__right__btn"
            onClick={open}
          />
        </span>
      </div>
      <div aria-live="assertive" role="presentation">
        <FlightTable
          className="content-templates__table"
          allowMultiSelect={tableData[0]?.key !== ''}
          allowRowSelect
          selectedDataKey={tableDataKey}
          handleDataSelect={handleTableDataSelect}
          handleCheckboxMultiSelect={handleTableDataSelect}
          handleSelectAll={handleTableSelectAll}
          tableHeaders={tableHeaders}
          isLoading={isEmpty(tableData)}
          tableData={tableData}
          hasPaginationBeforeTable={false}
          ariaLabel="content templates Table"
        />
      </div>
      <ConfirmModal
        isVisible={confirmModalState !== ConfirmModalState.HIDDEN}
        title={confirmModalTitle}
        message={confirmModalMessage}
        continueCallback={handleDelete}
        cancelCallback={() => setConfirmModalState(ConfirmModalState.HIDDEN)}
      />
      <FullPageOverlay isVisible={isTemplateEditIframeVisible}>
        <ContentIframeParent
          onSubmit={() => onIframeSubmit()}
          onCancel={() => onIframeCancel()}
          templateId={templateId}
        />
      </FullPageOverlay>
    </div>
  );
}
