/**
 *  ApnsSettings - Certificate
 */
export interface ApnsSettings {
  fileID: string;
  password: string;
  isProduction: boolean;
  isEnabled: boolean;
  name: string;
  isTouched?: boolean; // FE Property only
}

/**
 *  ApnsSettings - Token
 */
export interface ApnsTokenSettings {
  file: FormData;
  keyID: string;
  teamID: string;
  bundleID: string;
  isProduction: boolean;
  name: string;
}

export interface UploadApnsSetting {
  fileID: string;
  name: string;
  isUploaded: boolean;
}

/**
 *  FcmSettings
 */
export interface FcmSettings {
  key: string;
}

export interface FcmSettingsPayload {
  Key: string;
}

/**
 * HuaweiSettings
 */
export interface HuaweiSettings {
  appID: string;
  clientCredentials: string;
  clientSecretLast4: string;
}

/**
 *  FcmV1Settings
 */
export interface FcmV1Settings {
  projectID: string;
}

export interface HuaweiSettingsPayload {
  appID: string;
  clientCredentials: string;
  clientSecret: string;
}

/**
 *  SilentPushSettings
 */
export interface SilentPushSettings {
  action: string;
  subscribed: boolean;
}

/**
 *  PushLimitSettings
 */
export interface PushLimitSettings {
  timeValue: number;
  timeUnit: string;
  isActive: boolean;
  maxNotifications: number;
  limitType: string;
}

interface ConfigName {
  configName: string;
}

export interface EntityTypes {
  actions: SilentPushSettings[];
  entityKey: string;
}

export interface Entities {
  entities: EntityTypes[];
}

export interface ObjectForSilentPushApi {
  settings: Entities;
  configName: ConfigName;
}

/**
 *  UserSubscription
 */
export interface UserSubscription {
  createdAt: number;
  id: string;
  isChecked: boolean;
  isEnabled: boolean;
  isRequired: boolean;
  name: string;
  updatedAt: number;
  value: string;
}

export interface UserSubscriptionsForApi {
  config: string;
  configName: ConfigName;
}

export interface Schema {
  description?: string;
  isRequired?: boolean;
  type?: string;
  valueType?: string;
  valueOptions?: string[];
}

/**
 *  NotificationType
 */
export interface SchemaType {
  [schema: string]: Schema;
}

export interface NotificationType {
  id: string;
  isReserved: boolean;
  isActive: boolean;
  name: string;
  description: string;
  schema: SchemaType;
  isView?: boolean; //FE only property
  isCreate?: boolean; //FE only property
}

export interface SchemaTypeForCreate {
  isEnabled?: boolean;
  isRequired?: boolean;
  isTracked?: boolean;
  key?: string;
  description?: string;
  type?: string;
  valueType?: string;
  valueOptions?: Array<string>;
}

export interface CreateNotificationType {
  isDefault: boolean;
  isReserved: boolean;
  isActive: boolean;
  name: string;
  description: string;
  schema: SchemaTypeForCreate;
}

export enum primitiveTypes {
  'Float Field',
  'Text Field',
  'Integer Field',
}

export enum dropDownTypes {
  'Float Dropdown',
  'Text Dropdown',
  'Integer Dropdown',
}
