import React, { useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { ReactComponent as FileUploadIcon } from 'assets/images/file-upload.svg';
import { FlightButton } from '@flybits/design-system';
import './ImageUpload.scss';
import uniqueId from 'lodash/uniqueId';
import { getCurrentProjectId } from 'helpers/getCurrentProjectId';
import LocalStore from 'services/localstore';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';

interface Props {
  defaultValue?: string;
  value?: string;
  id?: string;
  maxWidth?: number;
  ratio?: number[];
  onError?: (err: AxiosError) => void;
  onSuccess: (url?: string) => void;
}

const ImageUpload: React.FC<Props> = ({
  value,
  id,
  onSuccess,
  onError,
  defaultValue,
  maxWidth = 1024,
  ratio = [16, 9],
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);
  const { current: elementId } = useRef(id || uniqueId('image-upload-'));
  const fileInputRef = useRef<HTMLInputElement>(null);
  const ratioFloat = ratio.reduce((acc, cur, index) => (index > 0 ? acc / cur : cur));

  const resizeImage = (img: HTMLImageElement) => {
    let { width, height, width: newWidth, height: newHeight } = img;
    let [offsetX, offsetY] = [0, 0];
    const imageRatio = width / height;

    if (imageRatio !== ratioFloat) {
      if (imageRatio > ratioFloat) {
        newWidth = height * ratioFloat;
        newHeight = height;
        offsetX = (width - newWidth) / 2;
      } else {
        newWidth = width;
        newHeight = width / ratioFloat;
        offsetY = (height - newHeight) / 2;
      }
    }

    if (width > maxWidth) {
      width = maxWidth;
      height = width / ratioFloat;
    }

    return {
      width,
      height,
      dx: newWidth,
      dy: newHeight,
      x: offsetX,
      y: offsetY,
    };
  };

  const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;
    if (!files || files.length < 1) return;

    const file = files[0] as File;

    if (!file.type.startsWith('image/')) {
      return;
    }

    const url = URL.createObjectURL(file);
    const image = new Image();
    setIsBusy(true);

    image.src = url;
    image.addEventListener('load', async () => {
      const projectId = getCurrentProjectId();
      const localStore = new LocalStore();
      const fm = new FormData();
      const { width, dx, height, dy, x, y } = resizeImage(image);

      fm.append('image', files[0]);
      fm.append('proxyTarget', `${process.env.REACT_APP_HOST_URL}/kernel/file-manager/files/upload`);
      fm.append(
        'proxyHeaders',
        JSON.stringify({
          'x-authorization': await localStore.getItem(`token+${projectId}`),
        }),
      );
      fm.append('proxyFileParam', 'image');
      fm.append(
        'crop',
        JSON.stringify({
          x: Math.round(x),
          y: Math.round(y),
          dx: Math.round(dx),
          dy: Math.round(dy),
        }),
      );
      fm.append(
        'resize',
        JSON.stringify({
          width: Math.round(width),
          height: Math.round(height),
        }),
      );

      try {
        const { data } = await axios.post('https://pixelpusher.solutions-us.flybits.com/upload', fm);
        onSuccess(data[0]?.url);
      } catch (err) {
        if (onError) onError(err as AxiosError);
      } finally {
        setIsBusy(false);
      }
      URL.revokeObjectURL(url);
    });
  };

  const handleFileRemoveClick = () => {
    onSuccess(defaultValue);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="image-upload">
      <label htmlFor={elementId} className="image-upload__styled-file">
        <p>Drag and drop your PNG/ JPG file here</p>
        <p>
          <strong>Recommended ratio:</strong> {`${ratio[0]}:${ratio[1]}`}
        </p>
        <FileUploadIcon />
        <input
          type="file"
          id={elementId}
          accept=".jpeg,.jpg,.png"
          multiple={false}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        {currentValue && <div className="image-upload__image" style={{ backgroundImage: `url(${currentValue})` }} />}
      </label>
      <div className="image-upload__upload-actions">
        <div>
          <FlightButton
            label="Upload"
            theme="secondary"
            iconLeft="export"
            size="small"
            onClick={() => fileInputRef.current?.click()}
          />
          {maxWidth && <small>Max. width: {maxWidth}px</small>}
        </div>
        <div>
          <FlightButton
            label="Remove"
            theme="secondary"
            iconLeft="trashCan"
            size="small"
            onClick={handleFileRemoveClick}
          />
          <small>Restore default</small>
        </div>
      </div>
      {isBusy && (
        <div className="image-upload__loading">
          <LoadingIcon visible />
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
