import { ProjectAction, Project, ProjectActionTypes } from 'model/project';

import createReducer from './createReducer';

const initialState = {} as Project;
export const project = createReducer<Project>(initialState, {
  [ProjectActionTypes.SET_PROJECT_STATE](state: Project, action: ProjectAction) {
    return { ...state, ...action.payload };
  },
});
