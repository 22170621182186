import React, { useEffect, useState } from 'react';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { NotificationType } from 'model/pushSettings/settings';
import * as NotificationTypeActions from 'actions/pushSettings/notificationTypes';
import { useActions } from 'actions';
import './ManagePushTemplate.scss';
import { FlightTable, FlightModal, FlightButton } from '@flybits/design-system';
import { getValueType } from 'helpers/getPushValueType';
import CreatePushTemplate from './CreatePushTemplate';
import { history } from 'configureStore';

interface Props {
  notificationTypeFromState?: NotificationType;
}

interface TableBody {
  keyField: string;
  description: string;
  required: string;
  inputType: JSX.Element;
  tracked: string;
  key: number;
}

export default function ManagePushTemplates(props: Props) {
  const { notificationTypeFromState } = props;
  const notificationTypeActions = useActions(NotificationTypeActions);
  const TRACKED_KEYS = 'trackedKeys';

  const name = notificationTypeFromState?.name || '';
  const description = notificationTypeFromState?.description || '';
  const schema = notificationTypeFromState?.schema || {};
  const isCreate = notificationTypeFromState?.isCreate;

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.go(1);
        notificationTypeActions.setNotificationType(null);
      } else {
        notificationTypeActions.setNotificationType(null);
      }
    };
    // eslint-disable-next-line
  }, []);

  const tableHeaders = [
    {
      name: 'Key',
      key: 'keyField',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Description',
      key: 'description',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Required',
      key: 'required',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Input type',
      key: 'inputType',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: 'Tracked',
      key: 'tracked',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  const isTrackedKey = (property: string) => {
    return property === TRACKED_KEYS ? true : false;
  };

  const isTracked = (key: string) => {
    const tracked = schema[TRACKED_KEYS];
    if (tracked?.valueOptions?.includes(key)) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  const [showValuesModal, setShowValuesModal] = useState(false);
  const [valuesModalContent, setValuesModalContent] = useState(['']);
  const toggleValuesModal = () => {
    setValuesModalContent(['']);
    setShowValuesModal(showValuesModal => !showValuesModal);
  };

  const showValues = (values: string[]) => {
    toggleValuesModal();
    setValuesModalContent(values);
  };

  const tableBody: TableBody[] = [];
  for (const property in schema) {
    if (!isTrackedKey(property)) {
      tableBody.push({
        keyField: property,
        description: schema[property].description || '',
        required: schema[property].isRequired ? 'Yes' : 'No',
        inputType: (
          <span>
            <div>{getValueType(schema[property].type, schema[property].valueType)}</div>
            <div>
              {schema[property].valueOptions && (
                <span
                  className="manage-templates__container__link"
                  onClick={() => showValues(schema[property].valueOptions || [''])}
                  tabIndex={0}
                  aria-label={property + " view values"}
                  onKeyDown={(event) => {
                   if(event.key === "Enter"){
                     showValues(schema[property].valueOptions || ['']);
                   }
                  }}
                >
                  View values
                </span>
              )}
            </div>
          </span>
        ),
        tracked: isTracked(property),
        key: Math.random(),
      });
    }
  }

  const handleClose = () => {
    notificationTypeActions.setNotificationType(null);
  };

  return (
    <div className="manage-templates">
      <FlightModal
        isVisible={showValuesModal}
        size="small"
        className="manage-templates__modal"
        toggleModalShown={toggleValuesModal}
        header={<span>Values</span>}
        content={
          <span className="manage-templates__modal__content">
            {valuesModalContent.map(values => (
              <div className="manage-templates__modal__content__values" key={Math.random()} tabIndex={0}>
                {values}
              </div>
            ))}
          </span>
        }
        footer={
          <div className="modal-footer">
            <FlightButton type="secondary" onClick={toggleValuesModal} label="Close" />
          </div>
        }
      />
      <PageHeader title={!isCreate ? name : 'Create push type'} isLarge={true} />
      <div className="pt-3 manage-templates__description">
        {!isCreate
          ? description
          : 'This Name and description help users on experience studio understand what this type is used for.'}
      </div>
      {!isCreate ? (
        <div>
          <div className="pt-5 manage-templates__description--bold">Custom input fields</div>
          <div className="pt-3 manage-templates__description">Fields in addition to the standard title and body.</div>
          <div className="manage-templates__container">
            <FlightTable
              className="manage-templates__table"
              tableHeaders={tableHeaders}
              tableData={tableBody}
              hasPaginationBeforeTable={false}
              ariaLabel="manage templates Table"
            />
          </div>{' '}
          <div className="pt-3">
            <FlightButton onClick={handleClose} label="Close" theme="secondary" />
          </div>
        </div>
      ) : (
        <CreatePushTemplate notificationTypeFromState={notificationTypeFromState} />
      )}
    </div>
  );
}
