import React, { FunctionComponent } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import slugify from 'slugify';
import { FlightButton, FlightModal, FlightTextInput, getIcon } from '@flybits/design-system';
import { useActions } from 'actions';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { CategoriesEntity } from 'model/manageTemplates';
import * as ManageTemplatesActions from 'actions/manageTemplates';
import { NAME_REQUIRED } from 'constants/errors/errors';

interface Props {
  parentCategory?: CategoriesEntity;
  categoryType?: 'category' | 'subcategory';
  handleClose: () => void;
  onError?: () => void;
  onSuccess: (category: { key: string; name: string }, categoryType: Props['categoryType']) => void;
}

const NewCategoryModal: FunctionComponent<Props> = ({
  parentCategory,
  categoryType,
  handleClose,
  onError,
  onSuccess,
}) => {
  const importLibraryCategories = useSelector((state: RootState) => state.manageTemplates.importLibraryCategories);
  const categories: CategoriesEntity[] = (importLibraryCategories?.settings?.categories || []).map(c => ({
    ...c,
    subcategories: c.subcategories ? [...c.subcategories] : [],
  }));
  const manageTemplatesActions = useActions(ManageTemplatesActions);

  const validationSchema = object().shape({
    name: string().required(NAME_REQUIRED),
  });

  const handleCategoryCreate = async (
    { name }: { name: string },
    { setSubmitting, setFieldError }: FormikHelpers<{ name: string }>,
  ) => {
    try {
      setSubmitting(true);
      const newCategory = { key: slugify(name, { lower: true }), name };
      if (categoryType === 'subcategory') {
        const selectedCategory = categories.find(category => category.key === parentCategory?.key);
        if (!selectedCategory) return;
        if (!selectedCategory?.subcategories) selectedCategory.subcategories = [];
        selectedCategory.subcategories.push(newCategory);
      } else {
        categories.push(newCategory);
      }

      const newImportLibraryCategories = {
        ...importLibraryCategories,
        settings: {
          ...importLibraryCategories.settings,
          categories,
        },
      };
      await manageTemplatesActions.updateTemplateCategories(newImportLibraryCategories);
      setSubmitting(false);
      onSuccess(newCategory, categoryType);
    } catch {
      setSubmitting(false);
      setFieldError('name', 'Something went wrong while creating the category, please try again');
      if (onError) onError();
    }
  };

  return (
    <FlightModal
      content={
        <Formik initialValues={{ name: '' }} onSubmit={handleCategoryCreate} validationSchema={validationSchema}>
          {({ errors, values, isSubmitting }) => (
            <Form>
              {categoryType === 'subcategory' && (
                <div className="update-template-details__field">
                  <label>Parent category</label>
                  <FlightTextInput
                    iconInput="editOutline"
                    width="100%"
                    value={parentCategory?.name}
                    onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.readOnly = true;
                    }}
                  />
                </div>
              )}
              <div className="update-template-details__field">
                <label htmlFor="new-category-name">Name</label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  as={FlightTextInput}
                  hasError={!!errors.name}
                  errorMessage={errors.name}
                  value={values.name}
                  width="100%"
                  iconInput="editOutline"
                  autoFocus
                />
              </div>
              <div className="update-template-details__modal-actions">
                <FlightButton
                  type="submit"
                  label="Add"
                  iconLeft="checkCircle"
                  onClick={() => undefined}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
                <FlightButton label="Cancel" onClick={handleClose} disabled={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      }
      header={
        <div>
          {getIcon('editOutline', { style: { marginRight: '5px' } })}New {categoryType}
        </div>
      }
      isVisible
      size="small"
      scrollable={false}
    />
  );
};

export default NewCategoryModal;
