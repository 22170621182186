import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isEqual, cloneDeep } from 'lodash';
import { Label } from 'reactstrap';
import {
  FlightButton,
  FlightModal,
  FlightTable,
  FlightTextInput,
  FlightLabelSearch,
  FlightLabel,
} from '@flybits/design-system';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import { ReactComponent as PolygonUpIcon } from 'assets/images/polygon-up-icon.svg';
import { ReactComponent as PolygonDownIcon } from 'assets/images/polygon-down-icon.svg';
import { ReactComponent as EditPencil } from 'assets/images/edit-pencil.svg';
import { ReactComponent as DeleteTrashBin } from 'assets/images/trash_bin.svg';
import { Category, TModalCategoryNames } from 'model/conciergeCategories';
import { useActions } from 'actions';
import * as ConciergeCategoriesActions from 'actions/conciergeCategories';
import * as LanguageActions from 'actions/languages';
import { RootState } from 'reducers';
import { searchWithRegExp } from 'helpers/searchWithRegExp';
import './ConciergeCategoryFilters.scss';
import FloatingSaveBar from 'components/Shared/FloatingSaveBar/FloatingSaveBar';
import ConciergeCategoryEmptyState from './ConciergeCategoryEmptyState';
import { Language } from 'model/language';

type TTableData = {
  key: string;
  appVisibilityOrder?: JSX.Element;
  categoryName?: JSX.Element;
  categoryNameInOtherLanguages?: JSX.Element;
  labels?: JSX.Element;
  addCategory?: JSX.Element;
};

const tableHeaders = [
  {
    name: 'App visibility order',
    key: 'appVisibilityOrder',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Category In English',
    key: 'categoryName',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Category in Other Languages',
    key: 'categoryInOtherLanguages',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Labels',
    key: 'labels',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: '',
    key: 'modify',
    isVisible: true,
    hideTooltip: true,
  },
];

const addCategoryTableHeaders = [
  {
    name: '',
    key: 'addCategory',
    isVisible: true,
    hideTooltip: true,
  },
];

function ConciergeCategoryFilters() {
  const tenantId = useSelector((state: RootState) => state.project.id);
  const conciergeCategoriesActions = useActions(ConciergeCategoriesActions);
  const conciergeCategoriesFromState = useSelector((state: RootState) => state.conciergeCategories.categories);
  const languagesFromState = useSelector((state: RootState) => state.languages.languages);
  const languagesActions = useActions(LanguageActions);
  const [areCategoriesLoading, setAreCategoriesLoading] = useState(true);
  const [areCategoriesUpdating, setAreCategoriesUpdating] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [areCategoriesEmpty, setAreCategoriesEmpty] = useState(false);
  const [categorySearchText, setCategorySearchText] = useState('');
  const [tableData, setTableData] = useState<TTableData[]>([]);
  const [areCategoriesUnsaved, setAreCategoriesUnsaved] = useState(false);
  const [numUnsavedChanges, setNumUnsavedChanges] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<TTableData>({ key: '' });
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [modalCategory, setModalCategory] = useState<Category>({
    labels: [],
    name: '',
    localizations: { en: { name: '' } },
    pos: 0,
  });
  const [modalCategoryNames, setModalCategoryNames] = useState<TModalCategoryNames>({
    en: { name: '', hasError: false, error: '' },
  });
  const [modalCategoryLabels, setModalCategoryLabels] = useState<string[]>([]);
  const [modalCategoryNewLabels, setModalCategoryNewLabels] = useState<string[]>([]);
  const [modalLabelSearchOptions, setModalLabelSearchOptions] = useState<string[]>([]);
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<Language>();
  const [viewMoreCategoryNames, setViewMoreCategoryNames] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalLabels, setTotalLabels] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labelSearchRef = useRef<any>();
  useEffect(() => {
    (async () => {
      await languagesActions.fetchAllLanguages();
      await conciergeCategoriesActions.getCategories();

      setAreCategoriesLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEmpty(languagesFromState)) {
      return;
    }

    setAvailableLanguages(cloneDeep(languagesFromState));
    setDefaultLanguage(languagesFromState.find(lang => lang.isDefault));
    setAreCategoriesLoading(false);
  }, [languagesFromState]);

  useEffect(() => {
    if (isEmpty(conciergeCategoriesFromState)) {
      setAreCategoriesEmpty(true);
    } else {
      const _conciergeCategoriesFromState = cloneDeep(conciergeCategoriesFromState);
      _conciergeCategoriesFromState.map(category => {
        availableLanguages.forEach(language => {
          if (!category.hasOwnProperty('localizations')) {
            category['localizations'] = {};
          }
          if (!category.localizations.hasOwnProperty('en')) {
            category.localizations['en'] = { name: category.name };
          }
          if (!category.localizations.hasOwnProperty(language.id)) {
            category.localizations[language.id] = { name: '' };
          }
        });
        return category;
      });
      setAreCategoriesEmpty(false);
      setCategories(_conciergeCategoriesFromState);
    }
  }, [availableLanguages, conciergeCategoriesFromState]);

  const deleteCategory = (categoryIndex: number) => {
    setCategories(
      categories
        .filter((_, index) => index !== categoryIndex)
        .map((category, index) => {
          if (category.pos - index !== 1) {
            category.pos = index + 1;
          }

          return category;
        }),
    );
    setAreCategoriesUnsaved(true);
    setNumUnsavedChanges(numChanges => numChanges + 1);
  };

  const prePopulateModalCategoryNames = () => {
    const _modalCategoryNames = cloneDeep(modalCategoryNames);
    if (availableLanguages.length > Object.keys(_modalCategoryNames).length) {
      availableLanguages.forEach(language => {
        if (!_modalCategoryNames.hasOwnProperty(language.id)) {
          _modalCategoryNames[language.id] = { name: '' };
        }
      });
      setModalCategoryNames(_modalCategoryNames);
    }
  };

  const categoryInOtherLanguages = (category: Category) => {
    const result = [];
    if (category.hasOwnProperty('localizations')) {
      for (const [langKey, value] of Object.entries(category.localizations)) {
        if (langKey !== 'iw' && langKey !== 'en') {
          const currLang = availableLanguages.filter(lang => lang.id === langKey)[0];
          if (currLang !== undefined) {
            result.push({ language: currLang.name, categories: value.name, direction: currLang.direction });
          }
        }
      }
    }
    return result;
  };

  const moveDown = (index: number) => {
    const _categories = [...categories];
    const nextCategory = cloneDeep(_categories[index + 1]);
    _categories[index + 1] = _categories[index];
    _categories[index] = nextCategory;

    setCategories(_categories);
    setAreCategoriesUnsaved(true);
    setNumUnsavedChanges(numChanges => numChanges + 1);
  };

  const moveUp = (index: number) => {
    const _categories = [...categories];
    const prevCategory = cloneDeep(_categories[index - 1]);
    _categories[index - 1] = _categories[index];
    _categories[index] = prevCategory;

    setCategories(_categories);
    setAreCategoriesUnsaved(true);
    setNumUnsavedChanges(numChanges => numChanges + 1);
  };

  const manageCategory = (mode: string) => {
    if (mode === 'add') {
      prePopulateModalCategoryNames();
    }

    setModalMode(mode.toLowerCase());
    setOpenModal(true);
  };

  const handleCategoriesTableRowSelect = (category: TTableData) => {
    setSelectedCategory(category);

    const categoryObj = cloneDeep(categories[Number(category.key)]);
    if (isEmpty(categoryObj.localizations)) {
      categoryObj.localizations = { en: { name: categoryObj.name } };
    }
    setModalCategory(categoryObj);

    manageCategory('edit');
  };

  useEffect(() => {
    let _tableData: TTableData[] = [];
    let _categories: Category[] = [];

    if (isEmpty(categorySearchText)) {
      _categories = [...categories];
    } else {
      _categories = searchWithRegExp(categories, categorySearchText);
    }

    _tableData = _categories.map((category, categoryIndex) => {
      const data = {
        key: String(categoryIndex),
        appVisibilityOrder: (
          <div className="concierge-categories__table__app-visibility-order">
            <div className="concierge-categories__table__app-visibility-order__number">{category.pos}</div>
            <div className="concierge-categories__table__app-visibility-order__control">
              {categoryIndex < _categories.length - 1 && (
                <span
                  tabIndex={0}
                  aria-label="move down"
                  role="button"
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                    e.stopPropagation();
                    moveDown(categoryIndex);
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                    if (e.key === 'Enter') {
                      moveDown(categoryIndex);
                    }
                  }}
                >
                  <PolygonDownIcon className="concierge-categories__table__app-visibility-order__control__icon" />
                </span>
              )}
              {categoryIndex > 0 && (
                <span
                  tabIndex={0}
                  aria-label="move up"
                  role="button"
                  onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                    e.stopPropagation();
                    moveUp(categoryIndex);
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                    if (e.key === 'Enter') {
                      moveUp(categoryIndex);
                    }
                  }}
                >
                  <PolygonUpIcon className="concierge-categories__table__app-visibility-order__control__icon" />
                </span>
              )}
            </div>
          </div>
        ),
        categoryName: <div className="concierge-categories__table__category-name">{category.name}</div>,
        categoryInOtherLanguages: (
          <div className="concierge-categories__table__category-name__other-languages">
            {categoryInOtherLanguages(category).map((categoryName, index) => {
              return (
                <div className="category-names" key={index}>
                  <span className="categoryLanguage">{categoryName.language}</span>
                  <span> - </span>
                  {categoryName.categories === '' ? (
                    <span>*</span>
                  ) : (
                    <span className={categoryName.direction === 'rtl' ? 'rtl' : ''}>{categoryName.categories}</span>
                  )}
                </div>
              );
            })}
          </div>
        ),
        labels: (
          <div className="concierge-categories__table__labels">
            {category.labels.map((label, index) => (
              <FlightLabel label={label} key={index} />
            ))}
          </div>
        ),
        modify: (
          <div className="concierge-categories__table__modify-icons">
            <span
              tabIndex={0}
              aria-label="edit concierge category"
              role="button"
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                e.stopPropagation();
                handleCategoriesTableRowSelect(data);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                if (e.key === 'Enter') {
                  handleCategoriesTableRowSelect(data);
                }
              }}
            >
              <EditPencil />
            </span>
            <span
              tabIndex={0}
              aria-label="delete concierge category"
              role="button"
              onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                e.stopPropagation();
                deleteCategory(categoryIndex);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                if (e.key === 'Enter') {
                  deleteCategory(categoryIndex);
                }
              }}
            >
              <DeleteTrashBin />
            </span>
          </div>
        ),
      };

      return data;
    });

    if (isEmpty(_tableData)) {
      _tableData = [
        {
          key: '',
          appVisibilityOrder: isEmpty(categorySearchText) ? (
            <span>No concierge categories have been added to this project.</span>
          ) : (
            <span>No results found.</span>
          ),
        },
      ];
    }

    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, categorySearchText]);

  useEffect(() => {
    if (!selectedCategory.key) {
      return;
    }

    const { labels } = categories[Number(selectedCategory.key)];

    setModalCategoryLabels(labels);
    setModalLabelSearchOptions(labels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (isEmpty(modalCategory)) {
      return;
    }

    setModalCategoryNames(cloneDeep(modalCategory.localizations));
  }, [modalCategory]);

  const cleanUpModalContent = () => {
    setModalCategoryNames({ en: { name: '', hasError: false, error: '' } });
    setModalCategoryLabels([]);
    setModalCategoryNewLabels([]);
    setModalLabelSearchOptions([]);
    setSelectedCategory({ key: '' });
    setViewMoreCategoryNames(false);
  };

  const hasRepeatedCategory = (newName: string, langID: string): boolean => {
    let hasDuplicate = false;
    categories.forEach(category => {
      if (category.name.localeCompare(newName, langID, { sensitivity: 'base' }) === 0) {
        hasDuplicate = true;
      }
    });
    return hasDuplicate;
  };

  const onCategoryNameChange = (newName: string, langID: string) => {
    if (newName === '') {
      setModalCategoryNames({
        ...modalCategoryNames,
        [langID]: { name: newName, hasError: true, error: 'Field required.' },
      });
    } else if (hasRepeatedCategory(newName, langID)) {
      setModalCategoryNames({
        ...modalCategoryNames,
        [langID]: { name: newName, hasError: true, error: 'Category name already exists.' },
      });
    } else if (langID === 'he') {
      setModalCategoryNames({
        ...modalCategoryNames,
        [langID]: { name: newName, hasError: false, error: '' },
        iw: { name: newName, hasError: false, error: '' },
      });
    } else {
      setModalCategoryNames({
        ...modalCategoryNames,
        [langID]: { name: newName, hasError: false, error: '' },
      });
    }
  };

  const handleLabelSearchAndScroll = () => {
    setIsDropdownOpen(true);
    setTimeout(() => {
      labelSearchRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 50);
    const labels = categories.map(category => category.labels).flat();
    const totalLabels = labels.filter((item, index) => labels.indexOf(item) === index);
    setModalLabelSearchOptions(totalLabels);
    setTotalLabels(totalLabels);
  };

  const handleLabelChange = async (labelName: string) => {
    const newLabels = modalLabelSearchOptions.filter((label: string) => label.startsWith(labelName));
    if (!labelName) {
      setModalLabelSearchOptions(totalLabels);
    } else {
      setModalLabelSearchOptions(newLabels);
      setIsDropdownOpen(true);
    }
  };

  const handleLabelSelected = (newLabels: string[]) => {
    setModalCategoryNewLabels(newLabels);
  };

  const getCategoryModalContent = () => {
    const nonEnglishLanguages = availableLanguages.filter(lang => lang.name !== 'English');

    return (
      <div className="concierge-categories__modal">
        {/* English category name - always displayed  */}
        <div className="concierge-categories__modal__content-block">
          <Label className="concierge-categories__modal__label" for="category-name">
            Category Name in English<sup className="concierge-categories__star">*</sup>
          </Label>
          <FlightTextInput
            name="category-name"
            className="concierge-categories__modal__category-name"
            hasError={modalCategoryNames.en.hasError}
            width=""
            autoFocus
            maxLength={80}
            errorMessage={modalCategoryNames.en.error}
            value={modalCategoryNames.en.name}
            label="Enter Category Name"
            isLabelAlwaysOn={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onCategoryNameChange(e.currentTarget.value, 'en');
              setAreCategoriesUnsaved(true);
            }}
          />
        </div>
        {/* Category names in other languages */}
        <div className="concierge-categories__modal__content-block">
          {nonEnglishLanguages.length > 0 ? (
            <Label className="concierge-categories__modal__label" for="category-name">
              Category Name in other languages
            </Label>
          ) : null}
          <div>
            {nonEnglishLanguages.length <= 2 ? (
              <div className="concierge-categories__modal__content-block__grid">
                {nonEnglishLanguages.map((lang, index) => (
                  <div key={index}>
                    <p className="concierge-categories__modal__category-name__title">
                      Category ({lang.name})
                      {lang.name === defaultLanguage?.name && <sup className="concierge-categories__star">*</sup>}
                    </p>
                    <FlightTextInput
                      name={'category-name-' + lang.name}
                      className="concierge-categories__modal__category-name__non-english"
                      width=""
                      autoFocus
                      maxLength={80}
                      direction={availableLanguages.filter(language => language.id === lang.id)[0].direction}
                      hasError={lang.isDefault && modalCategoryNames[lang.id]?.hasError}
                      errorMessage={modalCategoryNames[lang.id]?.error}
                      placeholderText={'Enter category name in ' + lang.name}
                      value={modalCategoryNames[lang.id]?.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onCategoryNameChange(e.currentTarget.value, lang.id);
                        setAreCategoriesUnsaved(true);
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="concierge-categories__modal__multi-non-english-block">
                <div className="concierge-categories__modal__content-block__grid">
                  {nonEnglishLanguages.map((lang, index) => {
                    if (index >= 0 && index < 2) {
                      return (
                        <div
                          className="concierge-categories__modal__category-name__multi-non-english-input"
                          key={index}
                        >
                          <p className="concierge-categories__modal__category-name__title">
                            Category ({lang.name})
                            {lang.name === defaultLanguage?.name && <sup className="concierge-categories__star">*</sup>}
                          </p>
                          <FlightTextInput
                            name={'category-name-' + lang.name}
                            className="concierge-categories__modal__category-name__non-english"
                            width=""
                            autoFocus
                            maxLength={80}
                            direction={availableLanguages.filter(language => language.id === lang.id)[0].direction}
                            hasError={lang.isDefault && modalCategoryNames[lang.id]?.hasError}
                            errorMessage={modalCategoryNames[lang.id]?.error}
                            placeholderText={'Enter category name in ' + lang.name}
                            value={modalCategoryNames[lang.id]?.name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              onCategoryNameChange(e.currentTarget.value, lang.id);
                              setAreCategoriesUnsaved(true);
                            }}
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {viewMoreCategoryNames
                    ? nonEnglishLanguages.map((lang, index) => {
                        if (index >= 2 && index < nonEnglishLanguages.length) {
                          return (
                            <div
                              className="concierge-categories__modal__category-name__multi-non-english-input"
                              key={index}
                            >
                              <p className="concierge-categories__modal__category-name__title">
                                Category ({lang.name})
                                {lang.name === defaultLanguage?.name && (
                                  <sup className="concierge-categories__star">*</sup>
                                )}
                              </p>
                              <FlightTextInput
                                name={'category-name-' + lang.name}
                                className="concierge-categories__modal__category-name__non-english"
                                width=""
                                autoFocus
                                maxLength={80}
                                direction={availableLanguages.filter(language => language.id === lang.id)[0].direction}
                                hasError={lang.isDefault && modalCategoryNames[lang.id]?.hasError}
                                errorMessage={modalCategoryNames[lang.id]?.error}
                                placeholderText={'Enter category name in ' + lang.name}
                                value={modalCategoryNames[lang.id]?.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onCategoryNameChange(e.currentTarget.value, lang.id);
                                  setAreCategoriesUnsaved(true);
                                }}
                              />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })
                    : null}
                </div>
                <div className="concierge-categories__modal__multi-non-english-block__button">
                  <FlightButton
                    label={viewMoreCategoryNames ? 'View Less' : 'View More'}
                    theme="minor"
                    onClick={() => setViewMoreCategoryNames(!viewMoreCategoryNames)}
                    iconRight={viewMoreCategoryNames ? 'baselineKeyboardArrowUp' : 'baselineKeyboardArrowDown'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="concierge-categories__modal__content-block">
          <Label className="concierge-categories__modal__label">
            Select content with Labels<sup className="concierge-categories__star">*</sup>
          </Label>
          <div ref={labelSearchRef} className={isDropdownOpen ? 'open' : ''}>
            <FlightLabelSearch
              height="200px"
              width="360px"
              labels={modalCategoryLabels}
              options={modalLabelSearchOptions}
              hasError={isEmpty(modalCategoryLabels) && isEmpty(modalCategoryNewLabels)}
              errorMessage="Require at least one label"
              onLabelAdd={(labels: string[]) => {
                setModalCategoryLabels(labels);
                setModalCategoryNewLabels([]);
                setAreCategoriesUnsaved(true);
              }}
              onLabelChange={handleLabelChange}
              onLabelClick={() => {}}
              onLabelRemove={(labelName: string) => {
                const remainingLabels = modalCategoryLabels.filter(
                  modalCategoryLabel => modalCategoryLabel !== labelName,
                );
                setModalCategoryLabels(remainingLabels);
                setAreCategoriesUnsaved(true);
              }}
              onLabelSelected={handleLabelSelected}
              onTriggerClick={handleLabelSearchAndScroll}
              onOutsideClick={() => {
                setIsDropdownOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const addCategoryTableData: TTableData[] = [
    {
      key: '0',
      addCategory: (
        <div className="concierge-categories__add-category-table__add-category">
          <span
            tabIndex={0}
            aria-label="add concierge category"
            role="button"
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
              e.stopPropagation();
              manageCategory('add');
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
              if (e.key === 'Enter') {
                manageCategory('add');
              }
            }}
          >
            + Add category
          </span>
        </div>
      ),
    },
  ];

  const saveCategoryModal = () => {
    const _modalCategoryNames = cloneDeep(modalCategoryNames);

    for (const langKey in _modalCategoryNames) {
      _modalCategoryNames[langKey].name = _modalCategoryNames[langKey].name.trim();
    }

    switch (modalMode) {
      case 'add':
        setCategories([
          ...categories,
          {
            labels: modalCategoryLabels,
            name: _modalCategoryNames.en.name.trim(),
            pos: categories.length + 1,
            localizations: { ..._modalCategoryNames },
          },
        ]);
        setAreCategoriesEmpty(false);
        break;

      case 'edit':
        setCategories(
          categories.map((category, categoryIndex) => {
            if (categoryIndex === Number(selectedCategory.key)) {
              category.labels = modalCategoryLabels;
              category.name = _modalCategoryNames.en.name.trim();
              category.localizations = { ..._modalCategoryNames };
            }

            return category;
          }),
        );
        break;

      default:
        cleanUpModalContent();
    }

    setNumUnsavedChanges(numChanges => numChanges + 1);
    cleanUpModalContent();
    setOpenModal(false);
  };

  const dismissModal = () => {
    setNumUnsavedChanges(numChanges => numChanges + 1);
    cleanUpModalContent();
    setOpenModal(false);
  };

  const saveChanges = () => {
    setAreCategoriesUpdating(true);

    const _categories = cloneDeep(categories);

    // Remove languages from localizations that doesn't have a category name
    _categories.map(category => {
      for (const langKey in category.localizations) {
        if (category.localizations[langKey].name === '') {
          delete category.localizations[langKey];
        }
      }
      return category;
    });

    (async () => {
      await conciergeCategoriesActions.updateCategories(tenantId, _categories);

      setAreCategoriesUpdating(false);
      setAreCategoriesUnsaved(false);
      setNumUnsavedChanges(0);
    })();
  };

  const discardChanges = () => {
    setAreCategoriesUnsaved(false);
    setNumUnsavedChanges(0);
    setAreCategoriesLoading(true);
    setCategories([]);

    (async () => {
      await conciergeCategoriesActions.getCategories();
      setAreCategoriesLoading(false);
    })();
  };

  const getConfirmNavigationModalContent = () => (
    <>
      <div className="concierge-categories__confirm-navigation-modal__subheader">
        You have unsaved changes that will be lost if you decide to continue.
      </div>
      <div className="concierge-categories__confirm-navigation-modal__subheader">
        Are you sure you want to leave this page?
      </div>
    </>
  );

  function getIsSaveCategoryModalDisabled() {
    if (defaultLanguage) {
      if (!modalCategoryNames[defaultLanguage.id]?.name) {
        return true;
      } else if (modalCategoryNames[defaultLanguage.id]?.hasError) {
        return true;
      }
    } else if (!modalCategoryNames.en?.name) {
      return true;
    } else if (modalCategoryNames.en?.hasError) {
      return true;
    }

    if (isEmpty(modalCategoryLabels) && isEmpty(modalCategoryNewLabels)) {
      return true;
    }

    return false;
  }

  return (
    <>
      {areCategoriesEmpty ? (
        <ConciergeCategoryEmptyState handleCreateConciergeCategory={() => manageCategory('add')} />
      ) : (
        <div className="concierge-categories">
          <SearchBar
            className="concierge-categories__search-bar"
            initialValue={categorySearchText}
            handleSearchTermChange={value => setCategorySearchText(value)}
            width="160px"
            labelId="concierge-categories__search-bar"
            label="Search"
          />
          <div aria-live="assertive">
            <FlightTable
              className="concierge-categories__table"
              tableHeaders={tableHeaders}
              isLoading={isEmpty(tableData) || areCategoriesLoading}
              tableData={tableData}
              hasPaginationBeforeTable={false}
              allowRowSelect
              handleDataSelect={handleCategoriesTableRowSelect}
              ariaLabel="Concierge categories Table"
            />
            <FlightTable
              className="concierge-categories__add-category-table"
              tableHeaders={addCategoryTableHeaders}
              tableData={addCategoryTableData}
              hasPaginationBeforeTable={false}
              ariaLabel="Concierge categories add category Table"
            />
          </div>
        </div>
      )}
      <FlightModal
        className="concierge-categories__modal-class"
        isVisible={openModal}
        size="large"
        toggleModalShown={dismissModal}
        header={modalMode === 'add' ? 'Create Concierge Category' : 'Edit Concierge Category'}
        content={getCategoryModalContent()}
        scrollable
        footer={
          <div className="modal-footer">
            <FlightButton
              theme="secondary"
              label="Cancel"
              onClick={() => {
                dismissModal();
                setAreCategoriesUnsaved(false);
              }}
            />
            <FlightButton
              type="submit"
              label={modalMode === 'add' ? 'Add' : 'Update'}
              disabled={getIsSaveCategoryModalDisabled()}
              onClick={saveCategoryModal}
            />
          </div>
        }
      />
      <FloatingSaveBar
        isVisible={areCategoriesUnsaved}
        numUnsavedChanges={numUnsavedChanges}
        primaryAction={{ label: 'Save changes', handleAction: saveChanges, isLoading: areCategoriesUpdating }}
        secondaryAction={{ label: 'Discard', handleAction: discardChanges }}
        showConfirmNavigationModal
        modalContent={getConfirmNavigationModalContent()}
        modalClassname="concierge-categories__confirm-navigation-modal"
        modalSize="small"
      />
    </>
  );
}

export default ConciergeCategoryFilters;
