import { Project, ProjectAction, ProjectActionTypes } from 'model/project';
import ProjectService from 'services/project.service';
import { generateUniqueId } from 'helpers/randomIdGenerator';
import { makeActionCreator } from 'helpers/actionCreator';
import { showNotification } from './snackBarNotifications';
import { history } from 'configureStore';

interface CreateProject {
  name: string;
  subDomain: string;
}

export const setProjectsState = makeActionCreator(ProjectActionTypes.SET_PROJECTS_STATE);
export const setProjectState = makeActionCreator(ProjectActionTypes.SET_PROJECT_STATE);
export const resetProjectsState = makeActionCreator(ProjectActionTypes.RESET_PROJECTS_STATE);

export function fetchProject(projectId: string) {
  return async (dispatch: { (arg0: ProjectAction): void }) => {
    try {
      const req = new ProjectService();
      const response = await req.getProject(projectId);
      // Transform response body to align with view models
      if (response.status === 200) {
        const transformedResponse = {
          id: response.data.id,
          name: response.data.Name,
          subDomain: response.data.Subdomain,
          creatorID: response.data.CreatorID,
          isActive: response.data.IsActive,
          tierID: response.data.TierID,
        };
        dispatch(setProjectState(transformedResponse));
      }
    } catch (error) {}
    return 'done';
  };
}

export function deleteProject(projectId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async () => {
    try {
      const req = new ProjectService();
      // permission level is set to 'owner' and passed as query params.
      const params = {
        projectId: projectId,
      };
      const response = await req.deleteProject(params);
      if (response.status === 200) {
        history.goBack();
      }
    } catch (error) {
      //* backend returns 400 bad request when you delete a project *//
      // this is a temporary fix to handle that
      // @ts-expect-error: TODO: fix the typescript error properly
      if (error?.response && error?.response.status === 400) {
        history.push('/projects');
      }
    }
    return 'done';
  };
}

export function createNewProject(params: CreateProject) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ProjectService();
      const response = await req.createProject(params);
      if (response.status === 201) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Project Created!',
            type: 'success',
          }),
        );
        dispatch(resetProjectsState());
      }
    } catch (e) {
      const randomId = generateUniqueId();
      // @ts-expect-error: TODO: fix the typescript error properly
      const message = e.response?.data?.error?.exceptionMessage;
      dispatch(
        showNotification({
          id: randomId,
          showNotification: false,
          header: 'Failed',
          body: message,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}

export function updateExistingProject(params: Project) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      const req = new ProjectService();
      const response = await req.updateProject(params);
      if (response.status === 200) {
        const randomId = generateUniqueId();
        dispatch(
          showNotification({
            id: randomId,
            showNotification: true,
            header: 'Success',
            body: 'Project updated',
            type: 'success',
          }),
        );
        //only reset state, projects page will fetch new projects if required.
        dispatch(resetProjectsState());
      }
    } catch (e) {
      const randomId = generateUniqueId();
      // @ts-expect-error: TODO: fix the typescript error properly
      const message = e.response?.data?.error?.exceptionMessage;
      dispatch(
        showNotification({
          id: randomId,
          showNotification: true,
          header: 'Failed',
          body: message,
          type: 'error',
        }),
      );
    }
    return 'done';
  };
}
