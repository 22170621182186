import { CategoriesEntity, TemplateConflicts, TemplatePayload } from 'model/manageTemplates/manageTemplates';
import React from 'react';
import { ReactComponent as RulesIcon } from 'assets/images/icon-c-rules.svg';
import { ReactComponent as PushesIcon } from 'assets/images/icon-c-pushes.svg';
import { ReactComponent as ContentIcon } from 'assets/images/icon-c-content.svg';
import './ConflictsList.scss';
import { getIcon } from '@flybits/design-system';

interface ListItemProps {
  title: string;
  icon?: string | null;
  category: string;
  selected?: boolean;
  onClickHandler: (arg0: string) => void;
}
const ItemIcon = ({ icon }: { icon: string }) => {
  if (icon) {
    switch (icon) {
      case 'rule_template':
        return <RulesIcon />;
      case 'push_template':
        return <PushesIcon />;
      case 'webhook_template':
        return getIcon('nodeConnect', {});
      case 'content_template':
        return <ContentIcon />;
      default:
        return null;
    }
  }
  return null;
};
export const ListItem = (props: ListItemProps) => {
  const { title, category, selected, icon, onClickHandler } = props;

  if (title === 'All') return null;
  return (
    <li
      className={`conflicts-filter__list-item ${selected ? 'active' : ''}`}
      onClick={() => {
        onClickHandler(category);
      }}
    >
      {icon && (
        <span className={`conflicts-filter__list-item-icon`}>
          <ItemIcon icon={icon} />
        </span>
      )}
      {title}
    </li>
  );
};

interface ListViewProps {
  title: string | null;
  filter: string | null;
  type?: string;
  categories: CategoriesEntity[] | undefined;
  library: TemplatePayload[];
  conflicts: TemplateConflicts;
  handleSelectFilter: (arg0: string) => void;
}
export const ListView = (props: ListViewProps) => {
  const { title, filter, type, categories, library, conflicts, handleSelectFilter } = props;

  const categoriesWithConflicts: string[] = [];
  conflicts?.templateConflicts?.forEach(conflict => {
    const libItem = library.find(lib => lib.id === conflict.id);
    const libItemCat = libItem?.categories?.find(c => c.startsWith('category-'))?.substring(9);
    if (!libItem || !libItemCat) return;
    if (!categoriesWithConflicts.includes(libItemCat)) {
      categoriesWithConflicts.push(libItemCat);
    }
  });

  return (
    <div className="conflicts-filter__list">
      {title && <h4 className="conflicts-filter__list-title">{title}</h4>}
      <ul className="conflicts-filter__list-wrapper">
        <ListItem
          key={'all'}
          title={'View all'}
          category={'all'}
          selected={!filter}
          onClickHandler={() => handleSelectFilter('')}
        />
        {categories
          ?.filter(c => {
            return type === 'components' ? true : categoriesWithConflicts.includes(c.key);
          })
          .map(({ name, key }) => (
            <ListItem
              key={key}
              title={name}
              icon={key}
              category={key}
              selected={filter === key}
              onClickHandler={() => handleSelectFilter(key)}
            />
          ))}
      </ul>
    </div>
  );
};
