export const SORT_VALUE_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
};

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export function sortArrayOfObjects<T extends object, U extends keyof T>(
  originalArray: Array<T>,
  key: U,
  sortByType: string,
  sortOrder: string = SORT_ORDER.ASC,
): Array<T> {
  switch (sortByType) {
    case SORT_VALUE_TYPES.NUMBER:
      if (sortOrder === SORT_ORDER.DESC) {
        return originalArray.sort((value1: T, value2: T) => Number(value2[key]) - Number(value1[key]));
      } else {
        return originalArray.sort((value1: T, value2: T) => Number(value1[key]) - Number(value2[key]));
      }
    case SORT_VALUE_TYPES.STRING:
      return originalArray.sort();
    default:
      return originalArray.sort();
  }
}
