import React, { useState } from 'react';
import { FlightButton, getIcon } from '@flybits/design-system';
import { ListView } from './ConflictsList';

import './ConflictsBase.scss';
import {
  TemplateCategories,
  TemplateConflicts,
  TemplateExportedContent,
  TemplateImportContent,
  TemplatePayload,
} from 'model/manageTemplates/manageTemplates';
import ConflictsTemplateResolution from './ConflictsTemplateResolution';
interface ConflictsBaseProps {
  conflicts: TemplateConflicts;
  categories: TemplateCategories;
  templateLibrary: TemplatePayload[];
  importedTemplates: TemplateExportedContent;
  templatesPayload: TemplateImportContent;
}

export default function ConflictsBaseJourney(props: ConflictsBaseProps) {
  const { importedTemplates, categories, conflicts, templateLibrary, templatesPayload } = props;
  const [currentListFilter, setCurrentListFilter] = useState('');
  return (
    <div className="resolve-conflicts-base">
      <div className="resolve-conflicts-base__top">
        <div className="resolve-conflicts-base__top-conflicts">
          Conflicts{' '}
          {conflicts?.templateConflicts.length > 0 && (
            <FlightButton
              onClick={() => null}
              label="Experience Templates"
              size={'small'}
              iconLeft={'error'}
              theme="secondary"
            />
          )}
          {conflicts?.componentConflicts.length > 0 && (
            <FlightButton
              onClick={() => null}
              label="Component Templates"
              size={'small'}
              iconLeft={'error'}
              theme="secondary"
            />
          )}
        </div>
        <div className="resolve-conflicts-base__top-info">
          <div>
            Which <strong>Experience Templates</strong> would you like to keep?
          </div>
          <div className="info">
            If you select both versions, the copied file&apos;s name will have a number added to it{' '}
            {getIcon('infoFilled', {})}
          </div>
        </div>
      </div>
      <div className="resolve-conflicts-base__bottom">
        <ListView
          title={'Template categories'}
          filter={currentListFilter}
          handleSelectFilter={setCurrentListFilter}
          categories={categories?.settings?.categories || []}
          library={templateLibrary}
          conflicts={conflicts}
        />

        <div className="resolve-conflicts-base__content">
          <ConflictsTemplateResolution
            library={templateLibrary}
            filter={currentListFilter}
            templatesJSON={importedTemplates}
            templatesPayload={templatesPayload}
            conflicts={conflicts}
            categories={categories?.settings?.categories || []}
          />
        </div>
      </div>
    </div>
  );
}
