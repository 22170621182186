import {
  ApnsSettings,
  ApnsTokenSettings,
  UploadApnsSetting,
  FcmSettings,
  HuaweiSettings,
  FcmV1Settings,
  NotificationType,
  SilentPushSettings,
  UserSubscription,
  PushLimitSettings,
} from './settings';

export interface PushSettings {
  apnsSettings: ApnsSettings[];
  apnsTokenSettings: ApnsTokenSettings;
  uploadApnsSetting: UploadApnsSetting;
  fcmSettings: FcmSettings;
  fcmV1Settings: FcmV1Settings;
  huaweiSettings: HuaweiSettings;
  notificationTypes: NotificationType[];
  notificationType?: NotificationType;
  silentPushSettings: SilentPushSettings;
  userSubscriptions: UserSubscription[];
  pushLimitSettngs: PushLimitSettings;
}

export enum PushSettingsActionTypes {
  SET_APNS_SETTINGS = 'SET_APNS_SETTINGS',
  SET_APNS_TOKEN_SETTINGS = 'SET_APNS_TOKEN_SETTINGS',
  SET_UPLOAD_APNS_SETTING = 'SET_UPLOAD_APNS_SETTING',
  SET_FCM_SETTINGS = 'SET_FCM_SETTINGS',
  SET_FCM_V1_SETTINGS = 'SET_FCM_V1_SETTINGS',
  SET_HUAWEI_SETTINGS = 'SET_HUAWEI_SETTINGS',
  SET_NOTIFICATION_TYPES_STATE = 'SET_NOTIFICATION_TYPES_STATE',
  SET_NOTIFICATION_TYPE_STATE = 'SET_NOTIFICATION_TYPE_STATE',
  SET_SILENT_PUSH_SETTINGS = 'SET_SILENT_PUSH_SETTINGS',
  SET_USER_SUBSCRIPTIONS_STATE = 'SET_USER_SUBSCRIPTIONS_STATE',
  SET_PUSH_LIMIT_STATE = 'SET_PUSH_LIMIT_STATE',
}

interface PushSettingsActionCreatorTypes<T, P> {
  type: T;
  payload?: P;
}

export type PushSettingsAction =
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_APNS_SETTINGS, ApnsSettings>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_APNS_TOKEN_SETTINGS, ApnsTokenSettings>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_UPLOAD_APNS_SETTING, UploadApnsSetting>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_FCM_SETTINGS, FcmSettings>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_FCM_V1_SETTINGS, FcmV1Settings>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_HUAWEI_SETTINGS, HuaweiSettings>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_NOTIFICATION_TYPES_STATE, NotificationType>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_NOTIFICATION_TYPE_STATE, NotificationType>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_SILENT_PUSH_SETTINGS, SilentPushSettings>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_USER_SUBSCRIPTIONS_STATE, UserSubscription>
  | PushSettingsActionCreatorTypes<typeof PushSettingsActionTypes.SET_PUSH_LIMIT_STATE, PushLimitSettings>;
