export interface ApiUsage {
  subscriptions: Subscription[];
  ratelimits: Ratelimits;
}

export interface Subscription {
  id?: string;
  percentage: number;
  emails: Array<string>;
}

export interface GetRatelimits {
  id?: string;
  includeCatalogueUsages: boolean;
}

interface Tenant {
  tenantID: string;
}

export interface Rate {
  tenant: Tenant;
  catalogueID: string;
  usage: string;
}

export interface Ratelimits {
  usageLimit: string;
  resetUsageAt: string;
  CatalogueUsages: Array<Rate>;
}

export enum ApiUsageActionTypes {
  SET_SUBSCRIPTIONS_STATE = 'SET_SUBSCRIPTIONS_STATE',
  SET_RATELIMITS_STATE = 'SET_RATELIMITS_STATE',
}

interface ApiUsageActionCreatorType<T, P> {
  type: T;
  payload?: P;
}

export type ApiUsageAction =
  | ApiUsageActionCreatorType<typeof ApiUsageActionTypes.SET_SUBSCRIPTIONS_STATE, Subscription>
  | ApiUsageActionCreatorType<typeof ApiUsageActionTypes.SET_RATELIMITS_STATE, Ratelimits>;
