import BaseService from './base.service';
import { ObjectForApi } from 'model/language';
import { ObjectForSilentPushApi } from 'model/pushSettings/settings';
import { SettingsAPIPayload } from 'model/analyticsExport';
import { ConciergeCategoriesAPIPayload } from 'model/conciergeCategories';

export default class SettingsService extends BaseService {
  CURRENT_PATH: string;
  SECONDARY_RESOURCE: string;
  LANGAUGE_RESOURCE: string;
  PUSH_RESOURCE: string;
  SILENT_PUSH_RESOURCE: string;
  FACEBOOK_SETTINGS_RESOURCE: string;
  GOOGLE_SETTINGS_RESOURCE: string;
  OPENID_SETTINGS_RESOURCE: string;
  OKTA_SETTINGS_RESOURCE: string;
  APIKEY_SETTINGS_RESOURCE: string;
  TENANT_ACCESS_RESOURCE: string;
  SIGNED_LOGIN_RESOURCE: string;
  SIGNED_LOGIN_SECONDARY_RESOURCE: string;
  PROJECT_CUSTOM_CONTENT_TEMPLATES_RESOURCE: string;
  ANALYTICS_EXPORT_WHITELISTED_DOMAINS_RESOURCE: string;
  CONCIERGE_CATEGORIES_RESOURCE: string;

  constructor() {
    super();
    this.CURRENT_PATH = 'sso/settings/';
    this.SECONDARY_RESOURCE = 'configs';
    this.LANGAUGE_RESOURCE = 'languages';
    this.PUSH_RESOURCE = 'push-settings';
    this.SILENT_PUSH_RESOURCE = 'entity-event-silent-push';
    this.FACEBOOK_SETTINGS_RESOURCE = 'facebook';
    this.GOOGLE_SETTINGS_RESOURCE = 'gplus';
    this.OPENID_SETTINGS_RESOURCE = 'oidc';
    this.OKTA_SETTINGS_RESOURCE = 'okta';
    this.APIKEY_SETTINGS_RESOURCE = 'apikey';
    this.TENANT_ACCESS_RESOURCE = 'tenant-access';
    this.SIGNED_LOGIN_RESOURCE = 'singedLogin/';
    this.SIGNED_LOGIN_SECONDARY_RESOURCE = 'publicKey?json=true';
    this.PROJECT_CUSTOM_CONTENT_TEMPLATES_RESOURCE = 'project-custom-content-templates';
    this.ANALYTICS_EXPORT_WHITELISTED_DOMAINS_RESOURCE = 'analyticsExportEmailDomainWhitelist';
    this.CONCIERGE_CATEGORIES_RESOURCE = 'sdk-categories';
  }

  getLanguages() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.LANGAUGE_RESOURCE}`);
  }

  getUserSettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.PUSH_RESOURCE}`);
  }

  getSilentPushSettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.SILENT_PUSH_RESOURCE}`);
  }

  createSilentPushSetting(data: ObjectForSilentPushApi) {
    return this.request('POST', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}`, data);
  }

  getFacebookSettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.FACEBOOK_SETTINGS_RESOURCE}`);
  }

  getGoogleSettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.GOOGLE_SETTINGS_RESOURCE}`);
  }

  getOpenIDSettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.OPENID_SETTINGS_RESOURCE}`);
  }

  getOktaSettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.OKTA_SETTINGS_RESOURCE}`);
  }

  getApiKeySettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.APIKEY_SETTINGS_RESOURCE}`);
  }

  getTenantAccessSettings() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.TENANT_ACCESS_RESOURCE}`);
  }

  getProjectCustomContentTemplates() {
    return this.request(
      'GET',
      `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.PROJECT_CUSTOM_CONTENT_TEMPLATES_RESOURCE}`,
    );
  }

  createOrUpdateSetting(data: ObjectForApi) {
    return this.request('POST', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}`, data);
  }

  getAnalyticsExportWhitelistedDomains() {
    return this.request(
      'GET',
      `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.ANALYTICS_EXPORT_WHITELISTED_DOMAINS_RESOURCE}`,
    );
  }

  updateAnalyticsExportWhitelistedDomains(payload: SettingsAPIPayload) {
    return this.request('POST', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}`, payload);
  }

  getConciergeCategories() {
    return this.request('GET', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}/${this.CONCIERGE_CATEGORIES_RESOURCE}`);
  }

  updateConciergeCategories(payload: ConciergeCategoriesAPIPayload) {
    return this.request('POST', `${this.CURRENT_PATH}${this.SECONDARY_RESOURCE}`, payload);
  }
}
