import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { FlightButton, FlightModal, FlightTextInput, FlightCheckbox, FlightTable } from '@flybits/design-system';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { cloneDeep, differenceBy, isEqual } from 'lodash';
import { useActions } from 'actions';
import * as ApnsActions from 'actions/pushSettings/apnsSettings';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as TrashBinIcon } from 'assets/images/trash_bin.svg';
import { ReactComponent as Upload } from 'assets/images/upload.svg';
import { PASSWORD_REQUIRED } from 'constants/errors/errors';
import { ApnsSettings, UploadApnsSetting } from 'model/pushSettings/settings';
import './ApnsSettingsModal.scss';

interface Props {
  showModal: boolean;
  apnsSettingsFromState: ApnsSettings[];
  uploadApnsSettingFromState?: UploadApnsSetting;
  closeModal: () => void;
  handleUploadCertificate: () => void;
}

export default function ApnsSettingsModal(props: Props) {
  const { showModal, uploadApnsSettingFromState, apnsSettingsFromState, closeModal, handleUploadCertificate } = props;
  const [showManageState, setShowManageState] = useState(apnsSettingsFromState.length > 0);
  const [productionCheckState, setProductionCheckState] = useState('UNSELECTED');
  const [activeState, setActiveState] = useState('UNSELECTED');
  const [localApnsSettings, setLocalApnsSettings] = useState(cloneDeep(apnsSettingsFromState));
  const apnsActions = useActions(ApnsActions);

  const uploadName: string = uploadApnsSettingFromState?.name || '';
  const uploadFileID: string = uploadApnsSettingFromState?.fileID || '';
  const isUploaded: boolean = uploadApnsSettingFromState?.isUploaded || false;

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(PASSWORD_REQUIRED),
  });

  function handleRemoveUpload() {
    apnsActions.setUploadApnsSetting(null);
  }

  function handleCloseModal() {
    handleRemoveUpload();
    setShowManageState(apnsSettingsFromState.length > 0);
    setLocalApnsSettings(cloneDeep(apnsSettingsFromState));
    closeModal();
  }

  function handleLocalChangeStatus(fileID: string) {
    const updatedLocalApnsSettings: ApnsSettings[] = localApnsSettings.map(setting => {
      return {
        ...setting,
        isEnabled: setting.fileID === fileID && !setting.isEnabled,
        isTouched: setting.isTouched || setting.isEnabled || setting.fileID === fileID,
      };
    });
    setLocalApnsSettings(updatedLocalApnsSettings);
  }

  function handleLocalDeleteApnsSetting(fileID: string) {
    const updatedLocalApnsSettings: ApnsSettings[] = localApnsSettings.filter(setting => setting.fileID !== fileID);
    setLocalApnsSettings(updatedLocalApnsSettings);
  }

  async function createNewCertificate(newApnsSetting: ApnsSettings) {
    const settingsToUpdate: ApnsSettings[] = localApnsSettings.filter(setting => setting.isTouched);
    for (const setting of settingsToUpdate) {
      await apnsActions.patchApnsSettings(setting);
    }

    await apnsActions.postApnsSettings(newApnsSetting);

    await apnsActions.fetchApnsSettings();
    setProductionCheckState('UNSELECTED');
    setActiveState('UNSELECTED');
  }

  async function updateActiveCertificate() {
    // First we need to adjust the status of the old and/or new active APNS setting.
    // The API expects the status of the currently active setting to be reset before
    // making another setting active.
    const oldActiveCertificate = apnsSettingsFromState.find(setting => setting.isEnabled);
    const newActiveCertificate = localApnsSettings.find(setting => setting.isEnabled);
    const updatedOldActiveCertificate = { ...oldActiveCertificate, isEnabled: false };
    const updatedNewActiveCertificate = { ...newActiveCertificate, isEnabled: true };

    if (oldActiveCertificate && newActiveCertificate && !isEqual(oldActiveCertificate, newActiveCertificate)) {
      await apnsActions.patchApnsSettings(updatedNewActiveCertificate);
    } else if (!oldActiveCertificate && newActiveCertificate) {
      // Just set new.isEnabled = true
      await apnsActions.patchApnsSettings(updatedNewActiveCertificate);
    } else if (oldActiveCertificate && !newActiveCertificate) {
      // Just set old.isEnabled = false
      await apnsActions.patchApnsSettings(updatedOldActiveCertificate);
    }
  }

  async function updateDeleteCertificates() {
    const settingsToDelete: ApnsSettings[] = differenceBy(apnsSettingsFromState, localApnsSettings, 'fileID');

    for (const setting of settingsToDelete) {
      await apnsActions.deleteApnsSetting(setting.fileID);
    }
  }

  async function handleAddNewCertificate() {
    await updateActiveCertificate();
    await updateDeleteCertificates();
    handleRemoveUpload();
    setShowManageState(false);
  }

  async function handleApplyChanges() {
    await updateActiveCertificate();
    await updateDeleteCertificates();
    await apnsActions.fetchApnsSettings();
    handleCloseModal();
  }

  useEffect(() => {
    setLocalApnsSettings(cloneDeep(apnsSettingsFromState));
    //eslint-disable-next-line
  }, [apnsSettingsFromState]);

  const tableHeaders = [
    {
      name: '',
      key: 'certificate',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'status',
      isVisible: true,
      hideTooltip: true,
    },
    {
      name: '',
      key: 'delete',
      isVisible: true,
      hideTooltip: true,
    },
  ];

  const tableBody = localApnsSettings.map(setting => {
    return {
      key: setting.fileID,
      certificate: (
        <div>
          <div className="apns-settings-modal__table__certificate-name">{setting.name}</div>
          <div className="apns-settings-modal__table__environment">{setting.isProduction ? 'Production' : 'Test'}</div>
        </div>
      ),
      status: (
        <span
          className={`apns-settings-modal__table__status${setting.isEnabled ? ' active' : ''}`}
          onClick={() => {
            handleLocalChangeStatus(setting.fileID);
          }}
        >
          {setting.isEnabled ? 'Active' : 'Inactive'}
        </span>
      ),
      delete: !setting.isEnabled ? (
        <TrashBinIcon
          className="apns-settings-modal__table__delete-icon"
          onClick={() => {
            handleLocalDeleteApnsSetting(setting.fileID);
          }}
        />
      ) : null,
    };
  });

  const manageSettingsContent =
    localApnsSettings.length > 0 ? (
      <div>
        <span className="apns-settings-modal__manage-text">Only 1 certificate may be active at a time.</span>
        <FlightTable
          className="apns-settings-modal__table"
          tableHeaders={tableHeaders}
          tableData={tableBody}
          isShowHeader={false}
          hasPaginationBeforeTable={false}
          ariaLabel="Apns settings modal Table"
        />
      </div>
    ) : (
      <div className="apns-settings-modal__manage-text">No certificates available.</div>
    );

  // Formik Content for Adding New APNS Certificate
  const addNewSettingContent = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: { [field: string]: any },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleChange: (e: React.ChangeEvent<any>) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleBlur: (e: any) => void,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
    isValid: boolean,
  ) => (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          {uploadApnsSettingFromState ? (
            <div className="apns-settings-modal__certificate-container">
              <span className="apns-settings-modal__certificate-title">Certificate</span>
              <div className="apns-settings-modal__certificate">
                <div>
                  {isUploaded && <CheckIcon className="apns-settings-modal__check" />}
                  <span className="apns-settings-modal__certificate-name">{uploadName}</span>
                </div>
                <FlightButton
                  className="apns-settings-modal__certificate-btn"
                  theme="link"
                  label="Remove"
                  onClick={handleRemoveUpload}
                />
              </div>
            </div>
          ) : (
            <div className="dropzone" onClick={handleUploadCertificate}>
              <div className="dropzone__content">
                <Upload />
                <span className="dropzone__text">Upload certificate</span>
              </div>
            </div>
          )}
          <span className="apns-settings-modal__certificate-req">Only .p12 files are accepted</span>
        </FormGroup>
        <FormGroup row>
          <Field
            type="text"
            placeholderText="Type in your password"
            name="password"
            id="password"
            label="Type in your password"
            as={FlightTextInput}
            width="385px"
            hasError={isValid ? false : true}
            errorMessage={errors.password}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup row>
          <Field
            type="checkbox"
            name="isProduction"
            id="isProduction"
            label="This certificate is for a production environment"
            placeholder="isProduction"
            as={FlightCheckbox}
            checkState={productionCheckState}
            onChange={handleChange}
            onBlur={handleBlur}
            onSelect={() => {
              const isProduction = !values.isProduction;
              values.isProduction = isProduction;
              isProduction ? setProductionCheckState('SELECTED') : setProductionCheckState('UNSELECTED');
            }}
          />
        </FormGroup>
        <FormGroup row>
          <Field
            type="checkbox"
            name="isActive"
            id="isActive"
            label="Activate this certificate"
            placeholder="isActive"
            as={FlightCheckbox}
            checkState={activeState}
            onSelect={() => {
              const isEnabled = !values.isEnabled;
              values.isEnabled = isEnabled;
              isEnabled ? setActiveState('SELECTED') : setActiveState('UNSELECTED');
            }}
          />
        </FormGroup>
      </Form>
    </div>
  );

  const header = showManageState ? 'APNS Settings' : 'New APNS Certificate';
  const footer = (handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void, isValid: boolean) =>
    showManageState ? (
      <div className="modal-footer">
        <FlightButton theme="secondary" label="Cancel" onClick={handleCloseModal} />
        <FlightButton theme="secondary" label="Add a new certificate" onClick={handleAddNewCertificate} />
        <FlightButton type="primary" label="Apply Changes" onClick={handleApplyChanges} />
      </div>
    ) : (
      <div className="modal-footer">
        <FlightButton theme="secondary" label="Cancel" onClick={handleCloseModal} />
        <FlightButton type="primary" label="Add" disabled={uploadFileID === '' || !isValid} onClick={handleSubmit} />
      </div>
    );

  return (
    <div className="apns-settings-modal">
      <Formik
        enableReinitialize
        initialValues={{ password: '', isProduction: false, isEnabled: false }}
        isInitialValid={false}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (uploadFileID !== '') {
            const newApnsSetting: ApnsSettings = {
              fileID: uploadFileID,
              name: uploadName,
              password: values.password,
              isProduction: productionCheckState === 'SELECTED',
              isEnabled: activeState === 'SELECTED',
              isTouched: true,
            };
            setLocalApnsSettings([...localApnsSettings, newApnsSetting]);
            setSubmitting(true);
            apnsActions.setUploadApnsSetting(null);
            resetForm();
            await createNewCertificate(newApnsSetting);
            setShowManageState(true);
          }
        }}
      >
        {({ errors, values, handleChange, handleBlur, handleSubmit, isValid }) => (
          <FlightModal
            isVisible={showModal}
            className={showManageState ? 'apns-settings-modal__manage-modal' : 'apns-settings-modal__modal'}
            toggleModalShown={handleCloseModal}
            header={header}
            content={
              showManageState
                ? manageSettingsContent
                : addNewSettingContent(errors, values, handleChange, handleBlur, handleSubmit, isValid)
            }
            footer={footer(handleSubmit, isValid)}
          />
        )}
      </Formik>
    </div>
  );
}
