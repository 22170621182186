import { LanguageActionTypes, Language, ObjectForApi } from 'model/language';
import SettingsService from 'services/settings.service';
import { makeActionCreator } from 'helpers/actionCreator';
import { API_DEFAULT_ERROR_RESPONSE_TYPE } from 'constants/errors/errors';

export const setLanguages = makeActionCreator(LanguageActionTypes.SET_LANGUAGES_STATE);

export function fetchAllLanguages() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: { (arg0: any): void }) => {
    try {
      const req = new SettingsService();
      const response = await req.getLanguages();
      dispatch(setLanguages(response.data.settings.data));
    } catch (error) {
      // @ts-expect-error: TODO: fix the typescript error properly
      if (error?.response?.status === 404) {
        // @ts-expect-error: TODO: fix the typescript error properly
        const errorObjectFromResponse = error.response.data.error.exceptionType;

        if (errorObjectFromResponse === API_DEFAULT_ERROR_RESPONSE_TYPE) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(addDefaultLanguage());
        }
      }
    }

    return 'done';
  };
}

export function addOrUpdateLanguage(languages: Language[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: '{"data":' + JSON.stringify(languages) + '}',
        configName: {
          configName: 'languages',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        dispatch(fetchAllLanguages());
      }
    } catch (error) {}
    return 'done';
  };
}

export function addDefaultLanguage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: (arg0: any) => void) => {
    try {
      const defaultLanguage: Language[] = [{ id: 'en', isDefault: true, name: 'English', direction: 'ltr' }];
      const req = new SettingsService();
      const objectForApi: ObjectForApi = {
        config: '{"data":' + JSON.stringify(defaultLanguage) + '}',
        configName: {
          configName: 'languages',
        },
      };
      const response = await req.createOrUpdateSetting(objectForApi);
      if (response.status === 200) {
        dispatch(fetchAllLanguages());
      }
    } catch (error) {}
    return 'done';
  };
}
