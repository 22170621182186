import {
  SnackBarNotificationAction,
  SnackBarNotification,
  SnackBarNotificationActionTypes,
} from 'model/snackBarNotification';
import createReducer from './createReducer';

const initialState = {} as SnackBarNotification;
export const snackBarNotifications = createReducer<SnackBarNotification>(initialState, {
  [SnackBarNotificationActionTypes.SHOW_NOTIFICATION](state: SnackBarNotification, action: SnackBarNotificationAction) {
    return {
      ...state,
      ...action.payload,
    };
  },

  [SnackBarNotificationActionTypes.HIDE_NOTIFICATION](state: SnackBarNotification) {
    return {
      ...state,
      showNotification: false,
    };
  },
});
