import React, { useEffect, useState } from 'react';
import { FlightButton, FlightOverflowMenu, FlightSelect, FlightTable } from '@flybits/design-system';
import { useActions } from 'actions';
import * as ContextPluginActions from 'actions/contextPlugins';
import { ContextPlugin, ContextPluginData, ContextPluginsDownload } from 'model/contextPlugins';

import './ContextAttributesTable.scss';
import { cloneDeep, isEmpty, isEqual, size } from 'lodash';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import { contextCategoryStatusOptions } from 'constants/contextAttributesOptions';
import { FlightSelectOptions } from 'model/misc/flightSelectOptions';
import { searchWithRegExp } from 'helpers/searchWithRegExp';
import ConfirmModal from 'components/Shared/ConfirmModal/ConfirmModal';
import { downloadAsJson } from 'helpers/downloadAsJson';

interface Props {
  contextPlugins: ContextPlugin[];
  onClickCategoryName: (plugin: ContextPlugin) => void;
  onClickViewAttributes: (plugin: ContextPlugin) => void;
  hasNewCategory: boolean;
}

interface TableData {
  key: string | undefined;
  name?: JSX.Element;
  createdBy?: string;
  status?: JSX.Element;
  attributes?: JSX.Element;
  moreOpts?: JSX.Element;
  values?: ContextPlugin;
  metadata?: {
    isMultiSelectDisabled?: boolean;
  };
}

enum ConfirmModalState {
  HIDDEN = 'HIDDEN',
  STATUS = 'STATUS',
  DELETE = 'DELETE',
}

const tableHeaders = [
  {
    name: 'Name',
    key: 'name',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Created by',
    key: 'createdBy',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Status',
    key: 'status',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Attributes',
    key: 'attributes',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Options',
    key: 'moreOpts',
    isVisible: true,
    hideTooltip: true,
  },
];

export default function ContextCategoriesTable(props: Props) {
  const { contextPlugins, onClickCategoryName, onClickViewAttributes, hasNewCategory } = props;
  const contextPluginActions = useActions(ContextPluginActions);
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(ConfirmModalState.HIDDEN);
  const [selectedPlugin, setSelectedPlugin] = useState<ContextPlugin>();
  const [statusFilter, setStatusFilter] = useState<FlightSelectOptions>({ key: 'default', name: 'Any status' });
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState<TableData[]>([]);
  const confirmModalTitle = 'Confirm delete';
  const confirmModalMessage = 'Are you sure you want to delete this category?';
  const [tableSelectedData, setTableSelectedData] = useState(['']);
  const [multiSelectPlugin, setMultiSelectPlugin] = useState<ContextPlugin[]>([]);

  const handleTableDataSelect = (item: TableData) => {
    // dont select reserved plugins
    if (item?.values) {
      if (item?.values?.isReserved) return;
      if (item.key) {
        if (tableSelectedData?.includes(item.key)) {
          const removeEl = tableSelectedData.filter(el => el !== item.key);
          const removedPlugins = multiSelectPlugin.filter(el => el.name !== item?.values?.name);
          setTableSelectedData(removeEl);
          setMultiSelectPlugin(removedPlugins);
        } else {
          const selectedPlugins = contextPlugins.filter(el => el.name === item?.values?.name);
          setTableSelectedData([...tableSelectedData, item.key]);
          setMultiSelectPlugin([...multiSelectPlugin, ...selectedPlugins]);
        }
      }
    }
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleTableSelectAll = (item: any) => {
    // we assume that select all was already selected, hence we de-select
    if (!isEmpty(multiSelectPlugin)) {
      setTableSelectedData(['']);
      setMultiSelectPlugin([]);
    } else {
      const selectedPlugins = contextPlugins.filter(item => item.isReserved === false);
      const array: string[] = Array?.from(item);
      setMultiSelectPlugin(selectedPlugins);
      // dont select reserved plugins
      return setTableSelectedData(array.splice(0, selectedPlugins.length));
    }
  };

  const tableDataKey = new Set(tableSelectedData);
  const handleExportCategory = (categories: ContextPlugin[]) => {
    const pluginData: ContextPluginData[] = [];
    categories.forEach(plugin => {
      pluginData.push({
        id: plugin.uid || '',
        attributes: {
          uniqueId: plugin.uid,
          tenantId: plugin.tenantId,
          isReserved: plugin.isReserved,
          isEnabled: plugin.tenantConfig.isEnabled,
          refreshRate: plugin.refreshRate,
          supportedPlatforms: plugin.supportedPlatforms,
          provider: plugin.provider,
          category: plugin.category,
          userScope: plugin.userScope,
          isSaRestricted: plugin.isSaRestricted,
          name: plugin.name,
          description: plugin.description,
          iconUrl: plugin.iconUrl,
          values: plugin.values,
          tenantConfigId: plugin.tenantConfig.id,
        },
        type: 'context-plugin',
      });
    });

    const downloadData: ContextPluginsDownload = {
      data: pluginData,
      meta: {
        downloadedAt: Date.now(),
        totalRecords: categories?.length,
      },
    };
    downloadAsJson(Date.now().toString(), JSON.stringify(downloadData), 'context-plugins');
  };

  const handleChangeStatus = (plugin: ContextPlugin) => {
    if (plugin.tenantConfig.isEnabled) {
      contextPluginActions.disableContextPluginStatus(plugin);
    } else {
      contextPluginActions.enableContextPluginStatus(plugin);
    }
  };

  const handleDeleteConfirmation = () => {
    if (selectedPlugin) {
      contextPluginActions.deleteContextPlugin(selectedPlugin.uid);
    }
    setConfirmModalState(ConfirmModalState.HIDDEN);
    setSelectedPlugin(undefined);
    setTableSelectedData(['']);
    setMultiSelectPlugin([]);
  };

  const handleMultiSelectExport = () => {
    handleExportCategory(multiSelectPlugin);
  };

  const createOptionGroups = (plugin: ContextPlugin) => {
    return [
      [
        {
          key: `opt1`,
          name: `${plugin.tenantConfig.isEnabled ? 'Deactivate' : 'Activate'}`,
          onClick: () => {
            setSelectedPlugin(plugin);
            handleChangeStatus(plugin);
          },
        },
        {
          key: `opt2`,
          name: 'Delete',
          disabled: plugin.isReserved,
          onClick: () => {
            setSelectedPlugin(plugin);
            setConfirmModalState(ConfirmModalState.DELETE);
          },
        },
        {
          key: `opt3`,
          name: plugin.isReserved || plugin.dataSource ? 'View' : 'Edit',
          onClick: () => onClickCategoryName(plugin),
        },
        {
          key: `opt4`,
          name: 'Export',
          disabled: plugin.isReserved,
          onClick: () => {
            handleExportCategory([plugin]);
          },
        },
      ],
    ];
  };

  useEffect(() => {
    const _tableData: TableData[] = [];
    let ctxPlugins: ContextPlugin[] = [];

    if (isEmpty(searchTerm)) {
      ctxPlugins = cloneDeep(contextPlugins);
    } else {
      ctxPlugins = cloneDeep(searchWithRegExp(contextPlugins, searchTerm));
    }
    ctxPlugins
      ?.sort((a, b) => (a.isReserved > b.isReserved ? 1 : -1))
      ?.map((ctxPlugin, index) => {
        const status = ctxPlugin.tenantConfig.isEnabled;
        const filterOrNot = () => {
          if (statusFilter.key === 'default') {
            return true;
          }
          if ((statusFilter.key === 'active' && status) || (statusFilter.key === 'inActive' && !status)) {
            return true;
          }
        };
        if (filterOrNot()) {
          _tableData.push({
            key: index?.toString(),
            name: (
              <>
                <a href="# " onClick={() => onClickCategoryName(ctxPlugin)} className="context-attributes__table__name">
                  {ctxPlugin.name}
                </a>
                <div>{ctxPlugin.uid}</div>
              </>
            ),
            createdBy: ctxPlugin.isReserved ? 'Flybits' : 'User',
            status: (
              <div className="context-attributes__table__status">
                <div
                  className={
                    status
                      ? 'context-attributes__table__status__indicator context-attributes__table__status__indicator--active'
                      : 'context-attributes__table__status__indicator context-attributes__table__status__indicator--inactive'
                  }
                />
                <div
                  className={
                    status
                      ? 'context-attributes__table__status__text context-attributes__table__status__text--active'
                      : 'context-attributes__table__status__text context-attributes__table__status__text--inactive'
                  }
                >
                  {status ? 'Active' : 'Inactive'}
                </div>
              </div>
            ),
            attributes: (
              <>
                <span className="context-attributes__table__attributes__count">{size(ctxPlugin.values)}</span>
                <a
                  href="# "
                  className="context-attributes__table__attributes__action"
                  onClick={() => onClickViewAttributes(ctxPlugin)}
                >
                  View attributes
                </a>
              </>
            ),
            moreOpts: <FlightOverflowMenu optionGroups={createOptionGroups(ctxPlugin)} direction="bottom-left" />,
            values: ctxPlugin,
            metadata: {
              isMultiSelectDisabled: ctxPlugin?.isReserved,
            },
          });
        }
      });

    if (isEmpty(_tableData) && isEmpty(searchTerm)) {
      _tableData.push({
        key: '',
        name: <span>No context categories have been added to this project.</span>,
      });
    }
    if (isEmpty(_tableData) && (!isEmpty(searchTerm) || statusFilter.key !== 'default')) {
      _tableData.push({
        key: '',
        name: <span>No results found.</span>,
      });
    }
    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
    if (hasNewCategory) {
      setTableSelectedData(['']);
      setMultiSelectPlugin([]);
    }
    //eslint-disable-next-line
  }, [contextPlugins, searchTerm, statusFilter, hasNewCategory]);

  return (
    <div className="context-attributes">
      <div className="context-attributes__filter">
        <SearchBar
          className="context-attributes__filter__search-bar"
          initialValue={searchTerm}
          handleSearchTermChange={e => setSearchTerm(e)}
          width="220px"
          label="Search"
          labelId="context-categories__filter__search-bar"
        />
        <FlightSelect
          label="Any input type"
          width="220px"
          selected={statusFilter}
          handleOptionClick={(opt: FlightSelectOptions) => setStatusFilter(opt)}
          className="context-attributes__filter__select"
          options={contextCategoryStatusOptions}
        />
        {!isEmpty(multiSelectPlugin) && (
          <FlightButton
            label={`Export ${multiSelectPlugin?.length} selected`}
            theme="primary"
            className="context-attributes__filter__select"
            onClick={handleMultiSelectExport}
          />
        )}
      </div>
      <div aria-live="assertive">
        <FlightTable
          allowMultiSelect={tableData[0]?.key !== ''}
          allowRowSelect
          handleDataSelect={handleTableDataSelect}
          handleCheckboxMultiSelect={handleTableDataSelect}
          handleSelectAll={handleTableSelectAll}
          selectedDataKey={tableDataKey}
          className="context-attributes__table"
          tableHeaders={tableHeaders}
          tableData={tableData}
          hasPaginationBeforeTable={false}
          ariaLabel="context attributes Table"
        />
      </div>
      <ConfirmModal
        isVisible={confirmModalState !== ConfirmModalState.HIDDEN}
        title={confirmModalTitle}
        message={confirmModalMessage}
        continueCallback={handleDeleteConfirmation}
        cancelCallback={() => setConfirmModalState(ConfirmModalState.HIDDEN)}
      />
    </div>
  );
}
